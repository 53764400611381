import { asTranslationKey } from './i18n-utils'

export const i18nLocales = [
  'en_US',
  'en_AU',
  'en_CA',
  'en_GB',
  'en_IE',
  'fr_CA',
  'fr_FR',
  'de_DE',
  'it_IT',
  'pt_BR',
  'es_MX',
  // es_EM (Español Moderno (Spain)) isn't yet supported by backend service
  //'es_EM',
  'es_ES',
  'ja_JP',
  'zh_CN',
  'ko_KR',
] as const

export type I18nLocale = (typeof i18nLocales)[number]

export const I18N_LOCALES = i18nLocales.slice() as readonly I18nLocale[]

export const isI18nLocale = (value: I18nLocale | unknown): value is I18nLocale =>
  !!I18N_LOCALES.slice().includes(String(value) as I18nLocale)

export const asI18nLocale = (value: unknown): I18nLocale | undefined =>
  isI18nLocale(value) ? (String(value) as I18nLocale) : undefined

export const i18nLocalesName: Record<I18nLocale, string> = {
  en_US: asTranslationKey('English (USA)'),
  en_AU: asTranslationKey('English (Australia)'),
  en_CA: asTranslationKey('English (Canada)'),
  en_GB: asTranslationKey('English (Great Britain)'),
  en_IE: asTranslationKey('English (Ireland)'),
  fr_CA: asTranslationKey('Français (Canada)'),
  fr_FR: asTranslationKey('Français (France)'),
  de_DE: asTranslationKey('Deutsch (Deutschland)'),
  it_IT: asTranslationKey('Italiano (Italia)'),
  pt_BR: asTranslationKey('Português (Brasil)'),
  es_MX: asTranslationKey('Español (México)'),
  // es_EM isn't yet supported by backend service
  //es_EM: asTranslationKey('Español Moderno (Spain)'),
  es_ES: asTranslationKey('Español (Spain)'),
  ja_JP: asTranslationKey('日本語 (Japan)'),
  zh_CN: asTranslationKey('普通话 (China)'),
  ko_KR: asTranslationKey('한국어 (Korea)'),
}
