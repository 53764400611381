import type { html } from 'lit-element'
import type { nothing } from 'lit-html'
import type { Testable } from '../../common'

export enum MenuItemType {
  NORMAL = 'text',
  SEPARATOR = 'separator',
  CHECKBOX = 'checkbox',
  CUSTOM = 'custom',
}

interface BaseMenuItem extends Testable {
  readonly type: MenuItemType
  readonly isVisible?: () => boolean
}

export interface SeparatorMenuItem extends BaseMenuItem {
  readonly type: MenuItemType.SEPARATOR
}

export interface CustomMenuItem extends BaseMenuItem {
  readonly type: MenuItemType.CUSTOM
  readonly render: () => ReturnType<typeof html> | typeof nothing
}

export interface NormalMenuItem extends BaseMenuItem {
  readonly type: MenuItemType.NORMAL
  readonly labelKey: string
  readonly href: string
  readonly executeFunction: () => void
  readonly isExternalLink?: boolean
}

export type MenuItem = SeparatorMenuItem | NormalMenuItem | CustomMenuItem
