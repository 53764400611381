import { setTimeout } from './dom-helpers'
import { getShellLogger } from './logger'
import { RateLimiter } from './rate-limiter'

/** A map-like object keeping a rate limiter per key. It is essentially a map of rate limiters with added logging when a
 * rate limit is reached. Intended usage:
 * ```ts
 * const rateLimiterMap = new RateLimiterMap('authenticatedFetch', 10, 1000) // max 10 requests per URL per 1000 ms
 *
 * const authenticatedFetch = async (url: string) => {
 *   await rateLimiterMap.waitUntilAllowed(url) // logs if actual waiting needs to be done due to the rate limit for this URL reached
 *   rateLimiterMap.recordRequest(url)
 *   // perform the authenticated fetch ...
 * }
 * ```
 */
export class RateLimiterMap {
  private readonly map = new Map<string, RateLimiter>()

  /**
   * @param name - The name of the rate limiter map. This name will be printed in the log when the rate limit is
   *   reached. (e.g. `authenticatedFetch` if you want to rate-limit the calls to `authenticatedFetch()`).
   * @param limit - The maximum number of requests allowed within the interval.
   * @param intervalMs - The interval in milliseconds.
   */
  constructor(
    private readonly name: string,
    private readonly limit: number,
    private readonly intervalMs: number,
  ) {}

  /** Waits until a new request is allowed for the given key. If the rate limit for the given key is not reached, no
   * awaiting is done (returns a resolved promise). If waiting needs to be done due to the rate limit for the given key
   * being reached, the method logs a line with the rate limier map name and the key.
   *
   * @returns a promise that will resolve when a new request for the given key is allowed. */
  waitUntilAllowed(key: string) {
    const rateLimiter = this.getOrCreateRateLimiter(key)
    const timeToWaitMs = rateLimiter.timeMsUntilAllowed()
    if (timeToWaitMs === 0) {
      return Promise.resolve()
    }

    getShellLogger().warn('Rate limit reached', { name: this.name, key, timeToWaitMs})
    return new Promise<void>(resolve => setTimeout(resolve, timeToWaitMs))
  }

  /** Informs the rate limiter that a new request was made for the given key. */
  recordRequest(key: string) {
    const rateLimiter = this.getOrCreateRateLimiter(key)
    rateLimiter.recordRequest()
  }

  private getOrCreateRateLimiter(key: string) {
    let rateLimiter = this.map.get(key)
    if (!rateLimiter) {
      this.cleanupRateLimitersIfNeeded()

      rateLimiter = new RateLimiter(this.limit, this.intervalMs)
      this.map.set(key, rateLimiter)
    }
    return rateLimiter
  }

  private cleanupRateLimitersIfNeeded() {
    // Very basic protection against growing the map too much. Not a perfect solution, but is OK for the current use.
    if (this.map.size > 200) {
      getShellLogger().log('Clearing rate limit map', { name: this.name })
      this.map.clear()
    }
  }
}
