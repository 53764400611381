import type { RTCError } from './models'

export const NETWORK_OFFLINE: RTCError = {
  errorCode: 'NETWORK_OFFLINE',
  message: 'Internet connection is offline',
}

export const NETWORK_ONLINE: RTCError = {
  errorCode: 'NETWORK_ONLINE',
  message: 'Internet connection is online',
}

export const NETWORK_NOT_RESPONDING: RTCError = {
  errorCode: 'NETWORK_NOT_RESPONDING',
  message: 'Server is not responding',
}

export const WEBSOCKET_NOT_CONNECTED: RTCError = {
  errorCode: 'WEBSOCKET_NOT_CONNECTED',
  message: 'Websocket not in connected state',
}

export const WEBSOCKET_NOT_READY: RTCError = {
  errorCode: 'WEBSOCKET_NOT_READY',
  message: 'Websocket not in ready state',
}

export const REQUEST_TIMEOUT: RTCError = {
  errorCode: 'REQUEST_TIMEOUT',
  message: 'Request has timed out',
}

export const WEBSOCKET_ERROR: RTCError = {
  errorCode: 'WEBSOCKET_ERROR',
  message: 'Websocket error event',
}

export const WEBSOCKET_CLOSE: RTCError = {
  errorCode: 'WEBSOCKET_CLOSE',
  message: 'Websocket close event',
}

export const WEBSOCKET_HOSTDOWN: RTCError = {
  errorCode: 'EHOSTDOWN',
  message: 'Too many failed connection attempts',
}

export const WEBSOCKET_TIMEDOUT: RTCError = {
  errorCode: 'ETIMEDOUT',
  message: 'Connection timeout',
}

export const SESSION_LOST: RTCError = {
  errorCode: 'SESSION_LOST',
  message: 'Session loss after network reconnection',
}

export const UNHANDLED_MESSAGE: RTCError = {
  errorCode: 'UNHANDLED_MESSAGE',
  message: 'Received response can not be matched to a request',
}

export const UNAVAILABLE: RTCError = {
  errorCode: 'UNAVAILABLE',
  message: 'Server busy, try again later',
}

export const UNAUTHORIZED: RTCError = {
  errorCode: 'UNAUTHORIZED',
  message: 'Token has expired, need to re-authenticate',
}

export const CHANNEL_NOT_CREATED: RTCError = {
  errorCode: 'CHANNEL_NOT_CREATED',
  message: 'No URL to connect the channel to.',
}

export class ChannelEvent {
  /**
   * The channel is connected and events can be sent or received.
   */
  static readonly CONNECTED = 'CHANNEL_CONNECTED'
  /**
   * The channel is in connecting, but no events can be sent or received yet.
   */
  static readonly RECONNECTED = 'CHANNEL_RECONNECTED'
  /**
   * The channel is disconnected. No events can be sent or received.
   */
  static readonly DISCONNECTED = 'CHANNEL_DISCONNECTED'
  /**
   * The network is online. The channel can be connected.
   */
  static readonly ONLINE = 'CHANNEL_NETWORK_ONLINE'
  /**
   * The network is offline. The channel can't be connected.
   */
  static readonly OFFLINE = 'CHANNEL_NETWORK_OFFLINE'
  /**
   * The channel has received an event.
   */
  static readonly MESSAGE = 'CHANNEL_MESSAGE'
  /**
   * An error on the channel occurred.
   */
  static readonly ERROR = 'CHANNEL_ERROR'
  /**
   * The channel needs to be refreshed
   */
  static readonly EXPIRED = 'CHANNEL_EXPIRED'
  /**
   * The notification channel server may be down.
   */
  static readonly SERVER_DOWN = 'SERVER_DOWN'

  static readonly TERMINATED = 'SERVER_TERMINATED'
}
