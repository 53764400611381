import {
  getFromLocalStorage,
  getLocationPathname,
  getWindow,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../common/dom-helpers'
import { LocalStorageKeys } from '../environments'
import { isMainWindow } from '../services/container'
import { onWindowUnload } from './helpers/window'
import { navigateToUrl } from './helpers'
import { getShellLogger } from './logger'
import { ROOT_ROUTE, SHELL_OPTIMIZED_PAGES_ROUTES } from './routes'
import { SINGLE_SPA_ROUTING_EVENT } from './single-spa-events'
import { getEventBus } from '../services/namespaces/event-bus'
import { type shellEvents, ShellNamespace } from '../services/shell-namespace'
import { getExtensionsManager } from '../extensions/extensions-manager'
import { isMainExperienceMounted } from '../experiences/helpers'

export type StoreCurrentRouteOptions = { readonly defaultRoute?: string }

const isRouteWithoutExtension = (locationPathname: string) => !!SHELL_OPTIMIZED_PAGES_ROUTES.find(pageRoute => pageRoute === locationPathname)

/** Ensures the user is not stuck on a wrong route (that is, a route without an extension and not one of the well-known
 * routes that don't load an extension).
 * Exported for unit testing only.
 */
export const navigateToDefaultRouteIfNothingOnCurrentRoute = (options?: StoreCurrentRouteOptions) => {
  const locationPathname = getLocationPathname()
  const { defaultRoute = ROOT_ROUTE } = options ?? {}
  const defaultRouteDifferentFromCurrent = locationPathname !== defaultRoute
  if (!defaultRouteDifferentFromCurrent) {
    return;
  }

  // The main check is if we have a mounted experience (module).
  // Since the app callback in createAppForExtensionModule is async, an extension could take a while to return a
  // component. This is why we check also if the route is a valid shell module route.
  // We also check if this is a route that doesn't need an extension loaded.
  const appIsOnValidRoute =
    isMainExperienceMounted() ||
    getExtensionsManager().isValidShellModuleRoute(locationPathname) ||
    isRouteWithoutExtension(locationPathname)

  if (!appIsOnValidRoute) {
    removeFromLocalStorage(LocalStorageKeys.gotoCurrentRoute)
    getShellLogger().warn(
      `[URL]: "${locationPathname}" does not have a supported extension. Redirecting to [DEFAULT URL]: "${defaultRoute}"`,
    )
    navigateToUrl(defaultRoute)
  }
}

/**
 * Init the routing event listener
 * This listener is only setup in the main Window to avoid race storage
 */
export const initializeRoutingListeners = (options?: StoreCurrentRouteOptions) => {
  if (!isMainWindow()) {
    return
  }

  const { applicationReady } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)

  const listener = () => navigateToDefaultRouteIfNothingOnCurrentRoute(options)

  getWindow().addEventListener(SINGLE_SPA_ROUTING_EVENT, listener)
  applicationReady.on(listener)

  onWindowUnload(() => {
    getWindow().removeEventListener(SINGLE_SPA_ROUTING_EVENT, listener)
    applicationReady.removeListener(listener)
  })
}

/**
 * Get the applicable localStorage store current route
 * @returns string
 */
export const getStoreCurrentRoute = (): string | null => getFromLocalStorage(LocalStorageKeys.gotoCurrentRoute)

/**
 * Set the applicable localStorage, store current route
 * @param gotoCurrentRoute
 */
export const setStoreCurrentRoute = (gotoCurrentRoute: string): void =>
  setToLocalStorage(LocalStorageKeys.gotoCurrentRoute, gotoCurrentRoute)

/**
 * Navigate to the current route saved in localStorage
 */
export const navigateToStoredRoute = () => {
  const storedRoute = getStoreCurrentRoute()
  if (storedRoute) {
    navigateToUrl(storedRoute)
  }
}
