import { onWindowUnload } from '../../common/helpers/window'
import { executeCommand } from '../namespaces/utils'

import { type ExternalInterface, type ExternalInterfaceCallback } from './external-interface'
import { type CommandMessagePayload } from './messages/commands'

export const externalInterfaceCommandDispatcher = (externalInterface: ExternalInterface) => {
  const handleExternalCommands: ExternalInterfaceCallback<CommandMessagePayload> = payload => {
    if (externalInterface.isCompanion) {
      executeCommand({ namespace: payload.namespace, commandName: payload.commandName }, ...payload.args, {
        callerId: payload.callerId,
      })
    }
  }

  externalInterface.addCallback('command', handleExternalCommands)

  onWindowUnload(() => {
    externalInterface.removeCallback('command', handleExternalCommands)
  })
}
