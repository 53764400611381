import { getLocationSearch, navigateToExternal } from '../../common/dom-helpers'
import type { EnvironmentConfig } from '../../environments'
import { environment } from '../../environments'
import { AuthBase } from './authentication.base'
import { authCodeVerificationKey } from './constants'
import { stringifyAndEncode } from './utils'

export class AuthWeb extends AuthBase {
  /**
   * @description Get the configuration and redirect to the auth service to complete the process.
   * @param param configuration parameters.
   */
  async authProcess({ authRedirectUrl, authUrl }: EnvironmentConfig, loginOnce: boolean, inflight?: string) {
    this.setLocalStorageEncodedNonce()
    const config = await this.getLoginConfig<string>(
      stringifyAndEncode(this.getState(inflight)),
      authRedirectUrl,
      authUrl,
    )
    const params = new URLSearchParams(config)
    navigateToExternal(`${authUrl}/oauth/authorize?${params}`)
  }

  /**
   * @param param configuration parameters.
   * @returns the URL for the logout process.
   */
  getLogoutURL({ authUrl, authClientId, logoutRedirectUrl }: EnvironmentConfig) {
    const searchParams = new URLSearchParams({
      client_id: authClientId,
      redirect_uri: `${logoutRedirectUrl}`,
    })
    return `${authUrl}/oauth/logout?${searchParams}`
  }

  /**
   * @description Call the auth client library to request a token.
   * @return token
   */
  requestToken() {
    const searchParams = new URLSearchParams(getLocationSearch())
    const authClientInstance = this.getAuthClientInstance()
    return authClientInstance.requestPKCEToken({
      code_verifier: sessionStorage.getItem(authCodeVerificationKey) ?? '',
      code: searchParams.get('code') ?? '',
      redirect_url: environment().authRedirectUrl,
    })
  }
}
