export interface CreateIncidentData {
  readonly serviceId: number
  readonly categoryId: number
  readonly priorityId: number
  readonly title: string
  readonly description?: string
  readonly date?: string
  readonly file?: FileAttachmentInfo
}

export interface CreateIncidentBody {
  readonly data: {
    readonly type: string
    readonly attributes: {
      readonly title: string
      readonly due_date?: string
    }
    readonly relationships: {
      readonly service: CreateIncidentSpecs
      readonly category: CreateIncidentSpecs
      readonly priority: CreateIncidentSpecs
      readonly symptoms?: CreateIncidentSymptoms
    }
  }
  readonly included?: readonly [
    {
      readonly 'temp-id'?: string
      readonly type?: string
      readonly attributes?: {
        readonly note?: string
        readonly note_type?: string
        readonly attachments?: readonly [CreateIncidentAttachment]
      }
    },
  ]
}

export interface CreateIncidentSpecs {
  readonly data: {
    readonly type: string
    readonly id: number
  }
}

export interface CreateIncidentSymptoms {
  readonly data: {
    readonly 'temp-id': string
    readonly type: string
    readonly method: string
  }
}

export interface CreateIncidentAttachment {
  readonly filename?: string
  readonly content_type?: string
  //TODO: fix types and file sending in Elisa's story
  readonly data?: unknown
}

export interface ResolveService {
  readonly id: number
  readonly name: string
  readonly path: string
}

export interface ResolveData {
  readonly data: readonly ResolveService[]
}

export interface ResolveCategory {
  readonly id: number
  readonly name: string
}

export interface ResolvePriority {
  readonly id: number
  readonly description: string
  readonly priority_level: number
}

export interface ResolveStatus {
  readonly id: number
  readonly name: string
}

export interface ResolveServiceExtractData {
  readonly id: number
  readonly name: string
  readonly description: string
  readonly path: string
  readonly allow_support_email: boolean
  readonly service_priorities: readonly ResolvePriority[]
  readonly categories: readonly ResolveCategory[]
}

export interface ResolveServiceData {
  readonly data: readonly ResolveServiceExtractData[]
}

export interface FileAttachmentInfo {
  readonly name: string
  readonly type: string
  readonly data?: string
  readonly attachmentError: boolean
}

export enum ResolveApiCallResult {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export type ResolvePostTicketResult =
  | {
      readonly status: ResolveApiCallResult.SUCCESS
    }
  | {
      readonly status: ResolveApiCallResult.FAILURE
      readonly message: string
    }

export enum TrackingEventTicketName {
  TICKET_SUBMIT = 'GoTo > Ticket Submit',
  TICKET_ERROR = 'GoTo > Ticket Error',
  TICKET_CANCEL = 'GoTo > Ticket Cancel',
}
interface TrackingEventTicketSubmit {
  readonly name: TrackingEventTicketName.TICKET_SUBMIT
}

interface TrackingEventTicketError {
  readonly name: TrackingEventTicketName.TICKET_ERROR
  readonly payload: { readonly message: string }
}

interface TrackingEventTicketCancel {
  readonly name: TrackingEventTicketName.TICKET_CANCEL
}

export type TrackingEventTicket = TrackingEventTicketSubmit | TrackingEventTicketError | TrackingEventTicketCancel
