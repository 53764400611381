// Highly inspired by these two projects
// https://github.com/andreasbm/lit-translate
// https://github.com/colscott/lit-i18n

import type { Part } from 'lit-html'
import { directive, AttributePart, NodePart, BooleanAttributePart, EventPart, PropertyPart } from 'lit-html'
import type { Translatable } from '../../common/translate-helpers/types'
import { resolveTranslatable } from '../../common/translate-helpers/translatable'
import { getI18n } from '../../services/i18n/i18nUtils'

interface I18nPartValue {
  readonly key: string
  readonly options: Record<string, unknown>
}

const CLEANUP_PARTS_MS = 1000 * 60

// In an ideal world where Parts could handle a disconnect hook
// we should use a WeakMap here
const registry = new Map<Part, I18nPartValue>()

/* istanbul ignore next */
const setPartValue = (part: Part, translatable: Translatable, opt?: () => I18nPartValue['options']) => {
  const options = opt?.() ?? {}
  const translation =
    typeof translatable === 'string' ? getI18n().t(translatable, options) : resolveTranslatable(translatable)

  registry.set(part, { key: resolveTranslatable(translatable, { raw: true }), options })

  if (part.value === translation) {
    return
  }

  part.setValue(translation)
  part.commit()
}

/* istanbul ignore next */
const isConnected = (part: Part) => {
  if (part instanceof NodePart) {
    return part.startNode.isConnected
  } else if (part instanceof AttributePart || part instanceof PropertyPart) {
    return part.committer.element.isConnected
  } else if (part instanceof BooleanAttributePart || part instanceof EventPart) {
    return part.element.isConnected
  }

  return false
}

/* istanbul ignore next */
const removeDisconnectedParts = () => {
  registry.forEach((_, part) => {
    if (!isConnected(part)) {
      registry.delete(part)
    }
  })
}

/* istanbul ignore next */
const whenIdle = (cb: () => void) => {
  ;(window as any).requestIdleCallback(cb)
}

// Since lit-html doesn't provide a way for us to run a hook when the part is disconnected
// we have to manually check
// https://github.com/Polymer/lit-html/issues/283
/* istanbul ignore next */
const cleanup = () => {
  setInterval(() => whenIdle(() => removeDisconnectedParts()), CLEANUP_PARTS_MS)
}

/* istanbul ignore next */
const listenForLanguageChanged = () => {
  const handler = () => {
    registry.forEach(({ key, options }, part) => {
      setPartValue(part, key, () => options)
    })
  }

  // listening on event-bus has a race condition
  // this handler was called too early
  getI18n().on('languageChanged', handler)

  const pageHideWindowHandler = (e: PageTransitionEvent) => {
    if (!e.persisted) {
      getI18n().off('languageChanged', handler)
    }
  }
  window.addEventListener('pagehide', pageHideWindowHandler)
}

listenForLanguageChanged()
cleanup()

/* istanbul ignore next */
/**
 * A translate directive that updates the translation when the language changes
 * @param key translation key
 * @optional @param options interpolation values
 * @example
 * t('key {{name}}', () => ({ name: 'code' }))
 * t('key')
 */
export const t = directive((translatable: Translatable, options?: () => I18nPartValue['options']) => (part: Part) => {
  setPartValue(part, translatable, options)
})
