import { type SidebarAPI } from './models'
import { SidebarService } from './sidebar-service'

let _sidebarAPI: SidebarAPI
let _sidebarService: SidebarService

export const getSidebarAPI = (): SidebarAPI => {
  if (!_sidebarAPI) {
    _sidebarAPI = createSidebarAPI()
  }
  return _sidebarAPI
}

const createSidebarAPI = (): SidebarAPI => {
  const sidebarService = getSidebarService()
  const sidebarAPI: SidebarAPI = {
    show() {
      return sidebarService.show()
    },
    hide() {
      return sidebarService.hide()
    },
    isVisible() {
      return sidebarService.isVisible()
    },
  }
  return Object.freeze(sidebarAPI)
}

export const getSidebarService = (): SidebarService => {
  if (!_sidebarService) {
    _sidebarService = new SidebarService()
  }
  return _sidebarService
}
