import { GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR } from '../../common/container'
import { createWindowsSupportBannerElement, getDocument } from '../../common/dom-helpers'
export class WindowsSupportBannerService {
  private windowsSupportBannerElement: HTMLElement | undefined

  show() {
    if (!this.windowsSupportBannerElement) {
      this.windowsSupportBannerElement = createWindowsSupportBannerElement()
    }
    const criticalBannerContainer = getDocument().querySelector(GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR)
    criticalBannerContainer?.setAttribute('style', 'height:32px;')
    criticalBannerContainer?.appendChild(this.windowsSupportBannerElement)
  }

  hide() {
    if (this.windowsSupportBannerElement) {
      this.windowsSupportBannerElement.remove()
      const criticalBannerContainer = getDocument().querySelector(GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR)
      if (!criticalBannerContainer?.childNodes?.length) {
        criticalBannerContainer?.removeAttribute('height')
      }
    }
  }
}
