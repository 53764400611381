/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'

import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { formatPersonName } from '../../directives/person-name'
import { i18nLocales, I18N_LOCALES } from '../../common/translate-helpers/locales'

export type Locale = (typeof i18nLocales)[number]

export const I18NNamespace = 'I18N'

export const I18NEvents = {
  localeChangeBegin: eventEmitter<Locale>(),
  localeChanged: eventEmitter<Locale>(),
}

const { emit } = getEventBus().register(I18NNamespace, I18NEvents)

export const localeChangeBegin = (locale: Locale) => emit.localeChangeBegin(locale)

/**
 * Sets the locale in the document
 * @param locale Locale to set in document
 * @param document Document
 */
export const setDocumentLocale = (locale: Locale, document: Document) => {
  document?.documentElement.setAttribute('lang', formatLocale(locale, '-'))
}

/** Changes the current locale of the Shell and emits the localeChanged event. */
export const setCurrentLocale = (locale: Locale) => {
  globalThis.shell.i18n.selectedLocale = locale
  i18n.changeLanguage(locale)
  emit.localeChanged(locale)
}

export const FALLBACK_LNG = 'en_US'

export interface ShellI18NAPI {
  readonly instance: typeof i18n
  selectedLocale: Locale
  readonly setCurrentLocale: typeof setCurrentLocale
  readonly formatPersonName: typeof formatPersonName
  readonly formatNumber: typeof formatNumber
  readonly formatDateTime: typeof formatDateTime
  readonly formatDateTimeRange: typeof formatDateTimeRange
}

/**
 * Verifies if the specified locale is supported in Shell internationalization system
 * @param locale Locale to verify
 * @returns True if locale is supported by Shell
 */
export const isLocaleSupported = (locale: Locale | string): locale is Locale =>
  i18nLocales.some(shellLocale => locale === shellLocale)

/**
 * Formats the specified locale with the given separator
 * @param locale Locale to format
 * @param separator New separator to use (dash "-" or underscore "_")
 * @returns Formatted locale
 */
export const formatLocale = (locale: string, separator: '-' | '_') => locale.replace(/[-_]/g, separator)

/**
 * Retrieves the list of preferred locales from the current browser
 * @returns browser's locales list
 */
export const getBrowserLocales = (): readonly string[] =>
  navigator.languages.length > 0 ? navigator.languages : [navigator.language]

/**
 * Retrieves the default locale that should be used from the browser
 * @returns Locale to be used by default
 */
export const getDefaultLocale = (): Locale => {
  const browserLocales = getBrowserLocales()
  return (
    (browserLocales
      .map(browserLocale => formatLocale(browserLocale, '_'))
      .find(shellLocale => isLocaleSupported(shellLocale)) as Locale) ?? FALLBACK_LNG
  )
}

/**
 * Base interface for common locale property
 */
interface BaseFormatOptions {
  /**
   * Optional locale to use for formatting
   * If undefined, the current locale will be used
   */
  locale?: Locale
}

export interface FormatNumberOptions extends Intl.NumberFormatOptions, BaseFormatOptions {}
export interface DateTimeFormatOptions extends Intl.DateTimeFormatOptions, BaseFormatOptions {}

/**
 * @description Helper function to get the locale and format options
 * @param options options containing the locale and other Intl options
 * @returns tuple containing the resolved locale and Intl options without locale
 */
const getLocaleAndOptions = <T extends BaseFormatOptions>(options?: T): [string, Omit<T, 'locale'>] => {
  const { locale, ...intlOptions } = options ?? {}
  const resolvedLocale: string = locale ?? getCurrentLocale()
  return [formatLocale(resolvedLocale, '-'), intlOptions as Omit<T, 'locale'>]
}

/**
 * Helper to get the NumberFormat with the current locale
 * @param options FormatNumberOptions use by NumberFormat
 * @returns javascript object NumberFormat initialized with the current locale used to format number with language-sensitive
 * ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
const getNumberFormatter = (options?: FormatNumberOptions) => {
  const [locale, intlOptions] = getLocaleAndOptions<FormatNumberOptions>(options)
  return new Intl.NumberFormat(locale, intlOptions)
}

/**
 * Helper to get the DateTimeFormat with the current locale
 * @param options FormatNumberOptions
 * @returns javascript object DateTimeFormat initialized with the current locale used to format date and time with language-sensitive
 * ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 */
const getDateTimeFormatter = (options?: DateTimeFormatOptions) => {
  const [locale, intlOptions] = getLocaleAndOptions<DateTimeFormatOptions>(options)
  return new Intl.DateTimeFormat(locale, intlOptions)
}

/**
 * @description Format a number using the specified options
 * @param value number to format
 * @param options options to format the number
 * @returns formatted number as a string
 */
export const formatNumber = (value: number, options?: FormatNumberOptions): string =>
  getNumberFormatter(options).format(value)

/**
 * @description Format a date/time using the specified options
 * @param value date/time value to format
 * @param options options to format the date/time
 * @returns formatted date/time as a string
 */
export const formatDateTime = (value: number, options?: DateTimeFormatOptions): string =>
  getDateTimeFormatter(options).format(value)

/**
 * @description Format a range of date/time using the specified options
 * @param startTime starting number/Date value to format
 * @param endTime Ending number/Date value to format
 * @param options DateTimeFormat options to format the date/time
 * @returns formatted range of date/time as a string
 */
export const formatDateTimeRange = (
  startTime: number | Date,
  endTime: number | Date,
  options?: DateTimeFormatOptions,
): string => getDateTimeFormatter(options).formatRange(startTime, endTime)

export function getShellI18NAPI(locale: Locale | null = null): ShellI18NAPI {
  return {
    instance: i18n,
    selectedLocale: locale ? locale : getDefaultLocale(),
    setCurrentLocale,
    formatPersonName,
    formatNumber,
    formatDateTime,
    formatDateTimeRange,
  }
}

export const getCurrentLocale = () => getShellApiInstance()?.i18n?.selectedLocale ?? getDefaultLocale()

export type I18nNextInitReturnType = ReturnType<(typeof i18n)['init']>

/**
 * This function returns a locale to be used for translations,
 * based on the supported language files available from our translation team.
 *
 * @param locale for which we want translations
 * @returns the starting locale or the alternative one if specified in the function
 */

export const getLocaleForTranslation = (locale: Locale) => {
  let localeForI18n = locale
  switch (locale) {
    case 'fr_CA':
      localeForI18n = 'fr_FR'
      break
    case 'es_MX':
      localeForI18n = 'es_ES'
  }

  return localeForI18n
}

export const initTranslations = async (locale: Locale, baseUrl?: string): I18nNextInitReturnType =>
  await i18n
    .use(HttpApi)
    .init({
      // https://github.com/i18next/i18next/blob/v19.8.7/index.d.ts#L167
      fallbackLng: FALLBACK_LNG,
      keySeparator: false,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
      },
      returnEmptyString: false,
      lng: getLocaleForTranslation(locale),
      load: 'currentOnly',
      backend: {
        loadPath: `${baseUrl ?? '/i18n/'}{{lng}}.json`,
      },
      supportedLngs: I18N_LOCALES.slice(),
    })
    .then(fn => {
      emit.localeChanged(locale)
      return fn
    })
