/* istanbul ignore file */
import type { Part } from 'lit-html'
import { directive } from 'lit-html'

// helper from lit-html https://lit-html.polymer-project.org/guide/creating-directives
/* istanbul ignore next */
export const resolvePromise = directive(promise => (part: Part) => {
  promise
    .then((resolvedValue: unknown) => {
      part.setValue(resolvedValue)
      part.commit()
    })
    .catch((err: unknown) => {
      part.setValue(err)
      part.commit()
    })
})
