import { reloadPreviousUrl } from '../../common'
import { assertUnreachable } from '../../common/assert-helpers'
import {
  getFromLocalStorage,
  navigateToExternal,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../../common/dom-helpers'
import { isMainWindow } from '../../services/container'
import { isContainer } from '../../services/container/helpers'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { GotoEmptyState } from '../empty-state/component'
import { EMPTY_STATE_ACTION } from '../empty-state/custom-events'
import type { GotoEmptyStateActionButton } from '../empty-state/model'
import styles from './component.styles.scss'
import { openCustomerSupportWindow } from './helpers'
import type { ErrorPageActions } from './models'
import { PICTOGRAM_ALERT_STATIC } from '@getgo/chameleon-pictograms'
import { removeRefreshToken, removeToken } from '../../services/auth/token'

export const LOCAL_STORAGE_RELOAD_ATTEMPTS_KEY = 'shell-reload-attempts'

export class GotoError extends GotoEmptyState {
  static readonly tagName = 'goto-error'
  static reloadAttempts = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_RELOAD_ATTEMPTS_KEY) ?? JSON.stringify(0))

  svgKey = PICTOGRAM_ALERT_STATIC
  title = getTranslation('Something went wrong')
  // TODO: `contact us` should be wrapped in an anchor tag https://jira.ops.expertcity.com/browse/SCAPI-963
  subTitle = isContainer()
    ? getTranslation(`GoTo couldn't load correctly. Try restarting the app, and if that doesn't work, contact us.`)
    : getTranslation(`GoTo couldn't load correctly. Try reloading the window, and if that doesn't work, contact us.`)

  actions: GotoEmptyStateActionButton<ErrorPageActions>[] = [
    { type: 'restart', label: isContainer() ? getTranslation('Restart GoTo') : getTranslation('Reload GoTo') },
    // TODO: remove this button https://jira.ops.expertcity.com/browse/SCAPI-963
    { type: 'support', label: getTranslation('Contact us'), variant: 'tertiary' },
  ]

  static get styles() {
    return styles
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.addEventListener(EMPTY_STATE_ACTION, this.handleEmptyStateActions)

    this.unsubscribeFunctions.push(() => {
      this.removeEventListener(EMPTY_STATE_ACTION, this.handleEmptyStateActions)
    })
  }

  private readonly handleEmptyStateActions = ({ detail }: CustomEvent<ErrorPageActions>) => {
    switch (detail) {
      case 'support':
        openCustomerSupportWindow()
        break
      case 'restart':
        if (isMainWindow()) {
          if (GotoError.reloadAttempts >= 1) {
            removeToken()
            removeRefreshToken()
            //reset the reload attempts so that they don't keep logging themselves out
            removeFromLocalStorage(LOCAL_STORAGE_RELOAD_ATTEMPTS_KEY)
          } else {
            setToLocalStorage(LOCAL_STORAGE_RELOAD_ATTEMPTS_KEY, GotoError.reloadAttempts + 1)
          }
          navigateToExternal(window.origin)
        } else {
          reloadPreviousUrl()
        }
        break
      default:
        assertUnreachable(detail)
    }
  }
}
