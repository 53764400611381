import type { TypedResponse } from './authenticatedFetch'
import { authenticatedFetch, authenticatedFetchWithRetries } from './authenticatedFetch'
import { login, logout } from './authentication'
import { IsLoggedInState } from './isLoggedIn-state'
import { getShellLogger } from '../../common/logger'
import { getToken } from './token'
import { refreshAuthentication } from './refresh'
import type { LoginOptions } from './models'

let isLoggingOut = false
let loginInitiated: Promise<void> | undefined

const getWrapperError = () => new Error('You are in the process of logging out')
const logWarning = (functionName: string) =>
  getShellLogger().warn(`You are in the process of logging out, ${functionName} should not be called`)

export const wrappedGetToken = (): ReturnType<typeof getToken> => {
  if (!isLoggingOut) {
    return getToken()
  }

  logWarning('getToken')
  throw getWrapperError()
}

export const wrappedAuthenticatedFetch = <T = unknown>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<TypedResponse<T>> => {
  if (!isLoggingOut) {
    return authenticatedFetch<T>(input, init)
  }

  logWarning('authenticatedFetch')
  return Promise.reject(getWrapperError())
}

export const wrappedAuthenticatedFetchWithRetries = <T = unknown>(
  input: RequestInfo,
  init?: RequestInit,
  retryInterval?: number,
  maxRetry?: number,
): Promise<TypedResponse<T>> => {
  if (!isLoggingOut) {
    return authenticatedFetchWithRetries<T>(input, init, retryInterval, maxRetry)
  }

  logWarning('authenticatedFetchWithRetries')
  return Promise.reject(getWrapperError())
}

export const wrappedLogin = (options?: LoginOptions): Promise<void> => {
  if (!isLoggingOut) {
    if (options?.restartFlow || !loginInitiated) {
      loginInitiated = login(options)
    }
    return loginInitiated
  }
  logWarning('login')
  return Promise.reject(getWrapperError())
}

export const wrappedLogout = (): Promise<void> => {
  if (!isLoggingOut) {
    isLoggingOut = true
    return logout()
      .then(() => {
        isLoggingOut = false
      })
      .catch(error => {
        isLoggingOut = false
        throw error
      })
  }

  logWarning('logout')
  return Promise.reject(getWrapperError())
}

export const wrappedRefresh = (): Promise<void> => {
  if (!isLoggingOut) {
    return refreshAuthentication()
  }

  logWarning('refresh')
  return Promise.reject(getWrapperError())
}

export const wrappedIsLoggedIn = (): Promise<boolean> => {
  if (!isLoggingOut) {
    return IsLoggedInState.getInstance().waitIsLoggedInState()
  }

  logWarning('IsLoggedIn')
  return Promise.reject(getWrapperError())
}

// For testing purposes
export const resetIsLoggingOut = () => {
  isLoggingOut = false
}
