import type { getUserPreferencesConnector } from './connectors/user-preferences.connector'
import type { UserPreferencesAPI } from './models'
import { UserPreferencesService } from './user-preferences.service'

let _userPreferencesAPI: UserPreferencesAPI
let _userPreferencesService: UserPreferencesService

export const getUserPreferencesAPI = (
  userPreferencesConnector: ReturnType<typeof getUserPreferencesConnector>,
): UserPreferencesAPI => {
  if (!_userPreferencesAPI) {
    _userPreferencesAPI = createUserPreferencesAPI(userPreferencesConnector)
  }
  return _userPreferencesAPI
}

const createUserPreferencesAPI = (
  userPreferencesConnector: ReturnType<typeof getUserPreferencesConnector>,
): UserPreferencesAPI => {
  const userPreferencesService = getUserPreferencesService(userPreferencesConnector)
  const userPreferencesAPI: UserPreferencesAPI = {
    async getPreferences() {
      return await userPreferencesService.getPreferences()
    },
    async getPreference(path, defaultValue) {
      return await userPreferencesService.getPreference(path, defaultValue)
    },
    async setPreference(path, data) {
      return await userPreferencesService.setPreference(path, data)
    },
    async deletePreference(path) {
      return await userPreferencesService.deletePreference(path)
    },
  }
  return Object.freeze(userPreferencesAPI)
}

export const getUserPreferencesService = (
  userPreferencesConnector: ReturnType<typeof getUserPreferencesConnector>,
): UserPreferencesService => {
  if (!_userPreferencesService) {
    _userPreferencesService = new UserPreferencesService(userPreferencesConnector)
  }
  return _userPreferencesService
}
