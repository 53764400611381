/**
 * {@inheritDoc KeyboardEventCodeAlphanumericWritingSystem}
 */
const ALPHANUMERIC_WRITING_SYSTEM = [
  'Backquote',
  'Backslash',
  'Backspace',
  'BracketLeft',
  'BracketRight',
  'Comma',
  'Digit0',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit8',
  'Digit9',
  'Equal',
  'IntlBackslash',
  'IntlRo',
  'IntlYen',
  'KeyA',
  'KeyB',
  'KeyC',
  'KeyD',
  'KeyE',
  'KeyF',
  'KeyG',
  'KeyH',
  'KeyI',
  'KeyJ',
  'KeyK',
  'KeyL',
  'KeyM',
  'KeyN',
  'KeyO',
  'KeyP',
  'KeyQ',
  'KeyR',
  'KeyS',
  'KeyT',
  'KeyU',
  'KeyV',
  'KeyW',
  'KeyX',
  'KeyY',
  'KeyZ',
  'Minus',
  'Period',
  'Quote',
  'Semicolon',
  'Slash',
] as const

/**
 * Key Codes for Standard Keyboards - Alphanumeric section - Writing System Keys
 *
 * {@link https://www.w3.org/TR/uievents-code/#key-alphanumeric-writing-system | 3.1.1.1. Writing System Keys }
 */
export type KeyboardEventCodeAlphanumericWritingSystem = (typeof ALPHANUMERIC_WRITING_SYSTEM)[number]

/**
 * {@inheritDoc KeyboardEventCodeFunctional}
 */
const ALPHANUMERIC_FUNCTIONAL_STANDARD = [
  'AltLeft',
  'AltRight',
  'CapsLock',
  'ContextMenu',
  'ControlLeft',
  'ControlRight',
  'Enter',
  'MetaLeft',
  'MetaRight',
  'ShiftLeft',
  'ShiftRight',
  'Space',
  'Tab',
] as const

/**
 * {@inheritDoc KeyboardEventCodeFunctional}
 */
const ALPHANUMERIC_FUNCTIONAL_JP_KO = [
  // Japanese: 変換 (henkan)
  'Convert',
  // Japanese: カタカナ/ひらがな/ローマ字 (katakana/hiragana/romaji)
  'KanaMode',
  // Korean: HangulMode 한/영 (han/yeong)
  // Japanese (Mac keyboard): かな (kana)
  'Lang1',
  // Korean: Hanja 한자 (hanja)
  // Japanese (Mac keyboard): 英数 (eisu)
  'Lang2',
  // Japanese (word-processing keyboard): Katakana
  'Lang3',
  // Japanese (word-processing keyboard): Hiragana
  'Lang4',
  // Japanese (word-processing keyboard): Zenkaku/Hankaku
  'Lang5',
  // Japanese: 無変換 (muhenkan)
  'NonConvert',
] as const

/**
 * {@inheritDoc KeyboardEventCodeFunctional}
 */
const ALPHANUMERIC_FUNCTIONAL = [...ALPHANUMERIC_FUNCTIONAL_STANDARD, ...ALPHANUMERIC_FUNCTIONAL_JP_KO] as const

/**
 * Key Codes for Standard Keyboards - Alphanumeric section - Functional Keys
 *
 * {@link https://www.w3.org/TR/uievents-code/#key-alphanumeric-functional | 3.1.1.2. Functional Keys }
 */
export type KeyboardEventCodeFunctional = (typeof ALPHANUMERIC_FUNCTIONAL)[number]

/**
 * {@inheritDoc KeyboardEventCodeAlphanumeric}
 */
const KEY_CODE_ALPHANUMERIC = [...ALPHANUMERIC_WRITING_SYSTEM, ...ALPHANUMERIC_FUNCTIONAL] as const

/**
 * Key Codes for Standard Keyboards - Alphanumeric section
 *
 * The main section of a standard keyboard layout.
 *
 * {@link https://www.w3.org/TR/uievents-code/#key-alphanumeric-section | 3.1.1. Alphanumeric Section }
 */
export type KeyboardEventCodeAlphanumeric = (typeof KEY_CODE_ALPHANUMERIC)[number]

/**
 * {@inheritDoc KeyboardEventCodeControlPad}
 */
const KEY_CODE_CONTROL_PAD = [
  // ⌦. The forward delete key. Note that on Apple keyboards,
  // the key labelled Delete on the main part of the keyboard
  // should be encoded as "Backspace".
  'Delete',
  // Page Down, End or ↘
  'End',
  // Help. Not present on standard PC keyboards.
  'Help',
  // Home or ↖
  'Home',
  // Insert or Ins. Not present on Apple keyboards.
  'Insert',
  // Page Down, PgDn or ⇟
  'PageDown',
  // Page Up, PgUp or ⇞
  'PageUp',
] as const

/**
 * Key Codes for Standard Keyboards - Control Pad Section
 *
 * {@link https://www.w3.org/TR/uievents-code/#key-controlpad-section | 3.1.2. Control Pad Section }
 */
export type KeyboardEventCodeControlPad = (typeof KEY_CODE_CONTROL_PAD)[number]

/**
 * {@inheritDoc KeyboardEventCodeArrowPad}.
 */
const KEY_CODE_ARROW_PAD = [
  // ↓
  'ArrowDown',
  // ←
  'ArrowLeft',
  // →
  'ArrowRight',
  // ↑
  'ArrowUp',
] as const

/**
 * Key Codes for Standard Keyboards - Arrow Pad Section
 *
 * {@link https://www.w3.org/TR/uievents-code/#key-arrowpad-section | 3.1.3. Arrow Pad Section }
 */
export type KeyboardEventCodeArrowPad = (typeof KEY_CODE_ARROW_PAD)[number]

/**
 * {@inheritDoc KeyboardEventCodeNumpad}
 */
const KEY_CODE_NUMPAD = [
  'NumLock',
  'Numpad0',
  'Numpad1',
  'Numpad2',
  'Numpad3',
  'Numpad4',
  'Numpad5',
  'Numpad6',
  'Numpad7',
  'Numpad8',
  'Numpad9',
  'NumpadAdd',
  'NumpadBackspace',
  'NumpadClear',
  'NumpadClearEntry',
  // , (thousands separator).
  // For locales where the thousands separator is a "." (e.g., Brazil), this key may generate a ..
  'NumpadComma',
  // . Del. For locales where the decimal separator is "," (e.g., Brazil), this key may generate a ,.
  'NumpadDecimal',
  // /
  'NumpadDivide',
  'NumpadEnter',
  'NumpadEqual',
  // # on a phone or remote control device.
  // This key is typically found below the 9 key and to the right of the 0 key.
  'NumpadHash',
  // M+ Add current entry to the value stored in memory.
  'NumpadMemoryAdd',
  // MC Clear the value stored in memory.
  'NumpadMemoryClear',
  // MR Replace the current entry with the value stored in memory.
  'NumpadMemoryRecall',
  'NumpadMemoryStore',
  'NumpadMemorySubtract',
  // * on a keyboard. For use with numpads that provide mathematical operations (+, -, * and /).
  // Use "NumpadStar" for the * key on phones and remote controls.
  'NumpadMultiply',
  'NumpadParenLeft',
  'NumpadParenRight',
  // * on a phone or remote control device.
  // This key is typically found below the 7 key and to the left of the 0 key.
  // Use "NumpadMultiply" for the * key on numeric keypads.
  'NumpadStar',
  'NumpadSubtract',
] as const

/**
 * Key Codes for Standard Keyboards - Numpad Section
 *
 * {@link https://www.w3.org/TR/uievents-code/#key-numpad-section | 3.1.4. Numpad Section }
 */
export type KeyboardEventCodeNumpad = (typeof KEY_CODE_NUMPAD)[number]

/**
 * {@inheritDoc KeyboardEventCodeFunction}.
 */
const KEY_CODE_FUNCTION = [
  'Escape',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
  // Fn This is typically a hardware key that does not generate a separate code.
  // Most keyboards do not place this key in the function section,
  // but it is included here to keep it with related keys.
  'Fn',
  // FLock or FnLock. Function Lock key. Found on the Microsoft Natural Keyboard.
  'FnLock',
  'PrintScreen',
  'ScrollLock',
  'Pause',
] as const

/**
 * Key Codes for Standard Keyboards - Function Section
 *
 * {@link https://www.w3.org/TR/uievents-code/#key-function-section | 3.1.5. Function Section }
 */
export type KeyboardEventCodeFunction = (typeof KEY_CODE_FUNCTION)[number]

/**
 * Key Codes for Standard Keyboards
 *
 * {@link https://www.w3.org/TR/uievents-code/#keyboard-key-codes | Key Codes for Standard Keyboards}
 */
export type KeyboardKeyCode =
  | KeyboardEventCodeAlphanumeric
  | KeyboardEventCodeControlPad
  | KeyboardEventCodeArrowPad
  | KeyboardEventCodeNumpad
  | KeyboardEventCodeFunction

/**
 * KeyboardEvent.code
 *
 * For standards compliance, use those as the "code" value for {@see KeyboardEventInit}
 *
 * The KeyboardEvent.code attribute is the current modern way of describing
 * what key was used on the keyboard event.
 *
 * It superseeds keyCode and keyIdentifier and key attributes because they can
 * change wildly depending of the user-agent platform (e.g. Android, Mozilla, etc.)
 *
 * Bookmarks:
 * - UI Events specification: https://www.w3.org/TR/uievents/
 * - KeyboardEvent class: https://www.w3.org/TR/uievents/#idl-keyboardevent
 * - KeyboardEvent.code property: https://www.w3.org/TR/uievents/#biblio-uievents-code
 * - KeyboardEvent.code possible values: https://www.w3.org/TR/2017/CR-uievents-code-20170601/#keyboard-key-codes
 * - https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
 */
export const KEY_CODE_SET = new Set([
  ...KEY_CODE_ALPHANUMERIC,
  ...KEY_CODE_CONTROL_PAD,
  ...KEY_CODE_ARROW_PAD,
  ...KEY_CODE_NUMPAD,
  ...KEY_CODE_FUNCTION,
]) as ReadonlySet<KeyboardKeyCode>

export const isKeyboardKeyCode = (keyCode: string): keyCode is KeyboardKeyCode =>
  KEY_CODE_SET.has(keyCode as KeyboardKeyCode)

/**
 * List of Keyboard Event types.
 *
 * This should mirror what the DOM supports for HTMLElementEventMap['keydown'], HTMLElementEventMap['keypress'], etc.
 */
const KEYBOARD_EVENT_TYPE = ['keydown', 'keypress', 'keyup'] as const

/**
 * The KeyboardEvent type.
 *
 * {@see KEYBOARD_EVENT_TYPE}
 *
 * Bookmarks:
 * - https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent#events
 */
export type KeyboardEventType = (typeof KEYBOARD_EVENT_TYPE)[number]

export const KEYBOARD_EVENT_TYPE_SET = new Set([...KEYBOARD_EVENT_TYPE]) as ReadonlySet<KeyboardEventType>

export const isKeyboardEventType = (type: string): type is KeyboardEventType =>
  KEYBOARD_EVENT_TYPE_SET.has(type as KeyboardEventType)

/**
 * For standards compliance, use those as the "code" value for {@see KeyboardEventInit}
 *
 * The "key" value might be different from one keyboard layout to another
 * For example; the combination Digit1+ShiftLeft may make key to be "!" but not on all layouts!
 * Same would be for Backquote combination with Alt or Shift, or Meta
 */
export type KeyboardEventInitWithCode = {
  readonly code: KeyboardKeyCode
} & Partial<KeyboardEventInit>

/**
 * In some situations, we might want an item to handle keyboard events to be adapted from
 * default DOM patterns.
 *
 * For example, we use a Chameleon MenuItem that acts as a button, but we might want to nest
 * another interactive, say a Switch, we want to handle activation via keyboard.
 * We can also optionally bypass the MenuItem focus directly to that interactive element or not.
 */
export interface WithForwardFocus {
  /**
   * If we're wrapping an interactive element into another one, and we have
   * an item with a label, we can make it forward focus directly to it.
   *
   * Say we a menu item’s sole purpose is to toggle a checkbox or a switch,
   * we can forward "focus" to that element and the Screen-Reader will read its type
   * and label, e.g.: "Do Not Disturb, switch, on."
   */
  directlyForwardFocusTo(event?: HTMLElementEventMap['focus']): Promise<void>
}

export type ElementKeyboardActivationTriggerEvent =
  | HTMLElementEventMap['keydown']
  | HTMLElementEventMap['keypress']
  | HTMLElementEventMap['keyup']

export type ElementActivationTriggerEvent = HTMLElementEventMap['click'] | ElementKeyboardActivationTriggerEvent

/**
 * In some situations, we might want an item to handle keyboard events to be adapted from
 * default DOM patterns.
 */
export interface KeyboardActivatable {
  /**
   * The default event handle.
   *
   * The method that serves as telling we're done interacting with this.
   *
   * Can be used for either clicking or called from handleKeydown.
   * If this method is called from handleKeydown, make sure it is called
   * with appropriate keyboard combination.
   */
  activate(event?: ElementActivationTriggerEvent): Promise<void> | void
  /**
   * To make an activatable element (e.g. a button) we have to consider not just clicking
   * but also activated via keyboard events.
   * In some case it has to be with the Enter key, some other times with different key combination.
   * @internal
   */
  handleKeyboard(event: ElementKeyboardActivationTriggerEvent): Promise<void> | void
}

export const isKeyboardEvent = (event: unknown): event is KeyboardEvent => {
  if (typeof event === 'object' && event && 'type' in event && 'code' in event) {
    const { type = '' } = event as { type: string }
    return isKeyboardEventType(type)
  }
  return false
}

/**
 * Create a condition to validate applicability for an event handler.
 *
 * @param event Event to pass along to validate
 * @param eventCodes Array of KeyboardEvent.code items
 * @param typeArg (optional) String of a keyboard event type. By default "keydown" is used for keyboard shortucts over keyup and keydown
 */
export const isKeyboardEventFor = (
  event: unknown,
  eventCodes: ReadonlyArray<KeyboardKeyCode>,
  eventType: KeyboardEventType = 'keydown',
): event is KeyboardEvent => {
  if (isKeyboardEvent(event)) {
    if (event.type !== eventType) {
      return false
    }
    const matches = (eventCodes ?? []).find(e => event.code === e && isKeyboardKeyCode(event.code))
    return matches !== undefined
  }
  return false
}

/**
 * Clones a keyboard event and returns it
 * @param event KeyboardEvent to clone
 * @param options options to add to the keyboard event, i.g. bubbles
 * @returns new Keyboard Event
 */
export const cloneKeyboardEvent = (event: KeyboardEvent, options: KeyboardEventInit = {}): KeyboardEvent =>
  new KeyboardEvent(event.type, {
    key: event.key,
    code: event.code,
    location: event.location,
    repeat: event.repeat,
    isComposing: event.isComposing,
    ctrlKey: event.ctrlKey,
    shiftKey: event.shiftKey,
    altKey: event.altKey,
    metaKey: event.metaKey,
    ...options,
  })
