import { getBrandValue } from '../branding/brand-helpers'
import type { BusyState } from '../app-state'
import type { AppStateEvents } from '../app-state/events'
import { AppStateNamespace } from '../app-state/events'
import type { ModalNotification, ModalNotificationActions } from '../display-notification'
import { modal } from '../display-notification'
import { BrandVariationKeys } from '../feature-flags/models'
import { getTranslation } from '../i18n/i18nUtils'
import { getEventBus } from '../namespaces/event-bus'
import { onWindowUnload } from '../../common/helpers/window'

/**
 * Once the reload prompt has been seen should not see it
 * again for the duration of the app's runtime.
 */
export class HasSeenReloadPromptFlagManager {
  #seen = false

  set() {
    this.#seen = true
  }

  get() {
    return this.#seen
  }
}

let _hasSeenReloadPromptManager: HasSeenReloadPromptFlagManager | undefined

export const getHasSeenReloadFlagManager = () => {
  if (!_hasSeenReloadPromptManager) {
    _hasSeenReloadPromptManager = new HasSeenReloadPromptFlagManager()
  }

  return _hasSeenReloadPromptManager
}

/**
 * A function whose responsibility is to prompt the user to accept or refuse a reload request.
 *
 * The accept or refuse callback should be called for the user's acceptance of refusal.
 */
export type GetCanReloadUserReponse = (reponses: { readonly accept: () => void; readonly refuse: () => void }) => void

/**
 * Return a Promise that resolves to true if the application SafeToReloadApp value is true, or prompts the user and
 * resolves to a boolean depending on the user's response
 */
export const isIdleOrGetUserResponse = (state: BusyState): Promise<boolean> =>
  new Promise(resolve => {
    if (state === 'idle') {
      resolve(true)
    } else if (state === 'ask') {
      getHasSeenReloadFlagManager().set()
      displayCanReloadModal({
        accept: () => resolve(true),
        refuse: () => resolve(false),
      })
    } else {
      resolve(false)
    }
  })

/**
 * Display a modal prompting the user to click the button and continue with the page refresh. Closing the banner should halt the refresh
 */
export const displayCanReloadModal: GetCanReloadUserReponse = ({ accept, refuse }): void => {
  const brand = getBrandValue(BrandVariationKeys.SHELL_BRAND_NAME) ?? 'GoTo'

  const notification: ModalNotification = {
    id: 'can-reload-modal',
    title: getTranslation('Something went wrong'),
    content: getTranslation('For the best experience, {{brand}} needs to be restarted.', { brand }),
  }

  const actions: ModalNotificationActions[] = [
    { label: getTranslation('Reload now'), handler: accept },
    {
      label: getTranslation('Cancel'),
      handler: () => {
        refuse()
        close()
      },
    },
  ]

  const { close } = modal({ ...notification, actions })
}

/**
 * Wait until the SafeToReloadApp is either 'idle' or 'ask', and resolve to true or prompt the user
 * and resolve their response.
 */
export const waitForIdleOrGetUserResponse = (): Promise<boolean> =>
  new Promise(resolve => {
    const { appStateChanged } = getEventBus().subscribeTo<typeof AppStateNamespace, typeof AppStateEvents>(
      AppStateNamespace,
    )

    const handleNonBusyState = (state: BusyState) => {
      if (state !== 'busy') {
        /**
         * Stop reacting after the first non 'busy' sate since we don't want to
         * create more isIdleOrGetUserResponse promise that might display more banners.
         */
        appStateChanged.removeListener(handleNonBusyState)

        isIdleOrGetUserResponse(state).then(canReload => {
          resolve(canReload)
        })
      }
    }

    appStateChanged.on(handleNonBusyState)

    onWindowUnload(() => {
      appStateChanged.removeListener(handleNonBusyState)
    })
  })
