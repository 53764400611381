import type { AvatarInfo } from '../../services/avatar/models'
import type { AccountInfoFromGraphQL, UserInfo } from '../../services/identity/models'
import type {
  ShellUserAccount,
  ShellUserEmail,
  ShellUserInfo,
  ShellUserLocation,
  ShellUserMeta,
  ShellUserName,
} from './model'
import type { ProductTrial } from '../../services/context/models'
import { ShellUserAccountImpl } from './user-account'
import { ProductTrialImpl } from './product-trial'
import type { ShellUserAuthorityContextPermissions } from '../models'
import { getAccountFromContext } from '../../common/shell-api-helpers/context'

export interface ShellUserInfoParams {
  readonly userInfo?: UserInfo
  readonly permissions?: readonly ShellUserAuthorityContextPermissions[]
  readonly licenses?: readonly string[]
  readonly avatar?: AvatarInfo
  readonly productTrial?: ProductTrial
}
export class ShellUserInfoImpl implements ShellUserInfo {
  private userInfo?: UserInfo
  private _avatar?: AvatarInfo
  private permissions: ShellUserAuthorityContextPermissions[]
  private _userContextLicenses: string[]
  productTrial: ProductTrial

  constructor(params: ShellUserInfoParams) {
    this.userInfo = params.userInfo
    this.permissions = [...(params.permissions ?? [])]
    this._userContextLicenses = [...(params.licenses ?? [])]
    this._avatar = params.avatar
    this.productTrial = new ProductTrialImpl(params.productTrial)
  }

  get key(): string | null {
    return this.userInfo?.user?.key ?? null
  }

  get name(): ShellUserName {
    return {
      givenName: this.userInfo?.user?.firstName ?? '',
      lastName: this.userInfo?.user?.lastName ?? '',
    }
  }

  get meta(): ShellUserMeta {
    return {
      createdDate: convertToCreatedDate(this.userInfo),
    }
  }

  get locale(): string | null {
    return this.userInfo?.user?.locale ?? null
  }

  get emails(): readonly ShellUserEmail[] {
    return this.userInfo?.user?.email ? [{ value: this.userInfo.user.email, type: 'primary', primary: true }] : []
  }

  get location(): ShellUserLocation {
    return {
      city: this.userInfo?.user?.location ?? '',
      timeZone: this.userInfo?.user?.timeZone ?? '',
    }
  }

  get accounts(): readonly ShellUserAccount[] {
    return convertToShellUserAccount(this.userInfo?.accounts)
  }

  get avatar(): AvatarInfo | undefined {
    return this._avatar
  }

  get entitlements(): string[] {
    const result = new Set<string>()
    this.accounts.forEach(account =>
      account.licenses.forEach(license => license.entitlements.forEach(entitlement => result.add(entitlement))),
    )

    return Array.from(result.values())
  }

  get roles(): string[] {
    const result = new Set<string>()
    this.accounts.forEach(account =>
      account.licenses.forEach(license => license.roles.forEach(role => result.add(role))),
    )

    return Array.from(result.values())
  }

  getAccountByKey(accountKey: string): ShellUserAccount | undefined {
    return convertToShellUserAccount(this.userInfo?.accounts ?? []).find(account => account.key === accountKey)
  }

  hasPermissions(permissions: readonly ShellUserAuthorityContextPermissions[]): boolean {
    return !permissions.find(permission => !this.permissions.includes(permission))
  }

  hasLicenses(licenses: readonly string[]): boolean {
    const accountContext = getAccountFromContext()
    const currentAccount = this.getAccountByKey(accountContext?.key ?? '')

    const allLicenses = [
      ...this._userContextLicenses,
      ...(currentAccount?.licenses.map(license => license.channel).flat(Infinity) ?? []),
    ]
    return !licenses.find(license => !allLicenses.includes(license))
  }

  getUserContextLicenses() {
    return this._userContextLicenses
  }
}

const convertToCreatedDate = (userInfo: UserInfo | undefined): Date | null =>
  userInfo?.user?.createTime ? new Date(+userInfo.user.createTime) : null

export const convertToShellUserAccount = (
  accounts: readonly AccountInfoFromGraphQL[] = [],
): readonly ShellUserAccount[] => accounts.reduce((acc, account) => [...acc, new ShellUserAccountImpl(account)], [])
