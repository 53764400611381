import { getFromLocalStorage } from '../../common/dom-helpers'
import { asI18nLocale } from '../../common/translate-helpers/locales'
import { FALLBACK_LNG, formatLocale, initTranslations } from '../../services/i18n'
import type { Locale } from '../../services/i18n'

const LOCAL_STORAGE_I18N_KEY = 'i18nextLng'

export const initializeI18n = async (locale?: Locale) => {
  if (!locale) {
    //get cached locale from local storage
    locale = asI18nLocale(formatLocale(getFromLocalStorage(LOCAL_STORAGE_I18N_KEY) ?? '', '_')) ?? undefined
  }
  await initTranslations(
    locale ?? FALLBACK_LNG,
    /* https://jira.ops.expertcity.com/browse/SCORE-1312— Insert baseUrl for i18n, e.g. "/foo/i18n/static/" should we have it. This gets executed client side. */
  )
}
