import type { AvatarSize, AvatarSizeV2, AvatarType, AvatarVariant, AvatarVariantV2 } from '@getgo/chameleon-core'
import { type ShellElement } from '../../common/shell-element'

export const GOTO_AVATAR = 'goto-avatar'

export interface AvatarProps {
  readonly showPresence?: boolean
  readonly usePresenceSnapshot?: boolean
  readonly givenName?: string
  readonly familyName?: string
  readonly profileImageUrl?: string
  readonly externalUserKey?: string
  readonly emailAddress?: string
  readonly icon?: string
  readonly iconBadge?: string
  readonly type?: AvatarType
  readonly size?: AvatarSize | AvatarSizeV2
  readonly variant?: AvatarVariant | AvatarVariantV2
  readonly colorToken?: string
  readonly label?: string
  readonly useChameleonAvatarV2?: boolean
}

export type GotoAvatarComponent = ShellElement & AvatarProps
