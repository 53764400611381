import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import type { BadgeConfig } from './models'

/**
 * @deprecated Use Badge Number Services -> window.shell.badgeNumbers
 * https://github.com/jive/goto-shell/blob/master/docs/adr/2022-05-13-SERVICES-badge-number-services.md
 *  */
export const BadgeNamespace = 'badge'

/**
 * @deprecated Use Badge Number Services -> window.shell.badgeNumbers
 * https://github.com/jive/goto-shell/blob/master/docs/adr/2022-05-13-SERVICES-badge-number-services.md
 *  */
export const BadgeEvents = {
  badgeNumberUpdated: eventEmitter<BadgeConfig>(),
}

const { emit } = getEventBus().register(BadgeNamespace, BadgeEvents)

/**
 * @deprecated Use Badge Number Services -> window.shell.badgeNumbers
 * https://github.com/jive/goto-shell/blob/master/docs/adr/2022-05-13-SERVICES-badge-number-services.md
 *  */
export const updateBadge = (data: BadgeConfig) => {
  emit.badgeNumberUpdated(data)
}
