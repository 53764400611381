import { getWindow } from '../../common/dom-helpers'
import type { Pendo, PendoParams, UpdateData, WindowPendo } from './models'
import { PendoFunctions } from './models'

const appendPendoScriptToDom = (
  windowRef: WindowPendo,
  documentRef: Document,
  apiKey: string,
  pendoSrc: string,
  onLoad: () => void,
  onError: () => void,
) => {
  // Code snippet retrieved from the Pendo App
  const pendo = windowRef['pendo'] ?? ({} as Pendo)
  windowRef['pendo'] = pendo
  pendo._q = pendo._q || []
  for (const func of Object.values(PendoFunctions)) {
    if (!pendo[func]) {
      pendo[func] = (...args: unknown[]) => pendo._q.push([func, [...args]])
    }
  }
  const agentScriptElement = documentRef.createElement('script')
  agentScriptElement.defer = true
  agentScriptElement.src = `https://content.ipd.goto.com/agent/static/${apiKey}/${pendoSrc}`
  agentScriptElement.onload = onLoad
  agentScriptElement.onerror = onError
  const firstScriptElement = documentRef.getElementsByTagName('script')[0]
  firstScriptElement.parentNode?.insertBefore(agentScriptElement, firstScriptElement)
}

export const configurePendo = async (pendoParams: PendoParams) => {
  const API_KEY = '09a65695-6563-4c7c-6349-ba8728f926f3'
  const windowRef = getWindow() as WindowPendo
  const documentRef = windowRef.document
  const {
    userKey,
    accountKey,
    entitlements,
    locale,
    product,
    pbxId,
    isContainer,
    licenses,
    isCalendarConnected,
    productEnabledDates,
    machineId,
    runId,
    pendoSrc,
  } = pendoParams

  await new Promise<void>((resolve, reject) =>
    appendPendoScriptToDom(
      windowRef,
      documentRef,
      API_KEY,
      pendoSrc,
      () => resolve(),
      // TODO: test this
      /* istanbul ignore next */
      () => reject(),
    ),
  )

  windowRef.pendo?.initialize({
    visitor: {
      id: userKey,
      entitlements,
      locale,
      product,
      goto_isContainer: isContainer,
      goto_licenses: licenses,
      goto_isCalendarConnected: await isCalendarConnected,
      goto_productEnabledDates: productEnabledDates.map(productEnabledDate => ({
        ...productEnabledDate,
        enabledDate: productEnabledDate.enabledDate?.toISOString(),
      })),
      goto_machineId: machineId,
      goto_runId: runId,
    },
    account: {
      id: accountKey,
      pbxId,
    },
  })
}

// TODO: test this
/* istanbul ignore next */
export const updateIPDData = ({ userData, accountData }: UpdateData) => {
  const w = getWindow() as WindowPendo
  w.pendo?.updateOptions({ visitor: userData, account: accountData })
}
