import type { ExtensionConfig } from '@goto/shell-common'

const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-surveys',
  url: new URL('/extensions/cc-surveys/@ucc/cc-surveys.mjs', location.origin),
  esModule: true,
  unguardedRoutes: ['/cc-surveys'],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

export default extensionConfig
