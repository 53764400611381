import type { RegisterApplicationConfig } from 'single-spa'
import type { CustomProps } from '../../common'
import type { GotoRegisteredApplications } from '../models'

export const findAppByName = (
  name: string,
  registeredExtensions: GotoRegisteredApplications,
  registeredInternals?: GotoRegisteredApplications,
): RegisterApplicationConfig<CustomProps> | undefined =>
  registeredExtensions.get(name) ?? registeredInternals?.get(name)
