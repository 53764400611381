import { LitElement, html } from 'lit-element'
import { getMobileOSDownloadInfo } from '../../common/user-agent-helpers'
import { getShellAnalytics } from '../../common/shell-api-helpers'
import { navigateToUrl } from '../../common/helpers'
import { t } from '../../directives/translate'
import styles from './mobile-badge.styles.scss'

export class MobileBadge extends LitElement {
  static readonly tagName = 'goto-mobile-badge'

  static get styles() {
    return styles
  }

  private handleDownloadMobileAppClick() {
    const mobileOSInfo = getMobileOSDownloadInfo()
    getShellAnalytics().track('GoTo > Download Mobile', { action: 'click', device: mobileOSInfo.type })
    navigateToUrl(mobileOSInfo.downloadUrl)
  }

  render() {
    return html`<div>
      <a href=${getMobileOSDownloadInfo().downloadUrl} @click=${this.handleDownloadMobileAppClick}>
        <img class="download-badge-img" alt=${t('Download mobile app')} src=${getMobileOSDownloadInfo().imageSrc}
      /></a>
    </div> `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-mobile-badge': MobileBadge
  }
}
