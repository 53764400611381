import type { LaunchRequest } from '@getgo/container-client'
import { getShellLogger } from '../../common/logger'
import { getNamespaces } from '../namespaces/namespace-manager'

export interface ShellNamespace {
  readonly commands: LauncherCommands
}

export type LaunchHandler = (launchRequest: LaunchRequest) => boolean

export interface LauncherCommands {
  // A simple command that accepts launch handlers from Experiences
  registerLauncherHandler(launchHandler: LaunchHandler): void
}

export const SHELL_NAMESPACE = 'shell'

/**
 * A simple class to allow bridging the Container Lifecycle Plugin
 * with Background services provided by an Experience Team.
 *
 * This lets the Container pass the details of launch triggers (such
 * as tel:, goto:, etc.) directly to an Experience's background service
 * and allow the Experience to react accordingly
 */
export class Launcher implements LauncherCommands {
  private constructor() { }
  private static instance: Launcher | undefined
  private readonly launchHandlers: LaunchHandler[] = []
  private currentLaunchRequest: LaunchRequest | null = null

  static getInstance() {
    if (!Launcher.instance) {
      Launcher.instance = new Launcher()
    }
    return Launcher.instance
  }

  private static getLaunchRequestForLogging(launchRequest: LaunchRequest) {
    return { url: launchRequest.url, params: launchRequest.params }
  }

  private static executeLaunchHandlerSafe(launchHandler: LaunchHandler, launchRequest: LaunchRequest) {
    try {
      return launchHandler(launchRequest)
    } catch (e) {
      getShellLogger().warn(
        'executeLaunchHandlerSafe',
        'Launch handler from an extension threw an error',
        Launcher.getLaunchRequestForLogging(launchRequest),
        e,
      )
      return false
    }
  }
  
  registerLauncherHandler(launchHandler: LaunchHandler) {
    const launcher = Launcher.getInstance()
    launcher.launchHandlers.push(launchHandler)

    // Check if the newly added launch handler can handle the pending launch request (if there is such).
    if (launcher.currentLaunchRequest) {
      const requestHandledSuccessfully = Launcher.executeLaunchHandlerSafe(launchHandler, launcher.currentLaunchRequest)
      getShellLogger().log('registerLauncherHandler', { requestHandledSuccessfully })

      if (requestHandledSuccessfully) {
        launcher.currentLaunchRequest = null
      }
    }
  }

  appLauncher(launchRequest: LaunchRequest) {
    getShellLogger().log('appLauncher', Launcher.getLaunchRequestForLogging(launchRequest))

    const launcher = Launcher.getInstance()
    const requestHandledSuccessfully = launcher.launchHandlers.some(launchHandler =>
      Launcher.executeLaunchHandlerSafe(launchHandler, launchRequest),
    )
    getShellLogger().log('appLauncher', { requestHandledSuccessfully })
    if (!requestHandledSuccessfully) {
      launcher.currentLaunchRequest = launchRequest
    }
  }

  setupLauncherCommands() {
    const launcher = Launcher.getInstance()
    const shellNamespace = getNamespaces().retrieve<ShellNamespace>(SHELL_NAMESPACE)
    shellNamespace.commands.registerLauncherHandler.addHandler(launcher.registerLauncherHandler)
  }
}
