import type { LogCategory } from '@getgo/goto-logger'
import { getShellLogger } from './logger'

type TryAndReturnProps<T> = {
  readonly func: () => T
  readonly defaultReturn?: any
  readonly logLevel?: LogCategory
}

type AsyncTryAndReturnProps<T> = Omit<TryAndReturnProps<T>, 'func'> & {
  readonly func: () => Promise<T>
}

/**
 * try/catch wrapper for function execution.
 * @func: The function to execute
 * @defaultReturn The default return value in case of error
 */
export const tryAndReturn = <T = unknown>(props: TryAndReturnProps<T>) => {
  const { func, defaultReturn = null, logLevel = 'warn' } = props
  try {
    return func()
  } catch (e) {
    getShellLogger()[logLevel](e)
    return defaultReturn
  }
}

/**
 * try/catch wrapper for async function execution.
 * @func: The function to execute
 * @defaultReturn The default return value in case of error
 */
export const asyncTryAndReturn = async <T = unknown>(props: AsyncTryAndReturnProps<T>) => {
  const { func, defaultReturn = null, logLevel = 'warn' } = props
  try {
    return await func()
  } catch (e) {
    getShellLogger()[logLevel](e)
    return defaultReturn
  }
}
