import { getExternalInterface } from './external-interface-adapter'
import { type UpdateInfo } from '@getgo/container-client'

/**
 * Sends a message to install an update on the companion.
 */
export const sendMessageInstallUpdate = () => {
  getExternalInterface().send({
    type: 'companion-update',
    payload: {
      topic: 'install-update',
    },
  })
}

/**
 * Sends a message to the external interface to get the update status of the companion.
 */
export const sendMessageGetUpdateStatus = () => {
  getExternalInterface().send({
    type: 'companion-update',
    payload: {
      topic: 'get-update-status',
    },
  })
}

/**
 * Sends a message to notify that an update is available for the companion.
 */
export const sendMessageUpdateAvailable = (updateInfo: UpdateInfo) => {
  getExternalInterface().send({
    type: 'companion-update',
    payload: {
      topic: 'update-available',
      data: { updateInfo },
    },
  })
}
