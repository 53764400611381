import { UserProfileEntitlement } from '../../services/identity/models'
import { getShellApiInstance } from '../../common/shell-api-helpers'

const isG2C = (entitlement: UserProfileEntitlement) => entitlement === UserProfileEntitlement.GoToConnect

const isG2COrJive = (entitlement: UserProfileEntitlement) =>
  entitlement === UserProfileEntitlement.Jive || entitlement === UserProfileEntitlement.GoToConnect

const isGoToMeeting = (entitlement: UserProfileEntitlement) => entitlement === UserProfileEntitlement.GoToMeeting
const isGoToTraining = (entitlement: UserProfileEntitlement) => entitlement === UserProfileEntitlement.GoToTraining

const hasEntitlement = (is: (entitlement: UserProfileEntitlement) => boolean) =>
  getShellApiInstance()?.user.entitlements.some(is) ?? false

export const hasGotoConnectEntitlement = () => hasEntitlement(isG2C)
export const hasGotoConnectOrJiveEntitlement = () => hasEntitlement(isG2COrJive)
export const hasGoToMeetingEntitlement = () => hasEntitlement(isGoToMeeting)
export const hasGoToTrainingEntitlement = () => hasEntitlement(isGoToTraining)

export const hasGTCOrJiveOrG2MEntitlement = () => hasGotoConnectOrJiveEntitlement() || hasGoToMeetingEntitlement()

export const isGoToMeetingCustomer = () => {
  const entitlements = getShellApiInstance().user.entitlements

  return (
    entitlements.includes(UserProfileEntitlement.GoToMeeting) &&
    !entitlements.includes(UserProfileEntitlement.Jive) &&
    !entitlements.includes(UserProfileEntitlement.GoToConnect)
  )
}

/**
 * @deprecated only used for me.entitlements which is also deprecated. Use shell.user.entitlements instead
 */
export const mapEntitlementsFromRoles = (roles: readonly string[]) => {
  const entitlements: UserProfileEntitlement[] = []
  roles.forEach(role => {
    if (role.includes(UserProfileEntitlement.GoToMeeting.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.GoToMeeting)
    }
    if (role.includes(UserProfileEntitlement.GoToWebinar.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.GoToWebinar)
    }
    if (role.includes(UserProfileEntitlement.GoToTraining.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.GoToTraining)
    }
    if (role.includes(UserProfileEntitlement.GoToConnect.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.GoToConnect)
    }
    if (role.includes(UserProfileEntitlement.Jive.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.Jive)
    }
    if (role.includes(UserProfileEntitlement.HVoice.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.HVoice)
    }
    if (role.includes(UserProfileEntitlement.Grasshopper.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.Grasshopper)
    }
    if (role.includes(UserProfileEntitlement.OpenVoice.toUpperCase())) {
      entitlements.push(UserProfileEntitlement.OpenVoice)
    }
  })
  return [...new Set(entitlements)]
}
