import type { DateTimeFormatOptions } from '../../services/i18n'
import { type DayOfWeek } from './schedule-manager/models'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'

export const defaultWorkScheduleTime = {
  start: '09:00:00',
  end: '17:00:00',
}

export const defaultWorkScheduleDays: Array<DayOfWeek> = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']

export const timeFormatOptions: DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
}

export const dateTimeFormatOptions: DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: 'numeric',
  minute: 'numeric',
}

export const daysOfWeekLocalized: { [key in DayOfWeek]: string } = {
  MONDAY: asTranslationKey('Monday'),
  TUESDAY: asTranslationKey('Tuesday'),
  WEDNESDAY: asTranslationKey('Wednesday'),
  THURSDAY: asTranslationKey('Thursday'),
  FRIDAY: asTranslationKey('Friday'),
  SATURDAY: asTranslationKey('Saturday'),
  SUNDAY: asTranslationKey('Sunday'),
}
