import { ShellElement } from '../../../common/shell-element'
import { html, property, state } from 'lit-element'
import { t } from '../../../directives/translate'
import scheduleWorkHoursStyles from './schedule-work-hours.styles.scss'
import { listOfDays, type WorkPeriod } from '../schedule-manager/models'
import { daysOfWeekLocalized } from '../schedule-settings-models'
import { formatISOTimeRange } from '../schedule-settings-utils'
import { repeat } from 'lit-html/directives/repeat'
import { SVG_EDIT_OUTLINED } from '@getgo/chameleon-icons'
import { nothing } from 'lit-html'
import {
  CLOSE_WORK_HOURS_MODAL,
  GoToScheduleWorkHoursModal,
  MODIFIED_WORK_PERIODS,
  type ModifiedWorkHoursEvent,
} from '../schedule-work-hours-modal/schedule-work-hours-modal'
import { getDocument } from '../../../common/dom-helpers'
import { getDialogService } from '../../../services/dialog/public-facade'
import { asTranslationKey } from '../../../common/translate-helpers/i18n-utils'
import { getScheduleManager } from '../schedule-manager/schedule-manager'

const scheduleWorkHoursSeparatorTranslationKey = asTranslationKey('scheduleWorkHours.times.separator', {
  defaultValue: ',',
})

export class GoToScheduleWorkHours extends ShellElement {
  static readonly tagName = 'goto-schedule-work-hours'

  private workHoursModal: GoToScheduleWorkHoursModal | undefined

  static get styles() {
    return scheduleWorkHoursStyles
  }

  @property({ type: Boolean }) isScheduleEnabled = false
  @property({ type: Boolean }) isDNDHoursEnabled = false

  @state() modifiedWorkSchedule: WorkPeriod[] = []

  connectedCallback() {
    super.connectedCallback()
    this.modifiedWorkSchedule = [...(getScheduleManager().getCurrentWorkSchedule() ?? [])]
  }

  private getDefaultTypographyColor(isEnabled: boolean): string {
    return isEnabled ? 'type-color-default' : 'type-color-disabled'
  }

  private getSecondaryTypographyColor(isEnabled: boolean): string {
    return isEnabled ? 'type-color-secondary' : 'type-color-disabled'
  }

  private renderWorkHoursScheduleList() {
    return html`<div class="work-hours-schedule-list">
      ${repeat(
        listOfDays,
        dayOfWeek => dayOfWeek,
        dayOfWeek => {
          const oneDaySchedule = this.modifiedWorkSchedule.filter(
            (workPeriod: WorkPeriod) => workPeriod.dayOfWeek === dayOfWeek,
          )
          return this.renderWorkHoursScheduleListItem(oneDaySchedule, dayOfWeek)
        },
      )}
    </div>`
  }

  private renderWorkHoursScheduleListItem(oneDaySchedule: WorkPeriod[], dayOfWeek: string) {
    return html`
      <div class="work-hours-schedule-list-item ${oneDaySchedule.length ? '' : 'hidden'}">
        <div class="work-hours-schedule-list-item-day">
          <chameleon-typography color=${this.getDefaultTypographyColor(this.isScheduleEnabled)} tag="span">
            ${daysOfWeekLocalized[dayOfWeek]}
          </chameleon-typography>
        </div>
        ${this.renderWorkHoursScheduleListItemTime(oneDaySchedule)}
      </div>
    `
  }

  private renderWorkHoursScheduleListItemTime(oneDaySchedule: WorkPeriod[]) {
    return html` <div class="work-hours-schedule-list-item-time">
      ${repeat(
        oneDaySchedule,
        workPeriod => `${workPeriod.startTime}-${workPeriod.endTime}`,
        (workPeriod: WorkPeriod, index) => html`
          <chameleon-typography
            class="work-hours-schedule-list-item-time-value"
            color=${this.getDefaultTypographyColor(this.isScheduleEnabled)}
            >${formatISOTimeRange(workPeriod.startTime, workPeriod.endTime)}</chameleon-typography
          >
          ${oneDaySchedule.length - 1 == index ? nothing : this.renderWorkHoursScheduleListItemTimeSeparator()}
        `,
      )}
    </div>`
  }

  private renderWorkHoursScheduleListItemTimeSeparator() {
    return html`<div class="work-hours-schedule-list-item-time-separator">
      <chameleon-typography color=${this.getDefaultTypographyColor(this.isScheduleEnabled)} tag="span"
        >${t(scheduleWorkHoursSeparatorTranslationKey)}</chameleon-typography
      >
    </div>`
  }

  private renderWorkHoursNoSchedule() {
    return html`<chameleon-alert-v2>
      ${t(`You have no hours defined. Your availability won't change.`)}
    </chameleon-alert-v2>`
  }

  private renderEditButton() {
    return html`<chameleon-icon-button
      size="small"
      class="work-hours-schedule-edit"
      variant="secondary"
      ?disabled=${!this.isScheduleEnabled}
      label-id="set-work-hours-label"
      @click=${() => {
        getDialogService().open(this.createWorkHoursModal())
      }}
      ><chameleon-svg>${SVG_EDIT_OUTLINED}</chameleon-svg></chameleon-icon-button
    >`
  }

  private renderDNDSection() {
    const isEnabled = this.modifiedWorkSchedule.length ? this.isScheduleEnabled : false
    const isChecked = this.modifiedWorkSchedule.length ? this.isDNDHoursEnabled : false
    return html` <div class="dnd-section">
      <chameleon-checkbox ?disabled=${!isEnabled} ?checked=${isChecked}>
        <chameleon-typography color=${this.getDefaultTypographyColor(isEnabled)} tag="span">
          ${t('Do not disturb after hours')}
        </chameleon-typography>
        <chameleon-typography color=${this.getSecondaryTypographyColor(isEnabled)} variant="caption-medium"
          >${t('Automatically turn off notifications outside this schedule.')}
        </chameleon-typography>
      </chameleon-checkbox>
    </div>`
  }

  private createWorkHoursModal() {
    this.workHoursModal = getDocument().createElement(GoToScheduleWorkHoursModal.tagName)
    this.workHoursModal.addEventListener(MODIFIED_WORK_PERIODS, this.handleModifiedWorkPeriods)
    this.workHoursModal.addEventListener(CLOSE_WORK_HOURS_MODAL, this.handleCloseWorkHoursModal)
    return this.workHoursModal
  }

  private readonly handleModifiedWorkPeriods = (event: CustomEvent<ModifiedWorkHoursEvent>) => {
    getScheduleManager().updateWorkSchedule(event.detail.modifiedWorkPeriods)
    this.modifiedWorkSchedule = [...event.detail.modifiedWorkPeriods]
    this.closeWorkHoursModal()
  }

  private readonly handleCloseWorkHoursModal = () => {
    this.closeWorkHoursModal()
  }

  private closeWorkHoursModal() {
    // Removing the listeners when closing an instance of the modal. This is to avoid memory leaks as the modal can be opened and closed multiple times
    this.workHoursModal?.removeEventListener(MODIFIED_WORK_PERIODS, this.handleModifiedWorkPeriods)
    this.workHoursModal?.removeEventListener(CLOSE_WORK_HOURS_MODAL, this.handleCloseWorkHoursModal)
    this.workHoursModal = undefined
    getDialogService().close()
  }

  private renderWorkHoursScheduleSection() {
    if (this.modifiedWorkSchedule.length) {
      return this.renderWorkHoursScheduleList()
    }

    return this.renderWorkHoursNoSchedule()
  }

  render() {
    return html`
      <div class="work-hours-header">
        <chameleon-typography class="small-header" variant="heading-small" tag="h3"
          >${t('Work hours')}</chameleon-typography
        >
        <chameleon-typography variant="body-small" color="type-color-secondary" id="set-work-hours-label"
          >${t('Set your regular availability for the week.')}</chameleon-typography
        >
      </div>
      <div class="work-hours-body">
        <div class="work-hours-schedule ${!this.modifiedWorkSchedule.length ? 'align-item-center' : ''}">
          ${this.renderWorkHoursScheduleSection()} ${this.renderEditButton()}
        </div>
        <div class="work-hours-options">${this.renderDNDSection()}</div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-work-hours': GoToScheduleWorkHours
  }
}
