import { GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR } from '../../common/container'
import { createBlockedExtensionsBannerElement, getDocument } from '../../common/dom-helpers'
export class BlockedExtensionsBannerService {
  private blockedExtensionsBannerElement: HTMLElement | undefined

  show() {
    if (!this.blockedExtensionsBannerElement) {
      this.blockedExtensionsBannerElement = createBlockedExtensionsBannerElement()
      const criticalBannerContainer = getDocument().querySelector(GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR)
      criticalBannerContainer?.setAttribute('style', 'height:32px;')
      criticalBannerContainer?.appendChild(this.blockedExtensionsBannerElement)
    }
  }

  hide() {
    if (this.blockedExtensionsBannerElement) {
      this.blockedExtensionsBannerElement.remove()
      const criticalBannerContainer = getDocument().querySelector(GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR)
      if (!criticalBannerContainer?.childNodes?.length) {
        criticalBannerContainer?.removeAttribute('height')
      }
    }
  }
}
