import { getRolesFromAccounts } from '.'
import { mapEntitlementsFromRoles } from './entitlement'
import type {
  Account,
  AccountInfoFromGraphQL,
  License,
  LicenseInfoFromGraphQL,
  UserAdminInfo,
  UserInfo,
  UserProfileInfo,
} from './models'

/**
 * @deprecated Use shell.user instead
 */
export const convertUserInfoToUserProfileInfo = (userInfo: UserInfo) => {
  const me = { meta: {} } as UserProfileInfo
  const { accounts = [] } = userInfo

  if (!userInfo.user) {
    return undefined
  }

  if (userInfo.user.firstName || userInfo.user.lastName) {
    if (userInfo.user.firstName && userInfo.user.lastName) {
      ;(me.displayName as any) = `${userInfo.user.firstName} ${userInfo.user.lastName}`
    } else {
      ;(me.displayName as any) = userInfo.user.firstName ?? userInfo.user.lastName
    }
  }
  if (userInfo.user.location) {
    ;(me.meta as any).location = userInfo.user.location
  }
  if (userInfo.user.createTime) {
    ;(me.meta as any).created = new Date(+userInfo.user.createTime).toString()
  }
  if (userInfo.user.email) {
    ;(me.userName as any) = userInfo.user.email
    ;(me.emails as any) = [{ value: userInfo.user.email, type: 'primary', primary: true }]
  }
  if (accounts.some(account => (account.licenses ?? []).some(license => license.roles))) {
    ;(me.entitlements as any) = mapEntitlementsFromRoles(getRolesFromAccounts(accounts))
  }
  if (userInfo.user.key) {
    ;(me.id as any) = userInfo.user.key
  }
  if (userInfo.user.locale) {
    ;(me.locale as any) = userInfo.user.locale
  }
  if (userInfo.user.firstName || userInfo.user.lastName) {
    ;(me.name as any) = { givenName: userInfo.user.firstName, familyName: userInfo.user.lastName }
  }
  if (userInfo.user.timeZone) {
    ;(me.timezone as any) = userInfo.user.timeZone
  }
  return me
}

export const convertUserInfoToUserAdminInfo = (userInfo: UserInfo) => {
  const meFromExternalAdmin = {} as UserAdminInfo
  const { accounts = [] } = userInfo

  if (!userInfo.user) {
    return undefined
  }

  if (accounts.length) {
    ;(meFromExternalAdmin.accounts as any) = mapAccountsFromUserInfo(accounts)

    if (accounts[0]?.key) {
      ;(meFromExternalAdmin.accountKey as any) = accounts[0].key
    }
  }
  if (userInfo.user.email) {
    ;(meFromExternalAdmin.email as any) = userInfo.user.email
  }
  if (userInfo.user.firstName) {
    ;(meFromExternalAdmin.firstName as any) = userInfo.user.firstName
  }
  if (userInfo.user.key) {
    ;(meFromExternalAdmin.key as any) = userInfo.user.key
  }
  if (userInfo.user.lastName) {
    ;(meFromExternalAdmin.lastName as any) = userInfo.user.lastName
  }
  if (userInfo.user.locale) {
    ;(meFromExternalAdmin.locale as any) = userInfo.user.locale
  }

  return meFromExternalAdmin
}

/**
 * @deprecated Use shell.user.accounts instead
 */
const mapAccountsFromUserInfo = (
  accountsFromUserInfo: readonly AccountInfoFromGraphQL[] | undefined,
): readonly Account[] => {
  const accounts: Account[] = []
  accountsFromUserInfo?.forEach(account =>
    accounts.push({
      key: account.key,
      licenses: mapLicensesFromUserInfo(account.licenses ?? []),
      name: account.name,
      slowChannelEnabled: account.slowChannelEnabled,
      partnerAccountKey: account.partnerAccountKey,
    }),
  )
  return accounts
}

/**
 * @deprecated Use shell.user.accounts.licenses instead
 */
const mapLicensesFromUserInfo = (licensesFromUserInfo: readonly LicenseInfoFromGraphQL[]) => {
  const licenses: License[] = []
  licensesFromUserInfo.forEach(license => {
    licenses.push({
      channel: license.channel,
      description: license.description,
      enabled: license.enabled,
      key: license.key,
      products: mapEntitlementsFromRoles(license.roles ?? []),
      type: license.type,
    })
  })
  return licenses
}
