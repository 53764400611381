import { isUserAuthenticated } from '../../common/user-helpers'
import { getExtensionsManager } from '../../extensions/extensions-manager'
import { BlockedExtensionsBannerService } from '../../services/banner/blocked-extensions-banner'

export interface BlockedExtensionIdsFlag {
  block: string[]
}

export const initializeBlockedExtensionsBanner = () => {
  const blockedBannerService = new BlockedExtensionsBannerService()
  const blockedExtensionIds = getExtensionsManager().getBlockedExtensionIds()

  if (canShowBanner(blockedExtensionIds)) {
    blockedBannerService.show()
  } else {
    blockedBannerService.hide()
  }
}

const canShowBanner = (blockedExtensionIds: Set<string>) => blockedExtensionIds.size > 0 && isUserAuthenticated()
