import { DEFAULT_ISLOGGEDIN_STATE } from './constant'
import { Subject } from 'rxjs'

export class IsLoggedInState extends Subject<boolean> {
  private static instance: IsLoggedInState | null = null
  private isLoggedIn = DEFAULT_ISLOGGEDIN_STATE

  private constructor() {
    super()
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new IsLoggedInState()
    }
    return this.instance
  }

  public update(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn
    this.next(isLoggedIn)
  }

  public getLastIsLoggedInState() {
    return this.isLoggedIn
  }
}
