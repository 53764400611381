import { MetricsService } from './metrics-service'
import type { MetricsAPI } from './public-api'

let _metricsAPI: ReturnType<typeof getMetricsAPI>
let _metricsService: MetricsService

export const getMetricsAPI = (): MetricsAPI => {
  if (!_metricsAPI) {
    createMetricsAPI()
  }

  return _metricsAPI
}

const createMetricsAPI = () => {
  const metricsService = getMetricsService()

  _metricsAPI = {
    updateData: metricsService.updateData,
  }
}

const getMetricsService = () => {
  if (!_metricsService) {
    _metricsService = new MetricsService()
  }

  return _metricsService
}
