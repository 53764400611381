import type { DefaultPluginRegistry } from '@getgo/container-client'
import { Plugins, Container } from '@getgo/container-client'
import { getOSName } from '../../common/user-agent-helpers'

export const isContainer = () => Container.isNative

export const getDesktopVersion = async () =>
  isContainer() && Container.isPluginAvailable('ContainerInfo')
    ? (await Plugins.ContainerInfo.getInfo()).appVersion
    : 'N/A'

export const getPlatform = () => (isContainer() ? 'Desktop' : 'Web')

export const isWindowsContainer = () => getOSName() === 'Windows' && isContainer()

export const isMac = () => getOSName() === 'Mac OS'

export const isMacContainer = () => isMac() && isContainer()

/**
 * @description return a function for a defined plugin.
 * @param pluginName name of targetted plugin.
 * @param funcName name of targetted function of this plugin.
 */
export const getPluginFunction = <P extends keyof DefaultPluginRegistry, F extends keyof DefaultPluginRegistry[P]>(
  pluginName: P,
  funcName: F,
) => {
  if (!Container.isPluginAvailable(pluginName)) {
    return /* Plugin is unavailable. */
  }
  const plugin = Container.plugins[pluginName]
  if (!plugin[funcName]) {
    return /* This plugin's function is unavailable(upgrade needed) */
  }
  return plugin[funcName]
}
