import { setFavicon } from '../common/dom-helpers'
import { getBrandAssetsPath } from '../services/branding/brand-helpers'
import { isDarkTheme } from './theme'

const setFaviconFromBranding = () => {
  const rebrandFolder = 'gotoRebrand/'
  const darkThemeFolder = isDarkTheme() === true ? 'darktheme/' : ''

  const faviconPath = getBrandAssetsPath(rebrandFolder + darkThemeFolder + 'favicon.ico')

  setFavicon(faviconPath)
}

export const initializeFavicon = () => {
  setFaviconFromBranding()
  /* istanbul ignore next not useful for unit test as the arrow function is already tested and the matchMedia belongs to the browser */
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => setFaviconFromBranding())
}
