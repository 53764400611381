import { getOS, getOSName, getOSVersion } from '../../common/user-agent-helpers'
import { MacOSSupportBannerService } from '../../services/banner/macos-support-banner-service'
import { WindowsSupportBannerService } from '../../services/banner/windows-support-banner-service'
import { isContainer, isWindowsContainer } from '../../services/container/helpers'

export const initializeWindowsSupportBanner = () => {
  const winSupportBannerService = new WindowsSupportBannerService()
  return canShowWindowsSupportBanner() ? winSupportBannerService.show() : winSupportBannerService.hide()
}

/**
 * Verifies if the user is on a windows desktop version below than 10
 * @returns true/false
 */
const canShowWindowsSupportBanner = (): boolean => {
  const osMajorVersion = Number(getOS().major)
  return isWindowsContainer() && osMajorVersion < 10
}

export const initializeMacOSSupportBanner = () => {
  const macOSSupportBannerService = new MacOSSupportBannerService()
  return canShowMacOSSupportBanner() ? macOSSupportBannerService.show() : macOSSupportBannerService.hide()
}

/**
 * Verifies if the user is on a mac desktop version below than 11
 * @returns true/false
 */
const canShowMacOSSupportBanner = (): boolean => isContainer() && isMacOSDeprecatedVersion()

const isMacOSDeprecatedVersion = () => {
  const osName = getOSName()
  const osVersionSplit = getOSVersion()?.split('.')
  const simplifiedOSVersion = osVersionSplit?.shift() + '.' + osVersionSplit?.join('')
  if (osName === 'Mac OS' && Number(simplifiedOSVersion) < 10.15) {
    return true
  }
  return false
}
