import { onWindowUnload } from '../../common/helpers/window'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import type { BadgeNumberEvent, BadgeNumbersService } from '../../services/badge-numbers'
import { getPluginFunction } from '../../services/container/helpers'

export const initializeDockBadgeCount = () => {
  const { user, badgeNumbers } = getShellApiInstance()
  const badgeNumberValue = badgeNumbers.getBadgeNumberValue({ showInDock: true })
  const setBadgeCount = getPluginFunction('Badge', 'setBadgeCount')

  if (user.key && setBadgeCount) {
    setBadgeCount(badgeNumberValue)
    listenForBadgeNumberUpdateEvent(badgeNumbers)
  }
}

const listenForBadgeNumberUpdateEvent = (badgeNumbers: BadgeNumbersService) => {
  badgeNumbers.addEventListener('change', badgeNumberEventListener, { showInDock: true })

  onWindowUnload(() => {
    badgeNumbers.removeEventListener('change', badgeNumberEventListener)
  })
}

const badgeNumberEventListener = (event: BadgeNumberEvent) => {
  const setBadgeCount = getPluginFunction('Badge', 'setBadgeCount')
  if (setBadgeCount) {
    setBadgeCount(event.value)
  }
}
