/* istanbul ignore file */
import type {
  GlobalSearchCategory,
  GlobalSearchCategoryColumn,
  GlobalSearchContext,
  GlobalSearchEmptyState,
  GlobalSearchQuery,
  GlobalSearchValueField,
  CommandDefinition,
  GlobalSearchResultItem,
} from '@goto/shell-common'

/**
 * Default data type for search results
 */
type GlobalSearchResultItemDataType = {
  readonly [key: string]: unknown
}

export const DEFAULT_GLOBAL_SEARCH_PARAMS: GlobalSearchParams = {
  pageSize: 25,
  pageMarker: 0,
}

/**
 * Interface describing a global search query
 */
export interface GlobalSearchQueryUI {
  /**
   * search query
   */
  readonly query: string
}

/**
 * Interface to describe the global search result UI element
 */
export interface GlobalSearchResultUI<ResultItemDataType = GlobalSearchResultItemDataType> {
  readonly title: string
  readonly preview: string
  readonly userKey?: string
  readonly givenName?: string
  readonly familyName?: string
  readonly icon?: string
  readonly actions: readonly GlobalSearchActionUI[]
  readonly originalResult: GlobalSearchResultItem<ResultItemDataType>
  readonly defaultAction: GlobalSearchActionUI | undefined
}

/**
 * Interface to describe the UI element to be rendered for each action
 */
export interface GlobalSearchActionUI {
  readonly id: string
  readonly displayName: string
  readonly icon: string
  readonly command: CommandDefinition
}

export interface GlobalSearchColumnUI extends Omit<GlobalSearchCategoryColumn, 'displayNameKey'> {
  readonly displayName: string
}

export interface GlobalSearchEmptyStateUI extends Omit<GlobalSearchEmptyState, 'messageKey' | 'actions'> {
  readonly message: string
  readonly actions: readonly GlobalSearchActionUI[]
}

export interface GlobalSearchCategoryUI extends Omit<GlobalSearchCategory, 'columns' | 'emptyState'> {
  readonly columns: readonly GlobalSearchColumnUI[]
  readonly emptyState: GlobalSearchEmptyStateUI
}

/**
 * Interface for the Global Search API Error Response Body
 */
export interface GlobalSearchApiError {
  readonly message: string
  readonly errorCode: string
  readonly reference: string
}



/**
 * Interface describing a search result item with raw json data
 */
export interface GlobalSearchResultItemJSON<ResultItemDataType = GlobalSearchResultItemDataType>
  extends Omit<GlobalSearchResultItem<ResultItemDataType>, 'data'> {
  /**
   * Raw JSON data of the result data found for the given query
   */
  readonly data: string
}

/**
 * Interface describing the search response total object
 */
export interface GlobalSearchResultTotal {
  /**
   * The total number of results that were found for the given query
   */
  readonly value: number
  /**
   * The string description of the operation to be applied to the total value
   */
  readonly relation: string
}

/**
 * Interface describing the response object with the parsed result items
 */
export interface GlobalSearchResponse<ResultItemDataType = GlobalSearchResultItemDataType> {
  /**
   * The numerical value of the starting point for the next group of data to be received from the search API
   */
  readonly nextPageMarker: number
  /**
   * An object containing the number of total results found for a query
   */
  readonly total: GlobalSearchResultTotal
  /**
   * An array of result items containing the result data
   */
  readonly items: readonly GlobalSearchResultItem<ResultItemDataType>[]
}

/**
 * Interface describing the response object from the Global Search API
 */
export interface GlobalSearchResponseJSON extends Omit<GlobalSearchResponse, 'items'> {
  readonly items: readonly GlobalSearchResultItemJSON[]
}

/**
 * Interface describing the url params that can be passed through to the global search API
 */
export interface GlobalSearchParams {
  /**
   * The maximum number of items to be returned by the global search API
   *
   * Default is 25
   *
   * Maximum is 100
   */
  readonly pageSize: number
  /**
   * The starting number of items to be returned by the global search API
   *
   * Default is 0
   */
  readonly pageMarker: number
}

/**
 * Interface describing the global search manager
 */
export interface GlobalSearchManager<ResultItemDataType = GlobalSearchResultItemDataType> {
  /**
   * Initialize (load integrations) the global search manager
   */
  initialize(): Promise<void>

  /**
   * Returns the category of a search result record
   * @param result Search result record
   */
  getCategory(result: GlobalSearchResultItem<ResultItemDataType>): GlobalSearchCategoryUI | undefined

  /**
   * Returns the list of all categories
   *
   * @returns the list of categories
   */
  getCategories(): readonly GlobalSearchCategoryUI[]

  /**
   * Returns a search category object based on application name
   * @param application name of the application that is associated with the configured category
   * @returns a GlobalSearchCategory object
   */
  getCategoryByApplication(application: string): GlobalSearchCategoryUI | undefined

  /**
   * Returns an array of actions for the search result
   * @param searchResult Search result record
   * @param context Search result context.  Can be 'preview' or 'list'
   * @returns an array of global search actions
   */
  getGlobalSearchResultActions(
    searchResult: GlobalSearchResultItem<ResultItemDataType>,
    context: GlobalSearchContext,
  ): readonly GlobalSearchActionUI[] | undefined

  /**
   * Retrieves the actions that can be executed on a given search string
   * @param searchString string used to search
   * @param context Search context.  Can be 'preview' or 'list'
   */
  getActionsForSearchString(
    searchString: string,
    context: GlobalSearchContext,
  ): readonly GlobalSearchActionUI[] | undefined

  /**
   * Retrieves the queries for the search engine for a given search string
   * @param searchString string used to search
   */
  getQueriesForSearchString(searchString: string): readonly GlobalSearchQuery[]

  /**
   * Returns a GlobalSearchResultUI objects array based on the search results
   *
   * @returns the search result objects with the correct properties for displaying in the UI
   */
  convertToGlobalSearchResultUI(
    results: readonly GlobalSearchResultItem<ResultItemDataType>[],
    context: GlobalSearchContext,
  ): readonly GlobalSearchResultUI[]

  /**
   * Executes a search on the search engine for specified string
   * @param searchQuery query object
   * @param params url query params to be included in API call
   * @returns A promise of results coming from search engine
   */
  searchFor(searchQuery: GlobalSearchQueryUI, params?: GlobalSearchParams): Promise<GlobalSearchResponse | undefined>

  /**
   * Returns the string result of the search value field
   * @param result Search result record
   * @param field Search value field
   */
  getResultFieldValue(
    result: GlobalSearchResultItem<ResultItemDataType>,
    field?: GlobalSearchValueField | undefined,
  ): string | undefined

  /**
   * Returns true if Global Search has at least one category
   */
  hasCategories(): boolean
}
