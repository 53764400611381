import { getAppStateAPI } from '../app-state'
import { AppManagerService } from './app-manager-service'
import type { AppManagerAPI } from './public-api'

let _appManagerAPI: ReturnType<typeof getAppManagerAPI>
let _appManagerService: AppManagerService

export const getAppManagerAPI = (): AppManagerAPI => {
  if (!_appManagerAPI) {
    createAppManagerAPI()
  }

  return _appManagerAPI
}

const createAppManagerAPI = () => {
  const { quit } = getAppManagerService()

  _appManagerAPI = {
    quit: quit.bind(_appManagerService),
    state: getAppStateAPI(),
  }
}

const getAppManagerService = () => {
  if (!_appManagerService) {
    _appManagerService = new AppManagerService()
  }

  return _appManagerService
}
