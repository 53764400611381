import {
  ChannelEvent,
  CHANNEL_NOT_CREATED,
  NETWORK_NOT_RESPONDING,
  NETWORK_OFFLINE,
  REQUEST_TIMEOUT,
  SESSION_LOST,
  UNAVAILABLE,
  UNHANDLED_MESSAGE,
  WEBSOCKET_CLOSE,
  WEBSOCKET_ERROR,
} from './channel-event'
import { EventEmitter } from 'events'
import type {
  ChannelRequest,
  ChannelResponse,
  ChannelInfo,
  ChannelRefreshInfo,
  RTCError,
  NotificationChannelEvent,
} from './models'
import { getShellLogger } from '../../common/logger'
import { Interval, isRTCerror, shouldRetryRefreshChannel, Timer } from './helpers'
import { authenticatedFetch } from '../auth'
import { getRandomInt } from '../../core/helpers/math'

const stopTimer = (timerOrInterval: Timer | Interval | null) => {
  timerOrInterval?.stop()
}

export const isChannelResponse = (obj: unknown): obj is ChannelResponse =>
  typeof obj === 'object' && !!obj && 'code' in obj && 'correlationId' in obj && 'data' in obj

export abstract class Channel<TChannelInfo extends ChannelInfo> extends EventEmitter {
  private readonly requests = new Map<number, ChannelRequest>()
  private static readonly PING_COMMAND = 'ping'
  private static readonly REQUEST_TIMEOUT_INTERVAL_MS = 10000
  private static readonly PING_INTERVAL_MS = 8000
  private static readonly PING_TIMEOUT_INTERVAL_MS = 4000
  private static readonly REFRESH_THRESHOLD_IN_SECONDS = 20
  // Issued when a WS fails to connect, or disconnects abnormally (including 404)
  private static readonly NORMAL_CLOSE_CODE = 1000
  // private static readonly ABNORMAL_CLOSE_CODE = 1006
  // 	CloseEvent.code: Available for use by applications.
  private static readonly REFRESH_CLOSE_CODE = 4000
  private static readonly KICKED_BY_NEWER_CONNECTION = 'Kicked by newer connection'
  private static readonly GOING_AWAY_CLOSE_CODE = 1001

  protected static readonly DISCONNECTED_TIMEOUT_MS = 1000
  protected static readonly DEFAULT_CHANNEL_LIFETIME = 3600
  protected static readonly DEFAULT_HTTP_RETRY_MS = 750
  protected static readonly DEFAULT_HTTP_RETRY_NUMBER = 2
  protected static readonly DEFAULT_CREATE_CHANNEL_REFRESH_MS = 750
  protected readonly logger = getShellLogger()

  protected get isOnline() {
    return navigator.onLine
  }

  private attempts = 0
  private correlationId = 0

  private isTerminated = false
  public isConnected = false
  private existingSession = false

  private refreshTimeout: Timer | null = null
  private disconnectTimeout: Timer | null = null
  protected reconnectTimeout: Timer | null = null
  protected pingInterval: Interval | null = null

  private webSocket: WebSocket | null = null
  public id = ''
  private _url: string | null = null
  public channelInfo!: TChannelInfo

  constructor(protected readonly externalUserKey: string) {
    super()
    window.addEventListener('online', this.onlineStatusChanged)
    window.addEventListener('offline', this.onlineStatusChanged)
  }

  /**
   * Creates a new channel and connects to it via a websocket
   */
  async start() {
    if (!this.isOnline) {
      const reconnectionDelay = Channel.DISCONNECTED_TIMEOUT_MS + getRandomInt(1, 5) * 1000
      this.logger.log(`Connection offline, next reconnecting attempt in ${reconnectionDelay}ms`)
      stopTimer(this.reconnectTimeout)
      this.reconnectTimeout = new Timer(() => this.start(), reconnectionDelay)
      return
    }
    // Before create a new channel we must remove the previous one to ensure we don't reach the 50 websocket open limit
    try {
      await this.removeChannel()
    } catch (error) {
      this.logger.error('Failed to remove previous notificationChannel', error)
    }
    await this.createChannel()
    this.open()
  }

  /**
   * Closes the websocket connection
   */
  stop() {
    this.close()
  }

  /**
   * Clean existing channel info
   */
  resetChannelInfo() {
    this._url = null
    this.id = ''
    // TODO null is not an acceptable channelInfo type https://jira.ops.expertcity.com/browse/SCORE-1404
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.channelInfo = null as any as TChannelInfo
  }

  /**
   * Set the channel url and setup the refresh scheduler
   */
  set url(url: string | null) {
    if (this._url !== url) {
      this._url = url
      this.resetWebSocket()
      this.refresh()
    }
  }

  get url() {
    return this._url
  }

  /**
   * Get the current channel info from the backend
   *
   * @throws {@link HttpResponseError}
   * This exception is thrown when the Notification Channel is closed.
   *
   */
  protected async getChannel() {
    if (this.channelInfo) {
      const channelGetUrl = this.getChannelResourceURL(this.channelInfo)
      return await authenticatedFetch<TChannelInfo>(channelGetUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }
  }

  /**
   * Remove the channel to the backend
   */
  protected async removeChannel() {
    if (!this.channelInfo) {
      return
    }
    const channelRemoveUrl = this.getChannelResourceURL(this.channelInfo)
    await authenticatedFetch<TChannelInfo>(channelRemoveUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      retry: {
        maxRetryNumber: Channel.DEFAULT_HTTP_RETRY_NUMBER,
        retryAfterInMilliseconds: Channel.DEFAULT_HTTP_RETRY_MS,
      },
    })
  }

  /**
   * Retrieve the required informations for a channel creation
   */
  protected async createChannel() {
    const channelInfoUrl = this.getChannelInfoURL()
    try {
      this.channelInfo = await authenticatedFetch<TChannelInfo>(channelInfoUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          channelLifetime: Channel.DEFAULT_CHANNEL_LIFETIME,
        }),
        retry: {
          maxRetryNumber: Channel.DEFAULT_HTTP_RETRY_NUMBER,
          retryAfterInMilliseconds: Channel.DEFAULT_HTTP_RETRY_MS,
        },
      }).then(response => response.json())

      this.url = this.getChannelURL(this.channelInfo)
      this.id = this.getChannelID(this.channelInfo)
      if (!this.id) {
        throw {
          errorCode: 'INVALID_RESOURCE_URL',
          message: 'Invalid Resource URL from notification channel',
        }
      }

      this.logger.log('Loaded channel info', this.channelInfo)
    } catch (error) {
      this.logger.error('Failed to fetch notificationChannelInfo', error)
      // clean the channel info to avoid reconnection with wrong data
      this.resetChannelInfo()
      /* istanbul ignore next */
      stopTimer(this.reconnectTimeout)
      this.reconnectTimeout = new Timer(() => this.start(), Channel.DISCONNECTED_TIMEOUT_MS + getRandomInt(1, 5) * 1000)
    }
  }

  protected abstract getChannelURL(channelInfo: TChannelInfo): string
  protected abstract getChannelResourceURL(channelInfo: TChannelInfo): string
  protected abstract getChannelID(channelInfo: TChannelInfo): string
  protected abstract getChannelRefreshInfoURL(): string
  protected abstract getChannelInfoURL(): string

  /**
   * Open a new WebSocket connection
   */
  protected open() {
    if (this.isTerminated) {
      return
    }

    if (!this._url) {
      this.doChannelError(CHANNEL_NOT_CREATED)
      return
    }

    if (!this.isOnline) {
      this.logger.log(`Connection offline, next reconnecting attempt in ${Channel.DISCONNECTED_TIMEOUT_MS}ms`)

      stopTimer(this.reconnectTimeout)
      this.reconnectTimeout = new Timer(() => this.open(), Channel.DISCONNECTED_TIMEOUT_MS + getRandomInt(1, 5) * 1000)
      return
    }

    this.logger.log('Opening a new connection', this._url)

    this.startDisconnectTimeout()
    this.createWebSocket()
  }

  private createWebSocket() {
    // setting the this.ws to null is important because otherwise sometimes chrome does not release the previous WS
    this.webSocket = null

    /* istanbul ignore next */
    if (!this._url) {
      this.doChannelError(CHANNEL_NOT_CREATED)
      return
    }

    try {
      this.webSocket = new WebSocket(this._url)
    } catch {
      this.doChannelError(WEBSOCKET_ERROR)
      return
    }

    this.webSocket.onopen = () => this.onOpenHandler()
    this.webSocket.onclose = e => this.onCloseHandler(e)
    this.webSocket.onerror = e => this.onErrorHandler(e)
    this.webSocket.onmessage = e => this.onMessageHandler(e)
  }

  private closeWebSocket(code: number) {
    // Don't close the websocket if already closed
    // Closed could have already been done by the server
    if (this.webSocket?.readyState === this.webSocket?.CLOSED) {
      return
    }
    try {
      this.webSocket?.close(code)
      this.webSocket = null
    } catch (error) {
      this.logger.error(error)
    }
  }

  private startDisconnectTimeout() {
    stopTimer(this.disconnectTimeout)

    this.disconnectTimeout = new Timer(
      () => {
        const error: RTCError = !this.isOnline ? NETWORK_OFFLINE : NETWORK_NOT_RESPONDING
        this.updateConnectionState(false, error)
      },
      Channel.REQUEST_TIMEOUT_INTERVAL_MS + getRandomInt(1, 5) * 1000,
    )
  }

  protected clearRequests(reason = REQUEST_TIMEOUT) {
    this.requests.forEach(promise => promise.reject(reason))
    this.requests.clear()
  }

  protected resetWebSocket() {
    if (this.webSocket) {
      stopTimer(this.reconnectTimeout)
      stopTimer(this.pingInterval)
      this.clearRequests()
    }
  }

  protected close() {
    this.isTerminated = true
    this.closeWebSocket(Channel.NORMAL_CLOSE_CODE)
  }

  /**
   * Reschedule an update of the channel
   */
  private refresh() {
    this.refreshTimeout?.stop()
    this.refreshTimeout = new Timer(
      () => this.refreshChannel(),
      // Refresh should really not even be called if we don't have a channel (and its info)

      (this.channelInfo!.channelLifetime - Channel.REFRESH_THRESHOLD_IN_SECONDS - getRandomInt(1, 5)) * 1000,
    )
  }

  /**
   * Update the channel lifetime to keep the channel open
   * Close the WebSocket in case of error
   */
  private refreshChannel() {
    const refreshUrl = this.getChannelRefreshInfoURL()
    authenticatedFetch<ChannelRefreshInfo>(refreshUrl, {
      method: 'PUT',
      retry: {
        maxRetryNumber: Channel.DEFAULT_HTTP_RETRY_NUMBER,
        retryAfterInMilliseconds: Channel.DEFAULT_HTTP_RETRY_MS,
        retryOn: shouldRetryRefreshChannel,
      },
    })
      .then(response => response.json())
      .then(({ channelLifetime }) => {
        this.channelInfo = {
          ...this.channelInfo,
          channelLifetime,
        }
        this.refresh()
      })
      .catch(async e => {
        // The backend service could return a HTTP code 503 which means that the gtcc/notification service is closed or draining
        // This can also be because the application is offline or the gtcc/notification service return a HTTP code 404 which mean the web socket is not alive
        // It is safer to close the webSocket in each case and try to open a new one
        this.logger.error(`Could not refresh notification channel`, e)
        this.closeWebSocket(Channel.REFRESH_CLOSE_CODE)
      })
  }

  private sendPingCommand() {
    this.correlationId++

    const request = {
      correlationId: this.correlationId,
      command: Channel.PING_COMMAND,
    }

    return new Promise<unknown>((resolve, reject) => {
      /* istanbul ignore next */
      const timeout = () => {
        this.requests.delete(request.correlationId)
        return reject(REQUEST_TIMEOUT)
      }

      const requestTimer = new Timer(timeout, Channel.PING_TIMEOUT_INTERVAL_MS)

      const promise: ChannelRequest = {
        resolve,
        reject,
        command: Channel.PING_COMMAND,
        requestTimer,
      }

      this.requests.set(this.correlationId, promise)
      this.webSocket?.send(JSON.stringify(request))
    })
  }

  async onOpenHandler() {
    this.logger.log('WebSocket channel connection established')

    this.attempts = 0

    stopTimer(this.reconnectTimeout)
    stopTimer(this.disconnectTimeout)
    stopTimer(this.pingInterval)

    try {
      this.updateConnectionState(true)
      /* istanbul ignore next */
      this.requests.forEach(promise => promise.requestTimer.resume())

      this.existingSession = true
      this.createPingInterval()
    } catch (error) {
      this.logger.error('Authentication failure:', error)
      this.existingSession = false

      const rtcError = isRTCerror(error)
        ? error
        : {
            errorCode: 'AuthenticationFailure',
            message: 'Unhandled error',
          }

      this.clearRequests(rtcError)

      if (isRTCerror(error) && error.errorCode === UNAVAILABLE.errorCode) {
        /* istanbul ignore next */
        if (error.constraintViolations && error.constraintViolations.length > 0) {
          if (error.constraintViolations[0].constraint) {
            const retryAfter = Number.parseInt(error.constraintViolations[0].constraint, 10)
            stopTimer(this.reconnectTimeout)
            this.reconnectTimeout = new Timer(() => this.onOpenHandler(), (retryAfter + getRandomInt(1, 5)) * 1000)
          }
        }
      }

      this.updateConnectionState(false, rtcError)
    }
  }

  private onErrorHandler(wsEvent: Event) {
    this.logger.error('Error event', wsEvent)
    this.doChannelError(WEBSOCKET_ERROR)
  }

  private async restartConnection() {
    // Check the channel is definitively closed before start a new one otherwise try recreate a websocket
    let isChannelOpen = false
    try {
      await this.getChannel()
      this.resetWebSocket()
      this.createWebSocket()
      isChannelOpen = true
    } catch (error) {
      this.logger.error('Failed to fetch notificationChannelInfo', error)
    }
    // If the websocket is setup that mean the connection is restarted.
    if (isChannelOpen && this.webSocket) {
      return
    }

    // We know at this point that we need to create a brand new channel
    this.updateConnectionState(false, WEBSOCKET_CLOSE)
    stopTimer(this.refreshTimeout)
    stopTimer(this.reconnectTimeout)
    stopTimer(this.pingInterval)
    this.existingSession = false
    this.clearRequests(SESSION_LOST)

    this.attempts++
    const attemptDelayInSec = this.attempts > 5 ? 30 : 2 ** this.attempts
    const delayInMs = (attemptDelayInSec + getRandomInt(1, 5)) * 1000
    /* istanbul ignore next */
    this.reconnectTimeout = new Timer(() => this.start(), delayInMs)
  }

  private onCloseHandler(event: CloseEvent) {
    // Don't execute reconnection if the notification channel state doesn't need it
    switch (true) {
      case this.isTerminated:
      case this.webSocket?.readyState === 1:
      case event?.reason === Channel.KICKED_BY_NEWER_CONNECTION:
      case event?.code === Channel.GOING_AWAY_CLOSE_CODE:
        return
    }

    this.restartConnection()
  }

  onMessageHandler(event: MessageEvent) {
    try {
      const data = JSON.parse(event.data)
      /* istanbul ignore next */
      if (isChannelResponse(data)) {
        if (!this.requests.has(data.correlationId)) {
          this.doChannelError(UNHANDLED_MESSAGE)
          return
        }

        const promise = this.requests.get(data.correlationId)

        if (promise) {
          if (data.code >= 200 && data.code < 300) {
            if (promise.command !== Channel.PING_COMMAND) {
              this.logger.log('Receiving correlated message', data)
            }

            promise.resolve(data.data)
          } else {
            const error = data.error

            this.logger.error('Receiving error', error)
            promise.reject(error)
          }
        }

        this.requests.delete(data.correlationId)
        return
      }
      this.doChannelMessage(data)
    } catch (error) {
      this.doChannelError({
        errorCode: 'INVALID_JSON',
        message: error as string,
      })
    }
  }

  private readonly onlineStatusChanged = (event: Event) => {
    this.logger.log('Network condition', event.type)

    if (event.type !== 'online') {
      stopTimer(this.pingInterval)
      /* istanbul ignore next */
      this.requests.forEach(promise => promise.requestTimer.pause())
      this.updateConnectionState(false, NETWORK_OFFLINE)
      return
    }

    const shouldReopenWebSocket = !this.webSocket && this.existingSession

    if (shouldReopenWebSocket) {
      this.open()
    }

    stopTimer(this.pingInterval)

    this.ping()
    this.createPingInterval()
  }

  private readonly handlePingInterval = () => {
    this.ping()
  }

  private createPingInterval() {
    this.pingInterval = new Interval(this.handlePingInterval, Channel.PING_INTERVAL_MS)
  }

  private async ping() {
    if (!this.isOnline) {
      return
    }

    try {
      await this.sendPingCommand()
      this.updateConnectionState(true)
    } catch (error) {
      this.logger.error('Error:', error)

      if (this.isConnected) {
        this.updateConnectionState(false, isRTCerror(error) ? error : undefined)
      }

      stopTimer(this.pingInterval)

      this.clearRequests()

      this.open()
    }
  }

  private updateConnectionState(isConnected: boolean, error?: RTCError) {
    if (isConnected) {
      if (this.isConnected !== isConnected) {
        this.isConnected = isConnected
        this.doChannelConnected()
      }
    } else {
      this.isConnected = isConnected

      this.doChannelDisconnected(error!) // assume that if we're not connected then we have an error
    }
  }

  protected doChannelMessage(data: NotificationChannelEvent) {
    this.emit(ChannelEvent.MESSAGE, data)
  }

  protected doChannelError(error: RTCError) {
    this.emit(ChannelEvent.ERROR, error)
  }

  protected doChannelConnected() {
    this.emit(ChannelEvent.CONNECTED, this.existingSession)
  }

  protected doChannelDisconnected(error: RTCError) {
    this.emit(ChannelEvent.DISCONNECTED, error)
  }
}
