import { navigateToUrl } from '../../common/helpers'
import type { NavigationAPI } from './public-api'

export class NavigationService implements NavigationAPI {
  navigateMainWindowToUrl(relativeUrl: string) {
    // This call has always the main window as a context, due to how the `window.shell` API is set up.

    navigateToUrl(relativeUrl)
  }
}
