import { Environment, getCurrentEnvironment } from '@goto/shell'

export const getEnvironmentUrl = () => {
  switch (getCurrentEnvironment()) {
    case Environment.ED:
      return 'https://api.dev.jive.com'
    case Environment.RC:
      return 'https://api.dev.jive.com'
    case Environment.STAGING:
      return 'https://api.staging.jive.com'
    case Environment.PROD:
    default:
      return 'https://api.jive.com'
  }
}

export const isInPathname = (pathname: string, testValue: string) =>
  new RegExp(`(^${testValue}$|${testValue}/|${testValue}$)`).test(pathname)

export interface MeetingBackgroundService {
  createBackgroundService: () => void
}

export const getOOSAssetsBaseUrl = () => {
  switch (getCurrentEnvironment()) {
    case Environment.ED:
      return 'https://ed.conferences.test.expertcity.com/assets'
    case Environment.RC:
      return 'https://rc.conferences.test.expertcity.com/assets'
    case Environment.STAGING:
      return 'https://stage.conferences.goto.com/assets'
    case Environment.NIGHTLY:
      return 'https://conferences.goto.com/assets'
    case Environment.PROD:
    default:
      return 'https://conferences.goto.com/assets'
  }
}

export const getOOSArtifactUrl = (artifact: string, version?: string): string => {
  const baseUrl = getOOSAssetsBaseUrl()
  const artifactBase = `${baseUrl}/${artifact}`
  const versionFolder = (version ?? 'release').trim()
  return `${artifactBase}/${versionFolder}/index.js`
}
