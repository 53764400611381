import kebabcase from 'lodash.kebabcase'
import accentCharactersMap from './accent-characters'

export const isEmptyOrNullOrUndefined = (value: string | null | undefined) =>
  value === '' || value === null || value === undefined

export const not = (predicateFn: (value: string) => boolean) => (value: string) => !predicateFn(value)

/**
 * Encodes a string to base64url format
 *
 *  the '+' and '/' characters of standard Base64 are respectively replaced by '-' and '_',
 *
 * https://datatracker.ietf.org/doc/html/rfc4648#page-7
 * @param stringToEncode
 * @returns string
 */
export const encodeStringToBase64url = (stringToEncode: string) => {
  const base64string = btoa(stringToEncode)
  const slashRE = new RegExp(/\//, 'g')
  const plusRE = new RegExp(/\+/, 'g')
  const equalRE = new RegExp(/=/, 'g')

  return base64string.replace(slashRE, '_').replace(plusRE, '-').replace(equalRE, '')
}

/**
 * @param strings Array of strings, empty strings, undefined and or nulls
 * @param delimiter Optional character to delimit the strings in the result string
 * @returns The strings that are not null, empty or undefined, appended and delimited by the delimiter.
 */
export const appendStrings = (strings: readonly (string | undefined | null)[], delimiter = ', ') =>
  strings.filter(string => !isEmptyOrWhiteSpacesOrNullOrUndefined(string)).join(delimiter)

/**
 * Convert string to kebab case
 * @param string
 * @returns
 */
export const convertToKebabCase = (string: string, keepCharacter?: string) => {
  if (keepCharacter) {
    return string.split(keepCharacter).map(kebabcase).join('/')
  }
  return kebabcase(string)
}

/**
 * Validate if the string is empty or only contains spaces
 */
export const isEmptyOrOnlyWhiteSpaces = (string: string) => string.replace(/\s/g, '').length === 0

export const isEmptyOrWhiteSpacesOrNullOrUndefined = (value: string | null | undefined) =>
  value === '' || value === null || value === undefined || value.replace(/\s/g, '').length === 0

const chars = Object.keys(accentCharactersMap).join('|')
const allAccentsRegExp = new RegExp(chars, 'g')
const hasAccentRegExp = new RegExp(chars, '')

/**
 * Replace accentuated characters with their equivalent
 * @param string string in which we want to remove accents
 * @returns string without accents
 */
export const removeAccents = (string: string): string =>
  replaceAccents(string, (accent: string) => asNoAccent(accent))

const replaceAccents = (string: string, replaceCallback: (accent: string) => string): string =>
  string.replace(allAccentsRegExp, (match: string) => replaceCallback(match))


export const hasAccents = (string: string): boolean => !!string.match(hasAccentRegExp)

export const asNoAccent = (char: string): string => accentCharactersMap[char] ?? char

export enum StringAnalysisPropertyValue {
  ALL = 'all',
  SOME = 'some',
  NONE = 'none',
  TRUE = 'true',
  POSSIBLE = 'possible',
  FALSE = 'false',
}

export const stringIsPhoneNumber = (string: string): StringAnalysisPropertyValue => {
  const phoneNumberReg =
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|;?ext\.?:?=?|extension:?)\s*(\d+))?$/i
  const onlyDigits = /^\d+$/
  if (phoneNumberReg.test(string)) {
    return StringAnalysisPropertyValue.TRUE
  }
  const cleanupPhoneNumber = /extension:?=?|;?ext=?.?:?\s*|[\sx*#.\-+()]*/gi
  const filteredString = string.replace(cleanupPhoneNumber, '')
  if (onlyDigits.test(filteredString)) {
    return StringAnalysisPropertyValue.POSSIBLE
  }
  return StringAnalysisPropertyValue.FALSE
}


export const stringContainsDigits = (string: string): StringAnalysisPropertyValue => {
  const stringWithoutWhitespace = string.replace(/\s+/g, '')
  const containsOnlyDigitsReg = /^\d+$/
  const containsDigitsReg = /\d/g
  if (containsOnlyDigitsReg.test(stringWithoutWhitespace)) {
    return StringAnalysisPropertyValue.ALL
  }
  if (containsDigitsReg.test(stringWithoutWhitespace)) {
    return StringAnalysisPropertyValue.SOME
  }
  return StringAnalysisPropertyValue.NONE
}

export const stringContainsLetters = (string: string): StringAnalysisPropertyValue => {
  const stringWithoutWhitespace = string.replace(/\s+/g, '')
  const containsOnlyLettersReg = /^[a-zA-Z]+$/g
  const containsLettersReg = /[a-zA-Z]/g
  if (containsOnlyLettersReg.test(stringWithoutWhitespace)) {
    return StringAnalysisPropertyValue.ALL
  }
  if (containsLettersReg.test(stringWithoutWhitespace)) {
    return StringAnalysisPropertyValue.SOME
  }
  return StringAnalysisPropertyValue.NONE
}

export const stringContainsSpecialCharacters = (string: string): StringAnalysisPropertyValue => {
  const stringWithoutWhitespace = string.replace(/\s+/g, '')
  const containsOnlySpecialCharactersReg = /^[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]+$/g
  const containsSpecialCharactersReg = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g
  if (containsOnlySpecialCharactersReg.test(stringWithoutWhitespace)) {
    return StringAnalysisPropertyValue.ALL
  }
  if (containsSpecialCharactersReg.test(stringWithoutWhitespace)) {
    return StringAnalysisPropertyValue.SOME
  }
  return StringAnalysisPropertyValue.NONE
}

export const stringWordCount = (string: string) => {
  const words = string.match(/\S+/g)
  if (words) {
    return words.length
  }
  return 0
}

export const stringContainsEmail = (string: string): StringAnalysisPropertyValue => {
  const containsOnlyEmail = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)+$/g
  const containsEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g
  if (containsOnlyEmail.test(string)) {
    return StringAnalysisPropertyValue.ALL
  }
  if (containsEmail.test(string)) {
    return StringAnalysisPropertyValue.SOME
  }
  return StringAnalysisPropertyValue.NONE
}

export const stringIsMeetingUrl = (string: string): StringAnalysisPropertyValue => {
  const meetingUrlReg = /^(https?:\/\/|)(www\.|)(meet\.goto\.com|gotomeet\.me|app\.goto\.com\/meeting|)\/?\/(\d{9})+$/g
  const possibleMeetingUrl =
    /^(https?:\/\/|)(www\.|)(meet\.goto\.com|gotomeet\.me|app\.goto\.com\/meeting|)\/?\/[/a-zA-Z_\-\d]+$/g

  if (meetingUrlReg.test(string)) {
    return StringAnalysisPropertyValue.TRUE
  }
  if (possibleMeetingUrl.test(string)) {
    return StringAnalysisPropertyValue.POSSIBLE
  }
  return StringAnalysisPropertyValue.FALSE
}

export const stringIsMeetingId = (string: string): StringAnalysisPropertyValue => {
  const nineDigitStringReg = /^\d{9}$/g
  const containsOnlyDigitsReg = /^[0-9]+$/g
  if (nineDigitStringReg.test(string)) {
    return StringAnalysisPropertyValue.TRUE
  }
  if (containsOnlyDigitsReg.test(string)) {
    return StringAnalysisPropertyValue.POSSIBLE
  }
  return StringAnalysisPropertyValue.FALSE
}

