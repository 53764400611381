import { html, LitElement, property, state, query } from 'lit-element'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import hotkeyInputStyles from './keyboard-shortcut-input.styles.scss'
import type { ButtonComponent } from '@getgo/chameleon-web'
import { getEmptyKeyCombination, getKeyCombinationInputValue } from '../../services/keyboard-shortcut/keyboard-shortcut'
import { SVG_CLOSE_OUTLINED } from '@getgo/chameleon-icons'
import { type KeyboardShortcutKeyCombination } from '@getgo/container-client'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { type KeyboardShortcutErrorState } from './keyboard-shortcut.models'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { KeyboardShortcutErrorCodeTranslation } from './keyboard-shortcut-translation'

export class GoToKeyboardShortcutInput extends LitElement {
  static readonly tagName = 'goto-keyboard-shortcut-input'

  private initialKeyCombination: KeyboardShortcutKeyCombination = getEmptyKeyCombination()

  @query('chameleon-text-field') input: ButtonComponent | undefined

  @property({ type: String }) customValue: string | undefined = undefined
  @property({ type: Object }) keyCombination: KeyboardShortcutKeyCombination = getEmptyKeyCombination()
  @property({ type: Number }) position = -1
  @property({ type: Boolean }) isLocal = true
  @property({ type: Boolean }) isEditable = true
  @property() combinationsAreEqual:
    | ((keyCombination1: KeyboardShortcutKeyCombination, keyCombination2: KeyboardShortcutKeyCombination) => boolean)
    | undefined
  @property() validateKeyCombination:
    | ((keyCombination: KeyboardShortcutKeyCombination) => KeyboardShortcutErrorState)
    | undefined
  @property() saveKeyCombination:
    | ((
        KeyboardShortcutKeyCombination: KeyboardShortcutKeyCombination,
        position: number,
        isLocal: boolean,
      ) => Promise<boolean>)
    | undefined
  @property() clearKeyCombination: (() => Promise<boolean>) | undefined

  @state() private buttonKeyboardCombination: KeyboardShortcutKeyCombination = getEmptyKeyCombination()
  @state() private isHover = false
  @state() private errorState: KeyboardShortcutErrorState = { errorCodes: [], isInvalid: false }

  connectedCallback() {
    super.connectedCallback()
    this.buttonKeyboardCombination = this.keyCombination
    this.initialKeyCombination = this.keyCombination
  }

  static get styles() {
    return hotkeyInputStyles
  }

  private combinationsAreEqualFn(
    keyCombination1: KeyboardShortcutKeyCombination,
    keyCombination2: KeyboardShortcutKeyCombination,
  ): boolean {
    return this.combinationsAreEqual ? this.combinationsAreEqual(keyCombination1, keyCombination2) : false
  }

  private validateKeyCombinationFn(): KeyboardShortcutErrorState {
    return this.validateKeyCombination
      ? this.validateKeyCombination(this.buttonKeyboardCombination)
      : { errorCodes: [], isInvalid: false }
  }

  private async saveKeyCombinationFn() {
    if (this.saveKeyCombination) {
      return await this.saveKeyCombination(this.buttonKeyboardCombination, this.position, this.isLocal)
    }
    return true
  }

  private async clearKeyCombinationFn() {
    if (this.clearKeyCombination) {
      return await this.clearKeyCombination()
    }
    return true
  }

  private onMouseEnter() {
    if (!this.isEditable) {
      return
    }
    this.isHover = true
  }

  private onMouseLeave() {
    if (!this.isEditable) {
      return
    }
    this.isHover = false
  }

  private addEventListenerToHandleKeyEvents() {
    window.document.addEventListener('keydown', this.handleKeyDown)
    window.document.addEventListener('keyup', this.handleKeyUp)
  }

  private removeEventListenerToHandleKeyEvents() {
    window.document.removeEventListener('keydown', this.handleKeyDown)
    window.document.removeEventListener('keyup', this.handleKeyUp)
  }

  private onClick() {
    if (!this.isEditable) {
      return
    }

    if (this.isHover) {
      this.addEventListenerToHandleKeyEvents()
    }
    this.isHover = false
  }

  private async onClickClearIcon(evt: MouseEvent) {
    evt.stopPropagation()
    evt.preventDefault()
    const success = await this.clearKeyCombinationFn()
    if (success) {
      this.errorState = { errorCodes: [], isInvalid: false }
      this.buttonKeyboardCombination = getEmptyKeyCombination()
      this.initialKeyCombination = getEmptyKeyCombination()
    }
  }

  private onFocusOut() {
    if (!this.isEditable) {
      return
    }

    this.removeEventListenerToHandleKeyEvents()
    this.isHover = false
  }

  private readonly handleKeyDown = (evt: KeyboardEvent) => {
    this.buttonKeyboardCombination = {
      code: evt.code,
      altKey: evt.altKey,
      shiftKey: evt.shiftKey,
      metaKey: evt.metaKey,
      ctrlKey: evt.ctrlKey,
    }
  }

  private readonly handleKeyUp = async () => {
    // If the user presses the same combination, the validation will produce an error "already in use", so we check
    // explicitly.
    const newCombination = !this.combinationsAreEqualFn(this.buttonKeyboardCombination, this.initialKeyCombination)
    if (newCombination) {
      this.errorState = this.validateKeyCombinationFn()
      await this.checkForErrorAndSaveKeyCombination()
    }

    this.isHover = true
    this.removeEventListenerToHandleKeyEvents()
  }

  private async checkForErrorAndSaveKeyCombination() {
    if (this.errorState.isInvalid) {
      this.buttonKeyboardCombination = this.initialKeyCombination
    } else {
      const success = await this.saveKeyCombinationFn()
      if (success) {
        this.initialKeyCombination = this.buttonKeyboardCombination
      } else {
        this.buttonKeyboardCombination = this.initialKeyCombination
      }
    }
  }

  private getInputValue() {
    return this.customValue ? this.customValue : getKeyCombinationInputValue(this.buttonKeyboardCombination)
  }

  render() {
    return html`
      <chameleon-text-field
        class=${`hotkey-shortcut-keys-input ${this.position > 0 ? 'input-left-space' : ''}`}
        value=${this.getInputValue()}
        @click=${() => this.onClick()}
        @mouseenter=${this.onMouseEnter}
        @mouseleave=${this.onMouseLeave}
        @focusout=${this.onFocusOut}
        ?error=${this.errorState.errorCodes.length > 0}
        readonly=${true}
        placeholder=${asTranslationKey('Not set')}
      >
        ${this.errorState.errorCodes.length > 0
          ? html`<span slot="helpertext"
              >${getTranslation(KeyboardShortcutErrorCodeTranslation[this.errorState.errorCodes[0]])}</span
            >`
          : null}
        ${this.isHover && this.buttonKeyboardCombination && this.isEditable
          ? // eslint-disable-next-line lit-a11y/click-events-have-key-events
            html`<span
              slot="end"
              class="clear-key-combination-button"
              @click=${(evt: MouseEvent) => this.onClickClearIcon(evt)}
            >
              <chameleon-svg>${unsafeSVG(SVG_CLOSE_OUTLINED)}</chameleon-svg>
            </span>`
          : null}
      </chameleon-text-field>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-keyboard-shortcut-input': GoToKeyboardShortcutInput
  }
}
