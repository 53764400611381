import { MetaService } from './meta-service'
import type { MetaAPI } from './public-api'

let _metaAPI: ReturnType<typeof getMetaAPI>
let _metaService: MetaService

export const getMetaAPI = (): MetaAPI => {
  if (!_metaAPI) {
    _metaAPI = createMetaAPI()
  }
  return _metaAPI
}

const createMetaAPI = (): MetaAPI => {
  const metaService = getMetaService()
  const metaAPI: MetaAPI = {
    get appVersion() {
      return metaService.getAppVersion()
    },
    get shellVersion() {
      return metaService.getShellVersion()
    },
    get chameleonWebVersion() {
      return metaService.getChameleonWebVersion()
    },
    get useExtensions() {
      return true
    },
    get extensions() {
      return metaService.getExtensions()
    },
  }
  return Object.freeze(metaAPI)
}

const getMetaService = (): MetaService => {
  if (!_metaService) {
    _metaService = new MetaService()
  }
  return _metaService
}
