import type { WindowManagerOptions } from '@getgo/container-client'
import { Plugins } from '@getgo/container-client'
import { getShellLogger } from '../../common/logger'
import { isWindowGroupPluginAvailable } from '../../core/helpers/container-client'

export const isMainWindow = (window?: Window) => {
  // In some cases, we might have a Cross Origin Window opening up a Shell based app
  // Then, we need to attempt to access the Shell API to trigger an error early on
  // and handle the error to find out if the current window is indeed the root window
  try {
    return !(window ?? globalThis).opener?.shell
  } catch {
    return true
  }
}

if (isWindowGroupPluginAvailable() && isMainWindow()) {
  Plugins.WindowGroup.create('NavigationWindows')
}

export interface ShellWindowOptions extends WindowManagerOptions {
  readonly showTopBar?: boolean
  readonly showSideBar?: boolean
  /** @deprecated Use features.resizable. */
  readonly resizable?: boolean
  /** @deprecated Use features.scrollbars. */
  readonly showScrollbars?: boolean
  /** @deprecated Use features.titlebar. */
  readonly showTitlebar?: boolean
  /** @deprecated Use features.status. */
  readonly showStatus?: boolean
  /** @deprecated Use features.location. */
  readonly showLocation?: boolean
  /** @deprecated Use features.menubar. */
  readonly showMenubar?: boolean
  /** @deprecated Use features.toolbar. */
  readonly showToolbar?: boolean
  /** @deprecated Use features.height. */
  readonly height?: number
  /** @deprecated Use features.width. */
  readonly width?: number
}

const defaultOptions: Readonly<ShellWindowOptions> = {
  showTopBar: true,
  showSideBar: true,
  features: {
    resizable: true,
    scrollbars: true,
    titlebar: false,
    status: false,
    location: false,
    toolbar: false,
    menubar: false,
    height: 778,
    width: 992,
  },
}

export interface ShellWindow {
  navigateTo(url: string): void
  close(): void
}

interface ShellWindowPrivate extends ShellWindow {
  readonly window: Window
  readonly options?: ShellWindowOptions
}

// Probably not a good place but without attempting to export ShellWindowPrivate,
// this seems like the only viable solution for now
declare global {
  // eslint-disable-next-line
  var activeWindows: Map<Window, ShellWindowPrivate>
}

let activeWindows: Map<Window, ShellWindowPrivate>
if (isMainWindow()) {
  globalThis.addEventListener('beforeunload', () => {
    closeAllWindows()
  })
  globalThis.activeWindows = activeWindows = new Map<Window, ShellWindowPrivate>()
} else {
  activeWindows = (globalThis.opener as unknown as { activeWindows: Map<Window, ShellWindowPrivate> }).activeWindows
}

/**
 * Removes keys with undefined as value from an object (deep).
 * Ensures that undefined doesn't overwrite our default values.
 */
const removeUndefinedProps = <T>(object: T) => JSON.parse(JSON.stringify(object)) as T

/** Maps the deprecated options format to the new one. */
const mapOptions = (options: ShellWindowOptions = {}): ShellWindowOptions =>
  removeUndefinedProps({
    groupId: options.groupId,
    showTopBar: options.showTopBar,
    showSideBar: options.showSideBar,
    features: {
      resizable: options.resizable,
      scrollbars: options.showScrollbars,
      titlebar: options.showTitlebar,
      status: options.showStatus,
      location: options.showLocation,
      toolbar: options.showToolbar,
      menubar: options.showMenubar,
      height: options.height,
      width: options.width,
    },
  })

const mergeOptionsWithDefault = (options?: ShellWindowOptions) => ({
  ...defaultOptions,
  ...options,
  features: { ...defaultOptions.features, ...options?.features },
})

type OpenWindow = {
  /** @deprecated Please use `openWindow(url, name, options)` instead. */
  (url: string, options?: ShellWindowOptions): ShellWindow | undefined
  (url?: string, windowName?: string, options?: ShellWindowOptions): ShellWindow | undefined
}

export const openWindow: OpenWindow = (
  url: string,
  nameOrOptions?: string | ShellWindowOptions,
  opts?: ShellWindowOptions,
) => {
  let name: string | undefined
  let options: ShellWindowOptions | undefined

  if (typeof nameOrOptions === 'string' || typeof nameOrOptions === 'undefined') {
    name = nameOrOptions
    options = opts
  } else {
    name = url
    options = mapOptions(nameOrOptions)
  }

  return openNamedWindow(url, name, options)
}

// This is necessary when the user use the window close button
const disposeClosedWindows = () => {
  Array.from(activeWindows.values()).forEach(shellWwindow => {
    if (shellWwindow.window.closed) {
      shellWwindow.close()
    }
  })
  updateWindowsContext()
}

const unloadHandler = () => {
  globalThis.console.clear()
  // Make sure createdWindows are disposed if the user clicks on the browser window close button
  setTimeout(() => {
    disposeClosedWindows()
  }, 1000)
}

const openNamedWindow = (url?: string, name?: string, options?: ShellWindowOptions): ShellWindow | undefined => {
  const windowOptions = mergeOptionsWithDefault(options)
  const createdWindow = Plugins.WindowManager.createWindow(url, name, windowOptions)

  if (!createdWindow) {
    getShellLogger().error('Could not create a window')
    return
  }

  const shellWindow: ShellWindowPrivate = {
    window: createdWindow,
    options,
    close: () => closeWindow(createdWindow),
    navigateTo: url => createdWindow.location.assign(url),
  }

  const loadHandler = () => {
    disposeClosedWindows()
    activeWindows.set(createdWindow, shellWindow)
    updateWindowsContext()
  }

  createdWindow.addEventListener('load', loadHandler)
  createdWindow.addEventListener('unload', unloadHandler)

  return shellWindow
}

export const getWindowOptions = (window?: Window): ShellWindowOptions => {
  const shellWindow = activeWindows.get(window ?? globalThis.window)
  if (shellWindow) {
    return mergeOptionsWithDefault(shellWindow.options)
  }
  return defaultOptions
}

export const closeWindow = (window?: Window | null) => {
  const windowToClose = window ?? globalThis.window
  if (activeWindows.has(windowToClose)) {
    windowToClose.close()
    activeWindows.delete(windowToClose)
    updateWindowsContext()
  }
}

export const closeAllWindows = () => {
  Array.from(activeWindows.values()).forEach(shellWindow => shellWindow.close())
  updateWindowsContext()
}

export const isShellWindowActive = (shellWindow: ShellWindow) =>
  activeWindows.has((shellWindow as ShellWindowPrivate).window)

const updateWindowsContext = () => {
  getShellLogger().setContext('shell-windows', activeWindows.size)
}
