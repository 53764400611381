import { GoToCreateIncident } from '../../packages/dialogs/create-incident/create-incident'
import { getNamespaces } from './index'
import { getDocument } from '../../common/dom-helpers'
import { getDialogService } from '../dialog/public-facade'
import { hasUnifiedAdminPermission } from '../identity/permissions'
import { isResolveFlagEnabledInContextPBX } from '../phone-system/pbx-feature-flags'

export const RESOLVE_NAMESPACE = 'resolve'

export interface ResolveNamespace {
  readonly commands: {
    openCreateIncidentDialog(): void
  }
}

export const setupResolveCommands = async () => {
  const resolveNamespace = getNamespaces().retrieve<ResolveNamespace>(RESOLVE_NAMESPACE)

  if (hasUnifiedAdminPermission()) {
    const isResolveFeatureEnabled = await isResolveFlagEnabledInContextPBX()

    if (isResolveFeatureEnabled) {
      resolveNamespace.commands.openCreateIncidentDialog.addHandler(() => {
        getDialogService().open(getDocument().createElement(GoToCreateIncident.tagName))
      })
    }
  }
}
