import { type AccountInfoFromGraphQL, type LicenseInfoFromGraphQL } from '../../services/identity/models'
import type { ShellUserAccountLicense, ShellUserAccount, ShellUserAccountSettings } from './model'
import { ShellUserAccountLicenseImpl } from './user-account-license'

export class ShellUserAccountImpl implements ShellUserAccount {
  key: string
  name: string
  unifiedAdmin?: boolean | undefined
  licenses: readonly ShellUserAccountLicense[]
  settings: ShellUserAccountSettings
  slowChannelEnabled: boolean
  partnerAccountKey: string

  constructor(params: AccountInfoFromGraphQL) {
    this.key = params.key
    this.name = params.name
    this.unifiedAdmin = params.unifiedAdmin
    this.licenses = convertToUserAccountLicenses(params.licenses ?? [])
    this.settings = params.settings
    this.slowChannelEnabled = params.slowChannelEnabled
    this.partnerAccountKey = params.partnerAccountKey
  }
}

const convertToUserAccountLicenses = (
  licenses: readonly LicenseInfoFromGraphQL[] = [],
): readonly ShellUserAccountLicense[] =>
  licenses.reduce((acc, license) => [...acc, new ShellUserAccountLicenseImpl(license)], [])
