import { PrerequisiteRuleTypes, type ExtensionConfig } from '@goto/shell-common'
import { getOOSArtifactUrl } from '../helpers/experience-helpers'
import { getFeatureFlag } from '../helpers/shell-helpers'
import { UnleashFlags } from '../models/feature-flag.models'
import { createIntegrationConfigIfFlagEnabled } from '../helpers/integration-helpers'

const integrationFlagSuffix = 'oos-webinar-v2'

const extensionConfig: ExtensionConfig = {
  id: '@goto/oos-webinar-app-v2',
  get url() {
    const version = getFeatureFlag(UnleashFlags.OOS_WEBINAR_V2_VERSION, 'release')
    return new URL(getOOSArtifactUrl('webinar-v2-extension', version))
  },
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: 'g2w',
    [PrerequisiteRuleTypes.ACCOUNT_SETTINGS]: 'webinar.isWebinarGoToAppEnabled',
    [PrerequisiteRuleTypes.SHELL_FEATURE_FLAGS]: "oos-webinar-app-v2-enabled",
  },
  get integrationConfig() {
    return createIntegrationConfigIfFlagEnabled(integrationFlagSuffix)
  },
}

export default extensionConfig
