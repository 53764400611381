export const userReportScriptID = 'userreport-launcher-script'
import { userReportURL } from '../../environments'

export type UserReportActionType = 'initSite' | 'Feedback_Open'

export type UserReportEvent = readonly [UserReportActionType, string?]

/**
 * What is pushed to window._urq as an API to communicate with www.UserReport.com
 */
export type UserReportQueries = UserReportEvent[]

export interface UserReportAction {
  readonly type: UserReportActionType
  readonly uuid?: string
}

export interface UserReportSetup {
  openFeedbackDialog(): void
  mounted(): boolean
  mount(uuid: string): Promise<boolean>
}

const createScriptFragment = (w: WindowProxy): DocumentFragment => {
  const fragment = w.document.createDocumentFragment()
  const el = w.document.createElement('script')
  el.src = userReportURL
  el.async = true
  el.id = userReportScriptID
  fragment.appendChild(el)

  return fragment
}

export const setupUserReport = (windowProxy: WindowProxy & { _urq?: UserReportQueries }): UserReportSetup => {
  if (!windowProxy._urq) {
    windowProxy._urq = []
  }

  const mounted = (): boolean => {
    let wasMounted = false
    if (windowProxy.document.getElementById(userReportScriptID)) {
      wasMounted = true
    }
    return wasMounted
  }

  const pushUserReportAction = (action: UserReportAction) => {
    const { type, uuid } = action
    const actionArray = [type, uuid].filter(Boolean) as unknown as UserReportEvent
    if (mounted() === false) {
      throw new Error(`This event won't be tracked because you forgot to load a dependency`)
    }
    windowProxy._urq?.push(actionArray)
  }

  /**
   * Load dependencies and initialize www.UserReport.com.
   */
  const mount = (uuid: string): Promise<boolean> => {
    let wasMounted = false
    // https://j11y.io/snippets/asynchronous-innerhtml/
    const p = new Promise<boolean>(resolveWith => {
      windowProxy.setTimeout(() => {
        if (windowProxy.document.getElementById(userReportScriptID)) {
          wasMounted = true
        } else {
          const fragment = createScriptFragment(windowProxy)
          windowProxy.document.body.appendChild(fragment)
        }
        resolveWith(wasMounted)
      }, 0)
    })
    return p.then(wasMounted => {
      if (wasMounted === false) {
        pushUserReportAction({ type: 'initSite', uuid })
      }

      return wasMounted
    })
  }

  /**
   * The only thing this file is for; opening a feedback dialog!
   * (maintained by www.UserReport.com)
   */
  const openFeedbackDialog = () => {
    pushUserReportAction({ type: 'Feedback_Open' })
  }

  return {
    openFeedbackDialog,
    mounted,
    mount,
  }
}
