import { compareNumbers, getBrowserTimezone } from './helpers'
import { DateTime } from 'luxon'

export const DAY_IN_S = 86400
export const MIN_IN_S = 60
export const MILLISECOND = 1000

export const DATE_STRING_PATTERN_ISO8601 = 'yyyy-MM-dd'

/**
 * Takes two date strings that represent a range and validates said range
 */
export class DateRange {
  protected readonly from: string
  protected readonly to: string
  protected readonly pattern: string = DATE_STRING_PATTERN_ISO8601
  constructor(from: string | number, to: string | number) {
    let fromDate: DateTime
    let toDate: DateTime
    const timezone = getBrowserTimezone()

    if (typeof from === 'string') {
      fromDate = DateTime.fromFormat(from.toString(), this.pattern, { zone: timezone })
    } else {
      fromDate = DateTime.fromMillis(from, { zone: timezone })
    }

    if (typeof to === 'string') {
      toDate = DateTime.fromFormat(to.toString(), this.pattern, { zone: timezone })
    } else {
      toDate = DateTime.fromMillis(to, { zone: timezone })
    }

    const diff = toDate.diff(fromDate).toObject().milliseconds
    if (diff && diff > 1) {
      // Improvement possibility: manage this.from and this.to as DateTime object instead
      this.from = fromDate.toISODate() as string
      this.to = toDate.toISODate() as string
    } else {
      throw Error('The "to" date must be at least one day after the "from" date')
    }
  }

  toJSON(): Record<'to' | 'from', string> {
    return {
      from: this.from,
      to: this.to,
    }
  }
}

/**
 * Add a few methods that are needed when working with the Calendar Integration Service
 */
export class CISDateRange extends DateRange {
  constructor(from: string | number, to: string | number) {
    super(from, to)
  }

  /**
   * Returns a ISO_8601 date string
   * @example '2011-10-05'
   */
  toCISdate(): Record<'to' | 'from', string> {
    return this.toJSON()
  }

  /**
   * Get the Unix time of the date used in the request. Input a string, output a number.
   * Offset unix time for the date provided. Offset is based on local computer timezone.
   */
  toOffsetUnixTime(): Record<'to' | 'from', number> {
    return {
      from: getOffsettedUnixTime(this.from),
      to: getOffsettedUnixTime(this.to),
    }
  }

  /**
   * Returns a sorted array of unix time stamps.
   */
  toTzNormalizedUnixKeys(): number[] {
    return [...this.getTzNormalizedUnixDateRange()]
  }

  /**
   * Returns an array of unix timestamps for each day of the CISDateRange range.
   * The timestamps are normalized to be at 00:00:00 of each day, according to the user's timezone.
   */
  private getTzNormalizedUnixDateRange = (): readonly number[] => {
    const { from, to } = this.toOffsetUnixTime()

    const emptyDateRange = new Array(getDateRangeLength(from, to)).fill(undefined)

    const unixDateRange = emptyDateRange.map((_: undefined, i: number) => getKeyFromStartDate(from, i))

    return unixDateRange.sort(compareNumbers)
  }
}

/**
 * Get the Unix time of the date used in the request. Input a string, output a number.
 * @param date Format yyyy-MM-dd ( i.e. '2019-08-15')
 * @returns Offset unix time for the date provided. Offset is based on local computer timezone.
 */
export const getOffsettedUnixTime = (date: string) => {
  const timezone = getBrowserTimezone()

  const offSettedDate = DateTime.fromFormat(date.toString(), DATE_STRING_PATTERN_ISO8601, { zone: timezone })
  return offSettedDate.toSeconds()
}

/**
 * Calculate the Unix time value of a day, based on the Unix time of the first day of the request
 * @param startDay Unix time. First day of the request ('from')
 * @param dayAfterStart The current day after the first day. i.e. 0 is the current day, 1 is the first day after, etc.
 * @returns Unix time for the day.
 */
export const getKeyFromStartDate = (startDay: number, dayAfterStart: number) => startDay + dayAfterStart * DAY_IN_S

/**
 * Calculate the number of days contained in the request
 * @param from Unix time 'from' value
 * @param to Unix time 'to' value.
 * @returns The number of days in between 'from' and 'to'
 */
export const getDateRangeLength = (from: number, to: number) => Math.round((to - from) / DAY_IN_S)
