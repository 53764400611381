export enum PendoFunctions {
  initialize = 'initialize',
  identify = 'identify',
  updateOptions = 'updateOptions',
  pageLoad = 'pageLoad',
  track = 'track',
}

interface PendoFunctionCallRecord {
  _q: (readonly [string, readonly unknown[]])[]
}

export interface GoToLicense {
  readonly channel: string
  readonly description: string
  readonly enabled: boolean
  readonly key: string
  readonly products: readonly string[]
  readonly type: string
  readonly accountKey: string
}

export interface ProductEnabledDate {
  readonly productName: string
  readonly enabledDate?: Date
}
export interface PendoParams {
  readonly userKey?: string
  readonly accountKey?: string | readonly string[]
  readonly entitlements: readonly string[]
  readonly locale: string
  readonly product: string
  readonly pbxId: string
  readonly isContainer: boolean
  readonly isCalendarConnected: Promise<boolean>
  readonly licenses?: readonly GoToLicense[]
  readonly productEnabledDates: readonly ProductEnabledDate[]
  readonly machineId: string
  readonly runId: string
  readonly pendoSrc: string
}

export interface UpdateData {
  readonly userData?: unknown
  readonly accountData?: unknown
}

export type Pendo = { [K in PendoFunctions]: (...args: readonly unknown[]) => void } & PendoFunctionCallRecord

export type WindowPendo = Window & { pendo?: Pendo }
