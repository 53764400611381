import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getAccountAndPBXFromLocalStorage } from '../../services/context'
import { TimeZoneSource, type UserSchedule } from './schedule-manager/models'

export const getDefaultUserSchedule = (): UserSchedule => {
  const user = getShellApiInstance()?.user
  const accountAndPBXFromLocalStorage = getAccountAndPBXFromLocalStorage(user?.key ?? '')

  return {
    userId: accountAndPBXFromLocalStorage.pbxUserId ?? '',
    userKey: user?.key ?? '',
    orgId: accountAndPBXFromLocalStorage.pbxId ?? '',
    accountKey: accountAndPBXFromLocalStorage.accountKey ?? '',
    timezoneSource: TimeZoneSource.USER,
    timezone: user?.location?.timeZone,
    workPeriods: [],
    oneTimePeriods: [],
    dndAutomationEnabled: false,
    enabled: false,
    userCanEdit: true,
  }
}
