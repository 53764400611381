import type { EOMInterfaceIdentifier } from '../eom'

/**
 * Extension that want to get options from the application could
 * extend this interface.  This is used when registering extensions (in the app) and
 * when the extension is initialized.
 *
 * @example In app
 * ``` ts
 *     const config = { url: new Url('myUrl'), myOption: true }
 *     Shell.addExtensions([config])
 * ```
 *
 *  @example In extension
 *   ```ts
 *   const messagingExtension = {
 *      /.../
 *      initialize: (config) => {
 *        if (config.myOption) {
 *          // do something here based on option passed by application
 *        }
 *      }
 *      /.../
 *  ```
 *   }
 *
 */
export interface ExtensionConfig {
  /**
   * Extension Id
   */
  readonly id: string
  /**
   * URL where the extension's script and css are stored
   */
  readonly url: URL

  /**
   * optional list of unguarded routes that does not require authentication
   */
  readonly unguardedRoutes?: readonly string[]

  /**
   * Whether the extension is served as an ES Module
   */
  readonly esModule?: boolean

  /**
   * list of route patterns that will load only that extension and it's dependencies
   */
  readonly standaloneRoutes?: readonly string[]

  /**
   * List of extension identifiers that are necessary to load prior this extension
   */
  readonly dependencies?: readonly string[]

  /**
   * List of extension identifiers that are optional to load prior to this extension
   */
  readonly optionalDependencies?: readonly string[]

  /**
   * List of conditions that need to pass before an extension is loaded
   */
  readonly prerequisites?: Prerequisites

  /**
   * List of all interfaces identifiers supported by the extension
   */
  readonly supportedInterfaces?: readonly InterfaceIdentifier[]

  /**
   * Optional config for extensions running within integrations, e.g. the MS Teams integration.
   *
   * **MS Teams integration**
   *
   * Currently, the only integration we have is with MS Teams. We have an app for MS Teams called "GoTo". When the user
   * clicks on the GoTo app in MS Teams, we load app.goto.com/ms-teams in an iframe provided by MS Teams. We call this
   * the *integration* side. In general, we want the integration to load as fast as possible.
   *
   * The *companion* side is a special flavor of the container (the GoTo desktop app), called "GoTo for Teams plugin".
   * It runs with the main window hidden, but it still loads app.goto.com/ms-teams. Currently the only UI shown is the
   * Softphone window (when there is a call) and a status/systray icon.
   *
   * How do the integration and companion sides communicate:
   *  - The integration and the companion sides can communicate via custom messages over the `externalInterface` API.
   *  - There is no notification channel running in the integration side, only in the companion side. Events from the
   *    companion are automatically forwarded to and received in the integration side.
   *  - Events and commands from the integration to companion are controlled by `eventsToForwardToCompanion` and
   *    `commandsToForwardToCompanion` respectively.
   * 
   */
  readonly integrationConfig?: {
    /**
     * Indicates whether this extension should be loaded when running on mobile
     * If not specified, the default is false
     *
     * For the MS Teams integration, this means whether the extension will be loaded when running in the mobile app of
     * MS Teams. Extension teams should carefully evaluate if the extension is needeed on mobile, so as not to load
     * unnecessary code and slow down the load of the app.
     */
    readonly shouldLoadOnMobile?: boolean
    /**
     * Indicates whether this extension should be loaded when running in an integration
     * If not specified, the default is false
     *
     * For the MS Teams integration, this means whether the extension will be loaded when running in the desktop app of
     * MS Teams and in the browser version of MS Teams. Extension teams should carefully evaluate if the extension is
     * needeed in the integration side, so as not to load unnecessary code and slow down the load of the app.
     * You should also be aware that your experience will be loaded on mobile devices so it has to be validated by UX
     * for responsiveness.
     */
    readonly shouldLoadInIntegration?: boolean
    /**
     * Indicates whether this extension should be loaded when running in the companion app
     * If not specified, the default is false
     *
     * For the MS Teams integration, this means whether the extension will be loaded in the GoTo for Teams plugin. The
     * default reasoning is that the extension should be loaded in the companion only if one of the following is true:
     *  - it needs to be loaded in the companion while the integration is not loaded (e.g. to receive events from the
     *    notification channel in the companion)
     *  - even when the integration is loaded, if it needs to receive events from the notification channel, it needs the
     *    extension to be loaded in the companion (where the notification channel is running)
     *  - implements custom messages over the `externalInterface` API in order to communicate from the companion module
     *    to the integration in MS Teams
     */
    readonly shouldLoadInCompanion?: boolean
    /**
     * Optional list of events that should be forwarded from the integration to the companion app
     * If the namespace is given, but no events are specified, all events are forwarded.
     * If namespace and events are specified, only the listed events are forwarded.
     * To have no events forwarded, do not provide a namespace to this config.*/
    readonly eventsToForwardToCompanion?: EventsNamespaceDefinition[]
    /**
     * Optional list of commands that should be forwarded from the integration to be executed by the companion app
     * If the namespace is given, but no commands are specified, all commands are forwarded.
     * If namespace and commands are specified, only the listed commands are forwarded.
     * To have no commands forwarded, do not provide a namespace to this config.*/
    readonly commandsToForwardToCompanion?: CommandsNamespaceDefinition[]
  }
}

/**
 * Events namespace definition
 */
export interface EventsNamespaceDefinition {
  readonly namespace: string
  readonly eventNames?: string[]
}

/**
 * Command namespace definition
 */
export interface CommandsNamespaceDefinition {
  readonly namespace: string
  readonly commandNames?: readonly string[]
}

export type InterfaceIdentifier = EOMInterfaceIdentifier | string

export interface Prerequisites {
  readonly [PrerequisiteRuleTypes.ENTITLEMENTS]?: string
  readonly [PrerequisiteRuleTypes.ROLES]?: string
  readonly [PrerequisiteRuleTypes.PBX_FLAGS]?: string
  readonly [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]?: string
  readonly [PrerequisiteRuleTypes.SHELL_FEATURE_FLAGS]?: string
  readonly [PrerequisiteRuleTypes.ACCOUNT_SETTINGS]?: string
}

export enum PrerequisiteRuleTypes {
  ENTITLEMENTS = 'entitlements',
  ROLES = 'roles',
  PBX_FLAGS = 'pbxFlags',
  CONTACT_CENTER_FLAGS = 'ccFlags',
  SHELL_FEATURE_FLAGS = 'shellFlags',
  ACCOUNT_SETTINGS = 'accountSettings',
}
