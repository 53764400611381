/* istanbul ignore file */
import { Container, Plugins } from '@getgo/container-client'
import { getEventBus } from '../namespaces/event-bus'
import type { I18NEvents } from '../i18n'
import { I18NNamespace } from '../i18n'
import {
  closeAllWindows,
  closeWindow,
  getWindowOptions,
  isMainWindow,
  isShellWindowActive,
  openWindow,
} from './windows-management'
import { Launcher } from '../launcher'
import { onWindowUnload } from '../../common/helpers/window'

export * from './windows-management'

/**
 * Represents the API for the container service.
 */
export interface ContainerAPI {
  /**
   * Determines whether the specified window is the main window.
   * @param window The window to check.
   * @returns True if the specified window is the main window, false otherwise.
   */
  readonly isMainWindow: (window: Window) => boolean

  /**
   * Opens a new window with the specified options.
   * @param options The options for the new window.
   * @returns The new window.
   */
  readonly openWindow: typeof openWindow

  /**
   * Gets the options for the specified window.
   * @param window The window to get the options for.
   * @returns The options for the specified window.
   */
  readonly getWindowOptions: typeof getWindowOptions

  /**
   * Closes the specified window.
   * @param window The window to close.
   */
  readonly closeWindow: typeof closeWindow

  /**
   * Closes all open windows.
   */
  readonly closeAllWindows: typeof closeAllWindows

  /**
   * Determines whether the shell window is active.
   * @returns True if the shell window is active, false otherwise.
   */
  readonly isShellWindowActive: typeof isShellWindowActive
  readonly currentWindow: typeof Plugins.CurrentWindow
  readonly windowManager: typeof Plugins.WindowManager
  readonly containerSettings: typeof Plugins.ContainerSettings
  readonly keyboardShortcut: typeof Plugins.KeyboardShortcuts
}

export const containerAPI: ContainerAPI = {
  isMainWindow: (window: Window) => isMainWindow(window),
  openWindow: openWindow,
  getWindowOptions: getWindowOptions,
  closeWindow: closeWindow,
  closeAllWindows: closeAllWindows,
  isShellWindowActive: isShellWindowActive,
  currentWindow: Plugins.CurrentWindow,
  windowManager: Plugins.WindowManager,
  containerSettings: Plugins.ContainerSettings,
  keyboardShortcut: Plugins.KeyboardShortcuts,
}

export const initContainerEvents = () => {
  if (Container.isPluginAvailable('ContainerSettings')) {
    const { localeChanged } = getEventBus().subscribeTo<typeof I18NNamespace, typeof I18NEvents>(I18NNamespace)

    const setLocaleListener = (locale: string) => {
      Plugins.ContainerSettings.setLocale(locale)
    }

    localeChanged.on(setLocaleListener)

    onWindowUnload(() => {
      localeChanged.removeListener(setLocaleListener)
    })
  }

  handleContainerLaunchCommands()
}

/** Forwards launch commands from the Container to the Shell/Experiences. */
const handleContainerLaunchCommands = () => {
  if (!Container.isPluginAvailable('LifeCycle')) {
    return
  }

  Plugins.LifeCycle.appReady({
    launcher: (request: never) => Launcher.getInstance().appLauncher(request), // TODO: fix types on Jenkins
    quitter: (): void => undefined, // TODO: implement handling of quit requests
  })
}
