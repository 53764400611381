import { getDesktopVersion, getPlatform, getPluginFunction } from '../../services/container/helpers'
import { createMixpanel } from '../../services/analytics/mixpanel/initialize'
import { getCurrentLocale } from '../../services/i18n'
import { getFeatureFlags, getLaunchDarklyFlags } from '../../services/feature-flags'
import type { ShellHook } from './models'
import {
  getAuthContextAccountKeys,
  getAuthContextCurrentAccountKey,
  getAuthContextCurrentAccountLicenseDescriptions,
  getAuthContextCurrentAccountName,
  getAuthContextCurrentPartnerAccountKey,
  getAuthContextIsCalendarConnected,
  getAuthContextIsUserInternal,
  getAuthContextLicenseDescriptions,
  getAuthContextLicenses,
  getAuthContextPbxId,
  getAuthContextPbxInfo,
  getAuthContextRoles,
  getAuthContextUserCreationDate,
  getAuthContextUserProfileEntitlements,
  getAuthContextUserProfileId,
  getAuthContextUserTimezone,
} from '../helpers/auth-context'
import { getShellVersion } from '../../services/meta/helpers'
import { getCurrentEnvironment } from '../../environments'
import { getBrowserTimezone } from '../../services/calendar/helpers'
import { getOSName, getOSVersion } from '../../common/user-agent-helpers'
import { getProduct } from '../../common/helpers/product'

export const initializeMixpanel: ShellHook = async shell => {
  const authContext = shell.getAuthContext()
  const locale = getCurrentLocale()

  const getContext = getPluginFunction('ExecutionContext', 'getContext')
  const { machineId, runId } = getContext ? getContext() : { machineId: '', runId: '' }

  const desktopVersion = await getDesktopVersion()
  const Platform = getPlatform()

  createMixpanel(getAuthContextUserProfileId(authContext), {
    accountKeys: getAuthContextAccountKeys(authContext),
    Entitlements: getAuthContextUserProfileEntitlements(authContext),
    pbxId: getAuthContextPbxId(authContext),
    'Active PBX Region': getAuthContextPbxInfo(authContext)?.region,
    shellVersion: getShellVersion(),
    isCalendarConnected: getAuthContextIsCalendarConnected(authContext),
    Platform,
    desktopVersion,
    locale,
    launchDarklyFlags: Object.entries(getLaunchDarklyFlags() ?? {})
      .filter(([, value]) => Boolean(value))
      .map(([key]) => key),
    unleashFlags: getFeatureFlags(),
    licences: getAuthContextLicenses(authContext),
    licenseDescriptions: getAuthContextLicenseDescriptions(authContext),
    isInternal: getAuthContextIsUserInternal(authContext),
    machineId,
    runId,
    environment: getCurrentEnvironment(),
    OSPlatform: getOSName() ?? 'N/A',
    OSVersion: getOSVersion() ?? 'N/A',
    browserTimezone: getBrowserTimezone(),
    userTimezone: getAuthContextUserTimezone(authContext) ?? 'N/A',
    userCreationDate: getAuthContextUserCreationDate(authContext) ?? '',
    product: getProduct(),
    currentAccountName: getAuthContextCurrentAccountName(authContext),
    currentAccountLicenses: getAuthContextCurrentAccountLicenseDescriptions(authContext),
    roles: getAuthContextRoles(authContext),
    currentAccountKey: getAuthContextCurrentAccountKey(authContext),
    partnerAccountKey: getAuthContextCurrentPartnerAccountKey(authContext),
  })
}
