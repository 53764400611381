import { BadgeNumbersServiceImpl } from './badge-numbers'
import type {
  BadgeNumberEventListener,
  BadgeNumberEventType,
  BadgeNumberFilter,
  BadgeNumberIdentifier,
  BadgeNumberInfo,
} from './models'
import type { BadgeNumbersService } from './public-api'

let _badgeNumbersService: BadgeNumbersService

const getBadgeNumbersService = (): BadgeNumbersService => {
  if (!_badgeNumbersService) {
    _badgeNumbersService = new BadgeNumbersServiceImpl()
  }

  return _badgeNumbersService
}

export const getBadgeNumbersAPI = (): BadgeNumbersService => ({
  setBadgeNumber: <BNI extends BadgeNumberInfo>(badgeNumberInfo: BNI): void =>
    getBadgeNumbersService().setBadgeNumber(badgeNumberInfo),
  removeBadgeNumber: <BNI extends BadgeNumberIdentifier>(badgeNumberInfo: BNI): void =>
    getBadgeNumbersService().removeBadgeNumber(badgeNumberInfo),
  getBadgeNumberValue: (...filters: readonly BadgeNumberFilter[]): number =>
    getBadgeNumbersService().getBadgeNumberValue(...filters),
  addEventListener: (
    type: BadgeNumberEventType,
    listener: BadgeNumberEventListener,
    ...filters: readonly BadgeNumberFilter[]
  ): void => getBadgeNumbersService().addEventListener(type, listener, ...filters),
  removeEventListener: (type: BadgeNumberEventType, listener: BadgeNumberEventListener): void =>
    getBadgeNumbersService().removeEventListener(type, listener),
})
