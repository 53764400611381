import type { MetricsAPI } from './public-api'
import type { UpdateData } from './models'
import { updateIPDData } from '../pendo-integration/pendo-integration'

export class MetricsService implements MetricsAPI {
  constructor() {}

  updateData({ userData, accountData }: UpdateData) {
    updateIPDData({ userData, accountData })
  }
}
