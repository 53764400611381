import type { ShellUserAuthorityContext } from '../../core/models'
import { getShellLogger } from '../../common/logger'
import { getUrlSearchParamsStr } from '../../core/permission-helpers'
import { authenticatedFetch, getToken } from '../auth'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getJiveApiBaseUrl } from '../../core/environment'

export const getUserContextPermissions = async (pbxId: string) => {
  if (getToken() && pbxId) {
    try {
      const response = await authenticatedFetch<ShellUserAuthorityContext>(
        `${getJiveApiBaseUrl()}/contact-center-authority/v1/organizations/${pbxId}/user/context${getUrlSearchParamsStr()}`,
        {
          credentials: 'omit',
        },
      )
      return await response.json()
    } catch (error) {
      getShellLogger().error('error in API call for Contact Center Permissions', error)
    }
  }

  return { permissions: [], license: null } as ShellUserAuthorityContext
}

export const hasUnifiedAdminPermission = (): boolean => {
  const accountKey = getShellApiInstance().context?.account?.key

  if (accountKey) {
    const account = getShellApiInstance().user.getAccountByKey(accountKey)
    return !!account?.unifiedAdmin
  }

  return false
}
