export function getWindow(): Window {
  return window
}

export const getLocationPathname = () => getWindow().location.pathname

export const getFirstRouteElementFromPathname = (pathname: string): string => {
  const paths = pathname.toLowerCase().split('/')
  return paths[1] ?? ''
}

export const onWindowUnload = (callback: (e: Event) => void) => {
  window.addEventListener(
    'pagehide',
    (e: Event) => {
      callback(e)
    },
    { once: true },
  )
}

export const reloadPage = () => {
  window.location.reload()
}

export const getValueFromLocalStorage = <T>(key: string, defaultValue?: T) => {
  const value = localStorage.getItem(key)
  if (value !== null) {
    try {
      return JSON.parse(value)
    } catch (e) {
      return defaultValue
    }
  }
  return defaultValue
}
