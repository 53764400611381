import { getIntegrationMetaData, MS_TEAMS_INTEGRATION_ID } from './integrations-helpers'
import { chameleonThemeStylesheetBaseUrl } from '../environments'

enum FileName {
  msteams = 'msteams',
  gotoapp = 'gotoapp',
}

export const CHAMELEON_THEME_STYLE = 'chameleon-theme-style'
export const CHAMELEON_REBRANDING_STYLE = 'chameleon-rebranding-style'
export const CHAMELEON_COMPAT_STYLE = 'chameleon-compat-style'

export const DEFAULT_CHAMELEON_THEME_STYLE_VERSION = 'latest'

const createChameleonThemesUrl = (version: string, fileName: string) =>
  `${chameleonThemeStylesheetBaseUrl}/${version}/${fileName}.css`

const cssFileNames: { [key: string]: string } = {
  [MS_TEAMS_INTEGRATION_ID]: FileName.msteams,
  default: FileName.gotoapp,
}

const getCSSFileName = () => {
  const theme = getIntegrationMetaData()?.id || 'default'
  return cssFileNames[theme]
}

const addStyleElement = (id: string, url: string) => {
  const element = document.head.querySelector(`#${id}`)
  if (!element) {
    const linkElement = document.createElement('link')
    linkElement.id = id
    linkElement.rel = 'stylesheet'
    linkElement.href = url
    document.head.appendChild(linkElement)
  }
}

export const appendInHeaderChameleonStylesLinkElement = (version?: string) => {
  const validVersion = !version ? DEFAULT_CHAMELEON_THEME_STYLE_VERSION : version

  addStyleElement(CHAMELEON_THEME_STYLE, createChameleonThemesUrl(validVersion, getCSSFileName()))
  appendInHeaderTemporaryChameleonStylesLinkElement(validVersion)
}

// Appends temporary CSS files for chameleon-core V3 to have complete backwards compatibility, see https://chameleondesignsystem.com/resources/migrations/core-v3/
const appendInHeaderTemporaryChameleonStylesLinkElement = (validVersion: string) => {
  addStyleElement(CHAMELEON_COMPAT_STYLE, createChameleonThemesUrl(validVersion, `${getCSSFileName()}-compat`))
  addStyleElement(CHAMELEON_REBRANDING_STYLE, createChameleonThemesUrl(validVersion, 'rebranding'))
}
