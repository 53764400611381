import { retryGetToken } from '../auth/authenticatedFetch'

export const notificationChannelTokenResolver = (): Promise<string> =>
  new Promise((resolve, reject) => {
    retryGetToken()
      .then(token => {
        if (token?.access_token) {
          resolve(token.access_token)
        } else {
          reject()
        }
      })
      .catch(reject)
  })
