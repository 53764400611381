import type { LifeCycles } from 'single-spa'
import { loadCSS, loadScript } from './helpers'
import { getDocumentHead } from '../common/dom-helpers'
import { getShellLogger } from './logger'

/**
 * Configuration object for a dynamic experience.
 * This allow to specify resources used for the dynamic loading of an experience (css and javascript assets)
 */
export interface ExperienceLoaderOptions {
  // experience lifecycles application script
  readonly lifecycles: { readonly url: string; readonly id: string }
  // additional styles to load with the experience
  readonly cssUrls?: readonly string[]
  // additional scripts to load with the experience (vendors, externals libraries)
  readonly jsUrls?: readonly string[]
}

type ExperienceLifeCyclesModule = { readonly lifecycles: LifeCycles }

type ExperienceDefaultLoaderModule = { readonly default: () => Promise<LifeCycles> }

// @TODO will be removed by https://jira.ops.expertcity.com/browse/SCORE-1094
export const isExperienceLoaderOptions = (experience: unknown): experience is ExperienceLoaderOptions => {
  if (!experience) {
    return false
  }
  // eslint-disable-next-line no-prototype-builtins
  if (!['lifecycles'].every(key => (experience as Record<string, unknown>).hasOwnProperty(key))) {
    return false
  }

  const lifecycles = (experience as any)['lifecycles']
  // eslint-disable-next-line no-prototype-builtins
  if (!['url', 'id'].every(key => lifecycles.hasOwnProperty(key))) {
    return false
  }

  const validUrls = (urls: any) => {
    if (!urls) {
      return true
    }
    return Array.isArray(urls) && urls.every(url => typeof url === 'string')
  }
  if (!validUrls((experience as any)['cssUrls'])) {
    return false
  }
  if (!validUrls((experience as any)['jsUrls'])) {
    return false
  }

  return true
}

export const isExperienceLifeCyclesModule = (module: any): module is ExperienceLifeCyclesModule =>
  'lifecycles' in module

export const isExperienceDefaultLoaderModule = (module: any): module is ExperienceDefaultLoaderModule =>
  !('lifecycles' in module) && 'default' in module && typeof module.default === 'function'

export type ExperienceLoader = () => Promise<LifeCycles>

/**
 * Load an experience script
 * The script should be an object containing the lifecycles methods for an experience (bootstrap, mount, umount)
 * @param options - set of options to load the experience
 * @returns The experience Lifecycles
 */
export const createExperienceLoader = (options: ExperienceLoaderOptions): ExperienceLoader => {
  const { cssUrls, jsUrls, lifecycles } = options
  return async () => {
    if (Array.isArray(cssUrls)) {
      // Parallel load for css
      await Promise.all(
        cssUrls.map(cssUrl =>
          loadCSS(cssUrl, getDocumentHead())
            .catch(() => {
              getShellLogger().error(`Cannot load css asset ${cssUrl} for experience`)
            })
            .then(),
        ),
      )
    }
    if (Array.isArray(jsUrls)) {
      // Sequential load for js
      for (const jsUrl of jsUrls) {
        await loadScript(jsUrl)
      }
    }
    const { url, id } = lifecycles
    const currentModule = await loadScript<ExperienceLifeCyclesModule | LifeCycles>(url, {
      resolveWithGlobal: id,
    })
    // script expose a lifecycle property
    if (isExperienceLifeCyclesModule(currentModule)) {
      return currentModule.lifecycles
    }
    // script expose a default async function (returning a dynamic import)
    if (isExperienceDefaultLoaderModule(currentModule)) {
      return await currentModule.default()
    }
    // script expose a default experience
    return currentModule
  }
}
