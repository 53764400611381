import type { KeyboardShortcutConfigObject } from './keyboard-shortcut'
import { isMac } from '../container/helpers'

const macOsDefaultConfiguration: Partial<KeyboardShortcutConfigObject> = {
  newItem: [
    {
      keyCombination: {
        code: 'KeyN',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  toggleMute: [
    {
      keyCombination: {
        code: 'KeyM',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  toggleCamera: [
    {
      keyCombination: {
        code: 'KeyK',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  leaveMeetingOrHangUpCall: [
    {
      keyCombination: {
        code: 'Backspace',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  search: [
    {
      keyCombination: {
        code: 'KeyF',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  jumpTo: [
    {
      keyCombination: {
        code: 'KeyG',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  callCurrentContact: [
    {
      keyCombination: {
        code: 'Enter',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  openSoftphone: [
    {
      keyCombination: {
        code: 'Digit1',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  openSettings: [
    {
      keyCombination: {
        code: 'Comma',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  answerCall: [
    {
      keyCombination: {
        code: 'KeyA',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  declineCall: [
    {
      keyCombination: {
        code: 'KeyD',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  navigation1: [
    {
      keyCombination: {
        code: 'Digit1',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation2: [
    {
      keyCombination: {
        code: 'Digit2',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation3: [
    {
      keyCombination: {
        code: 'Digit3',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation4: [
    {
      keyCombination: {
        code: 'Digit4',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation5: [
    {
      keyCombination: {
        code: 'Digit5',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation6: [
    {
      keyCombination: {
        code: 'Digit6',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation7: [
    {
      keyCombination: {
        code: 'Digit7',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation8: [
    {
      keyCombination: {
        code: 'Digit8',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation9: [
    {
      keyCombination: {
        code: 'Digit9',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  showMeetingParticipants: [
    {
      keyCombination: {
        code: 'Digit1',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  showMeetingChatMessages: [
    {
      keyCombination: {
        code: 'Digit2',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  showSettings: [
    {
      keyCombination: {
        code: 'Digit3',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  showNotes: [
    {
      keyCombination: {
        code: 'Digit4',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  toggleViewMode: [
    {
      keyCombination: {
        code: 'KeyY',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  holdCall: [
    {
      keyCombination: {
        code: 'KeyH',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  transferCall: [
    {
      keyCombination: {
        code: 'KeyR',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  dial: [
    {
      keyCombination: {
        code: 'KeyD',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: false,
      },
      global: false,
    },
  ],
  addParticipants: [
    {
      keyCombination: {
        code: 'Equal',
        altKey: true,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
  parkCall: [
    {
      keyCombination: {
        code: 'KeyP',
        altKey: false,
        ctrlKey: false,
        metaKey: true,
        shiftKey: true,
      },
      global: false,
    },
  ],
}

const windowsDefaultConfiguration: Partial<KeyboardShortcutConfigObject> = {
  newItem: [
    {
      keyCombination: {
        code: 'KeyN',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  toggleMute: [
    {
      keyCombination: {
        code: 'KeyM',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  toggleCamera: [
    {
      keyCombination: {
        code: 'KeyK',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
      useKeyUp: true,
    },
  ],
  leaveMeetingOrHangUpCall: [
    {
      keyCombination: {
        code: 'Backspace',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  search: [
    {
      keyCombination: {
        code: 'KeyF',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  jumpTo: [
    {
      keyCombination: {
        code: 'KeyG',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  callCurrentContact: [
    {
      keyCombination: {
        code: 'Enter',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  openSoftphone: [
    {
      keyCombination: {
        code: 'Digit1',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  openSettings: [
    {
      keyCombination: {
        code: 'Comma',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  answerCall: [
    {
      keyCombination: {
        code: 'KeyA',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  declineCall: [
    {
      keyCombination: {
        code: 'KeyD',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  navigation1: [
    {
      keyCombination: {
        code: 'Digit1',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation2: [
    {
      keyCombination: {
        code: 'Digit2',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation3: [
    {
      keyCombination: {
        code: 'Digit3',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation4: [
    {
      keyCombination: {
        code: 'Digit4',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation5: [
    {
      keyCombination: {
        code: 'Digit5',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation6: [
    {
      keyCombination: {
        code: 'Digit6',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation7: [
    {
      keyCombination: {
        code: 'Digit7',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation8: [
    {
      keyCombination: {
        code: 'Digit8',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  navigation9: [
    {
      keyCombination: {
        code: 'Digit9',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  showMeetingParticipants: [
    {
      keyCombination: {
        code: 'Digit1',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  showMeetingChatMessages: [
    {
      keyCombination: {
        code: 'Digit2',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  showSettings: [
    {
      keyCombination: {
        code: 'Digit3',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  showNotes: [
    {
      keyCombination: {
        code: 'Digit4',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  toggleViewMode: [
    {
      keyCombination: {
        code: 'KeyY',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  holdCall: [
    {
      keyCombination: {
        code: 'KeyH',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  transferCall: [
    {
      keyCombination: {
        code: 'KeyR',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  dial: [
    {
      keyCombination: {
        code: 'KeyD',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: false,
      },
      global: false,
    },
  ],
  addParticipants: [
    {
      keyCombination: {
        code: 'Equal',
        altKey: true,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
  parkCall: [
    {
      keyCombination: {
        code: 'KeyP',
        altKey: false,
        ctrlKey: true,
        metaKey: false,
        shiftKey: true,
      },
      global: false,
    },
  ],
}

export const DefaultKeyboardConfigurationProvider = {
  get: (): Partial<KeyboardShortcutConfigObject> => (isMac() ? macOsDefaultConfiguration : windowsDefaultConfiguration),
}
