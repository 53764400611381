import { getEventBus, eventEmitter } from '../namespaces/event-bus'

export const InspectorPanelNamespace = 'inspector-panel'

export const InspectorPanelEvents = {
  opened: eventEmitter<void>(),
  closed: eventEmitter<void>(),
}

const { emit } = getEventBus().register<typeof InspectorPanelEvents>(InspectorPanelNamespace, InspectorPanelEvents)

export const emitInspectorPanelOpenedEvent = (): void => {
  emit.opened()
}

export const emitInspectorPanelClosedEvent = (): void => {
  emit.closed()
}
