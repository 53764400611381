import { getFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { DefaultKeyboardConfigurationProvider } from './default-keyboard-configuration-provider'
import type {
  KeyboardShortcutConfig,
  KeyboardShortcutConfigs,
  KeyboardShortcutId,
  KeyboardShortcutKeyCombination,
} from '@getgo/container-client'
import { Container, Plugins } from '@getgo/container-client'
import type { Hotkey, MacOSKeys, OtherOSKeys } from '../../packages/keyboard-shortcut/keyboard-shortcut.models'
import { isMac } from '../container/helpers'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { getShellLogger } from '../../common/logger'

const keyboardShortcutLocalStorageKey = 'goto-keyboard-shortcut'

export type KeyboardShortcutConfigObject = Record<KeyboardShortcutId, ReadonlyArray<KeyboardShortcutConfig>>

// Load Configuration from the localStorage
// Return: JSON
function getConfigurationFromExternalService(): Partial<KeyboardShortcutConfigObject> | undefined {
  const keyboardShortcutJSONConfig = getFromLocalStorage(keyboardShortcutLocalStorageKey)
  if (keyboardShortcutJSONConfig) {
    try {
      const keyboardShortcutObject: KeyboardShortcutConfigObject = JSON.parse(keyboardShortcutJSONConfig)
      if (
        keyboardShortcutObject &&
        Plugins.KeyboardShortcuts.systemInterface.isValidConfiguration(keyboardShortcutObject)
      ) {
        return keyboardShortcutObject
      } else {
        console.error('KeyboardShortcut plugin', 'KeyboardShortcut configuration is invalid')
        throw new Error(
          'KeyboardShortcut configuration is invalid, one or more parameter do not follow the structure required',
        )
      }
    } catch (e) {
      // TODO: do I use: Throw error / use default / use empty config ??
      console.error('KeyboardShortcut plugin', e)
      //throw new Error('Cannot parse the keyboard shortcut configuration sent as JSON')
      return undefined
    }
  }
  return undefined
}

export function saveConfigurationToExternalService(
  shortcutId: KeyboardShortcutId,
  shortcutIdConfig: ReadonlyArray<KeyboardShortcutConfig>,
) {
  let keyboardShortcutsConfig = getConfigurationFromExternalService()
  if (!keyboardShortcutsConfig) {
    keyboardShortcutsConfig = DefaultKeyboardConfigurationProvider.get()
  }
  keyboardShortcutsConfig[shortcutId] = shortcutIdConfig
  localStorage.setItem(keyboardShortcutLocalStorageKey, JSON.stringify(keyboardShortcutsConfig))
  return keyboardShortcutsConfig
}

export const getKeyboardShortcutConfiguration = (): Partial<KeyboardShortcutConfigObject> => {
  const externalConfiguration = getConfigurationFromExternalService()
  return externalConfiguration ? externalConfiguration : DefaultKeyboardConfigurationProvider.get()
}

export async function doLoadKeyboardShortcutConfiguration() {
  // Load Configuration : return Object
  const editedConfiguration = getConfigurationFromExternalService()
  // If a configuration is defined in the localStorage, that mean the user use a custom configuration
  // We update the config with the latest defaultConfig and use merged config as configuration for the KeyboardShortcut Plugin
  if (
    editedConfiguration &&
    Object.keys(editedConfiguration)?.length &&
    Plugins.KeyboardShortcuts.systemInterface.isValidConfiguration(editedConfiguration)
  ) {
    const newConfigUpdated = { ...DefaultKeyboardConfigurationProvider.get(), ...editedConfiguration }
    setToLocalStorage(keyboardShortcutLocalStorageKey, JSON.stringify(newConfigUpdated))
    await updatePluginWithConfiguration(newConfigUpdated)
    console.log(
      'KeyboardShortcut plugin',
      'Configuration updated from localStorage with new KeyboardShortcutId from the Default configuration',
    )
  } else {
    // Load default Config if the user do not have edited configuration
    console.log('KeyboardShortcut plugin', 'Configuration updated with Default configuration')
    await updatePluginWithConfiguration(DefaultKeyboardConfigurationProvider.get())
  }
}

// Update plugin with the configuration by converting the Object to a Map
async function updatePluginWithConfiguration(configs: KeyboardShortcutConfigs) {
  if (Container.isPluginAvailable('KeyboardShortcuts')) {
    await Plugins.KeyboardShortcuts.systemInterface.updateConfigurations(
      filterOutEmptyKeyCombinationFromConfigs(configs),
    )
  } else {
    console.error('KeyboardShortcut plugin', 'Parsing error with the configuration in JSON format')
    getShellLogger().error(`KeyboardShortcut plugin is not available`)
  }
}

// Conversion
type KeyboardShortcutKeyCombinationKeyList = keyof KeyboardShortcutKeyCombination
export const convertKeyCombinationToHotkey = (keyCombination: KeyboardShortcutKeyCombination): Hotkey => {
  const macOSKey: Array<MacOSKeys> = []
  const otherOSKey: Array<OtherOSKeys> = []

  const macOSSpecialKeysOrder: ReadonlyArray<Partial<KeyboardShortcutKeyCombinationKeyList>> = [
    'shiftKey',
    'ctrlKey',
    'altKey',
    'metaKey',
  ]
  const otherOSSpecialKeysOrder: ReadonlyArray<Partial<KeyboardShortcutKeyCombinationKeyList>> = [
    'ctrlKey',
    'shiftKey',
    'altKey',
    'metaKey',
  ]

  const specialCodeHidden = [
    'AltLeft',
    'AltRight',
    'ControlLeft',
    'ControlRight',
    'MetaLeft',
    'MetaRight',
    'ShiftLeft',
    'ShiftRight',
  ]

  const symbols: any = {
    shiftKey: {
      macOS: '⇧',
      otherOS: 'Shift',
    },
    ctrlKey: {
      macOS: '⌃',
      otherOS: 'Ctrl',
    },
    altKey: {
      macOS: '⌥',
      otherOS: 'Alt',
    },
    metaKey: {
      macOS: '⌘',
      otherOS: 'Windows',
    },
  }

  macOSSpecialKeysOrder.forEach(key => {
    if (keyCombination[key]) {
      macOSKey.push(symbols[key].macOS)
    }
  })

  otherOSSpecialKeysOrder.forEach(key => {
    if (keyCombination[key]) {
      otherOSKey.push(symbols[key].otherOS)
    }
  })

  if (keyCombination.code) {
    let codeUI = ''
    if (keyCombination.code.indexOf('Key') !== -1) {
      codeUI = keyCombination.code?.split('Key').pop() ?? ''
    } else if (keyCombination.code.indexOf('Digit') !== -1) {
      codeUI = keyCombination.code?.split('Digit').pop() ?? ''
    } else if (keyCombination.code === 'Comma') {
      codeUI = ','
    } else if (keyCombination.code === 'Equal') {
      codeUI = "'+'"
    } else if (keyCombination.code === 'Backspace' && isMac()) {
      codeUI = '⌫'
    } else if (specialCodeHidden.indexOf(keyCombination.code) !== -1) {
      codeUI = ''
    } else {
      codeUI = keyCombination.code
    }
    if (codeUI.length > 0) {
      macOSKey.push(codeUI as MacOSKeys)
      otherOSKey.push(codeUI as OtherOSKeys)
    }
  }

  return {
    macOSKey,
    otherOSKey,
  }
}

export const getHotKeyFormattedByOS = (hotkey: Hotkey): string => {
  if (isMac()) {
    return hotkey.macOSKey.map(key => key?.toString() ?? key).join('')
  } else {
    return hotkey.otherOSKey.join('+')
  }
}

const isValidKeyCombination = (keyCombination: KeyboardShortcutKeyCombination) => keyCombination?.code.length > 0

export const getEmptyKeyCombination = (): KeyboardShortcutKeyCombination => ({
  code: '',
  altKey: false,
  shiftKey: false,
  metaKey: false,
  ctrlKey: false,
})

export const getKeyCombinationInputValue = (keyCombination: KeyboardShortcutKeyCombination) => {
  if (isValidKeyCombination(keyCombination)) {
    return getHotKeyFormattedByOS(convertKeyCombinationToHotkey(keyCombination))
  }
  return asTranslationKey('Not set')
}

/** Removes `Not set` (empty) key combinations from the config. The UI and the local storage of the Keyboard Shortcuts
 * Settings page allows for `Not set` (empty) key combinations, which we need to filter out when providing the config to
 * the `KeyboardShortcuts` plugin. */
export const filterOutEmptyKeyCombinationFromConfig = (config: ReadonlyArray<KeyboardShortcutConfig>) =>
  config.filter(keyboardShortcutConfig => isValidKeyCombination(keyboardShortcutConfig.keyCombination))

/** Similar to `filterOutEmptyKeyCombinationFromConfig` but works for multiple configs. */
export const filterOutEmptyKeyCombinationFromConfigs = (configs: KeyboardShortcutConfigs) => {
  const newConfigs: KeyboardShortcutConfigs = {}
  Object.keys(configs).forEach(key => {
    newConfigs[key] = filterOutEmptyKeyCombinationFromConfig(configs[key])
  })
  return newConfigs
}

export class KeyCombinationValidator {
  private listOfKeyCombinationsUsed: KeyboardShortcutKeyCombination[] = []

  combinationsAreEqual(val1: KeyboardShortcutKeyCombination, val2: KeyboardShortcutKeyCombination): boolean {
    return (
      val1.code === val2.code &&
      val1.altKey === val2.altKey &&
      val1.shiftKey === val2.shiftKey &&
      val1.metaKey === val2.metaKey &&
      val1.ctrlKey === val2.ctrlKey
    )
  }

  isCombinationBeingUsed = (keyCombination: KeyboardShortcutKeyCombination): boolean =>
    this.listOfKeyCombinationsUsed.findIndex(key => this.combinationsAreEqual(key, keyCombination)) !== -1

  fillListOfUsedCombinationsFromConfig(config: Partial<KeyboardShortcutConfigObject>) {
    this.listOfKeyCombinationsUsed = []
    Object.values(config).forEach(keyboardShortcutConfigArray =>
      keyboardShortcutConfigArray?.forEach(keyboardShortcutConfig =>
        this.listOfKeyCombinationsUsed.push(keyboardShortcutConfig.keyCombination),
      ),
    )
  }
}
