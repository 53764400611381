import { Environment } from '@goto/shell'

export async function loadEnvironment() {
  try {
    const response = await fetch(location.href)
    window.environment = response.headers.get('x-amz-meta-gotoenv') as Environment
    console.log('window.environment', window.environment)
    return window.environment ?? Environment.PROD
  } catch {
    return Environment.PROD
  }
}
