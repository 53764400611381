import { type RulePrivate, type Rule } from './rule'

export type LogicalOperator = '&&' | '||'

export class LogicalOperatorRule implements Rule, RulePrivate {
  constructor(
    private rule1: Rule,
    private rule2: Rule,
    private operator: LogicalOperator,
  ) {}

  public isValid(): boolean {
    return this.operator === '&&'
      ? this.rule1.isValid() && this.rule2.isValid()
      : this.rule1.isValid() || this.rule2.isValid()
  }

  public getNames(): readonly string[] {
    const rulePrivate1 = this.rule1 as unknown as RulePrivate
    const rulePrivate2 = this.rule2 as unknown as RulePrivate

    return [...rulePrivate1.getNames(), ...rulePrivate2.getNames()]
  }
}
