import {
  type ShellExtension,
  type ExtensionConfig,
  type Settings,
  type SettingsSection,
  type NavigationItem,
} from '@goto/shell-common'
import { updateSettingsRoutes } from './settings'
import { type SettingsInfo } from '../../common/global-props'
import { createSettingsHref } from '../../common/settings'
import { isSettings } from './type-checking'
import { convertToKebabCase } from '../../common/helpers/string'
import { autoResolve } from '../../common/helpers'
import { type TranslatableFnOptions } from '../../common/translate-helpers/types'
import { getShellLogger } from '../../common/logger'
import { getFromLocalStorage, getLocationPathname, setToLocalStorage } from '../../common/dom-helpers'
import { LocalStorageKeys } from '../../environments'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getStoredCurrentAccount } from '../../common/user-helpers'
import { type ExtensionCache, type NavigationItemCache, type AccountCache, type UserCache } from './types'
import { getAccountFromContext } from '../../common/shell-api-helpers/context'
import { getExtensionsManager } from '../extensions-manager'
import { isMainWindow } from '../../services/container/windows-management'
import { isPathIncluded } from './application'

/**
 * Returns the key used to get the external user key from the user cache in the local storage
 * @returns external user key for the user cache in the local storage
 */
export const getUserCacheKey = (externalUserKey: string) => 'user-' + externalUserKey

/**
 * Returns the key used to get the account cache key from the UserCache in the local storage
 * @returns account key for the user cache in the local storage
 */
export const getAccountCacheKey = (accountKey: string) => 'account-' + accountKey

/**
 * Returns an empty array if there is an error loading the navigation items of the extension
 * @param extension Extension to get the navigation items
 * @returns
 */
export const getNavigationItems = (extension: ShellExtension): readonly NavigationItem[] => {
  let navItems: readonly NavigationItem[] = []
  try {
    navItems = extension.getNavigationItems()
  } catch (e) {
    getShellLogger().error(`${extension.id} failed to get navigation items`, e)
  }
  return navItems
}

/**
 * Creates a function which can return the translated key value or the raw key value for an extension
 * @param key Key of value to localize
 * @param extension Extension to use to get the localized value
 * @returns Translatable function
 */
export const createGetLocalizedStringFunction = (key: string, extension: ShellExtension) => {
  const translatableFn = (options: TranslatableFnOptions = {}) => {
    try {
      const { raw } = options
      if (raw) {
        return key
      }
      return extension.getString(key)
    } catch (e) {
      getShellLogger().error(`${extension.id} failed to get string with key "${key}"`, e)
    }
    return key
  }
  return translatableFn
}

/**
 * Create and store in the localstorage an AccountCache
 * @param extensions Array<ShellExtension>
 */
export const cacheAccountInfo = (extensions: readonly ShellExtension[]) => {
  const accountInfo = createAccountInfoForCache(extensions)
  setUserCacheToLocalStorage(accountInfo)
}

/**
 * Get the list of extension from the extensions manager and store the AccountCache built with the list of extensions
 * if it's the main window and not a standalone route
 */
export const updateCacheAccountInfo = () => {
  if (isMainWindow() && !isStandaloneRoute(getLocationPathname())) {
    cacheAccountInfo(getExtensionsManager().getExtensions())
  }
}

/**
 * Create an AccountCache with a list of extensions
 * @param extensions Array<ShellExtension>
 * @return AccountCache
 */

export const createAccountInfoForCache = (extensions: readonly ShellExtension[]): AccountCache => {
  const accountInfo: AccountCache = {
    timestamp: Date.now(),
    extensions: [] as ExtensionCache[],
  }
  extensions.forEach(extension => {
    const navigationItems = getNavigationItems(extension).map(navigationItem => {
      const getDisplayName = createGetLocalizedStringFunction(navigationItem.displayNameKey, extension)
      return {
        ...navigationItem,
        dataTest: navigationItem.dataTest ?? `${extension.id}/${navigationItem.id}`,
        displayName: getDisplayName(),
      } as NavigationItemCache
    })
    accountInfo.extensions.push({ extensionId: extension.id, navigationItems })
  })
  return accountInfo
}

/**
 * Set the AccountCache in the localStorage
 * @param accountInfo AccountCache
 */
export const setUserCacheToLocalStorage = (accountInfo: AccountCache) => {
  const externalUserKey = getShellApiInstance().user.key ?? ''
  const accountKey = getStoredCurrentAccount(externalUserKey).accountKey
  const userCacheFromLocalStorage = getUserCacheFromLocalStorage() ?? {}
  const updateUserCache: UserCache = {
    ...userCacheFromLocalStorage,
    [getUserCacheKey(externalUserKey)]: {
      ...userCacheFromLocalStorage[getUserCacheKey(externalUserKey)],
      [getAccountCacheKey(accountKey)]: accountInfo,
    },
  }
  setToLocalStorage(LocalStorageKeys.userCache, JSON.stringify(updateUserCache))
}

const getUserCacheFromLocalStorage = (): UserCache | undefined => {
  const userCache = getFromLocalStorage(LocalStorageKeys.userCache)
  if (userCache) {
    try {
      return JSON.parse(userCache)
    } catch {
      return undefined
    }
  }
}
/**
 * Returns the extension id from the current user cache, where the extension has a navItem whose pathname is included in the given url
 * @param url the url for which we want to find the matching extension id
 * @returns extension id for the extension that is active on the given url
 */

export const getExtensionIdForUrlFromCache = (url: string) => {
  const externalUserKey = getShellApiInstance().user.key
  const currentAccountKey = getAccountFromContext()?.key
  const userCache = getUserCacheFromLocalStorage()

  if (userCache && externalUserKey && currentAccountKey) {
    const currentUserCache = userCache[getUserCacheKey(externalUserKey)]
    if (currentUserCache) {
      const currentAccountCache = currentUserCache[getAccountCacheKey(currentAccountKey)]
      const currentExtensionCache = currentAccountCache?.extensions.find((extension: ExtensionCache) =>
        extension.navigationItems.some(({ pathname }: NavigationItemCache) => isPathIncluded(pathname, url)),
      )

      return currentExtensionCache?.extensionId
    }
  }
}

/**
 * return the list of extensions set in the cache from the local storage for this external user key and account key
 * @returns ExtensionCache array
 */
export const getExtensionsCacheFromLocalStorage = (): ExtensionCache[] => {
  const userCacheFromLocalStorage = getUserCacheFromLocalStorage()
  const externalUserKey = getShellApiInstance().user.key ?? ''
  const accountKey = getStoredCurrentAccount(externalUserKey).accountKey
  return (
    userCacheFromLocalStorage?.[getUserCacheKey(externalUserKey)]?.[getAccountCacheKey(accountKey)]?.extensions ?? []
  )
}

/**
 * Create the settingsInfo for the extension
 * This will produce a list of settings info that will be used as input by the GotoSettings Experience
 * As the SettingsInfo[] is not a tree structure the extension settings will be flatten to 2 levels
 * SettingsInfo[] is used by goto-specific-settings which only display sections and settings
 */
export function createExtensionsSettingsInfo(extensions: readonly ShellExtension[]): readonly SettingsInfo[] {
  let extensionsSettingsInfo: readonly SettingsInfo[] = []
  extensions.forEach(extension => {
    const settingsDefinitions = extension.getSettings()
    settingsDefinitions.forEach(settingsDefinition => {
      if (isSettings(settingsDefinition)) {
        const settingsInfo = convertSettingsToSettingsInfo(settingsDefinition, extension)
        extensionsSettingsInfo = extensionsSettingsInfo.concat(settingsInfo)
        return
      }
      const settingsInfo = convertSettingsSectionToSettingsInfo(settingsDefinition, extension)
      extensionsSettingsInfo = extensionsSettingsInfo.concat(settingsInfo)
    })
  })
  return extensionsSettingsInfo
}

function convertSettingsToSettingsInfo(settingsDefinition: Settings, extension: ShellExtension): SettingsInfo {
  const settingsInfo: SettingsInfo = {
    accordionName: createGetLocalizedStringFunction(settingsDefinition.displayNameKey, extension),
    settingsIcon: settingsDefinition.icon ?? '',
    isVisible: autoResolve(true),
    settingsLinksInfo: [
      {
        name: createGetLocalizedStringFunction(settingsDefinition.displayNameKey, extension),
        href: createSettingsHref(settingsDefinition.route),
      },
    ],
  }
  return settingsInfo
}

function convertSettingsSectionToSettingsInfo(
  settingsDefinition: SettingsSection,
  extension: ShellExtension,
): SettingsInfo {
  const settingsInfo: SettingsInfo = {
    accordionName: createGetLocalizedStringFunction(settingsDefinition.displayNameKey, extension),
    settingsIcon: settingsDefinition.icon ?? '',
    isVisible: autoResolve(true),
    settingsLinksInfo: updateSettingsRoutes(
      settingsDefinition.children,
      convertToKebabCase(settingsDefinition.displayNameKey),
    ).map(settings => ({
      name: createGetLocalizedStringFunction(settings.displayNameKey, extension),
      href: settings.route,
    })),
  }
  return settingsInfo
}

/**
 * Validate if the extensions has all the dependencies required from the extension configurations
 *
 * @param extensionConfig ExtensionConfig
 * @param extensionConfigs Array<ExtensionConfig>
 * @param dependencies
 *
 * @return Boolean
 */
export const hasAllDependencies = (
  extensionConfig: ExtensionConfig,
  extensionConfigs: readonly ExtensionConfig[],
  dependencies: Set<string> = new Set(),
): boolean => {
  if (!extensionConfig.dependencies) {
    return true
  }

  if (dependencies.has(extensionConfig.id)) {
    getShellLogger().error(`Extension ${extensionConfig.id} has circular dependencies`)
    return false
  }

  dependencies.add(extensionConfig.id)

  for (const extensionId of extensionConfig.dependencies) {
    const dependency = extensionConfigs.find(config => config.id === extensionId)
    if (!dependency || !hasAllDependencies(dependency, extensionConfigs, dependencies)) {
      return false
    }
  }

  dependencies.delete(extensionConfig.id)

  return true
}

/**
 * Add an extension if all the dependencies are loaded
 *
 * @param extensions Set with all extension
 * @param extensionConfigs Array<ExtensionConfig>
 * @param config ExtensionConfig
 */

export const addExtensionWithDependencies = (
  extensions: Set<string>,
  extensionConfigs: readonly ExtensionConfig[],
  config: ExtensionConfig,
) => {
  if (!extensions.has(config.id)) {
    if (hasAllDependencies(config, extensionConfigs)) {
      const dependencies = [...(config.dependencies ?? []), ...(config.optionalDependencies ?? [])]

      dependencies.forEach(dependencyId => {
        const dependency = extensionConfigs.find(extensionConfig => extensionConfig.id === dependencyId)
        if (dependency) {
          addExtensionWithDependencies(extensions, extensionConfigs, dependency)
        }
      })

      extensions.add(config.id) // Add the extension itself after all its dependencies
    } else {
      getShellLogger().error(`Cannot add all dependencies of Extension ${config.id}`)
    }
  }
}

/**
 * Validate if the route sent as parameter is a standalone route
 * @param route String
 * @return Boolean
 */
export const isStandaloneRoute = (route: string): boolean => getExtensionsManager().isStandaloneRoute(route)
