import { environment } from '../environments'
import { getExternalInterface } from '../services/external-interface'

export const getApiBaseUrl = () => environment().gotoApiBaseUrl

export const getJiveApiBaseUrl = () => environment().jiveApiBaseUrl

const getWebAuthUrlRedirectServiceBaseUrl = () =>
  `https://goto-desktop.goto.com/${getExternalInterface().isCompanion ? 'gotoforteams/' : ''}web/auth/`

export const getWebAuthRedirectUrlSignIn = () => `${getWebAuthUrlRedirectServiceBaseUrl()}sign-in.html`

export const getWebAuthRedirectUrlSignOut = () =>
  `${getWebAuthUrlRedirectServiceBaseUrl()}sign-out.html?signOutUrl=${encodeURIComponent(
    `${environment().authRedirectUrlContainerSignOut}=${encodeURIComponent(window.location.origin)}`,
  )}`

export const getExternalInterfacePorts = () => environment().externalInterfacePorts as number[]

/** Returns a URL (that should be opened in the external browser), which starts MS Teams (desktop app or web version)
 * and opens the GoTo app on the Home tab.
 *
 * For example, this is link that opens the production GoTo app within MS Teams:
 * https://teams.microsoft.com/l/entity/4b956fb6-b72c-48a6-b3e2-326693cc2881/HomeTab
 *
 * Note: On Windows, the MS Teams desktop app registers a univeral link handler for teams.microsoft.com. This means that
 * when opening such links via native APIs like `ShellExecuteEx`, this will start the desktop app of MS Teams directly,
 * if it is installed, without opening the browser first. If it is not installed, the browser will be opened. MS Teams
 * on Mac does not seem to have this feature.
 *
 * Note 2: MS Teams desktop app also supports scheme `msteams` on both Mac and Windows. Replacing `https` with `msteams`
 * in the link above will open the MS Teams desktop app directly, but only if it is installed. Something to keep in mind
 * if needed.
 */
export const getOpenMsTeamsUrl = () => {
  const env = environment();
  return `https://teams.microsoft.com/l/entity/${env.msTeamsManifestAppId}/${env.msTeamsManifestEntityId}`
}
