import type { KeyboardShortcutId } from '@getgo/container-client'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils';
import { type KeyboardShortcutAllValidationErrorCode } from './keyboard-shortcut.models';

export const KeyboardShortcutIdTranslation: Partial<Record<KeyboardShortcutId, string>> = {
  newItem: asTranslationKey('newItem'),
  toggleMute: asTranslationKey('toggleMute'),
  toggleCamera: asTranslationKey('toggleCamera'),
  // TODO: change translation key after leave meeting is implemented https://jira.ops.expertcity.com/browse/CONTAINER-1226
  leaveMeetingOrHangUpCall: asTranslationKey('hangUpCall'),
  search: asTranslationKey('search'),
  jumpTo: asTranslationKey('jumpTo'),
  callCurrentContact: asTranslationKey('callCurrentContact'),
  openSoftphone: asTranslationKey('openSoftphone'),
  openSettings: asTranslationKey('openSettings'),
  openMenuBarWindowsOnly: asTranslationKey('openMenuBarWindowsOnly'),
  answerCall: asTranslationKey('answerCall'),
  declineCall: asTranslationKey('declineCall'),
  navigation1: asTranslationKey('navigationKB'),
  showMeetingParticipants: asTranslationKey('showMeetingParticipants'),
  showMeetingChatMessages: asTranslationKey('showMeetingChatMessages'),
  showSettings: asTranslationKey('showSettings'),
  showNotes: asTranslationKey('showNotes'),
  recordMeeting: asTranslationKey('recordMeeting'),
  raiseHand: asTranslationKey('raiseHand'),
  startStopScreenShare: asTranslationKey('startStopScreenShare'),
  fullScreenMode: asTranslationKey('fullScreenMode'),
  toggleViewMode: asTranslationKey('toggleViewMode'),
  changeVideoFocus: asTranslationKey('changeVideoFocus'),
  holdCall: asTranslationKey('holdCall'),
  transferCall: asTranslationKey('transferCall'),
  dial: asTranslationKey('dial'),
  flip: asTranslationKey('flip'),
  flipToMeeting: asTranslationKey('flipToMeeting'),
  addParticipants: asTranslationKey('addParticipants'),
  parkCall: asTranslationKey('parkCall'),
}

export const KeyboardShortcutErrorCodeTranslation: Record<KeyboardShortcutAllValidationErrorCode, string> = {
  AlreadyUsedAnotherShortcut: asTranslationKey('AlreadyUsedAnotherShortcut'),
  AlreadyUsedGoTo: asTranslationKey('AlreadyUsedGoTo'),
  AlreadyUsedSystem: asTranslationKey('AlreadyUsedSystem'),
  // TODO: this error code is not currently generated by either goto-shell or `@getgo/container-client`, seems like an
  // omission, leaving with emtpy string for now. 
  GlobalSubscribeError: '',
}
