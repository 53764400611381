import { GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR } from '../../common/container'
import { createTrialBannerElement, getDocument } from '../../common/dom-helpers'
export class TrialBannerService {
  private trialBannerElement: HTMLElement | undefined

  show() {
    if (!this.trialBannerElement) {
      this.trialBannerElement = createTrialBannerElement()
    }
    const criticalBannerContainer = getDocument().querySelector(GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR)
    criticalBannerContainer?.setAttribute('style', 'height:32px;')
    criticalBannerContainer?.insertBefore(this.trialBannerElement, criticalBannerContainer.firstChild)
  }

  hide() {
    if (this.trialBannerElement) {
      this.trialBannerElement.remove()
      const criticalBannerContainer = getDocument().querySelector(GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR)
      if (!criticalBannerContainer?.childNodes?.length) {
        criticalBannerContainer?.removeAttribute('height')
      }
    }
  }
}
