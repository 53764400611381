/* eslint-disable @typescript-eslint/no-unused-expressions */
import type { BrowserAuthConfig } from '@getgo/container-client'
import { Plugins } from '@getgo/container-client'
import { ROOT_ROUTE } from '../../common/routes'
import { getWindow, navigateToExternal } from '../../common/dom-helpers'
import type { EnvironmentConfig } from '../../environments'
import { AuthBase } from './authentication.base'
import { authCodeVerificationKey } from './constants'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getTranslation } from '../i18n/i18nUtils'
import { getWebAuthRedirectUrlSignIn, getWebAuthRedirectUrlSignOut } from '../../core/environment'
import { isUserIdPluginAvailable } from '../../core/helpers/container-client'

export class AuthContainer extends AuthBase {
  private localServerUri: string | undefined
  private authorizationCode: string | undefined
  private isLoginInProgress = false
  /**
   * @description All the auth flow for the container side.
   * 1 - request the authorization code with the Browser Auth Plugin.
   * 2 - request the token.
   * 3 - redirect to the root page.
   * @param param configuration parameters.
   */
  async authProcess({ authUrl }: EnvironmentConfig, loginOnce: boolean, inflight?: string) {
    if (this.isLoginInProgress && loginOnce) {
      return
    }

    this.isLoginInProgress = true

    this.setLocalStorageEncodedNonce()

    const config = await this.getLoginConfig<{ inflightRequest?: string; [key: string]: unknown }>(
      this.getState(inflight),
      getWebAuthRedirectUrlSignIn(),
      `${authUrl}/oauth/authorize`,
    )
    const params = { ...config, final_redirect_uri: getWebAuthRedirectUrlSignIn() }

    try {
      const searchParams = await this.requestAuthorizationCode(params)
      await this.completeAuthProcess(searchParams)

      navigateToExternal(config?.state?.inflightRequest ?? ROOT_ROUTE)
      this.isLoginInProgress = false
    } catch (ex) {
      const showAuthenticationFailedBannerCallback = () =>
        this.showAuthenticationFailedBanner(() => {
          this.isLoginInProgress = false
        })

      this.authErrorHandler(ex, showAuthenticationFailedBannerCallback)
    }
  }

  private showAuthenticationFailedBanner(onclose: () => void) {
    const banner = getShellApiInstance().display.banner({
      description: getTranslation('Authentication failed.'),
      id: 'retry-login-container',
      closable: true,
      variant: 'neutral',
      link: {
        text: getTranslation('Please retry'),
        onClick: () => {
          banner?.close()
          onclose()
        },
      },
    })
  }

  private tryAndTimeout<T>(fn: Promise<T>): Promise<Awaited<T>> {
    let timer = 0
    const newTimer = () =>
      new Promise<T>(
        (_r, rej) =>
          (timer = window.setTimeout(
            () => rej(new Error("shell timeout - container authenticate didn't respond in time")),
            1000 * 60 * 20, // 20 minutes
          )),
      )

    return Promise.race([fn, newTimer()]).finally(() => clearTimeout(timer))
  }

  /**
   * @description Use the Browser auth plugin from the container client to get the local server uri and the authorization code.
   * @param config configuration needed for the plugin.
   * @return the query string parameters.
   */
  private readonly requestAuthorizationCode = async (config: BrowserAuthConfig) => {
    const localServerUriWithParams = await this.tryAndTimeout(Plugins.BrowserAuth.authenticate(config))

    this.localServerUri = localServerUriWithParams.redirect_uri
    const searchParams = new URLSearchParams(localServerUriWithParams.query_string)
    this.authorizationCode = searchParams.get('code') ?? ''
    return searchParams
  }

  /**
   * @param param configuration parameters.
   * @returns the URL for the logout process.
   */
  getLogoutURL() {
    return this.logoutContainerFlow()
  }

  /**
   * @description Clear current authenticated user key and open a new browser window with the sign-out page.
   * @return container logout url.
   */
  private logoutContainerFlow() {
    if (isUserIdPluginAvailable()) {
      Plugins.UserId.clearCurrAuthenticatedUserKey()
    }
    getWindow().open(getWebAuthRedirectUrlSignOut())
    return getWindow().location.origin
  }

  /**
   * @description Call the auth client library to request a token.
   * @return token
   */
  requestToken() {
    const authClientInstance = this.getAuthClientInstance()
    const token = authClientInstance.requestPKCEToken({
      code_verifier: sessionStorage.getItem(authCodeVerificationKey) ?? '',
      code: this.authorizationCode,
      redirect_url: this.localServerUri,
    })
    return token
  }
}
