import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import type { UserPreferenceUpdated } from './models'

export const UserPreferencesNamespace = 'UserPreferences'

export const UserPreferencesEvents = {
  userPreferencesUpdatedEvent: eventEmitter<UserPreferenceUpdated>(),
} as const

const { emit } = getEventBus().register(UserPreferencesNamespace, UserPreferencesEvents)

export const emitUserPreferencesUpdatedEvent = (event: UserPreferenceUpdated): void => {
  emit.userPreferencesUpdatedEvent(event)
}
