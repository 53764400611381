import { GOTO_TOPBAR_CONTAINER_SELECTOR } from '../../common/container'
import { getDocument } from '../../common/dom-helpers'
import { GoToIntegrationTopbar } from '../../packages/integration-topbar/integration-topbar'
import { GoToTopbar } from '../../packages/topbar/topbar'
import { getExternalInterface } from '../external-interface/external-interface-adapter'
export class TopbarService {
  private topbarElement: HTMLElement | undefined
  topbarContainerSelector = GOTO_TOPBAR_CONTAINER_SELECTOR

  createTopbarElement() {
    return getExternalInterface().isIntegration
      ? getDocument().createElement(GoToIntegrationTopbar.tagName)
      : getDocument().createElement(GoToTopbar.tagName)
  }

  show() {
    if (!this.topbarElement) {
      this.topbarElement = this.createTopbarElement()
    }
    const topbarContainer = getDocument().querySelector(this.topbarContainerSelector)
    if (this.topbarElement.parentElement !== topbarContainer) {
      topbarContainer?.appendChild(this.topbarElement)
    }
  }

  hide() {
    if (this.topbarElement?.parentElement) {
      this.topbarElement?.remove()
    }
  }

  isVisible() {
    return !!this.topbarElement && this.topbarElement.parentNode !== null
  }
}
