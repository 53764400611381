import { canReloadApplication } from '../services/auth/can-reload'
import { type I18NEvents, I18NNamespace, type Locale, setCurrentLocale } from '../services/i18n'
import { getEventBus } from '../services/namespaces'
import { reloadPage } from './dom-helpers'
import { onWindowUnload } from './helpers/window'

export const subscribeToLocaleChangeBegin = () => {
  const handleLocaleChangeBegin = async (locale: Locale) => {
    const canReload = await canReloadApplication()
    setCurrentLocale(locale)
    if (canReload) {
      reloadPage()
    }
  }

  const { localeChangeBegin } = getEventBus().subscribeTo<typeof I18NNamespace, typeof I18NEvents>(I18NNamespace)
  localeChangeBegin.addListener(handleLocaleChangeBegin)

  onWindowUnload(() => {
    localeChangeBegin.removeListener(handleLocaleChangeBegin)
  })
}
