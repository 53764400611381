import type { RegisterApplicationConfig } from 'single-spa'
import type { CustomProps } from '../../common'
import { findAppByName } from '../helpers/registered-app'
import { getExtensionsManager } from '../../extensions/extensions-manager'

const isRegisterApplicationConfig = (
  item: RegisterApplicationConfig<CustomProps> | undefined,
): item is RegisterApplicationConfig<CustomProps> => !!item

export const findRegisteredApplicationsByNames = (
  mountedApps: readonly string[],
): readonly RegisterApplicationConfig<CustomProps>[] =>
  mountedApps
    .map((mountedApp: string) => findAppByName(mountedApp, getExtensionsManager().getRegisteredApplications()))
    .filter(isRegisterApplicationConfig)
