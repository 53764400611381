import { getFromLocalStorage } from '../common/dom-helpers'
import type { ShellUserAuthorityContextOverrideParams } from './models'

export const LOCAL_STORAGE_CONTACT_CENTER_KEY = 'contact-center-settings'

export const getUrlSearchParamsStr = () => {
  let urlSearchParamsStr = ''
  const storage = getFromLocalStorage(LOCAL_STORAGE_CONTACT_CENTER_KEY)

  if (storage) {
    const { urlSearchParams } = JSON.parse(storage) as {
      urlSearchParams: ShellUserAuthorityContextOverrideParams
    }

    if (urlSearchParams) {
      const paramKeys = Object.keys(urlSearchParams)
      const searchParamsBuilder = new URLSearchParams()

      paramKeys.forEach(key => {
        const urlSearchParamsValue = urlSearchParams[key as keyof ShellUserAuthorityContextOverrideParams]
        const items = Array.isArray(urlSearchParamsValue) ? urlSearchParamsValue : [urlSearchParamsValue]
        items.forEach((value: string) => {
          searchParamsBuilder.append(key, value)
        })
      })
      // this is a temp solution until http.get support explode query param array syntax
      urlSearchParamsStr = `?${searchParamsBuilder.toString()}`
    }
  }
  return urlSearchParamsStr
}
