import type { RegisterApplicationConfig } from 'single-spa'
import type { CustomProps } from '../../common'
import { waitForSettingsContainer } from '../../common'
import { GOTO_CONTENT_ID, GOTO_SETTINGS_SELECTOR } from '../../common/container'
import { getExtensionsManager } from '../extensions-manager'
import { type ShellModule, type ShellExtension, type RouteMatcher, type ShellModuleGroup } from '@goto/shell-common'
import { createAppForExtensionModule } from './application'
import { transformSettingsToShellModule, updateSettingsRoutes } from './settings'
import { createElementGetter, createGroupLifeCycleWrapper } from '../../core/microfrontend/utils'
import { supportsModuleGroups } from './type-checking'
import { waitForElement } from '../../common/dom-helpers'

export const MAIN_APPS_GROUP_IDENTIFIER = 'main-apps'
export const SETTINGS_VIEWS_GROUP_IDENTIFIER = 'settings-views'
export interface ShellExtensionRegisterApplicationConfig<T extends CustomProps> extends RegisterApplicationConfig<T> {
  readonly routes: RouteMatcher[]
}

type RegisterModuleToSpaOptions = {
  readonly domElementGetter?: () => HTMLElement | null
  readonly beforeMount?: () => Promise<void>
  readonly groupIdentifier: string
}

type RegisterModuleToSpaFn = (extension: ShellExtension, options?: RegisterModuleToSpaOptions) => void

/**
 * Register a ShellModule to singleSpa
 * @param extension the extension providing the ShellModule
 * @param module ShellModule to register
 * @param options options for the singeSpa registration
 */
const registerExtensionModuleToSingleSpa = (extension: ShellExtension,
  module: ShellModule,
  options?: RegisterModuleToSpaOptions
): void => {
  const { domElementGetter, beforeMount, groupIdentifier } = options ?? { groupIdentifier: '' }
  const component = createAppForExtensionModule(extension, module)
  const appIdentifier = `${extension.id}/${module.componentId}`
  const singleSpaApp: RegisterApplicationConfig = {
    activeWhen: component.activeWhen,
    app: createGroupLifeCycleWrapper(component.app, appIdentifier, groupIdentifier, beforeMount),
    name: component.name,
    customProps: {
      ...component.customProps,
      domElementGetter,
    },
  }
  getExtensionsManager().registerApplication(singleSpaApp.name, singleSpaApp)
}

const getModuleGroupById = (groupIdentifier: string): ShellModuleGroup | undefined => {
  const extensions = getExtensionsManager().getExtensions()
  for (const extension of extensions) {
    if (supportsModuleGroups(extension)) {
      const module = extension.getModuleGroups().find(group => group.id === groupIdentifier)
      if (module) {
        return module
      }
    }
  }
}

const createBeforeMountFunction = (group: ShellModuleGroup): () => Promise<void> => {
  if (typeof group.target === 'string') {
    const target: string = group.target
    return async () => {
      await waitForElement(target, document.body)
    }
  } else {
    const targetFunction = group.target
    return async () => {
      await waitForElement(() => !!targetFunction(document), document.body)
    }
  }
}

const createDOMElementGetter = (group: ShellModuleGroup) => {
  if (typeof group.target === 'string') {
    const target: string = group.target
    return createElementGetter(target)
  } else {
    const targetFunction = group.target
    return () => targetFunction(document)
  }
}
interface PendingModule {
  extension: ShellExtension
  module: ShellModule
}

const pendingGroups = new Map<string, PendingModule[]>()

const registerShellModuleGroups = (extension: ShellExtension) => {
  if (supportsModuleGroups(extension)) {
    extension.getModuleGroups().forEach((group: ShellModuleGroup) => {
      if (pendingGroups.has(group.id)) {
        const pendingModules = pendingGroups.get(group.id)
        pendingModules?.forEach(pendingModule => {
          registerExtensionModuleForGroupToSingleSpa(pendingModule.extension, group, pendingModule.module)
        })
        pendingGroups.delete(group.id)
      }
    })
  }
}

const registerExtensionModuleForGroupToSingleSpa = (extension: ShellExtension,
  group: ShellModuleGroup,
  module: ShellModule) => {
  const groupIdentifier = module.groupId as string
  const beforeMount = createBeforeMountFunction(group)
  const domElementGetter = createDOMElementGetter(group)
  registerExtensionModuleToSingleSpa(extension, module, { groupIdentifier, beforeMount, domElementGetter })
}

/**
 * Register a list of ShellModule to singleSpa
 * @param extension the extension providing the ShellModule
 * @param modules the list of ShellModule
 * @param options options for the singeSpa registration
 */
const registerExtensionModulesToSingleSpa = (
  extension: ShellExtension,
  modules: readonly ShellModule[],
  options?: RegisterModuleToSpaOptions,
): void => {
  modules.forEach(module => {

    if (module.groupId) {

      const groupIdentifier = module.groupId
      const group = getModuleGroupById(groupIdentifier)
      if (group) {
        registerExtensionModuleForGroupToSingleSpa(extension, group, module)
      } else {
        // add to pending
        const modules = pendingGroups.get(groupIdentifier) || []
        modules.push({ extension, module })
        pendingGroups.set(groupIdentifier, modules)
      }
    } else {
      registerExtensionModuleToSingleSpa(extension, module, options)
    }

  })
}

/**
 * Register all the settings modules
 * @param extension
 */
export const registerExtensionSettingsToSingleSpa: RegisterModuleToSpaFn = (extension, options): void => {
  // Transform SettingsDefinition to ShellModule
  const modules: readonly ShellModule[] = updateSettingsRoutes(extension.getSettings()).map(
    transformSettingsToShellModule,
  )
  registerExtensionModulesToSingleSpa(extension, modules, options)
}

/**
 * Register all the application modules
 * @param extension
 */
export const registerExtensionApplicationToSingleSpa: RegisterModuleToSpaFn = (extension, options): void => {
  const modules = extension.getModules()
  registerExtensionModulesToSingleSpa(extension, modules, options)
}

/**
 * Register extension's modules and settings as single-spa applications
 * @param extension Shell extension to register
 */
export const registerExtensionToSingleSpa = (extension: ShellExtension): void => {
  registerShellModuleGroups(extension)
  registerExtensionApplicationToSingleSpa(extension, {
    groupIdentifier: MAIN_APPS_GROUP_IDENTIFIER,
    domElementGetter: createElementGetter(`#${GOTO_CONTENT_ID}`),
  })

  registerExtensionSettingsToSingleSpa(extension, {
    domElementGetter: createElementGetter(GOTO_SETTINGS_SELECTOR),
    groupIdentifier: SETTINGS_VIEWS_GROUP_IDENTIFIER,
    beforeMount: waitForSettingsContainer,
  })
}
