import { getDocument } from '../../common/dom-helpers'
import { EditUserPresenceNoteDialog } from '../../packages/dialogs/edit-user-presence-note-dialog'
import { getDialogService } from '../dialog/public-facade'
import { getNamespaces } from './index'

export const PRESENCE_NAMESPACE = 'presence'

export interface PresenceNamespace {
  readonly commands: {
    openPresenceNoteDialog(): void
  }
}

export const setupPresenceCommands = () => {
  const presenceNamespace = getNamespaces().retrieve<PresenceNamespace>(PRESENCE_NAMESPACE)

  presenceNamespace.commands.openPresenceNoteDialog.addHandler(() => {
    getDialogService().open(getDocument().createElement(EditUserPresenceNoteDialog.tagName))
  })
}
