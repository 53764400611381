import { html, LitElement, property, query } from 'lit-element'
import { nothing } from 'lit-html'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import type { TextFieldComponent } from '@getgo/chameleon-web'
import { SVG_MESSAGE_DOTS_OUTLINED, SVG_CLOSE_CIRCLE_FILLED } from '@getgo/chameleon-icons'

import { t } from '../../../directives/translate'

import { GOTO_USER_PRESENCE_NOTE_MAX_LENGTH, transKeys } from './variables'
import type { UserPresenceNoteFieldProps } from './models'
import elementStyles from './user-presence-note-field.styles.scss'
import { getShellAnalytics } from '../../../common/shell-api-helpers'

export class UserPresenceNoteField extends LitElement implements UserPresenceNoteFieldProps {
  static readonly tagName = 'goto-user-presence-note-field'

  @property({ type: String }) private _value = ''
  // TODO fix type https://jira.ops.expertcity.com/browse/SCAPI-536
  @query('chameleon-text-field') private noteInput: TextFieldComponent | undefined
  @property({ type: Boolean }) private canClearNoteInput = false
  @property({ type: Boolean }) private _hasErrors = false

  hasErrorsState?: (state: boolean) => void

  @property({ type: Boolean })
  get hasErrors(): boolean {
    return this._hasErrors
  }

  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false
  @property({ type: String, attribute: true, reflect: true })
  get value(): string {
    return this._value
  }

  set value(val: string) {
    this._value = `${val ?? ''}`.trim()
    this.validate()
    this.updateCanClearNoteInput()
  }

  static get styles() {
    return elementStyles
  }

  focus() {
    this.noteInput?.focus()
  }

  reset() {
    this.value = ''
  }

  render() {
    return html`
      <chameleon-text-field
        .value=${this._value}
        placeholder=${t(transKeys.inputPlaceholder)}
        ?error=${this.hasErrors}
        fieldsize="large"
        ?disabled=${this.disabled}
        @input=${this.handleInputChangeValueUpdate}
        @click=${() => {
          getShellAnalytics().track('GoTo > Custom Status Modal', { action: 'UI Tap', event: 'Input' })
        }}
        label-id="user-note-dialog-title"
      >
        <span slot="start" class="inline">
          <chameleon-svg>${unsafeSVG(SVG_MESSAGE_DOTS_OUTLINED)}</chameleon-svg>
        </span>
        ${this.renderClearButton()}
        <span slot="helpertext" class="text-left">
          ${t(transKeys.inputHelperText, () => ({ maxLength: GOTO_USER_PRESENCE_NOTE_MAX_LENGTH }))}
        </span>
      </chameleon-text-field>
    `
  }

  private renderClearButton() {
    return this.canClearNoteInput
      ? html`<span slot="end" class="inline" @click=${this.reset} @keyup=${this.reset}>
          <chameleon-svg>${unsafeSVG(SVG_CLOSE_CIRCLE_FILLED)}</chameleon-svg>
        </span>`
      : nothing
  }

  private handleInputChangeValueUpdate() {
    if (this.noteInput) {
      this.value = this.noteInput.value
    }
  }

  private updateCanClearNoteInput() {
    this.canClearNoteInput = this.value.length > 0
  }

  private validate() {
    const hasErrors = this._hasErrors
    this._hasErrors = this.value.length > GOTO_USER_PRESENCE_NOTE_MAX_LENGTH
    if (hasErrors !== this._hasErrors && this.hasErrorsState) {
      this.hasErrorsState(this._hasErrors)
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-user-presence-note-field': UserPresenceNoteField
  }
}
