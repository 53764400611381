import { getShellAnalytics } from './../../common/shell-api-helpers/analytics'
import { html, state } from 'lit-element'
import { nothing } from 'lit-html'
import { SVG_INFO_ACTIVE } from '@getgo/chameleon-icons'
import { translationKeys } from './content'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { getProductTrialEndDate, isFreeUser, isTrialUser } from '../../common/user-helpers'
import { ShellElement } from '../../common/shell-element'
import { TRIAL_PURCHASE_URL } from '../../environments'
import trialBannerStyles from './trial-banner.styles.scss'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { getNumberOfDaysBetweenTwoDates } from '../../common/helpers/date'

export class TrialBanner extends ShellElement {
  static readonly tagName = 'goto-trial-banner'

  @state() private isFreeUser = false
  @state() private isTrialUser = false

  connectedCallback() {
    super.connectedCallback()
    this.isFreeUser = isFreeUser()
    this.isTrialUser = isTrialUser()
  }

  static get styles() {
    return trialBannerStyles
  }

  private getTrialDaysLeft() {
    const trialEndDate = getProductTrialEndDate()
    if (!trialEndDate) {
      return 0
    }
    const currentDate = new Date()
    const trialDaysLeft = getNumberOfDaysBetweenTwoDates(currentDate, trialEndDate)
    if (trialDaysLeft < 0) {
      return 0
    }
    return trialDaysLeft
  }

  private getBannerMessage() {
    return this.isTrialUser ? this.getTrialMessage() : this.isFreeUser ? this.getFreeMessage() : nothing
  }

  private getBannerLinkText() {
    return this.isTrialUser ? this.getTrialLinkText() : this.isFreeUser ? this.getFreeLinkText() : nothing
  }

  private getTrialMessage() {
    const trialDaysLeft = this.getTrialDaysLeft()
    return trialDaysLeft
      ? getTranslation(translationKeys.activeTrialMessage, { trialDaysLeft })
      : getTranslation(translationKeys.expiredTrialMessage)
  }

  private getFreeMessage() {
    return getTranslation(translationKeys.activeFreeMessage)
  }

  private getTrialLinkText() {
    return getTranslation(translationKeys.buyButtonText)
  }

  private getFreeLinkText() {
    return getTranslation(translationKeys.upgradeButtonText)
  }

  private getEventText() {
    return this.isTrialUser ? 'Visit purchase site' : this.isFreeUser ? 'Visit upgrade site' : nothing
  }

  private getTrackingText() {
    return this.isTrialUser ? 'GoTo > GTM Trial Banner' : this.isFreeUser ? 'GoTo > GTM Free Banner' : nothing
  }

  render() {
    return html`<chameleon-banner variant="marketing">
      <chameleon-svg slot="icon">${unsafeSVG(SVG_INFO_ACTIVE)}</chameleon-svg>
      <span>${this.getBannerMessage()}</span>
      <chameleon-link
        slot="action"
        href="${TRIAL_PURCHASE_URL}"
        target="_blank"
        @click=${() => {
          getShellAnalytics().track(`${this.getTrackingText()}`, { action: 'Click', event: this.getEventText() })
        }}
      >
        ${this.getBannerLinkText()}
      </chameleon-link>
    </chameleon-banner>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-trial-banner': TrialBanner
  }
}
