const meetingIdInputRegex= {
  separator: "-",
  replacePattern: /^(\d{1,3})-?(\d{1,3})?-?(\d{1,3})?/,
  replaceWith: "$1$2$3",
};

export const formatMeetingId = (
  value: string,
): string => {
  const getRawValue = (text: string) => text.replace(meetingIdInputRegex.replacePattern, meetingIdInputRegex.replaceWith)
  const getDisplayValue =
    (displayValue: string) => {
      const rawValue = getRawValue(displayValue);
      const newDisplayValue = rawValue.match(/.{1,3}/g)?.join(meetingIdInputRegex.separator) as string;
      if (displayValue.endsWith(meetingIdInputRegex.separator)) {
        return newDisplayValue + meetingIdInputRegex.separator
      }
      return newDisplayValue
    }

  return getDisplayValue(value)
};

const formatMeetingUrlRegex = {
  supportedPrefixes: [
    "gotomeet.me",
    "meet.goto.com",
    "app.goto.com/meet",
    "app.goto.com/meeting",
    "global.gotomeeting.com/join",
  ],
  // eslint-disable-next-line no-useless-escape
  invalidCharacters: /[^\u00C0-\u1FFF\u2C00-\uD7FF\w0-9_\/-]/gi,
};

export const formatMeetingUrl = (
  value: string,
) => {
  const prefixRegex = () => {
    const urlRegex = formatMeetingUrlRegex.supportedPrefixes
      .map((prefix) => prefix.replace("/", "\\/").replace(".", "\\."))
      .map((prefix) => `(${prefix})`)
      .join("|");
    return new RegExp(`^(https?:\\/\\/)?(www\\.)?(${urlRegex})\\/`, "gi");
  };

  const getRawValue = (text: string) => text.replace(prefixRegex(), "").replace(formatMeetingUrlRegex.invalidCharacters, "")

  const getDisplayValue = (text: string) => getRawValue(text);

  return getDisplayValue(value)
};

