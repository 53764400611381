import mixpanel from 'mixpanel-browser'
import { getShellLogger } from '../../../common/logger'
import type { AnalyticsAPI, AnalyticsProperties } from '../models'
export type { MixPanelParams } from './models'

export { createMixpanel } from './initialize'

export const optOutMixpanel = () => {
  mixpanel.opt_out_tracking()
}

const track: AnalyticsAPI['track'] = (eventName, properties) => {
  const safeProperties: Partial<AnalyticsProperties> = {}

  for (const prop in properties) {
    if (typeof properties[prop] === 'object' && !Array.isArray(properties[prop])) {
      const description = `The mixpanel event named "${eventName}" contain an object in ${prop}`
      getShellLogger().warn('Invalid property type', new Error(description))
    } else {
      safeProperties[prop] = properties[prop]
    }
  }

  mixpanel.track(eventName, safeProperties)
}

export const analytics: AnalyticsAPI = {
  track,
}
