import { getShellLogger } from '../../common/logger'
import { isI18nLocale } from '../../common/translate-helpers/locales'
import type { ShellUserInfo } from '../../core/models'
import { environment } from '../../environments'
import { wrappedAuthenticatedFetch } from '../auth/wrapper'
import type { UserProfileEmail, UserProfileInfo } from './models'

export const getEmailsFromUserProfile = (emails?: readonly UserProfileEmail[]) => emails ?? []

export const getEntitlementsFromUserProfile = (user: ShellUserInfo) => user.entitlements ?? []

export const getIdFromUserProfile = (key?: string | null) => key ?? ''

export const getLocaleFromUserProfile = (me?: UserProfileInfo) => me?.locale

export const getUserNameFromUserProfile = (me?: UserProfileInfo) => me?.userName

export const updateUserProfileLocale = async (value: unknown) => {
  if (!isI18nLocale(value)) {
    throw new TypeError(`Unsupported locale ${value}`)
  }

  try {
    await wrappedAuthenticatedFetch<UserProfileInfo>(`${environment().iamBaseUrl}/identity/v1/Users/me`, {
      credentials: undefined,
      method: 'PATCH',
      body: JSON.stringify({ locale: value }),
      headers: [['Content-Type', 'application/json']],
    })
  } catch (err) {
    const msg = `Unable to update user profile's locale with value: '${value}'.`
    getShellLogger().error(msg, err)
    throw new Error(msg)
  }
}
