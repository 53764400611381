import type { IPresenceIndicatorComponent } from '@getgo/chameleon-web'
import type { PopoverPosition } from '@getgo/chameleon-core'
import { type ShellElement } from '../../common/shell-element'

export const GOTO_PRESENCE_INDICATOR = 'goto-presence-indicator'

export interface PresenceProps extends Readonly<Omit<IPresenceIndicatorComponent, 'presence'>> {
  readonly externalUserKey: string
  readonly presenceSnapshot?: boolean
  readonly tooltipPosition?: PopoverPosition
}

export type GotoPresenceIndicatorComponent = ShellElement & PresenceProps
