import { DesktopCompanionConnectionStatus, type DesktopCompanionStatusChangedEvent } from '@getgo/container-client'
import { getEventBus } from '../namespaces'
import { UpdateEvents, UpdateNamespace } from '../update'
import { type ExternalInterface } from './external-interface'
import { sendMessageGetUpdateStatus } from './external-interface-companion-update-helper'

import { type CompanionMessagePayload } from './messages/companion-update'

export const externalInterfaceCompanionUpdateListener = (externalInterface: ExternalInterface) => {
  const handleCompanionUpdate = (payload: CompanionMessagePayload) => {
    if (payload.topic === 'update-available' && payload.data?.updateInfo) {
      const { emit } = getEventBus().register(UpdateNamespace, UpdateEvents)
      const update = payload.data.updateInfo
      emit.updateAvailable(update)
    }
  }

  const handleConnectionChanged = (e: DesktopCompanionStatusChangedEvent) => {
    if (e.connectionStatus === DesktopCompanionConnectionStatus.connected) {
      sendMessageGetUpdateStatus()
    } else if (e.connectionStatus === DesktopCompanionConnectionStatus.disconnected) {
      // Reset the update age when the companion is disconnected
      const { emit } = getEventBus().register(UpdateNamespace, UpdateEvents)
      emit.resetUpdateStatus()
    }
  }

  if (externalInterface.supportsCompanion) {
    externalInterface.addCallback('companion-update', handleCompanionUpdate)
    if (externalInterface.available) {
      sendMessageGetUpdateStatus()
    }
    externalInterface.addConnectionCallback(handleConnectionChanged)
  }
}
