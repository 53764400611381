import type { BadgeNumberFilter, BadgeNumberIdentifier, BadgeNumberInfo } from './models'

export const isBadgeNumberMatchingFilter = (badgeNumberInfo: BadgeNumberIdentifier, filter: BadgeNumberFilter) => {
  const isInclusion = !filter.exclude
  for (const key in filter) {
    if (filter[key] !== badgeNumberInfo[key]) {
      return !isInclusion
    }
  }
  return isInclusion
}

export const compareBadgeNumbers = (badgeNumberInfo1: BadgeNumberInfo, badgeNumberInfo2: BadgeNumberInfo): boolean => {
  const keys1 = Object.keys(badgeNumberInfo1)
  const keys2 = Object.keys(badgeNumberInfo2)
  return (
    keys1.length === keys2.length &&
    keys1.every(key => key === 'value' || badgeNumberInfo1[key] === badgeNumberInfo2[key])
  )
}

export const badgeToMapKey = <BNI extends BadgeNumberIdentifier>(badgeNumberInfo: BNI): string => {
  const parts = Object.keys(badgeNumberInfo)
    .filter(k => k !== 'value')
    .sort()
    .map(k => badgeNumberInfo[k].toString())
  return parts.reduce((result, part) => {
    if (result) {
      return `${result}.${part}`
    }
    return `${part}`
  }, '')
}
