export enum ProviderName {
  PERSONAL = 'Personal',
  OFFICE365 = 'Office365',
  OFFICE365_ENTERPRISE = 'Office365Enterprise',
  ENTERPRISE = 'Enterprise',
  ORGANIZATION = 'Organization',
  SHARED = 'Shared',
}

export interface ContactsQuery extends PagingQuery {
  /**
   * The text you want to search
   */
  readonly q?: string
  /**
   * @description
   *
   * Outlook Provider :
   *
   * It is only possible to filter text with the startswith conditional and exact match.
   * The only phone that is possible to filter on, is the mobilePhone. The bussinesPhones and homePhones are not possible to be filtered, this will trigger a bad request response.
   * The filter only works for firstName, lastName, number and email. All the other properties are not supported.
   * It is not possible to filter by contact Id, it is necessary to request the contact using the Id as a path parameter.
   */
  readonly filter?: readonly ContactFilterFields[]
  /**
   * @description
   * + : Ascending,
   * - : Descending
   * Outlook Provider : The sort will only work for a filter
   * The sort will only work if the field has been filtered too. If not, it will throw a 400 error Bad Request.
   * The order of the sort must match the order of the filters. If not, it will throw a 400 error Bad Request.
   */
  readonly sort?: readonly SortContactBy[]

  /**
   * The ids of the contacts you want to get
   */
  readonly contactId?: readonly string[]
  /**
   * Fetch favorites
   */
  readonly favorite?: boolean
}

export interface MeetingProfile {
  readonly profileId?: string
  readonly meetingId?: string
  readonly theme?: string
  readonly userKey?: string
  readonly created?: string
  readonly html5?: boolean
  readonly html5Present?: boolean
  readonly name?: string
}

enum SortContactBy {
  LastNameDescending = '-lastName',
  LastNameAscending = '+lastName',
  FirstNameDescending = '-firstName',
  FirstNameAscending = '+firstName',
  NumberDescending = '-number',
  NumberAscending = '+number',
  EmailDescending = '-email',
  EmailAscending = '+email',
}

enum ContactFilterFields {
  'lastName',
  'firstName',
  'number',
  'email',
}

export interface PagingQuery {
  readonly page: number
  readonly pageSize: number
}
