import { getDocument } from '../../common/dom-helpers'
import { GoToInspectorPanel } from '../../packages/inspector-panel/inspector-panel'
import { emitInspectorPanelClosedEvent, emitInspectorPanelOpenedEvent } from './events'
import type { InspectorPanelAPI } from './models'

export class InspectorPanelService implements InspectorPanelAPI {
  isOpen() {
    const container = getDocument().querySelector(GoToInspectorPanel.tagName)

    return container ? container.isOpen() : false
  }

  open(content: HTMLElement) {
    const container = getDocument().querySelector(GoToInspectorPanel.tagName)

    if (container) {
      container.open(content)
      emitInspectorPanelOpenedEvent()
    }
  }

  close() {
    const container = getDocument().querySelector(GoToInspectorPanel.tagName)

    if (container) {
      container.close()
      emitInspectorPanelClosedEvent()
    }
  }
}
