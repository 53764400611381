import type { UpdateInfo } from '@getgo/container-client'
import { Container, Plugins, UpdaterError } from '@getgo/container-client'
import { updateAvailable, checkForUpdateStartedOrStopped, resetUpdateStatus } from './event-bus'
import { noop } from '../../common'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { getTranslation } from '../i18n/i18nUtils'
import { getPluginFunction } from '../container/helpers'
import { sendMessageInstallUpdate } from '../external-interface/external-interface-companion-update-helper'
import { getDaysBetweenTimestampsMs } from '../../common/date-helpers'

let updateInProgress: boolean
let shellUpdatesEnabled: boolean
let subscribedForAutoUpdates: boolean
let autoUpdate: UpdateInfo
/*
 * Checks all the different conditions to know if updates are enabled.
 * @returns a promise for a boolean representing the result of said checks.
 */
export const areShellUpdatesEnabled = async () => {
  if (shellUpdatesEnabled === undefined) {
    shellUpdatesEnabled =
      Container.isPluginAvailable('AutoUpdater2') && (await Plugins.AutoUpdater2.areUpdatesEnabled())
  }

  return shellUpdatesEnabled
}

/**
 * @returns a boolean representing if a check for update is already in progress. Useful as an initial check after subscribing to the event.
 */
export const isCheckForUpdateInProgress = () => !!updateInProgress

/**
 * Performs a check for updates
 * @param updateAvailableCallback a callback to trigger if an update is available By default, this will emit an updateAvailable event
 * @param noUpdateAvailableCallback a callback to trigger if no update is available. By default, this will do nothing.
 * @param errorCallback a callback to trigger if the check ends with an error. By default, this will do nothing.
 */
export const checkForUpdates = (
  updateAvailableCallback = updateAvailable,
  noUpdateAvailableCallback = noop,
  errorCallback: (error: UpdaterError) => void = noop,
) => {
  Plugins.AutoUpdater2.checkForUpdates(updateAvailableCallback, noUpdateAvailableCallback, errorCallback)
}

/**
 * Restarts the app, installing updates if any are available
 */
export const quitAndInstall = () => {
  const quitAndInstall = getPluginFunction('AutoUpdater2', 'quitAndInstall')
  if (quitAndInstall) {
    quitAndInstall()
  }
}

const checkForUpdateStartedOrStoppedHandler = (inProgress: boolean) => {
  updateInProgress = inProgress
  checkForUpdateStartedOrStopped(inProgress)
  // If there is a check in progress, wait for it to finish before subscribing for auto updates.
  // Subscribing for auto updates triggers another check and if there is one already running,
  // the second one will fail with "another check in progress" error.
  if (!inProgress && !subscribedForAutoUpdates) {
    Plugins.AutoUpdater2.onUpdateAvailable(handleAutoUpdateAvailable)
    subscribedForAutoUpdates = true
  }
}

const handleAutoUpdateAvailable = (update: UpdateInfo) => {
  autoUpdate = update
  updateAvailable(update)
}

/**
 * Used for an initial check by the components listening to the updater events in case they weren't ready to listen before the initial check.
 *
 * @returns An update found by the automatic checks for update
 */
export const getAutoUpdate = () => autoUpdate

/**
 * Performs a check for updates and then subscribes to automatic checks.
 * If a new update is found, this emits an updateAvailable event through the event-bus.
 */
export const initializeAutoUpdater = () => {
  if (Container.isPluginAvailable('AutoUpdater2')) {
    Plugins.AutoUpdater2.areUpdatesEnabled().then((result: boolean) => {
      if (result) {
        subscribedForAutoUpdates = false
        Plugins.AutoUpdater2.onCheckForUpdatesStartedOrStopped(checkForUpdateStartedOrStoppedHandler)
      }
    })
  }
}

/**
 * Opens a modal to confirm or delay the installation of container updates by restarting the app
 */
export const displayUpdateConfirmModal = () => {
  const modal = window.shell.display.modal({
    id: 'update-modal',
    title: asTranslationKey('Update GoTo now?'),
    closable: true,
    content: asTranslationKey('Your GoTo app will restart during the update.'),
    actions: [
      {
        label: asTranslationKey('Update & restart'),
        handler: () => {
          quitAndInstall()
        },
        size: 'medium',
      },
      {
        label: asTranslationKey('Ask me later'),
        handler: () => {
          modal.close()
        },
        variant: 'tertiary',
        size: 'medium',
      },
    ],
  })
}

/**
 * Opens a modal to confirm or delay the installation of companion updates by restarting the app
 */
export const displayUpdateConfirmModalInIntegration = () => {
  const modal = window.shell.display.modal({
    id: 'update-modal',
    title: asTranslationKey('Update GoTo for Teams plugin now?'),
    closable: true,
    content: asTranslationKey('Both your GoTo app and your GoTo for Teams plugin will restart during the update.'),
    actions: [
      {
        label: asTranslationKey('Update & restart'),
        handler: () => {
          resetUpdateStatus()
          modal.close()
          sendMessageInstallUpdate()
        },
        size: 'medium',
      },
      {
        label: asTranslationKey('Ask me later'),
        handler: () => {
          modal.close()
        },
        variant: 'tertiary',
        size: 'medium',
      },
    ],
  })
}

export const displayUpdateErrorModal = (error: UpdaterError) => {
  const title = asTranslationKey('Something went wrong')
  let body

  if (error === UpdaterError.PermissionDeniedMac) {
    body = asTranslationKey('Try asking your admin for help.')
  } else {
    body = asTranslationKey('Give it another try later')
  }

  const modal = window.shell.display.modal({
    id: 'update-error-modal',
    title: title,
    content: body,
    actions: [
      {
        label: asTranslationKey('Close'),
        handler: () => {
          modal.close()
        },
      },
    ],
  })
}

export const displayUpToDateSnackbar = () => {
  window.shell.display.snackbar({
    id: 'update-snackbar',
    title: getTranslation('GoTo app is up to date'),
  })
}

/**
 * Calculates the age of an update in days.
 *
 * @param updateInfo - The update information.
 * @returns The age of the update in days.
 */
export const calculateUpdateAgeInDays = (updateInfo: UpdateInfo) => {
  const currentDate = Date.now()
  return getDaysBetweenTimestampsMs(currentDate, Date.parse(updateInfo.releaseDate))
}
