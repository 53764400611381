import { getCurrentLocale } from '../../services/i18n'
import { configurePendo } from '../../services/pendo-integration/pendo-integration'
import { getPluginFunction, isContainer } from '../../services/container/helpers'
import type { ShellHook } from './models'
import {
  getAuthContextAccountKey,
  getAuthContextLicenses,
  getAuthContextPbxId,
  getAuthContextProductEnabledDates,
  getAuthContextUserProfileEntitlements,
  getAuthContextUserProfileId,
  getAuthContextIsCalendarConnected,
} from '../helpers/auth-context'
import { getCurrentAccountSettings } from '../../common/user-helpers'
import { environment } from '../../environments'
import { getProduct } from '../../common/helpers/product'

export const initializePendo: ShellHook = async shell => {
  const authContext = shell.getAuthContext()
  const locale = getCurrentLocale()
  const pendoSrc = environment()?.pendoScriptSrc ?? 'pendo.js'

  const getContext = getPluginFunction('ExecutionContext', 'getContext')
  const { machineId, runId } = getContext ? getContext() : { machineId: '', runId: '' }

  const meetingSettings = getCurrentAccountSettings()?.meeting ?? {}

  configurePendo({
    userKey: getAuthContextUserProfileId(authContext),
    accountKey: getAuthContextAccountKey(authContext),
    entitlements: getAuthContextUserProfileEntitlements(authContext),
    locale,
    product: getProduct(),
    pbxId: getAuthContextPbxId(authContext),
    isContainer: isContainer(),
    isCalendarConnected: getAuthContextIsCalendarConnected(authContext),
    licenses: getAuthContextLicenses(authContext),
    productEnabledDates: getAuthContextProductEnabledDates(authContext),
    machineId,
    runId,
    pendoSrc,
    ...meetingSettings,
  })
}
