import { getShellLogger } from '../../common/logger'
import { getShellAnalytics } from '../../common/shell-api-helpers'
import type { EventEmitter } from '../namespaces'
import { getEventBus } from '../namespaces/event-bus'
import { AppStateEvents, AppStateNamespace } from './events'
import { isValidBusyState } from './helpers'
import type { AppStateService as IAppStateService, BusyState } from './models'

export class AppStateService implements IAppStateService {
  private readonly state = new Map<string, BusyState>()
  private readonly appStateChanged: EventEmitter<BusyState> | undefined

  constructor() {
    const {
      emit: { appStateChanged },
    } = getEventBus().register(AppStateNamespace, AppStateEvents)
    this.appStateChanged = appStateChanged as EventEmitter<BusyState>
  }

  getAppState() {
    return this.getReducedState()
  }

  setCheckPointState(checkpointName: string, state: BusyState): void {
    isValidBusyState(state)
    this.state.set(checkpointName, state)
    this.emitNewStateAndLog(checkpointName, state)
  }

  private emitNewStateAndLog(checkpointName: string, state: string) {
    const appState = this.getReducedState()
    this.appStateChanged?.(appState)

    getShellAnalytics().track('Goto > AppStateService', {
      appState,
      state,
      checkpointName,
    })

    getShellLogger().info(`AppStateService: ${checkpointName} set to ${state}. Reduced State is ${appState}`)
  }

  /**
   * Reduce all of the checkpoint states to a single BusyState value
   */
  private getReducedState(): BusyState {
    if (this.isIdle()) {
      return 'idle'
    } else if (this.shouldAsk()) {
      return 'ask'
    } else {
      return 'busy'
    }
  }

  /**
   * Returns true when all states are 'idle', or the state is empty
   */
  private isIdle(): boolean {
    return Array.from(this.state.values()).every(state => state === 'idle')
  }

  /**
   * Return true if all these conditions are met:
   * 1. all states are not 'idle'
   * 2. all states are 'idle' or 'ask'
   * 3. the state is not empty
   */
  private shouldAsk(): boolean {
    const states = Array.from(this.state.values())
    const statesArIdleOrAsk = states.every(state => state === 'idle' || state === 'ask')
    return states.length > 0 && statesArIdleOrAsk && !this.isIdle()
  }
}
