/* istanbul ignore file */
export class HttpResponseError<T> extends Error {
  response: T
  status: number
  statusText: string | null
  body: ReadableStream<Uint8Array> | null
  constructor(params: HttpResponseErrorParams<T>) {
    const { message, response, status, statusText, body } = params
    super(message)
    this.response = response
    this.status = status
    this.statusText = statusText
    this.body = body ?? null
    this.name = 'HttpResponseError'
    Object.setPrototypeOf(this, HttpResponseError.prototype)
  }

  toString(): string {
    return `${this.name}: ${this.message}\nStatus: ${this.status}\nStatus Text: ${this.statusText}\nMessage: ${this.message}`
  }
}

export interface HttpResponseErrorParams<T> {
  readonly message: string
  readonly response: T
  readonly status: number
  readonly statusText: string | null
  readonly body?: ReadableStream<Uint8Array> | null
}
