/**
 * @description SignalController merges multiple AbortSignals into one.
 * It listens to a set of AbortSignals and triggers its own signal if any of the provided signals are aborted.
 * API: https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 */
export class SignalController {
  public signal: AbortSignal
  private listeners: { signal: AbortSignal; listener: () => void }[] = []

  constructor(signals: AbortSignal[] = []) {
    const controller = new AbortController()
    this.signal = controller.signal

    signals.forEach(signal => {
      const onAbort = () => controller.abort()

      if (signal.aborted) {
        controller.abort()
      } else {
        signal.addEventListener('abort', onAbort, { once: true })
        this.listeners.push({ signal, listener: onAbort })
      }
    })
  }

  /**
   * @description Clean up the abort event listeners
   */
  cleanup() {
    this.listeners.forEach(({ signal, listener }) => {
      signal.removeEventListener('abort', listener)
    })
    this.listeners = []
  }
}
