/* istanbul ignore file */
import { html } from 'lit-element'
import { singleSpaLit } from '../../../common'
import { GOTO_CONTENT_SELECTOR } from '../../../common/container'

const lifecycles = singleSpaLit({
  template: () => html`<goto-search-results-page></goto-search-results-page>`,
  domElement: GOTO_CONTENT_SELECTOR,
})

export default lifecycles
