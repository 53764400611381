/* istanbul ignore file */

import {
  type EventBusEventPayload,
  type EventBusExternalInterfaceAdapter,
} from '../types/event-bus-adapter-strategy';
import {
  DisplayNotificationEvents,
  DisplayNotificationsNamespace,
  type WindowReferencedNotification
} from '../../display-notification';
import { isMainWindow } from '../../container';

export interface DisplayNotificationMessage extends EventBusEventPayload {
  isMainWindow: boolean | undefined
}

export interface DisplayNotificationEvent extends EventBusEventPayload, WindowReferencedNotification {}

export class DisplayNotificationStrategy implements EventBusExternalInterfaceAdapter {
  canHandleEvent(namespace: string, eventName: string): boolean {
    return namespace === DisplayNotificationsNamespace && this.isEventInDisplayNotification(eventName)
  }

  canForwardToCompanion(_namespace: string, _eventName: string, eventPayload: DisplayNotificationEvent): boolean {
    return !eventPayload.target
  }

  canForwardToIntegration(_namespace: string, _eventName: string, eventPayload: DisplayNotificationEvent): boolean {
    return (!eventPayload.target) || isMainWindow(eventPayload.target)
  }

  toEventBusEventPayload(messageEventPayload: DisplayNotificationMessage): DisplayNotificationEvent | EventBusEventPayload {
    const { isMainWindow, ...data } = messageEventPayload
    if (isMainWindow) {
      return {
        ...data,
        target: window.document.defaultView
      }
    } else {
      return data
    }
  }

  toMessageEventPayload(eventPayload: DisplayNotificationEvent): DisplayNotificationMessage {
    const { target, ...data } = eventPayload
    return {
      ...data,
      isMainWindow: !!(target && isMainWindow(target))
    }
  }

  private isEventInDisplayNotification(event: string) {
    return Object.keys(DisplayNotificationEvents).findIndex((eventName) => eventName === event) !== -1
  }
}
