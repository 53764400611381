import { Container } from '@getgo/container-client'
import { isContainer } from '../container/helpers'
import type { AuthBase } from './authentication.base'
import { AuthContainer } from './authentication.container'
import { AuthWeb } from './authentication.web'
import { getExternalInterface } from '../external-interface'
import { AuthIntegration } from './authentication.integration'
import { AuthCompanion } from './authentication.companion'

let instance: AuthBase | undefined = undefined

// @deprecated only for test
export const resetInstance = () => (instance = undefined)

export const getAuthInstance = async () => {
  if (!instance) {
    instance = await makeAuthentication()
  }

  return instance
}

/**
 * @description factory to split web instance VS container instance
 */
const makeAuthentication = async (): Promise<AuthBase> => {
  let auth
  if (isContainer() && Container.isPluginAvailable('BrowserAuth')) {
    auth = new AuthContainer()
    if (getExternalInterface().isCompanion) {
      auth = new AuthCompanion()
    }
  } else {
    auth = new AuthWeb()
    if (getExternalInterface().supportsCompanion) {
      auth = new AuthIntegration()
    }
  }
  return auth
}
