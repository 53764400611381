export const MILLISECONDS_IN_A_MINUTE = 60000
export const MILLISECONDS_IN_A_HOUR = MILLISECONDS_IN_A_MINUTE * 60
export const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_A_HOUR * 24

export const dateIsValid = (dateDDMMYYYY: string) => dateDDMMYYYYRegex.test(dateDDMMYYYY)

/**
 *
 * @param dateDDMMYYYY a string with the format requested by UX: DD/MM/YYYY
 * @returns dateIso a ISO-8601 formatted string
 */
export const formatDateISO = (dateDDMMYYYY: string) => {
  const year = getYear(dateDDMMYYYY)
  const month = getMonth(dateDDMMYYYY)
  const day = getDay(dateDDMMYYYY)
  return `${year}-${month}-${day}`
}

const getYear = (dateDDMMYYYY: string) => dateDDMMYYYY.substring(6, 10)

const getMonth = (dateDDMMYYYY: string) => dateDDMMYYYY.substring(3, 5)

const getDay = (dateDDMMYYYY: string) => dateDDMMYYYY.substring(0, 2)

const dateDDMMYYYYRegex = new RegExp(/^((0)[1-9]|[1-2][0-9]|(3)[0-1])(\/)(((0)[1-9])|((1)[0-2]))(\/)\d{4}$/)

// pass input date in format ISO
export const dateIsTodayOrInFuture = (date: Date) => {
  const now = new Date()
  const today = now.setHours(0, 0, 0, 0)
  const inputDate = new Date(date).setHours(0, 0, 0, 0)
  return inputDate >= today
}

export const getNumberOfDaysBetweenTwoDates = (startDate: Date, endDate: Date) => {
  const startDateUTC = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  const endDateUTC = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

  return Math.floor((endDateUTC - startDateUTC) / MILLISECONDS_IN_A_DAY)
}

export const getCurrentDate = () => new Date()
