import type { BannerNotification, ModalNotification, SnackbarNotification } from './models'
import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import { nanoid } from 'nanoid'

export * from './models'

export const DisplayNotificationsNamespace = 'DisplayNotifications'
export interface SnackbarNotificationInstance {
  readonly close: () => void
  readonly updateProgress: (progress: number) => void
}

export interface BannerNotificationInstance {
  readonly close: () => void
}

export const DisplayNotificationEvents = {
  banner: eventEmitter<BannerNotification>(),
  removeBanner: eventEmitter<BannerNotification>(),
  snackbar: eventEmitter<SnackbarNotification>(),
  removeSnackbar: eventEmitter<SnackbarNotification>(),
  updateSnackbarProgress: eventEmitter<SnackbarNotification>(),
  modal: eventEmitter<ModalNotification>(),
  removeModal: eventEmitter<ModalNotification>(),
}

const { emit } = getEventBus().register(DisplayNotificationsNamespace, DisplayNotificationEvents)

export const banner = (data: BannerNotification): BannerNotificationInstance => {
  const banner = {
    id: nanoid(),
    ...data,
  }
  emit.banner(banner)

  return {
    close: () => {
      emit.removeBanner(banner)
    },
  }
}

export type Snackbar = typeof snackbar

export const snackbar = (data: SnackbarNotification): SnackbarNotificationInstance => {
  const snackbar = {
    ...data,
    id: nanoid(),
  }
  emit.snackbar(snackbar)

  return {
    close: () => {
      emit.removeSnackbar(snackbar)
    },
    updateProgress: (progress: number) =>
      emit.updateSnackbarProgress({
        ...snackbar,
        progress,
      }),
  }
}

export const modal = (data: ModalNotification) => {
  const modal = {
    ...data,
    id: nanoid(),
  }
  emit.modal(modal)

  return {
    close: () => {
      emit.removeModal(modal)
    },
  }
}

export const shellDisplayNotificationAPI = {
  banner,
  snackbar,
  modal,
}
