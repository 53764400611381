import type { ShellUserAuthorityContextPermissions } from '../../core/models'
import type { AvatarInfo } from '../../services/avatar/models'
import type {
  ShellUserAccount,
  ShellUserEmail,
  ShellUserInfo,
  ShellUserLocation,
  ShellUserMeta,
  ShellUserName,
} from './model'

export const createUserInfoAPI = (userInfo: ShellUserInfo): ShellUserInfo => ({
  get key(): string | null {
    return userInfo.key
  },
  get name(): ShellUserName {
    return userInfo.name
  },
  get meta(): ShellUserMeta {
    return userInfo.meta
  },
  get locale(): string | null {
    return userInfo.locale
  },
  get emails(): readonly ShellUserEmail[] {
    return userInfo.emails
  },
  get location(): ShellUserLocation {
    return userInfo.location
  },
  get accounts(): readonly ShellUserAccount[] {
    return userInfo.accounts
  },
  get avatar(): AvatarInfo | undefined {
    return userInfo.avatar
  },
  get entitlements(): readonly string[] {
    return userInfo.entitlements
  },
  get roles(): readonly string[] {
    return userInfo.roles
  },
  getAccountByKey(accountKey: string): ShellUserAccount | undefined {
    return userInfo.getAccountByKey(accountKey)
  },
  hasPermissions(permissions: readonly ShellUserAuthorityContextPermissions[]) {
    return userInfo.hasPermissions(permissions)
  },
  hasLicenses(licenses: readonly string[]) {
    return userInfo.hasLicenses(licenses)
  },
  getUserContextLicenses() {
    return userInfo.getUserContextLicenses()
  },
})
