import { onWindowUnload } from '../../common/helpers/window'
import { getUniqueIdentifier } from '../../common/number-helpers'
import { type ExternalUserKey } from '../../core/models'
import { getExternalInterface } from '../external-interface'
import {
  type PresenceServiceMessage,
  type PresenceServiceMessagePayload,
} from '../external-interface/messages/presence-service'
import {
  type ShellServiceSubscriptionState,
  type IShellPresenceService,
  type PresenceSnapshot,
  type UserPresence,
} from './models'

type ResolveType = (arg: unknown) => void

export class ShellPresenceServiceIntegrationAdapter implements IShellPresenceService {
  /**
   * @deprecated Leave this empty
   */
  subscriptions: Map<string, ShellServiceSubscriptionState> = new Map()

  private readonly promisesToResolve = new Map<number, { resolve: ResolveType }>()

  constructor() {
    this.initEvents()
  }

  subscribe(externalUserKey: ExternalUserKey): Promise<void> {
    return new Promise(resolve => {
      const requestId = this.addPromiseToResolve(resolve)

      this.send<PresenceServiceMessagePayload<'subscribe'>>({
        topic: 'subscribe',
        data: {
          requestId,
          externalUserKey,
        },
      })
    })
  }

  unsubscribe(externalUserKey: ExternalUserKey): void {
    const requestId = getUniqueIdentifier()

    this.send<PresenceServiceMessagePayload<'unsubscribe'>>({
      topic: 'unsubscribe',
      data: {
        requestId,
        externalUserKey,
      },
    })
  }

  getUserPresence(): Promise<void | UserPresence> {
    return new Promise(resolve => {
      const requestId = this.addPromiseToResolve(resolve)

      this.send<PresenceServiceMessagePayload<'get-user-presence'>>({
        topic: 'get-user-presence',
        data: {
          requestId,
        },
      })
    })
  }

  setUserPresence(presence: Partial<UserPresence>): Promise<void | UserPresence> {
    return new Promise(resolve => {
      const requestId = this.addPromiseToResolve(resolve)

      this.send<PresenceServiceMessagePayload<'set-user-presence'>>({
        topic: 'set-user-presence',
        data: {
          requestId,
          presence,
        },
      })
    })
  }

  getPresenceSnapshots(externalUserKey: ExternalUserKey): Promise<PresenceSnapshot> {
    return new Promise(resolve => {
      const requestId = this.addPromiseToResolve(resolve)

      this.send<PresenceServiceMessagePayload<'get-presence-snapshots'>>({
        topic: 'get-presence-snapshots',
        data: {
          requestId,
          externalUserKey,
        },
      })
    })
  }

  private initEvents() {
    const externalInterface = getExternalInterface()

    externalInterface.addCallback('presence-service', this.handlePresenceServiceEvents)

    onWindowUnload(() => {
      externalInterface.removeCallback('presence-service', this.handlePresenceServiceEvents)
    })
  }

  private send<T extends PresenceServiceMessagePayload>(payload: T) {
    getExternalInterface().send<PresenceServiceMessage>({
      type: 'presence-service',
      payload,
    })
  }

  private readonly handlePresenceServiceEvents = (payload: PresenceServiceMessagePayload) => {
    switch (payload.topic) {
      case 'subscribe-response': {
        const typedPayload = payload as PresenceServiceMessagePayload<'subscribe-response'>

        this.resolvePromise(typedPayload.data.requestId, undefined)
        break
      }

      case 'get-user-presence-response': {
        const typedPayload = payload as PresenceServiceMessagePayload<'get-user-presence-response'>

        this.resolvePromise(typedPayload.data.requestId, typedPayload.data.presence)
        break
      }

      case 'set-user-presence-response': {
        const typedPayload = payload as PresenceServiceMessagePayload<'set-user-presence-response'>

        this.resolvePromise(typedPayload.data.requestId, typedPayload.data.presence)
        break
      }

      case 'get-presence-snapshots-response': {
        const typedPayload = payload as PresenceServiceMessagePayload<'get-presence-snapshots-response'>

        this.resolvePromise(typedPayload.data.requestId, typedPayload.data.presenceSnapshot)
        break
      }
    }
  }

  private resolvePromise(requestId: number, data: unknown): void {
    const promise = this.promisesToResolve.get(requestId)

    if (promise) {
      promise.resolve(data)
      this.promisesToResolve.delete(requestId)
    }
  }

  private addPromiseToResolve(resolve: ResolveType): number {
    const requestId = getUniqueIdentifier()
    this.promisesToResolve.set(requestId, { resolve })
    return requestId
  }
}
