import { CISDateRange, DAY_IN_S, getKeyFromStartDate, MILLISECOND } from './date-utils'
import type { EventsCacheMap, CalendarEvent, CalendarCacheAPI } from './models'
import { DateTime } from 'luxon'

/**
 *  Note: CIS means Calendar Integration Service.
 *  API docs: https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html
 */
export class CalendarCache implements CalendarCacheAPI {
  private cacheDays: number[] = []
  private cacheEvents: EventsCacheMap = new Map([])

  public clear = () => {
    this.cacheDays = []
    this.cacheEvents = new Map()
  }

  /**
   * Takes a unix timestamp range and returns a new range that spans days the first and last days with missing values.
   */
  getMissingDatesRange(dateRange: CISDateRange): CISDateRange {
    // cache is empty, return unmodified range
    if (this.cacheDays.length === 0) {
      return dateRange
    }

    // get the date range as unix timestamps
    const unixDateRange = dateRange.toTzNormalizedUnixKeys()

    // map each timestamp to it's cached state
    const unixDateCached = unixDateRange.map(dateKey => this.cacheDays.includes(dateKey))

    // Get an array of the missing day's indexes ie: [1,3,5].
    const missingDaysIndexes = unixDateCached
      .map((isCached, i) => (isCached === false ? i : undefined))
      // isDateRangeCached is called before this method, we know for certain that there is atleast 1 missing day
      .filter(v => v !== undefined) as number[]

    const firstMissingDay = unixDateRange[missingDaysIndexes[0]]

    // only one day missing, return a one day range
    if (missingDaysIndexes.length === 1) {
      return new CISDateRange(firstMissingDay * MILLISECOND, getKeyFromStartDate(firstMissingDay, 1) * MILLISECOND)
    } else {
      /**
       * If more than one day is missing then return the first and the day after the last day of the missing range.
       *
       * @example if the missing indexes are [1,3,5], then return days between indexes 1 and 6.
       * The CIS includes the "from" value, but exclude the "to" value.
       * So if we want [1 to 5], we need to ask [1 to 6]
       *
       * Our goal is to minimize the amount of requests to the backend, so even if some days
       * are cached in the range (days at index 2 and 4 in the example) we fetch them again.
       */
      const lastMissingDay = unixDateRange[missingDaysIndexes[missingDaysIndexes.length - 1]] + DAY_IN_S

      return new CISDateRange(firstMissingDay * MILLISECOND, lastMissingDay * MILLISECOND)
    }
  }

  isDateRangeCached(dateRange: CISDateRange) {
    return dateRange.toTzNormalizedUnixKeys().every(date => this.cacheDays.includes(date))
  }

  setEvents(dateRange: CISDateRange, events: readonly CalendarEvent[]) {
    this.markRangeAsFetched(dateRange)
    this.setCache([...events])
    this.sortDays()
  }

  getEvents(dateRange: CISDateRange, excludePrivate?: boolean): readonly CalendarEvent[] {
    const { from, to } = dateRange.toOffsetUnixTime()

    const eventsList: CalendarEvent[] = []
    const filterByPrivateEvent = (privateEvent: boolean) => !excludePrivate || (excludePrivate && !privateEvent)

    this.cacheEvents.forEach(event => {
      // The event's start and end dates include the GMT parameter.
      // This allow us to get the exact unix time of the event.
      const eventStart = DateTime.fromJSDate(new Date(event.start)).toSeconds()
      const eventEnd = DateTime.fromJSDate(new Date(event.end)).toSeconds()

      // This logic covers the case of events spanning more than one day, as implemented by Outlook.
      // Outlook: return an event if it is happening (even partially) during the day(s) requested;
      // Google: seems to return events based on the start date.
      // We'll keep Outlook's logic here and won't implement other vendor-specific logic for the time being
      // TODO Remove after completion of GTR-1833 (make sure Shell's Calendar API follows the new CIS standard)
      if (
        (eventStart >= from && eventStart < to) ||
        (eventEnd >= from && eventEnd < to) ||
        (eventStart <= from && eventEnd >= to)
      ) {
        eventsList.push(event)
      }
    })

    return eventsList
      .filter(event => filterByPrivateEvent(event.privateEvent))
      .sort(
        (a, b) => DateTime.fromJSDate(new Date(a.start)).toSeconds() - DateTime.fromJSDate(new Date(b.end)).toSeconds(),
      )
  }

  /**
   * Parse the events from the calendar response and merge each CalendarEvent into the events cache
   *
   * @param events CalendarEvent array
   */
  private setCache(events: CalendarEvent[]): void {
    events.forEach(event => {
      this.cacheEvents.set(event.eventId, event)
    })
  }

  /**
   * Set each new fetched day in the days list to keep track of which has already been fetched
   *
   * @param from Unix time 'from' value
   * @param to Unix time 'to' value
   */
  private markRangeAsFetched(dateRange: CISDateRange): void {
    dateRange.toTzNormalizedUnixKeys().forEach(unixDateKey => {
      if (!this.cacheDays.includes(unixDateKey)) {
        this.cacheDays.push(unixDateKey)
      }
    })
  }

  private sortDays(): void {
    this.cacheDays.sort()
  }
}
