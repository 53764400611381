import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getProductTrialFromLocalStorage, isFreeUser, isTrialUser } from '../../common/user-helpers'
import { TrialBannerService } from '../../services/banner/trial-banner-service'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { getProductTrialInfo } from '../../services/product'

export const initializeTrialBanner = () => {
  const isAuthenticated = !!getShellApiInstance()?.user?.key
  if (isAuthenticated && getFeatureFlagValue(FeatureFlagsVariations.SHELL_CAN_SEE_TRIAL_BANNER)) {
    const trialBannerService = new TrialBannerService()
    getProductTrialInfo().then(() => {
      if (canShowTrialBanner()) {
        trialBannerService.show()
      } else {
        trialBannerService.hide()
      }
    })
  }
}

/**
 * Checks if user has an active product trial to show the trial banner
 * @returns true/false
 */
export const canShowTrialBanner = (): boolean => {
  const isAuthenticated = !!getShellApiInstance()?.user?.key
  const productTrialExists = !!getProductTrialFromLocalStorage()
  if (!isAuthenticated) {
    return false
  }
  if (!productTrialExists) {
    return false
  }
  return isFreeUser() || isTrialUser()
}
