import { Presence } from '@getgo/chameleon-core'
import type {
  IShellPresenceService,
  PresenceSnapshot,
  PresenceSubscription,
  ShellServiceSubscriptionState,
  UserPresence,
} from './models'
import {
  isCurrentUser,
  hasSubscribers,
  isPresenceMessage,
  getUsersAppearance,
  isUserPresenceMessage,
  createPresenceSubscription,
  convertAppearanceToPresence,
  convertUsersAppearanceToPresenceSnapshots,
} from './helpers'
import { onWindowUnload } from '../../common/helpers/window'
import { getShellLogger } from '../../common/logger'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getEventBus } from '../namespaces/event-bus'
import type { NotificationChannelEvents } from '../notification-channel'
import { NotificationChannelNamespace } from '../notification-channel'
import type { ChannelInfo, NotificationChannelEvent } from '../notification-channel/models'
import { Interval, Timer } from '../notification-channel/helpers'
import type { PresenceService } from './service'
import { presenceUpdate, userPresenceUpdate } from './event-bus'
import type { ExternalUserKey } from '../../core/models'
import { clearTimeout, setTimeout } from '../../common/dom-helpers'

type AccumulatedResolve = readonly ((value: PresenceSnapshot | PromiseLike<PresenceSnapshot>) => void)[]

const UNSUBSCRIPTION_INTERVAL_IN_MS = 10 * 1000 // 10 seconds
const SHOW_DISCONNECTION_DELAY_IN_MS = 10 * 1000 // 10 seconds

const mergeWithCurrentSubscription = (
  subscription: PresenceSubscription,
  subscriptions: ReadonlyMap<ExternalUserKey, ShellServiceSubscriptionState>,
) => {
  const currentSubscription = subscriptions.get(subscription.externalUserKey)
  return { ...currentSubscription, ...subscription }
}

export class ShellPresenceService implements IShellPresenceService {
  private disconnectionTimeout: number | undefined = undefined

  private readonly presenceService: PresenceService
  private notificationChannelId?: string
  private isNotificationChannelAvailable(): boolean {
    return !!this.notificationChannelId
  }
  private oldNotificationChannelId?: string

  private externalUserKey = ''

  private readonly unsubscribeInterval?: Interval

  private accumulatedResolves: Map<string, AccumulatedResolve> = new Map()
  private accumulatorTimer: Timer | null = null

  private externalUserKeys: Map<string, (value: void | PromiseLike<void>) => void> = new Map()
  private subscribeTimer: Timer | null = null

  readonly subscriptions = new Map<ExternalUserKey, ShellServiceSubscriptionState>()

  constructor(presenceService: PresenceService, notificationChannelId: string) {
    this.presenceService = presenceService
    this.notificationChannelId = notificationChannelId

    this.subscribeToChannelEvents()
    this.unsubscribeInterval = new Interval(this.unsubscribeIntervalHandler, UNSUBSCRIPTION_INTERVAL_IN_MS)
  }

  /**
   * Get the current presence status for a list of users.
   *
   * Useful when you want to limit the amount of notification channel subscriptions and/or don't care to receive updates about a user's presence.
   */
  getPresenceSnapshots(externalUserKey: ExternalUserKey): Promise<PresenceSnapshot> {
    return new Promise(resolve => {
      const userKeyResolvers = this.accumulatedResolves.get(externalUserKey) ?? []
      this.accumulatedResolves.set(externalUserKey, [...userKeyResolvers, resolve])

      if (!this.accumulatorTimer) {
        this.accumulatorTimer = new Timer(() => {
          this.accumulatorTimer = null

          const userKeys = this.getAccumulatedUserKeys()
          const resolves = this.getAccumulatedResolvesCopy()
          this.resetAccumulatedUserKeys()

          this.fetchUserAppearance(userKeys, resolves)
        }, 250)
      }
    })
  }

  /**
   * Callback function will get called when there is already a subscription for a certain external user key
   */
  async subscribe(externalUserKey: ExternalUserKey): Promise<void> {
    const subscription: ShellServiceSubscriptionState | undefined = this.subscriptions.get(externalUserKey)

    if (subscription) {
      const { userDoNotDisturb, userAppearance, userStatus, userNote, status, presence, appearance } = subscription
      this.increaseSubscriberCount(externalUserKey)
      presenceUpdate({ appearance, presence, externalUserKey, userNote, status })
      if (isCurrentUser(externalUserKey)) {
        userPresenceUpdate({
          externalUserKey,
          userDoNotDisturb,
          userAppearance,
          userStatus,
          userNote,
          appearance,
          status,
        })
      }
    } else {
      return await new Promise(resolve => {
        this.externalUserKeys.set(externalUserKey, resolve)

        if (!this.subscribeTimer) {
          this.subscribeTimer = new Timer(() => {
            this.subscribeTimer = null
            this.internalSubscribe(new Map(this.externalUserKeys))
            this.externalUserKeys = new Map()
          }, 250)
        }
      })
    }
  }

  unsubscribe(externalUserKey: ExternalUserKey): void {
    const subscription = this.subscriptions.get(externalUserKey)

    if (!subscription) {
      return
    }

    this.decreaseSubscriberCount(externalUserKey)
    this.unsubscribeInterval?.reset()
  }

  /**
   * Get current user's presence.
   */
  getUserPresence(): Promise<UserPresence | void> {
    return this.presenceService
      .getUserPresence()
      .then(async res => await res.json())
      .catch(error => {
        getShellLogger().error(`Failed to get user presence with error: ${error}`)
      })
  }

  /**
   * Change current user's presence.
   *
   * We can’t and shouldn’t be able to update someone else’s.
   *
   * Bookmarks:
   * - https://developer-internal.goto.com/apis/presence/v1#operation/setUserPresence
   */
  setUserPresence(presence: Partial<UserPresence>): Promise<UserPresence | void> {
    return this.presenceService
      .setUserPresence(presence)
      .then(async response => await response.json())
      .catch(error => {
        getShellLogger().error(`Failed to set user presence with error: ${error}`)
      })
  }

  private subscribeToChannelEvents() {
    const channelEvents = getEventBus().subscribeTo<
      typeof NotificationChannelNamespace,
      typeof NotificationChannelEvents
    >(NotificationChannelNamespace)

    channelEvents.connected.on(this.notificationChannelConnectedHandler)
    channelEvents.disconnected.on(this.notificationChannelDisconnectedHandler)
    channelEvents.message.on(this.handleNotificationEvent)

    onWindowUnload(() => {
      channelEvents.connected.removeListener(this.notificationChannelConnectedHandler)
      channelEvents.disconnected.removeListener(this.notificationChannelDisconnectedHandler)
      channelEvents.message.removeListener(this.handleNotificationEvent)
    })
  }

  private readonly notificationChannelConnectedHandler = (channelInfo: ChannelInfo) => {
    if (this.disconnectionTimeout !== undefined) {
      clearTimeout(this.disconnectionTimeout)
      this.disconnectionTimeout = undefined
    }
    const { user } = getShellApiInstance()

    this.notificationChannelId = channelInfo.channelId

    if (this.externalUserKey === '') {
      const externalUserKey = user.key ?? ''
      this.externalUserKey = externalUserKey
    }

    // If after a reconnection the notificationChannelId has changed, we invalidate old subscriptions.
    if (this.notificationChannelId !== this.oldNotificationChannelId) {
      this.unregisterSubscriptionsToPresenceService(this.oldNotificationChannelId)
    }

    this.oldNotificationChannelId = this.notificationChannelId

    this.registerSubscriptionsToPresenceService()
  }

  private readonly notificationChannelDisconnectedHandler = () => {
    this.disconnectionTimeout = setTimeout(this.showPresenceDisconnected, SHOW_DISCONNECTION_DELAY_IN_MS)
  }

  private readonly showPresenceDisconnected = () => {
    this.disconnectionTimeout = undefined
    presenceUpdate({ presence: Presence.DISCONNECTED, externalUserKey: this.externalUserKey })
    this.notificationChannelId = undefined
  }

  private emitUserCurrentPresence() {
    const subscription = this.subscriptions.get(this.externalUserKey)
    // When the notification channel is recreated, the subscriptions are re created too. Thus, the presence
    // of the current user is undefined but the presence service will fetch it and emit the update
    if (subscription?.presence) {
      const { presence, userNote } = subscription
      presenceUpdate({ presence, externalUserKey: this.externalUserKey, userNote })
    }
  }

  private createPresenceSubscriptionHandler(externalUserKeys: ExternalUserKey[]) {
    const presenceServiceSubscription = this.presenceService.createSubscription({
      externalUserKeys: externalUserKeys,
      // TODO handle undefined notificationChannelId https://jira.ops.expertcity.com/browse/SCAPI-459
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      channelId: this.notificationChannelId!,
    })

    this.unsubscribeInterval?.reset()

    return createPresenceSubscription(externalUserKeys, presenceServiceSubscription)
  }

  private registerSubscriptionsToPresenceService() {
    const externalUserKeys: ExternalUserKey[] = []

    this.subscriptions.forEach((value: ShellServiceSubscriptionState, externalUserKey: string) => {
      if (!value.subscriptionId) {
        externalUserKeys.push(externalUserKey)
      }
    })

    if (externalUserKeys.length) {
      this.createPresenceSubscriptionHandler(externalUserKeys).then(subscriptions => {
        subscriptions.forEach(subscription => {
          this.updateSubscription(subscription.externalUserKey, {
            ...mergeWithCurrentSubscription(subscription, this.subscriptions),
          })
        })

        this.emitUserCurrentPresence()
      })
    } else {
      this.emitUserCurrentPresence()
    }
  }

  private unregisterSubscriptionsToPresenceService(channelId?: string) {
    if (channelId) {
      this.presenceService.deleteSubscriptionByChannelId(channelId).catch(e => {
        this.emitUserCurrentPresence()
        getShellLogger().log('Presence:deleteSubscriptionByChannelId', e)
      })
    }
    this.subscriptions.forEach((value: ShellServiceSubscriptionState, externalUserKey: string) => {
      if (value.subscriptionId) {
        this.subscriptions.set(externalUserKey, {
          subscriptionId: undefined,
          subscribersCount: value.subscribersCount,
        })
      }
    })
  }

  private readonly handleNotificationEvent = (message: NotificationChannelEvent<unknown>) => {
    if (isPresenceMessage(message)) {
      const messageContent = message.data.content
      const { appearance, externalUserKey, userNote, status } = messageContent
      if (isUserPresenceMessage(message)) {
        const { userDoNotDisturb, userAppearance, userStatus } = message.data.content
        this.updateSubscription(externalUserKey, { userDoNotDisturb, userAppearance, userNote, userStatus, status })
        userPresenceUpdate({
          eventId: message.eventId,
          externalUserKey,
          userDoNotDisturb,
          userAppearance,
          userNote,
          userStatus,
          appearance,
          status,
        })
      }
      const presence = convertAppearanceToPresence(appearance)
      this.updateSubscription(externalUserKey, { presence, appearance, userNote, status })
      presenceUpdate({
        eventId: message.eventId,
        externalUserKey,
        presence,
        appearance,
        userNote,
        status,
      })
    }
  }

  private async internalSubscribe(
    externalUserKeys: Map<string, (value: void | PromiseLike<void>) => void>,
  ): Promise<void> {
    const userKeys = Array.from(externalUserKeys.keys())

    userKeys.forEach(userKey => {
      this.updateSubscription(userKey, {
        subscriptionId: undefined,
        subscribersCount: 1,
      })
    })

    if (this.isNotificationChannelAvailable()) {
      this.createPresenceSubscriptionHandler(userKeys).then(subscriptions => {
        subscriptions.forEach(subscription => {
          this.updateSubscription(subscription.externalUserKey, { ...subscription })
          const resolve = externalUserKeys.get(subscription.externalUserKey)

          if (resolve) {
            resolve()
          }
        })
      })
    }

    this.unsubscribeInterval?.reset()
  }

  private increaseSubscriberCount(externalUserKey: string) {
    const previousValue = this.subscriptions.get(externalUserKey)
    if (previousValue) {
      this.subscriptions.set(externalUserKey, {
        ...previousValue,
        subscribersCount: ++previousValue.subscribersCount,
      })
    }
  }

  private getAccumulatedUserKeys() {
    return Array.from(this.accumulatedResolves.keys())
  }

  private getAccumulatedResolvesCopy() {
    return new Map(this.accumulatedResolves)
  }

  private resetAccumulatedUserKeys() {
    this.accumulatedResolves = new Map()
  }

  private fetchUserAppearance(userKeys: string[], resolves: Map<string, AccumulatedResolve>) {
    getUsersAppearance(userKeys).then(usersAppearances => {
      const presenceSnapshots = convertUsersAppearanceToPresenceSnapshots(usersAppearances)

      presenceSnapshots.forEach(presenceSnapshot => {
        const userKeyResolvers = resolves.get(presenceSnapshot.externalUserKey)
        if (userKeyResolvers) {
          userKeyResolvers.forEach(resolve => resolve(presenceSnapshot))
        }
      })
    })
  }

  private decreaseSubscriberCount(externalUserKey: string) {
    const previousValue = this.subscriptions.get(externalUserKey)
    if (previousValue) {
      this.subscriptions.set(externalUserKey, {
        ...previousValue,
        subscribersCount: --previousValue.subscribersCount,
      })
    }
  }

  private readonly updateSubscription = (
    externalUserKey: string,
    subscription: Partial<ShellServiceSubscriptionState>,
  ) => {
    this.subscriptions.set(externalUserKey, {
      ...this.subscriptions.get(externalUserKey),
      // TODO properly handle updating a subscription with no subscribers count https://jira.ops.expertcity.com/browse/SCAPI-460
      ...(subscription as ShellServiceSubscriptionState),
    })
  }

  private readonly unsubscribeIntervalHandler = () => {
    Array.from(this.subscriptions.keys()).forEach(externalUserKey => {
      const subscription = this.subscriptions.get(externalUserKey)

      if (!hasSubscribers(subscription)) {
        if (subscription?.subscriptionId) {
          this.presenceService.deleteSubscriptionBySubscriptionId(subscription.subscriptionId)
        }

        this.subscriptions.delete(externalUserKey)
      }
    })
  }
}
