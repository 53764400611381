import { nameSpacedCustomEventCreator } from '../../common/helpers/custom-event'

export const EMPTY_STATE_ACTION = 'empty-state-action'

/**
 * @param detail: the action button type
 */
export const emptyStateActionButtonClickedEvent = nameSpacedCustomEventCreator<string>(EMPTY_STATE_ACTION)

declare global {
  interface HTMLElementEventMap {
    readonly [EMPTY_STATE_ACTION]: CustomEvent<string>
  }
}
