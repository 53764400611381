import { getFeatureFlagValue } from '../feature-flags'
import { FeatureFlagsVariations } from '../feature-flags/models'
import { NotificationChannel } from '../notification-channel'
import type {
  GotoNotificationChannelConfig,
  NotificationChannel as INotificationChannel,
} from '@goto/shell-notification-channel'
import { GotoNotificationChannel } from '@goto/shell-notification-channel'
import { environment } from '../../environments'
import { getShellLogger } from '../../common/logger'
import { notificationChannelTokenResolver } from './notification-channel-token-resolver'
import { createNotificationDispatcher } from './dispatchers/notification-channel-dispatcher-factory'

export const createNotificationChannel = (userKey: string): INotificationChannel => {
  if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_NOTIFICATION_CHANNEL_V2)) {
    const baseURL = environment().notificationChannelUrl
    const notificationChannelConfig: GotoNotificationChannelConfig = {
      userKey,
      baseURL,
      tokenResolver: notificationChannelTokenResolver,
      logger: getShellLogger(),
    }
    const notificationChannel = new GotoNotificationChannel(notificationChannelConfig)

    return createNotificationDispatcher(notificationChannel)
  }
  return new NotificationChannel(userKey) as INotificationChannel
}
