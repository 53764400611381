import { environment } from '../../environments'
import { getEventBus, eventEmitter } from '../namespaces/event-bus'

import { Channel } from './channel'
import { ChannelEvent } from './channel-event'
import type { NotificationChannelInfo, NotificationChannelEvent, RTCError, ChannelInfo } from './models'

export const NotificationChannelNamespace = 'NotificationChannel'

export const NotificationChannelEvents = {
  message: eventEmitter<NotificationChannelEvent>(),
  error: eventEmitter<RTCError>(),
  connected: eventEmitter<ChannelInfo>(),
  disconnected: eventEmitter<RTCError>(),
}
export class NotificationChannel extends Channel<NotificationChannelInfo> {
  private readonly notificationNamespace = getEventBus().register(
    NotificationChannelNamespace,
    NotificationChannelEvents,
  )

  protected getChannelRefreshInfoURL() {
    const { notificationChannelUrl } = environment()
    return `${notificationChannelUrl}/channels/${this.externalUserKey}/${this.channelInfo?.channelId}/channelLifetime`
  }

  protected getChannelInfoURL() {
    const { notificationChannelUrl } = environment()
    return `${notificationChannelUrl}/channels/${this.externalUserKey}`
  }

  protected getChannelURL(channelInfo: NotificationChannelInfo) {
    return channelInfo.channelData?.channelURL ?? ''
  }

  protected getChannelID(channelInfo: NotificationChannelInfo) {
    return channelInfo.resourceURL?.split('/').pop() ?? ''
  }

  protected getChannelResourceURL(channelInfo: NotificationChannelInfo) {
    return channelInfo.resourceURL ?? ''
  }

  subscribe<T>(eventListener: (event: NotificationChannelEvent<T>) => void) {
    this.addListener(ChannelEvent.MESSAGE, eventListener)
  }

  unsubscribe<T>(eventListener: (event: NotificationChannelEvent<T>) => void) {
    this.removeListener(ChannelEvent.MESSAGE, eventListener)
  }

  protected doChannelConnected() {
    super.doChannelConnected()
    const channelInfo: ChannelInfo = {
      channelId: this.channelInfo.channelId,
      channelLifetime: this.channelInfo.channelLifetime,
    }
    this.notificationNamespace.emit.connected(channelInfo)
  }

  protected doChannelDisconnected(error: RTCError) {
    super.doChannelDisconnected(error)
    this.logger.log('NotificationChannel.Disconnected', {
      error,
    })
    this.notificationNamespace.emit.disconnected(error)
  }

  protected doChannelMessage(data: NotificationChannelEvent) {
    super.doChannelMessage(data)
    this.notificationNamespace.emit.message(data)
  }

  protected doChannelError(error: RTCError) {
    super.doChannelError(error)
    this.notificationNamespace.emit.error(error)
  }
}
