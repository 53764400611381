import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import { Plugins } from '@getgo/container-client'
import type { KeyboardShortcutId, KeyboardShortcutConfig, KeyboardShortcutConfigs } from '@getgo/container-client'
import { onWindowUnload } from '../../common/helpers/window'
import { filterOutEmptyKeyCombinationFromConfig, filterOutEmptyKeyCombinationFromConfigs } from './keyboard-shortcut'

export const KeyboardShortcutNamespace = 'keyboard-shortcut'

type KeyboardShortcutUpdateConfigEventPayload = {
  readonly shortcutId: KeyboardShortcutId
  readonly config: ReadonlyArray<KeyboardShortcutConfig>
}
type KeyboardShortcutUpdateConfigsEventPayload = {
  readonly configs: KeyboardShortcutConfigs
}

export const KeyboardShortcutEvents = {
  updateConfiguration: eventEmitter<KeyboardShortcutUpdateConfigEventPayload>(),
  updateConfigurations: eventEmitter<KeyboardShortcutUpdateConfigsEventPayload>(),
}

const { emit } = getEventBus().register<typeof KeyboardShortcutEvents>(
  KeyboardShortcutNamespace,
  KeyboardShortcutEvents,
)

export const updateKeyBoardShortcutConfiguration = (
  shortcutId: KeyboardShortcutId,
  config: ReadonlyArray<KeyboardShortcutConfig>,
) =>
  emit.updateConfiguration({
    shortcutId,
    config,
  })

export const updateKeyBoardShortcutConfigurations = (configs: KeyboardShortcutConfigs) =>
  emit.updateConfigurations({ configs })

export const subscribeToKeyboardShortcutConfigurationChange = () => {
  const updateConfigurationCallback = (payload: KeyboardShortcutUpdateConfigEventPayload) =>
    Plugins.KeyboardShortcuts.systemInterface.updateConfiguration(
      payload.shortcutId,
      filterOutEmptyKeyCombinationFromConfig(payload.config),
    )

  const updateConfigurationsCallback = (payload: KeyboardShortcutUpdateConfigsEventPayload) =>
    Plugins.KeyboardShortcuts.systemInterface.updateConfigurations(
      filterOutEmptyKeyCombinationFromConfigs(payload.configs),
    )

  const channelEvents = getEventBus().subscribeTo<typeof KeyboardShortcutNamespace, typeof KeyboardShortcutEvents>(
    KeyboardShortcutNamespace,
  )

  channelEvents.updateConfiguration.addListener(updateConfigurationCallback)
  channelEvents.updateConfigurations.addListener(updateConfigurationsCallback)
  onWindowUnload(() => {
    channelEvents.updateConfiguration.removeListener(updateConfigurationCallback)
    channelEvents.updateConfigurations.removeListener(updateConfigurationsCallback)
  })
}
