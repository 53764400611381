import { html } from 'lit-element'
import { ShellElement } from '../../common/shell-element'
import type { I18NEvents } from '../../services/i18n'
import { setDocumentLocale, getCurrentLocale, I18NNamespace } from '../../services/i18n'
import { getEventBus } from '../../services/namespaces/event-bus'
import { updateChameleonThemeProvider, subscribeToMsTeamThemeChanged, saveChameleonTheme } from '../../services/theme'
import { getDocument } from '../../common/dom-helpers'
import { CHAMELEON_THEME_PROVIDER } from '../../common/container'
import { ShellNamespace } from '../../services/shell-namespace'
import type { Theme, shellEvents } from '../../services/shell-namespace'
import { getExternalInterface } from '../../services/external-interface'

/**
 * Element that allows to interact with the shell instance in a document context
 */
export class GoToShellController extends ShellElement {
  static readonly tagName = 'goto-shell-controller'
  private initialized = false

  /**
   * initialize the controller with shell.  Shell API must be available prior calling this
   */
  public initializeController() {
    this.initialized = true
    if (this.isConnected) {
      this.init()
    }
  }

  private init() {
    this.updateLocale()
    this.subscribeToLocaleChange()
    this.subscribeToThemeChange()
    if (getExternalInterface().isIntegration) {
      subscribeToMsTeamThemeChanged()
    }
  }

  connectedCallback() {
    super.connectedCallback()
    if (this.initialized) {
      this.init()
    }
  }

  render() {
    return html`<slot></slot>`
  }

  private readonly updateLocale = () => {
    setDocumentLocale(getCurrentLocale(), document)
  }

  private subscribeToLocaleChange() {
    const { localeChanged } = getEventBus().subscribeTo<typeof I18NNamespace, typeof I18NEvents>(I18NNamespace)
    localeChanged.on(this.updateLocale)
    this.unsubscribeFunctions.push(() => {
      localeChanged.removeListener(this.updateLocale)
    })
  }

  private readonly updateTheme = (theme: Theme) => {
    const chameleonThemeProvider = getDocument().getElementsByTagName(CHAMELEON_THEME_PROVIDER)[0]
    updateChameleonThemeProvider(chameleonThemeProvider, theme)
    saveChameleonTheme(theme)
  }

  private subscribeToThemeChange() {
    const { themeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    themeChanged.on(this.updateTheme)
    this.unsubscribeFunctions.push(() => {
      themeChanged.removeListener(this.updateTheme)
    })
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-shell-controller': GoToShellController
  }
}
