import { Launcher } from '../../services/launcher'
import { isMainWindow } from '../../services/container/windows-management'
import { setupResolveCommands } from '../../services/namespaces/resolve-namespace'
import { setupPresenceCommands } from '../../services/namespaces/presence-namespace'
import type { ShellHook } from './models'
import { setupShellHelpCommands } from '../../services/namespaces/shell-help-namespace'

export const initializeCommands: ShellHook = async () => {
  if (isMainWindow(window)) {
    Launcher.getInstance().setupLauncherCommands()
    setupResolveCommands()
    setupPresenceCommands()
    setupShellHelpCommands()
  }
}
