import { html, LitElement } from 'lit-element'
import { t } from '../../directives/translate'
import styles from './integration-start-plugin.styles.scss'
import { DOWNLOAD_MS_TEAMS_PLUGIN_URL, START_MS_TEAMS_PLUGIN_URL } from '../../core/integrations-helpers'
import { navigateToExternal } from '../../common/dom-helpers'

export class IntegrationStartPlugin extends LitElement {
  static readonly tagName = 'goto-integration-start-plugin'

  static get styles() {
    return styles
  }

  private readonly redirectToStartMsTeamsPlugin = () => {
    navigateToExternal(START_MS_TEAMS_PLUGIN_URL)
  }

  firstUpdated() {
    this.redirectToStartMsTeamsPlugin()
  }

  render() {
    return html` <chameleon-goto-logo></chameleon-goto-logo>
      <div class="integration-disconnected-container">
        <chameleon-typography variant="heading-large" class="container-title">
          ${t('Start the GoTo plugin')}
        </chameleon-typography>
        <chameleon-typography variant="body-large" class="container-description">
          ${t('For calls to work in Microsoft Teams, you must run the GoTo for Teams plugin on your desktop.')}
        </chameleon-typography>
        <chameleon-typography variant="body-large" class="container-description">
          ${t('If prompted, allow your browser to open the plugin.')}
        </chameleon-typography>
        <chameleon-typography variant="body-large">${t('Plugin not starting?')}</chameleon-typography>
        <div class="user-action-container">
          <chameleon-link id="start" @click=${this.redirectToStartMsTeamsPlugin} variant="body-large"
            >${t('Try again')}</chameleon-link
          >
          <chameleon-typography variant="body-large" color="type-color-secondary-inverted" class="divider"
            >|</chameleon-typography
          >
          <chameleon-link id="download" href="${DOWNLOAD_MS_TEAMS_PLUGIN_URL}" target="_blank" variant="body-large"
            >${t('Download again')}</chameleon-link
          >
        </div>
      </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-integration-start-plugin': IntegrationStartPlugin
  }
}
