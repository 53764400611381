import { setTimeout, clearTimeout } from '../../../common/dom-helpers'
import { DEFAULT_ISLOGGEDIN_STATE, IS_LOGGEDIN_IFRAME_LOAD_TIMEOUT } from './constant'
import { reloadIsLoggedInIframe } from './isLoggedIn-iframe'
import { IsLoggedInState } from './isLoggedIn-state'
import type { Observer, Subscription } from 'rxjs'

export const isLoggedIn = () => new IsLoggedInObserver().getPromise()

class IsLoggedInObserver implements Observer<boolean> {
  closed?: boolean
  error: (err: any) => void = () => {}
  complete: () => void = () => {}

  private resolve: (value: boolean) => void = () => {}
  private timer: number | undefined
  private subscription: Subscription | undefined

  getPromise() {
    return new Promise<boolean>(resolve => {
      this.resolve = resolve

      this.subscription = IsLoggedInState.getInstance().subscribe(this)
      // *** DO NOT LOWER VALUE WITHOUT READING COMMENT IN ./constants FILE ***
      this.timer = setTimeout(this.timeout.bind(this), IS_LOGGEDIN_IFRAME_LOAD_TIMEOUT)

      reloadIsLoggedInIframe()
    })
  }

  next(value: boolean) {
    this.unsubscribeAndClearTimeout()
    this.resolve(value)
  }

  private timeout() {
    this.unsubscribeAndClearTimeout()
    this.resolve(DEFAULT_ISLOGGEDIN_STATE)
  }

  private unsubscribeAndClearTimeout() {
    if (typeof this.timer === 'number') {
      clearTimeout(this.timer)
    }
    this.subscription?.unsubscribe()
  }
}
