import { DesktopCompanionConnectionStatus, type DesktopCompanionStatusChangedEvent } from '@getgo/container-client'
import { clearTimeout, openNewTabFor, setTimeout } from '../../common/dom-helpers'
import { navigateToUrl } from '../../common/helpers'
import { getShellLogger } from '../../common/logger'
import { getOpenMsTeamsUrl } from '../../core/environment'
import { type ExternalInterface } from './external-interface'
import { getExternalInterface } from './external-interface-adapter'
import { type NavigationMessage, type NavigationMessagePayload } from './messages/navigation'

/*
  When companion wants integration to navigate to a relatve URL:
  1. Companion app opens/focuses the integration app (via external browser).
  2. Companion broadcasts 'notify-navigation-available' message.
    - If there is an open integration and it receives the message, integration sends 'get-navigation' message.
  3. When integration starts, it also sends 'get-navigation' message to check for pending navigation requests.
  4. Companion responds to 'get-navigation' message with `get-navigation-response` message with the relative URL.
  5. Integration navigates to the received relative URL from the `get-navigation-response` message.
*/

let pendingNavigationRelativeUrl: string | undefined = undefined
let clearPendingNavigationTimerId: number | undefined = undefined
const CLEAR_PENDING_NAVIGATION_TIMEOUT_MS = 60 * 1000 // 1 min

/**
 * Integration-side handling of navigation messages. Also checks if there is a pending navigation. Should be called in
 * the beginning of the lifecycle of the integration app.
 * @param externalInterface the external interface instance
 */
export const externalInterfaceIntegrationNavigationHandler = (externalInterface: ExternalInterface) => {
  if (!externalInterface.isIntegration) {
    return
  }

  const getNavigation = () => {
    externalInterface.send<NavigationMessage<'get-navigation'>>({
      type: 'navigation',
      payload: {
        topic: 'get-navigation',
        data: {},
      },
    })
  }

  const handleNavigationMessages = (payload: NavigationMessagePayload) => {
    if (payload.topic === 'notify-navigation-available') {
      // New navigation available. Request it.
      getShellLogger().log('New navigation available')
      getNavigation()
    } else if (payload.topic === 'get-navigation-response') {
      // Navigate to the received relative URL.
      const typedPayload = payload as NavigationMessagePayload<'get-navigation-response'>
      getShellLogger().log(`Navigating to relative URL: ${typedPayload.data.relativeUrl}`)
      navigateToUrl(typedPayload.data.relativeUrl)
    }
  }

  const handleConnectionChanged = (e: DesktopCompanionStatusChangedEvent) => {
    if (e.connectionStatus === DesktopCompanionConnectionStatus.connected) {
      getNavigation()
    }
  }

  externalInterface.addCallback('navigation', handleNavigationMessages)

  // Check for pending navigation on startup (if connected already), and every time the integration connects. Checking
  // every time helps in case the integration has intermittent connection issues and has missed a
  // 'notify-navigation-available' message. In general, sending the 'get-navigation' message when there is no pending
  // navigation is not an issue.
  if (externalInterface.available) {
    getNavigation()
  }
  externalInterface.addConnectionCallback(handleConnectionChanged)
}

/**
 * Companion-side handling of navigation messages. Should be called in the beginning of the lifecycle of the comnpanion
 * app.
 * @param externalInterface the external interface instance
 */
export const externalInterfaceCompanionNavigationHandler = (externalInterface: ExternalInterface) => {
  if (!externalInterface.isCompanion) {
    return
  }

  const handleNavigationMessages = (payload: NavigationMessagePayload) => {
    if (payload.topic === 'get-navigation') {
      if (pendingNavigationRelativeUrl) {
        getShellLogger().log(`Integration claimed navigation relative URL: ${pendingNavigationRelativeUrl}`)

        // Respond to the 'get-navigation' message with the pending relative URL, and clear it.
        externalInterface.send<NavigationMessage<'get-navigation-response'>>({
          type: 'navigation',
          payload: {
            topic: 'get-navigation-response',
            data: {
              relativeUrl: pendingNavigationRelativeUrl,
            },
          },
        })
        pendingNavigationRelativeUrl = undefined
        if (clearPendingNavigationTimerId) {
          clearTimeout(clearPendingNavigationTimerId)
          clearPendingNavigationTimerId = undefined
        }
      }
    }
  }

  externalInterface.addCallback('navigation', handleNavigationMessages)
}

/** When called from the companion, ensures the integration app is opened, focused, and navigated to the given relative
 * URL.
 * @param relativeUrl The relative URL the integration should navigate to.
 */
export const externalInterfaceOpenIntegrationWithRelUrl = (relativeUrl: string) => {
  const externalInterface = getExternalInterface()
  if (!externalInterface.isCompanion) {
    return
  }

  getShellLogger().log(`Opening integration with relative URL: ${relativeUrl}`)

  openNewTabFor(getOpenMsTeamsUrl())

  pendingNavigationRelativeUrl = relativeUrl
  // Clearing unclaimed navigations after some time helps to avoid cases where the user declines or delays to start the
  // integration, and when it starts later, it gets an unexpected starting page.
  if (clearPendingNavigationTimerId) {
    clearTimeout(clearPendingNavigationTimerId)
  }
  clearPendingNavigationTimerId = setTimeout(() => {
    if (pendingNavigationRelativeUrl) {
      getShellLogger().log(
        `No integration claimed navigation, clearing pending relative URL: ${pendingNavigationRelativeUrl}`,
      )
      pendingNavigationRelativeUrl = undefined
    }
  }, CLEAR_PENDING_NAVIGATION_TIMEOUT_MS)

  // Let any open integrations know they can claim the navigation.
  externalInterface.send<NavigationMessage<'notify-navigation-available'>>({
    type: 'navigation',
    payload: {
      topic: 'notify-navigation-available',
      data: {},
    },
  })
}
