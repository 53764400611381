let chameleonVersion = '0.0.0-development-SNAPSHOT'

export const setChameleonWebVersion = (version: string | null) => {
  if (version) {
    chameleonVersion = version
  }
  console.log(`Chameleon version: ${version}`)
}

export const getChameleonWebVersion = () => chameleonVersion
