import type { Translatable, TranslatableFnOptions } from './types'

/**
 * Resolve a Translatable type by executing the translation or return the translate key
 * @param translatable
 * @param options
 * @returns
 */
export const resolveTranslatable = (translatable: Translatable, options?: TranslatableFnOptions) => {
  if (typeof translatable === 'string') {
    return translatable
  }

  return translatable(options)
}
