import type { RegisterApplicationConfig } from 'single-spa'
import { SHELL_APP_PREFIX } from '../constants'
import {
  CALENDAR_CONNECTED_ROUTE,
  GENERIC_ERROR,
  INTEGRATION_REDIRECT_PROXY_ROUTE,
  LANDING_ROUTE,
  SEARCH_ROUTE,
  START_MS_TEAMS_PLUGIN_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  UNAUTHENTICATED_ERROR,
} from '../../common/routes'

import GoToTermsOfService from '../../packages/terms-of-service/goto-terms-of-service'
import GoToShellLanding from '../../packages/shell-landing/goto-shell-landing'
import GoToCalendarConnected from '../../packages/calendar-connected/goto-calendar-connected'
import GoToUnauthenticatedError from '../../packages/unauthenticated-error/goto-unauthenticated-error'
import { GotoErrorPage } from '../../packages/error-page'
import GoToSearchResultsPage from '../../packages/global-search/search-results-page/goto-search-results-page'
import GoToIntegrationStartPlugin from '../../packages/integration-start-plugin/goto-integration-start-plugin'
import GoToIntegrationRedirectProxy from '../../packages/integration-redirect-proxy/goto-integration-redirect-proxy'
import { type CustomProps } from '../../common'
import { findAndDecodeBase64UrlParam } from '../../common/dom-helpers'

export const termsOfServiceConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}/terms-of-service`,
  app: GoToTermsOfService,
  activeWhen: TERMS_OF_SERVICE_ROUTE,
}

export const gotoShellLandingPageConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}/shell-landing`,
  app: GoToShellLanding,
  activeWhen: LANDING_ROUTE,
}

export const integrationStartPluginPageConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}${START_MS_TEAMS_PLUGIN_ROUTE}`,
  app: GoToIntegrationStartPlugin,
  activeWhen: START_MS_TEAMS_PLUGIN_ROUTE,
}

export const integrationRedirectProxyConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}${INTEGRATION_REDIRECT_PROXY_ROUTE}`,
  app: GoToIntegrationRedirectProxy,
  activeWhen: INTEGRATION_REDIRECT_PROXY_ROUTE,
  customProps: { data: findAndDecodeBase64UrlParam('data') } as CustomProps,
}

export const calendarConnected: RegisterApplicationConfig = {
  app: GoToCalendarConnected,
  name: `${SHELL_APP_PREFIX}${CALENDAR_CONNECTED_ROUTE}`,
  activeWhen: CALENDAR_CONNECTED_ROUTE,
}

export const unauthenticatedError: RegisterApplicationConfig = {
  app: GoToUnauthenticatedError,
  name: `${SHELL_APP_PREFIX}${UNAUTHENTICATED_ERROR}`,
  activeWhen: UNAUTHENTICATED_ERROR,
}

export const gotoErrorPageConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}/error-page`,
  app: GotoErrorPage,
  activeWhen: GENERIC_ERROR,
}

export const searchResultsPageConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}/search`,
  app: GoToSearchResultsPage,
  activeWhen: SEARCH_ROUTE,
}

export const getViewsApplication = (): readonly RegisterApplicationConfig[] => {
  const viewsApplication = [
    termsOfServiceConfig,
    gotoShellLandingPageConfig,
    calendarConnected,
    unauthenticatedError,
    gotoErrorPageConfig,
    integrationStartPluginPageConfig,
    integrationRedirectProxyConfig,
    searchResultsPageConfig,
  ]
  return viewsApplication
}
