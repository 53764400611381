import { type Token } from '@getgo/auth-client'
import { navigateToUrl } from '../../common/helpers'
import { DesktopCompanionConnectionStatus, type DesktopCompanionStatusChangedEvent } from '@getgo/container-client'
import { type ExternalInterface } from './external-interface'
import { LANDING_ROUTE } from '../../common/routes'
import { updateAuthToken } from './utils'

export const externalInterfaceIntegrationConnectionHandler = (externalInterface: ExternalInterface) => {
  const handleAuthToken = (payload: Token) => {
    updateAuthToken(payload)
  }

  const handleConnectionChanged = (e: DesktopCompanionStatusChangedEvent) => {
    if (e.connectionStatus === DesktopCompanionConnectionStatus.disconnected) {
      navigateToUrl(LANDING_ROUTE)
      externalInterface.connect()
    }
  }

  if (externalInterface.supportsCompanion) {
    externalInterface.addCallback('auth-token', handleAuthToken)
    externalInterface.addConnectionCallback(handleConnectionChanged)
    externalInterface.connect()
  }
}
