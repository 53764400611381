import type { ExtensionConfig } from '@goto/shell-common'
import { PrerequisiteRuleTypes } from '@goto/shell-common'
import { getFeatureFlag } from "../helpers/shell-helpers";

enum Flags {
  ENGAGEMENTS_VERSION = 'engagements.version'
}

enum EngagementsApps {
  PLG = 'plg',
  ONBOARDING = 'onboarding',
  MAIN = 'main',
  WC_LOADER = 'wc-loader'
}

type EngagementsVersion = {
  [key in EngagementsApps]: string
}

const fallbackAppVersions: EngagementsVersion = {
  [EngagementsApps.PLG]: 'latest',
  [EngagementsApps.ONBOARDING]: 'latest',
  [EngagementsApps.MAIN]: 'latest',
  [EngagementsApps.WC_LOADER]: 'latest'
}

const engagementsExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-ui-extension',
  get url() {
    const version = getFeatureFlag<EngagementsVersion>(Flags.ENGAGEMENTS_VERSION, fallbackAppVersions)?.main ?? fallbackAppVersions.main
    return new URL(`/experiences/engagements/${version}/@dce/engagements-ui-extension.mjs`, location.origin)
  },
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]: 'engagements',
  },
  dependencies: [
    '@dce/engagements-web-components-loader-extension',
    '@ucc/cc-config/extension'
  ],
  optionalDependencies: [
    '@goto/contacts-ui-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

const onboardingExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-onboarding-ui-extension',
  get url() {
    const version = getFeatureFlag<EngagementsVersion>(Flags.ENGAGEMENTS_VERSION, fallbackAppVersions)?.onboarding ?? fallbackAppVersions.onboarding
    return new URL(`/experiences/engagements/onboarding/${version}/@dce/engagements-onboarding-ui-extension.mjs`, location.origin)
  },
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]: 'engagements',
  },
  dependencies: [
    '@dce/engagements-web-components-loader-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

const plgExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-plg-ui-extension',
  get url() {
    const version = getFeatureFlag<EngagementsVersion>(Flags.ENGAGEMENTS_VERSION, fallbackAppVersions)?.plg ?? fallbackAppVersions.plg
    return new URL(`/experiences/engagements/plg/${version}/@dce/engagements-plg-ui-extension.mjs`, location.origin);
  },
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]: 'plg-motions.engagements && !engagements',
  },
  dependencies: [
    '@dce/engagements-web-components-loader-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

// Voluntarily without prerequisites. WC can be loaded by many extensions
// and they must have access to the commands to load them.
// WCs are loaded on demand only.
const wcLoaderExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-web-components-loader-extension',
  get url() {
    const version = getFeatureFlag<EngagementsVersion>(Flags.ENGAGEMENTS_VERSION, fallbackAppVersions)?.["wc-loader"] ?? fallbackAppVersions?.["wc-loader"]
    return new URL(`/experiences/engagements/wc-loader/${version}/extension.mjs`, location.origin)
  },
  esModule: true,
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

export default [wcLoaderExtensionConfig, engagementsExtensionConfig, onboardingExtensionConfig, plgExtensionConfig]
