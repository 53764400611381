import { html } from 'lit-element'
import { nothing } from 'lit-html'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { SVG_EXTERNAL_LINK_OUTLINED } from '@getgo/chameleon-icons'
import type { NormalMenuItem } from './menu-item-models'
import { MenuItemType } from './menu-item-models'
import { ifDefined } from 'lit-html/directives/if-defined'
import { t } from '../../directives/translate/translate'
import { getShellAnalytics } from '../../common/shell-api-helpers'

const createEndSlotTemplate = (menuItem: NormalMenuItem) => {
  const slotName = 'end'
  const { type, isExternalLink } = menuItem

  if (type === MenuItemType.NORMAL) {
    return isExternalLink
      ? html`<chameleon-svg slot="${slotName}" data-test="external-link">
          ${unsafeSVG(SVG_EXTERNAL_LINK_OUTLINED)}
        </chameleon-svg>`
      : nothing
  }

  return nothing
}

export const renderNormalMenuItem = (menuItem: NormalMenuItem) => {
  const { labelKey, executeFunction, dataTest } = menuItem

  return html`
    <chameleon-menu-item
      @menuitemclick=${() => {
        getShellAnalytics().track('GoTo > Avatar menu', { action: 'Click', target: labelKey, from: 'Topbar' })
        executeFunction()
      }}
      aria-label=${t(labelKey)}
      data-test=${ifDefined(dataTest)}
    >
      ${t(labelKey)} ${createEndSlotTemplate(menuItem)}
    </chameleon-menu-item>
  `
}
