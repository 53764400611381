import { getSearchHistory } from './../helpers'
import type { GlobalSearchQueryUI, GlobalSearchResponse, GlobalSearchResultUI } from '../global-search-models'
import { DEFAULT_GLOBAL_SEARCH_PARAMS } from '../global-search-models'
import type {
  ListboxComponent,
  ListboxItemComponent,
  PopoverComponent,
  SearchFieldComponent,
} from '@getgo/chameleon-web'
import { html, query, state } from 'lit-element'
import { ShellElement } from '../../../common/shell-element'
import { getGlobalSearchManager } from '../global-search-manager'
import globalSearch from './global-search-field.styles.scss'
import { nothing } from 'lit-html'
import debounce from 'lodash.debounce'
import type { globalSearchEvents, GlobalSearchQueryEventPayload } from '../namespace'
import { executeSearch, GLOBAL_SEARCH_NAMESPACE, notifySearchFieldQueryChange } from '../namespace'
import { getEventBus } from '../../../services/namespaces/event-bus'
import { cloneKeyboardEvent } from '../../../common/keyboard-events'
import { trackGlobalSearch } from '../analytics/analytics'
import { type UserPreferencesEvents, UserPreferencesNamespace } from '../../../services/user-preferences/events'
import { type UserPreferenceUpdated } from '../../../services/user-preferences/models'
import { getExternalInterface } from '../../../services/external-interface'
import { getTranslation } from '../../../services/i18n/i18nUtils'

export const LOADING_ROWS = 2

export interface ListboxEvent {
  readonly listbox: ListboxComponent | null | undefined
}

export interface GoToGlobalSearchFieldProps {
  readonly searchResults: readonly GlobalSearchResultUI[] | undefined
}

export const SEARCH_HISTORY_USER_PREFERENCE_KEY = 'search-history'

export const KEYUP_SEARCH_KEYS_WHITELIST = ['Backspace']

export class GoToGlobalSearchField extends ShellElement implements GoToGlobalSearchFieldProps {
  static readonly tagName = 'goto-global-search'

  @state() searchResults: GoToGlobalSearchFieldProps['searchResults'] | undefined
  @state() loading = false
  @state() private searchString = ''
  @state() private searchHistory: readonly GlobalSearchQueryUI[] = []
  @state() private activeKey = ''
  @state() private searchError = false
  @state() private globalSearchAvailable = false

  @query('chameleon-search-field') private readonly searchField: SearchFieldComponent | undefined
  @query('chameleon-popover') private readonly chameleonPopover: PopoverComponent | undefined

  public listbox: ListboxComponent | null | undefined
  private getPlaceholder() {
    return getExternalInterface().isIntegration ? getTranslation('Search') : getTranslation('Search contacts')
  }

  private readonly resizeObserver = new ResizeObserver(() => {
    this.updateSearchPopoverWidth()
  })

  static get styles() {
    return globalSearch
  }

  connectedCallback() {
    super.connectedCallback()
    if (getGlobalSearchManager().hasCategories()) {
      this.globalSearchAvailable = true
    }
    const { searchFieldQueryChange, categoriesUpdated } = getEventBus().subscribeTo<
      typeof GLOBAL_SEARCH_NAMESPACE,
      typeof globalSearchEvents
    >(GLOBAL_SEARCH_NAMESPACE)
    searchFieldQueryChange.addListener(this.queryListener)
    categoriesUpdated.addListener(this.categoriesUpdatedListener)
    this.unsubscribeFunctions.push(() => {
      searchFieldQueryChange.removeListener(this.queryListener)
      categoriesUpdated.removeListener(this.categoriesUpdatedListener)
    })
    this.subscribeToGlobalSearchHistory()
  }

  private readonly queryListener = (event: GlobalSearchQueryEventPayload) => {
    this.searchString = event.query.query
    if (this.searchField) {
      this.searchField.value = this.searchString
    }
    if (this.listbox) {
      this.deselectListboxItems()
    }
  }

  private readonly categoriesUpdatedListener = () => {
    this.globalSearchAvailable = true
  }

  private subscribeToGlobalSearchHistory() {
    const { userPreferencesUpdatedEvent } = getEventBus().subscribeTo<
      typeof UserPreferencesNamespace,
      typeof UserPreferencesEvents
    >(UserPreferencesNamespace)

    userPreferencesUpdatedEvent.addListener(this.userPreferencesUpdatedListener.bind(this))

    this.unsubscribeFunctions.push(() => {
      userPreferencesUpdatedEvent.removeListener(this.userPreferencesUpdatedListener)
    })
  }

  private readonly userPreferencesUpdatedListener = async (updatedUserPreference: UserPreferenceUpdated) => {
    if (updatedUserPreference.path === SEARCH_HISTORY_USER_PREFERENCE_KEY) {
      await this.setSearchHistory()
      if (!this.searchHistory.length) {
        this.closePopover()
      }
    }
  }

  private addFocusOutHandler(element: HTMLElement) {
    const focusOutHandler = (e: FocusEvent) => {
      element.removeEventListener('focusout', focusOutHandler)
      this.handleFocusOut(e)
    }
    element.addEventListener('focusout', focusOutHandler)
  }

  private handleFocusOut(e: FocusEvent) {
    const isDescendant = (child: HTMLElement, parent: HTMLElement) => {
      let node = child
      while (node != null) {
        if (node === parent) {
          return true
        }
        node = node.parentNode as HTMLElement
      }
      return false
    }

    if (
      e.relatedTarget &&
      (isDescendant(e.relatedTarget as HTMLElement, this) ||
        isDescendant(e.relatedTarget as HTMLElement, this.chameleonPopover!['popoverContentElement'] as HTMLElement))
    ) {
      this.addFocusOutHandler(e.relatedTarget as HTMLElement)
    } else {
      this.closePopover()
    }
  }

  // With the logic of having the first extension loaded first which may not support global search, we need to use updated instead of firstUpdated
  updated() {
    if (this.searchField) {
      this.resizeObserver.observe(this.searchField)
      this.searchField.addEventListener('paste', this.handlePasteEvent)

      this.unsubscribeFunctions.push(() => {
        this.resizeObserver.unobserve(this.searchField as Element)
        this.searchField?.removeEventListener('paste', this.handlePasteEvent)
      })
    }
  }

  handleItemClick() {
    this.closePopover()
  }

  render() {
    /**
     * When we click on the clear button and focus back on the search field this line will make sure that it doesn't display the previous search results
     * We can't use the change event from the chameleon search field since it's not working properly. Needs to be fixed on chameleon's side https://jira.ops.expertcity.com/browse/CHAMELEON-2001
     */
    this.searchString = this.searchField?.value ?? ''

    return this.globalSearchAvailable
      ? html`
          <chameleon-popover data-test="global-search-popover" menu z-index="60000">
            <div slot="trigger" class="popover-trigger" tabindex="-1">
              <chameleon-search-field
                label=${this.getPlaceholder()}
                autocomplete="off"
                placeholder=${this.getPlaceholder()}
                @click=${this.handleClickEvent}
                @keydown=${this.handleKeydownEvent}
                @keyup=${this.handleKeyupEvent}
                @focus=${this.handleFocus}
              >
              </chameleon-search-field>
            </div>
            <div slot="content">${this.renderPopoverContent()}</div>
          </chameleon-popover>
        `
      : nothing
  }

  private renderPopoverContent() {
    if (this.loading) {
      return html`${this.renderLoadingState()}`
    }
    if (this.searchError) {
      return html` <goto-search-error size="small"></goto-search-error> `
    } else if (this.searchString && this.searchResults) {
      return html`<goto-search-result
        @item-click=${this.handleItemClick}
        @listboxrendered=${this.handleListboxrendered}
        searchString=${this.searchString}
        .searchResults=${this.searchResults}
        ?searchError=${this.searchError}
      ></goto-search-result>`
    } else if (this.searchHistory.length && !this.searchString) {
      return html` <goto-search-history
        @item-click=${this.handleItemClick}
        @listboxrendered=${this.handleListboxrendered}
        .searchHistory=${this.searchHistory}
      ></goto-search-history>`
    } else {
      return nothing
    }
  }

  renderLoadingState() {
    return html`<goto-search-loading-state loadingRows=${LOADING_ROWS}></goto-search-loading-state>`
  }

  private async setSearchHistory() {
    this.searchHistory = await getSearchHistory()
  }

  private async handleFocus() {
    if (this.searchField) {
      this.addFocusOutHandler(this.searchField)
    }
    await this.setSearchHistory()
    // Force the popover open if a user refocuses on the field and search string is present or there is a search history
    if (this.searchString || this.searchHistory.length) {
      this.chameleonPopover?.open()
    }
  }

  private updateSearchPopoverWidth() {
    this.chameleonPopover?.setAttribute('width', Math.max(400, this.searchField?.clientWidth ?? 0).toString())
  }

  private updateSelectionState() {
    if (this.listbox) {
      const selectedIndex = this.listbox.selectedIndex
      const listboxItems = this.listbox.querySelectorAll('chameleon-listbox-item')
      if (listboxItems) {
        listboxItems.forEach((listBoxItem, index) => {
          this.updateItemSelectionState(listBoxItem, index === selectedIndex)
        })
      }
    }
  }

  /**
   * Toggle class to item to show/hide interactive sub items.
   * @param listboxItem chameleon listbox component item
   * @param selected boolean, indicates if the item is the current selected item of the listbox
   */
  private updateItemSelectionState(listboxItem: ListboxItemComponent, selected: boolean) {
    const previewItem = listboxItem.querySelector('goto-search-result-preview-item')
    const moreResultsItem = listboxItem.querySelector('chameleon-button.more-results-button')
    if (selected) {
      listboxItem.classList.add('selected')
      previewItem?.classList.add('selected')
      moreResultsItem?.classList.add('selected')
    } else {
      listboxItem.classList.remove('selected')
      previewItem?.classList.remove('selected')
      moreResultsItem?.classList.remove('selected')
    }
  }

  private setIndex(newIndex: number) {
    if (this.listbox) {
      this.listbox.selectedIndex = newIndex
      this.updateSelectionState()
    }
  }

  /**
   * A keyup event is when a pressed key on the keyboard is released
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/keyup_event}
   */
  private handleKeyupEvent(event: KeyboardEvent) {
    this.activeKey = event.key
    this.searchString = this.searchField?.value ?? ''
    if (this.keyShouldTriggerSearch(event.key) && this.searchString.trim()) {
      const query = { query: this.searchString }
      notifySearchFieldQueryChange({ query })
      this.searchDelay()
    } else {
      this.searchDelay.cancel()
      if (this.shouldClosePopover()) {
        this.closePopover()
      }
    }
  }

  private keyShouldTriggerSearch(key: string) {
    return key.length === 1 || KEYUP_SEARCH_KEYS_WHITELIST.includes(key)
  }

  /**
   * A keydown event is when a key on the keyboard is pressed
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event}
   */
  private handleKeydownEvent(event: KeyboardEvent) {
    this.activeKey = event.key
    if (this.listbox) {
      switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowRight':
        case ' ':
          this.dispatchEventToListboxAndPreventDefault(event)
          break

        case 'Enter':
          this.handleEnterEvent(event)
          break

        case 'ArrowUp':
          this.handleArrowUpEvent(event)
          break

        case 'ArrowDown':
          this.handleArrowDownEvent(event)
          break
      }
    }
    // Enables us to use the spacebar in the search field
    event.stopPropagation()
  }

  /**
   * Passes the event off to the Chameleon ListboxComponent
   * @see {@link https://chameleon.dev.gtc.goto.com/components/?pageid=listbox&platform=web&tab=web-component}
   */
  private dispatchEventToListbox(event: KeyboardEvent) {
    const forwardedEvent = cloneKeyboardEvent(event, { bubbles: false })
    this.listbox?.dispatchEvent(new KeyboardEvent(event.type, forwardedEvent))
  }

  /**
   * Prevents the default behavior for the event and passes it off to
   * the Chameleon ListboxComponent
   */
  private dispatchEventToListboxAndPreventDefault(event: KeyboardEvent) {
    this.dispatchEventToListbox(event)
    if (this.listboxItemsAreSelected()) {
      event.preventDefault()
    }
  }

  /**
   * When a user navigates with the `Up` key, we want to loop over each listbox item in an "upward" fashion
   */
  private handleArrowUpEvent(event: KeyboardEvent) {
    event.preventDefault()
    if (this.userIsAtStartOfListBox()) {
      //if the user is on the first item in the listbox, we deselect all items in the listbox
      this.deselectListboxItems()
    } else if (this.noListboxItemsSelected()) {
      //if the user currently has no items selected in the listbox, we bring them to the end of the listbox and select the last item in the inbox
      //thus restarting the navigation loop from the bottom up
      this.navigateToEndOfListBox()
    } else {
      //the user is navigating within the listbox component and we pass the event to the Chameleon ListboxComponent
      this.dispatchEventToListbox(event)
      this.updateSelectionState()
    }
  }

  /**
   * When a user navigates with the `Down` key, we want to loop over each listbox item in a "downward" fashion
   */
  private handleArrowDownEvent(event: KeyboardEvent) {
    event.preventDefault()
    if (this.userIsAtEndOfListbox()) {
      //if the user is on the last item in the listbox, we navigate out of the inbox
      this.deselectListboxItems()
    } else {
      //the user is navigating within the listbox component and we pass the event to the Chameleon ListboxComponent
      this.dispatchEventToListbox(event)
      this.updateSelectionState()
    }
  }

  /**
   * If the user presses enter on an item inside the listbox, we want to close the popover and perform a new search
   */
  private handleEnterEvent(event: KeyboardEvent) {
    //If user is inside the listbox
    if (this.listboxItemsAreSelected()) {
      //User is using the listbox so we pass the event to the listbox to handle and close the popover
      this.dispatchEventToListboxAndPreventDefault(event)
      this.closePopoverAndResetListbox()
    } else {
      //User is in the search bar

      //Enter event bubbles from clear button to the field itself, it is being addressed here: https://jira.ops.expertcity.com/browse/CHAMELEON-2344
      //SetTimeout is necessary to prevent the enter on clear from triggering a redirect to the full results page - we need time to clear the field.
      setTimeout(() => {
        this.searchString = this.searchField?.value ?? ''

        //Close the popover and execute the search if string isn't empty
        if (this.searchString.trim()) {
          this.deselectListboxItems()
          this.closePopoverAndExecuteSearch()
        }
      })
    }
  }

  private closePopoverAndResetListbox() {
    this.closePopover()
    this.deselectListboxItems()
    this.updateSelectionState()
  }

  private handleClickEvent(event: MouseEvent) {
    // Prevents the popover from opening when there is no search query or history
    event.stopPropagation()
    this.searchString = this.searchField?.value ?? ''
    if (!this.searchString.trim().length && !this.searchHistory.length) {
      this.closePopover()
    }
  }

  /**
   * When the selected index of the listbox is >= 0, the user is on an item within the listbox
   */
  private listboxItemsAreSelected() {
    const selectedIndex = this.listbox?.selectedIndex ?? -1
    return selectedIndex >= 0
  }

  /**
   * When the selected index of the listbox is < 0, the user is not navigating the listbox
   */
  private noListboxItemsSelected() {
    const selectedIndex = this.listbox?.selectedIndex ?? -1
    return selectedIndex < 0
  }

  /**
   * When the selected index of the listbox = listbox.listbox - 1, the user is on the last item within the listbox
   */
  private userIsAtEndOfListbox() {
    const selectedIndex = this.listbox?.selectedIndex ?? -1
    const listboxLength = this.listbox?.length ?? 0
    return selectedIndex === listboxLength - 1
  }

  /**
   * When the selected index of the listbox is 0, the user is on the first item within the listbox
   */
  private userIsAtStartOfListBox() {
    const selectedIndex = this.listbox?.selectedIndex ?? -1
    return selectedIndex === 0
  }

  /**
   * Deselect all listbox items so that are events are handled by default again
   */
  private deselectListboxItems() {
    this.setIndex(-1)
  }

  /**
   * Bring the to the last item in the listbox
   */
  private navigateToEndOfListBox() {
    const listboxLength = this.listbox?.length ?? 0
    this.setIndex(listboxLength - 1)
  }

  private readonly handlePasteEvent = (e: ClipboardEvent) => {
    trackGlobalSearch({
      searchString: e.clipboardData?.getData('text') ?? '',
      eventName: 'GoTo > Global Search Input Field Paste',
      eventType: 'paste',
      properties: {},
    })
  }

  private closePopover() {
    if (this.chameleonPopover?.isOpen) {
      this.chameleonPopover?.close()
    }
  }

  private readonly triggerSearch = () => {
    this.loading = true
    this.closeOrOpenPopover()
    this.updateSearchPopoverWidth()
    if (this.searchString.trim()) {
      trackGlobalSearch({
        searchString: this.searchString,
        eventName: 'GoTo > Global Search Input Field Search Preview',
        eventType: 'keyup',
        properties: {},
      })
      const query = { query: this.searchString }
      getGlobalSearchManager()
        .searchFor(query, DEFAULT_GLOBAL_SEARCH_PARAMS)
        .then(this.handleSearchResponse, this.handleSearchError)
    }
  }
  private readonly searchDelay = debounce(this.triggerSearch, 500)

  private readonly handleSearchError = () => {
    this.loading = false
    this.searchError = true
  }

  private closeOrOpenPopover() {
    if (this.shouldClosePopover()) {
      this.closePopover()
    } else if (this.shouldOpenPopover()) {
      this.chameleonPopover?.open()
    }
  }

  private shouldClosePopover() {
    if (this.chameleonPopover && this.chameleonPopover.isOpen) {
      //User has activated a search with a populated search string (should be brought to results page) OR
      //User has deleted entire search query and no search history exists
      return (
        (this.activeKey === 'Enter' && this.searchString.trim()) ||
        (this.activeKey === 'Backspace' && !this.searchString.trim() && !this.searchHistory.length)
      )
    }
    return false
  }

  private shouldOpenPopover() {
    //Popover should open if the search query is populated OR
    //the search query is empty BUT the search history is populated
    if (this.chameleonPopover && !this.chameleonPopover.isOpen && this.keyShouldTriggerSearch(this.activeKey)) {
      return this.searchString.trim() || (!this.searchString.trim() && this.searchHistory.length)
    }
    return false
  }

  private readonly handleSearchResponse = (response: GlobalSearchResponse | undefined) => {
    this.searchError = false
    this.loading = false
    this.searchResults = response?.items?.length
      ? getGlobalSearchManager().convertToGlobalSearchResultUI(response.items, 'preview')
      : []
  }

  private readonly handleListboxrendered = (e: CustomEvent<ListboxEvent>) => {
    this.listbox = e.detail.listbox
  }

  private readonly closePopoverAndExecuteSearch = () => {
    this.closePopover()
    trackGlobalSearch({
      searchString: this.searchString,
      eventName: 'GoTo > Global Search Input Field Enter',
      eventType: 'keydown',
      properties: {},
    })
    const query = { query: this.searchString }
    executeSearch(query)
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-global-search': GoToGlobalSearchField
  }
}
