import type { AvatarInfo } from './models'
import { AvatarSize } from './models'
import { environment } from '../../environments'
import { isUserOwnedEmail } from '../../common/user-helpers'
import { getFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { isCurrentUser } from '../presence'

const CACHE_BUST_INTERVAL = 1000 * 60 * 5 // 5 minutes

const getAvatarURLByUserKey =
  (userKey: string) =>
  (avatarSize: AvatarSize = AvatarSize.SMALL) =>
    `${environment().avatarBaseUrl}/${userKey}_${avatarSize}.jpg`
const getAvatarURLByEmail =
  (emailAddress: string) =>
  (avatarSize: AvatarSize = AvatarSize.SMALL) =>
    `${environment().avatarBaseUrl}/emails/${emailAddress}/${avatarSize}.jpg`

export async function getAvatarUrls(userKey: string = '', emailAddress: string = ''): Promise<AvatarInfo> {
  const cacheBuster = Date.now()
  let params: string = ''

  let formatURL: ReturnType<typeof getAvatarURLByEmail | typeof getAvatarURLByUserKey>
  if (userKey) {
    formatURL = getAvatarURLByUserKey(userKey)
    if (shouldBustCache(formatURL(), userKey)) {
      setTimestampInLocalStorage(formatURL())
      params = `?cacheBuster=${cacheBuster}`
    }
  } else if (emailAddress) {
    formatURL = getAvatarURLByEmail(emailAddress)
    if (shouldBustCache(formatURL(), undefined, emailAddress)) {
      setTimestampInLocalStorage(formatURL())
      params = `?cacheBuster=${cacheBuster}`
    }
  } else {
    throw new Error('Cannot get avatar without a user key or email address')
  }

  const { headers } = await fetch(`${formatURL()}` + params, {
    method: 'GET',
    cache: 'no-store',
  })

  return {
    isDefault: headers.get('x-amz-meta-type') === 'default',
    urlSmall: formatURL(AvatarSize.SMALL),
    urlMedium: formatURL(AvatarSize.MEDIUM),
    urlLarge: formatURL(AvatarSize.LARGE),
    urlXLarge: formatURL(AvatarSize.X_LARGE),
  }
}

const setTimestampInLocalStorage = (url: string) => {
  const key = getLocalStorageKey(url)
  setToLocalStorage(key, Date.now().toString())
}

/**
 * This relates to the CloudFront cache which has a cache period of 1 day.
 * We only want to force the latest avatar photo if it's the current user's avatar
 * and the last time we fetched said photo was more than 5 minutes ago.
 *
 * @see {@link https://confluence.ops.expertcity.com/display/SOA/SCIM+Identity+Service#SCIMIdentityService-Avatars}
 */
const shouldBustCache = (url: string, userKey?: string, email?: string) =>
  isCurrentUserAvatar(userKey, email) && isAvatarPhotoExpired(url)

const isCurrentUserAvatar = (userKey: string | undefined, email: string | undefined) => {
  if (userKey) {
    return isCurrentUser(userKey)
  } else if (email) {
    return isUserOwnedEmail(email)
  }
}

const isAvatarPhotoExpired = (url: string) => {
  const key = getLocalStorageKey(url)
  const timestamp = getFromLocalStorage(key)
  return !timestamp || Date.now() - parseInt(timestamp, 10) > CACHE_BUST_INTERVAL
}

const getLocalStorageKey = (url: string) => `avatar-timestamp-${url}`
