import { Token, getTokenFromStorage, removeTokenFromStorage, setTokenInStorage } from '@getgo/auth-client'

import { getShellLogger } from '../../common/logger'
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { LocalStorageKeys } from '../../environments'

// This has to be a function as calling `getShellLogger()` on runtime throws
const getTokenStorageOptions = () => ({
  key: LocalStorageKeys.authLocalStorageKey,
  logger: getShellLogger(),
})

/** Gets token from localStorage. */
export const getToken = (): Token | undefined => {
  const token = getTokenFromStorage(getTokenStorageOptions())
  if (!token?.isValid()) {
    return undefined
  }
  return token
}

export const haveToken = () => getToken() !== undefined
export const haveRefreshToken = () => getRefreshToken() !== null

export const getRefreshToken = (): string | null => {
  let refreshToken = getFromLocalStorage(LocalStorageKeys.refreshTokenLocalStorageKey)

  if (!refreshToken || isRefreshTokenExpired(refreshToken)) {
    removeFromLocalStorage(LocalStorageKeys.refreshTokenLocalStorageKey)
    refreshToken = null
  }

  return refreshToken
}

/** Stores token in localStorage. */
export const setToken = (token: Token | null | undefined): void => {
  if (!token) {
    removeToken()
  } else {
    setTokenInStorage(new Token(token), getTokenStorageOptions())
  }
}

export const setRefreshToken = (refreshToken?: string) => {
  if (refreshToken) {
    setToLocalStorage(LocalStorageKeys.refreshTokenLocalStorageKey, refreshToken)
  } else {
    // If there is no refreshToken then the Token should also be null
    removeToken()
  }
}

/** Removes token from localStorage. */
export const removeToken = (): void => removeTokenFromStorage(getTokenStorageOptions())

export const removeRefreshToken = () => {
  try {
    removeFromLocalStorage(LocalStorageKeys.refreshTokenLocalStorageKey)
  } catch (error) {
    getShellLogger().error('cannot remove token', error)
  }
}

export const isRefreshTokenExpired = (tokenValue: string) => {
  try {
    const token = JSON.parse(atob(tokenValue.split('.')[1]))
    const today = Date.now() / 1000
    return today > (token.exp ?? 0)
  } catch (error) {
    getShellLogger().error('cannot parse token', error)
    return false
  }
}
