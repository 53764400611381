import { getJiveApiBaseUrl } from '../core/environment'
import { type RetryableRequestInit, fetchRetry } from './fetch-utils'

/**
 * Checks if an endpoint is alive by making a request to it.
 * @param url - The URL of the endpoint.
 * @param init - Optional additional request options.
 * @returns A promise that resolves to `true` if the endpoint is alive, or `false` otherwise.
 */
export const isEndpointReachable = (url: RequestInfo, init?: RetryableRequestInit) =>
  fetchRetry(url, { timeoutInMS: 5000, ...init })
    .then(() => true)
    .catch(() => false)

/**
 * Checks if the backend is reachable.
 * @returns A promise that resolves to a boolean indicating if the backend is reachable.
 */
export const isBackendReachable = (): Promise<boolean> =>
  isEndpointReachable(`${getJiveApiBaseUrl()}/feature-flags/v1/health`)
