import { getShellApiInstance } from '../../common/shell-api-helpers'
import { type ShellApi, type ExternalUserKey } from '../../core/models'
import {
  setInterval,
  clearInterval,
  setToLocalStorage,
  navigateToExternal,
  getWindow,
  getFromLocalStorage,
  removeFromLocalStorage,
} from '../../common/dom-helpers'
import { LocalStorageKeys } from '../../environments'
import { type Token } from '@getgo/auth-client'
import { getToken, setToken } from '../auth'
import { cleanupTokens } from '../auth/token-helpers'

export const isFromExternalInterface = (...args: readonly any[]): boolean =>
  args.reduce((result, arg) => {
    if (typeof arg === 'object' && 'callerId' in arg) {
      result = true
    }
    return result
  }, false)

export const getUserKey = (): Promise<ExternalUserKey> =>
  new Promise(resolve => {
    let intervalId: number | undefined = undefined
    const checkResolve = () => {
      const shellApi = getShellApiInstance()

      if (shellApi?.user.key) {
        if (intervalId) {
          clearInterval(intervalId)
        }
        resolve(shellApi.user.key)
      }
    }

    intervalId = setInterval(checkResolve, 100)
    checkResolve()
  })

export const waitForShellApi = (): Promise<ShellApi> =>
  new Promise(resolve => {
    let intervalId: number | undefined = undefined
    const checkResolve = () => {
      const shellApi = getShellApiInstance()

      if (shellApi) {
        if (intervalId) {
          clearInterval(intervalId)
        }
        resolve(shellApi)
      }
    }

    intervalId = setInterval(checkResolve, 100)
    checkResolve()
  })

export const getUserPreferences = () =>
  getShellApiInstance()
    .userPreferences?.getPreferences()
    .then(preferences => preferences)

// Temporary until the user preferences service has a functioning database
export const setUserPreferencesInLocalStorage = (userPreferences: any) => {
  setToLocalStorage(LocalStorageKeys.userPreferences, JSON.stringify(userPreferences ?? {}))
}

export const updateAuthToken = (token: Token | undefined) => {
  const actualToken = getToken()

  if (token) {
    if (actualToken?.access_token !== token.access_token) {
      setToken(token)
      navigateToExternal(getWindow().origin)
    }
  } else {
    cleanupTokens()
  }
}

// Ms teams without plugin Utils
/**
 * shouldUsePlugin return a boolean that said if the user uses the app with or without the plugin when running in ms teams.
 * Default value: true
 **/
export const shouldUsePlugin = () => getFromLocalStorage(LocalStorageKeys.teamsUsePlugin) !== 'false'

export const setUsePlugin = (usePlugin: boolean) =>
  setToLocalStorage(LocalStorageKeys.teamsUsePlugin, usePlugin.toString())

export const cleanUsePlugin = () => removeFromLocalStorage(LocalStorageKeys.teamsUsePlugin)
