import { LitElement, html, property } from 'lit-element'
import popoverMenuStyles from './popover-menu.styles.scss'
import { repeat } from 'lit-html/directives/repeat'
import type { PopoverMenu } from './models'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { nothing } from 'lit-html'
import { t } from '../../directives/translate'
import { navigateToUrl } from '../../common/helpers'
import { openNewTabFor } from '../../common/dom-helpers'
import { ifDefined } from 'lit-html/directives/if-defined'
import { getShellAnalytics } from '../../common/shell-api-helpers'

export class GoToPopoverMenu extends LitElement {
  static readonly tagName = 'goto-popover-menu'

  @property({ type: Array })
  menuItems: readonly PopoverMenu[] = []

  static get styles() {
    return popoverMenuStyles
  }

  private renderMenuItem(popoverMenu: PopoverMenu) {
    const handleClick = (popoverMenu: PopoverMenu) => {
      getShellAnalytics().track(`GoTo > ${popoverMenu.origin}`, { action: 'click', event: popoverMenu.text })
      if (popoverMenu.isExternalLink) {
        openNewTabFor(popoverMenu.href)
      } else {
        navigateToUrl(popoverMenu.href)
      }
    }
    return html`
      <chameleon-menu-item
        @menuitemclick=${() => handleClick(popoverMenu)}
        aria-label=${popoverMenu.text}
        data-test=${ifDefined(popoverMenu.dataTest)}
      >
        ${popoverMenu.icon
        ? html`<chameleon-svg slot="leading-icon">${unsafeSVG(popoverMenu.icon)}</chameleon-svg>`
        : nothing}
        ${popoverMenu.text}
      </chameleon-menu-item>
    `
  }

  render() {
    return html`
      <nav>
        <chameleon-menu label=${t('GoTo menu')}>
          ${repeat(this.menuItems, menuItem => menuItem.href, this.renderMenuItem)}
        </chameleon-menu>
      </nav>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-popover-menu': GoToPopoverMenu
  }
}
