import { navigateToUrl } from '../../common/helpers'
import { getShellLogger } from '../../common/logger'
import { CALENDAR_SETTINGS_ROUTE } from '../../common/routes'
import { CalendarCache } from './cache'
import { type CalendarConnector } from './cis-connector'
import { CISDateRange } from './date-utils'
import type {
  CalendarEvent,
  CalendarEventsResponse,
  ConnectedServiceType,
  GetEventsAPIOptions,
  GetEventsOptions,
} from './models'
import type { NotificationChannelEvents } from '../notification-channel'
import { NotificationChannelNamespace } from '../notification-channel'
import { type EventBus } from '../namespaces/event-bus'
import { addIdleHandler } from '../../common/idle-handler'
import type { CalendarAPI } from './public-api'
import type { CalendarCustomEvent } from './events'
import { calendarUpdate } from './events'
import { onWindowUnload } from '../../common/helpers/window'
import type { NotificationChannelEvent } from '../notification-channel/models'

/**
 *  Note: CIS means Calendar Integration Service.
 *  API docs: https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html
 */
export class CalendarService implements CalendarAPI {
  constructor(
    private readonly calendarServiceConnector: CalendarConnector,
    private readonly externalUserKey: string,
    private readonly eventBus: EventBus,
  ) {
    this.subscribeToChannelEvents()
    this.clearCacheWhenIdle()
    this.subscribeToCalendarEvents()
  }

  private readonly cache: CalendarCache = new CalendarCache()

  isConnected() {
    return this.calendarServiceConnector.isConnected(this.externalUserKey)
  }

  async getEvents(...args: Parameters<CalendarAPI['getEvents']>): Promise<CalendarEventsResponse> {
    const [from, to, options] = args

    const dateRange = new CISDateRange(from, to)

    if (!this.cache.isDateRangeCached(dateRange)) {
      const fetchedEvents = await this.fetchAllEvents(dateRange, options)
      this.cache.setEvents(dateRange, fetchedEvents)
    }

    return { events: this.cache.getEvents(dateRange, options?.excludePrivate) }
  }

  navigateToCalendarSettings() {
    navigateToUrl(CALENDAR_SETTINGS_ROUTE)
  }

  getConnectedServiceType(): Promise<ConnectedServiceType | null> {
    return this.calendarServiceConnector.getConnectedServiceType(this.externalUserKey)
  }

  /**
   * Fetches all events within a given date range
   * @param dateRange
   */
  private async fetchAllEvents(dateRange: CISDateRange, options?: GetEventsOptions): Promise<CalendarEvent[]> {
    const { events, nextPageToken } = await this.fetchEventsFirstPage(dateRange, options)

    let paginatedEvents: CalendarEvent[] = []
    if (nextPageToken) {
      paginatedEvents = await this.fetchAllPaginatedEvents(nextPageToken)
    }

    return [...events, ...paginatedEvents]
  }

  /**
   * Fetches a single page of missing events within a date range.
   */
  private async fetchEventsFirstPage(
    dateRange: CISDateRange,
    options?: GetEventsOptions,
  ): Promise<CalendarEventsResponse> {
    const newDateRange = this.cache.getMissingDatesRange(dateRange)

    const calendarEventResponse = await this.getEventsFromAPI(newDateRange, options)

    return calendarEventResponse
  }

  /**
   * Fetched all of the paginated events from the backend or log an error if we need more than 25 requests
   * @param nextPageEventToken : token returned from a previous request to the calendar integration service api
   */
  private async fetchAllPaginatedEvents(nextPageEventToken: string): Promise<CalendarEvent[]> {
    //Safety net if the backend returns a lot of nextpagetoken
    let maxNextPageTokenRequest = 25
    let token: string | undefined = nextPageEventToken
    const events: CalendarEvent[] = []

    while (token && maxNextPageTokenRequest > 0) {
      getShellLogger().debug('fetchAllNextPageEvents max next page token request: ', maxNextPageTokenRequest)
      const nextPageResponse: CalendarEventsResponse = await this.getNextPageResponse(token)
      events.push(...nextPageResponse.events)
      token = nextPageResponse.nextPageToken
      maxNextPageTokenRequest--
    }

    if (maxNextPageTokenRequest === 0) {
      getShellLogger().error(
        'Stopped querying nextPageEvents even if the backend returned a next page token in the request. maxNextPageTokenRequest reached',
      )
      throw 'Too many events to request to the backend. Try smaller requests'
    }

    return events
  }

  /**
   * Fetch the missing events on the backend with the provided next page token
   * @param nextPageToken : Provided by the backend on a previous request
   */
  private async getNextPageResponse(nextPageToken: string): Promise<CalendarEventsResponse> {
    try {
      const calendarEventResponse = await this.calendarServiceConnector.getNextPage(nextPageToken)
      return calendarEventResponse
    } catch (e) {
      getShellLogger().error('Could not get next page calendar events from API: ', e)
      throw e
    }
  }

  /**
   * The actual API request.
   * @param formattedFrom Formatted date. Format yyyy-MM-dd (i.e. '2019-08-15')
   * @param formattedTo Formatted date. Format yyyy-MM-dd (i.e. '2019-08-16')
   * @param options Optional parameter allowed by the CIS
   * @returns the CIS response. See interface calendarEventResponse
   */
  private async getEventsFromAPI(dateRange: CISDateRange, options?: GetEventsAPIOptions) {
    try {
      const calendarEventResponse = await this.calendarServiceConnector.getEvents(dateRange, options)
      return calendarEventResponse
    } catch (e) {
      getShellLogger().error('Could not get calendar events from API:', e)
      throw e
    }
  }

  private subscribeToChannelEvents(): void {
    const channelEvents = this.eventBus.subscribeTo<
      typeof NotificationChannelNamespace,
      typeof NotificationChannelEvents
    >(NotificationChannelNamespace)

    channelEvents.message.addListener(this.calendarEventUpdatedHandler)
    channelEvents.disconnected.addListener(this.notificationChannelDisconnectedHandler)

    onWindowUnload(() => {
      channelEvents.message.removeListener(this.calendarEventUpdatedHandler)
      channelEvents.disconnected.removeListener(this.notificationChannelDisconnectedHandler)
    })
  }

  private subscribeToCalendarEvents() {
    document.addEventListener('calendar', (event: CustomEvent<CalendarCustomEvent>) => {
      if (event.detail.eventName === 'disconnect') {
        this.cache.clear()
      }
    })
  }

  private readonly calendarEventUpdatedHandler = (message: NotificationChannelEvent<unknown>) => {
    // There are two possible sources for the calendar-integration-service type change event. We need to handle both.
    const isCalendarIntegrationServiceTypeChange =
      (message.data.source === 'calendar-integration-service' ||
        message.data.source === 'goto-calendar-integration-service') &&
      message.data.type === 'change'
    // When using notificationUrl as a param for getEvents with the CIS api, the data returned from the NC is null. Which is why we need to check for this.
    // This legacy check can be cleaned up once the shell-calendar-getevents-use-id will be turned on in all environments as this will return the correctly populated data.
    const isSourceAndTypeNull = message.data.source === null && message.data.type === null
    if (isCalendarIntegrationServiceTypeChange || isSourceAndTypeNull) {
      this.cache.clear()
      calendarUpdate()
    }
  }

  private readonly notificationChannelDisconnectedHandler = () => {
    this.cache.clear()
    // This event emit should be temporary, see: https://jira.ops.expertcity.com/browse/SCAPI-422
    calendarUpdate()
  }

  private clearCacheWhenIdle() {
    const fourHoursInMinutes = 4 * 60

    addIdleHandler(fourHoursInMinutes, () => this.cache.clear())
  }
}
