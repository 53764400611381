import { ContactService } from './contact-service'
import type { ContactAPI } from './public-api'

let contactAPI: ReturnType<typeof getContactAPI>
let contactService: ContactService

export const getContactAPI = (): ContactAPI => {
  if (!contactAPI) {
    contactAPI = createContactAPI()
  }
  return contactAPI
}

const createContactAPI = (): ContactAPI => {
  const contactService = getContactService()
  const contactAPI: ContactAPI = {
    search: contactService.search,
    lookup: contactService.lookup,
    lookupByAccount: contactService.lookupByAccount,
    lookupByPhoneNumbers: contactService.lookupByPhoneNumbers,
    createContact: contactService.createContact,
    deleteContact: contactService.deleteContact,
    updateContact: contactService.updateContact,
    getContacts: contactService.getContacts,
    getContact: contactService.getContact,
    getPersonalAddressBookId: contactService.getPersonalAddressBookId,
    updateContactFavoriteStatus: contactService.updateContactFavoriteStatus,
    getMeetingProfile: contactService.getMeetingProfile,
  }
  return Object.freeze(contactAPI)
}

export const getContactService = () => {
  if (!contactService) {
    contactService = new ContactService()
  }

  return contactService
}
