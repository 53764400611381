import { html, property } from 'lit-element'
import { ShellElement } from '../../../common/shell-element'
import searchLoadingState from './search-loading-state.styles.scss'
export interface searchLoadingProps {
  readonly loadingRows: number
}

export class SearchLoadingState extends ShellElement implements searchLoadingProps {
  static readonly tagName = 'goto-search-loading-state'

  @property({ type: Number }) loadingRows: number = 2

  static get styles() {
    return searchLoadingState
  }

  private getLoadingItems() {
    const loadingItems = []
    for (let i = 0; i < this.loadingRows; i++) {
      const iterationClass = i % 2 == 0 ? 'even' : 'odd'
      loadingItems.push(
        html`<goto-loading-state class="${iterationClass}" iteration=${i} contact></goto-loading-state>`,
      )
    }
    return loadingItems
  }

  render() {
    return html`
      <chameleon-listbox>
        <chameleon-listbox-item>
          <div class="results-loading">${this.getLoadingItems()}</div>
        </chameleon-listbox-item>
      </chameleon-listbox>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-search-loading-state': SearchLoadingState
  }
}
