import { formatDateTime, formatDateTimeRange } from '../../services/i18n'
import { type WorkPeriod } from './schedule-manager/models'

import { timeFormatOptions } from './schedule-settings-models'

const isISOTimeStringValid = (timeString: string) => {
  const regExp = /^([0-9]|[01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
  return regExp.test(timeString)
}

const parseTime = (timeString: string) => {
  if (!isISOTimeStringValid(timeString)) {
    return null
  }

  return timeString.split(':').map(Number)
}

export const newDateTime = (ISOTime: string) => {
  const parsedTime = parseTime(ISOTime)
  if (parsedTime) {
    const [hours, minutes, seconds] = parsedTime
    return new Date().setHours(hours, minutes, seconds, 0)
  }
}

export const formatISOTime = (ISOTime: string): string => {
  const dateTime = newDateTime(ISOTime) ?? new Date().setHours(0, 0, 0, 0)
  return formatDateTime(dateTime, timeFormatOptions)
}

export const formatISOTimeRange = (ISOStartTime: string, ISOEndTime: string): string => {
  const parsedStartTime = parseTime(ISOStartTime)
  const parsedEndTime = parseTime(ISOEndTime)
  if (parsedStartTime && parsedEndTime) {
    const [startHours, startMinutes, startSeconds] = parsedStartTime
    const [endHours, endMinutes, endSeconds] = parsedEndTime
    return formatDateTimeRange(
      new Date().setHours(startHours, startMinutes, startSeconds),
      new Date().setHours(endHours, endMinutes, endSeconds),
      timeFormatOptions,
    )
  }

  return formatDateTime(new Date().setHours(0, 0, 0, 0), timeFormatOptions)
}

export const initialTimeOfTheDay = '00:00:00'
export const lastTimeOfTheDay = '23:59:59'

export const getListOfTimeForOneDayWithInterval = (intervalInMinutes: number) => {
  const result = []

  const currentTime = new Date()
  currentTime.setHours(0, 0, 0, 0)

  const originalDay = currentTime.getDay()

  while (currentTime.getDay() === originalDay) {
    result.push(currentTime.toTimeString().split(' ')[0])
    currentTime.setMinutes(currentTime.getMinutes() + intervalInMinutes)
  }
  return result
}

//TODO: update with assertSchema in this story: https://jira.ops.expertcity.com/browse/SCORE-3512
export const isWorkPeriod = (partialWorkPeriod: Partial<WorkPeriod>): partialWorkPeriod is WorkPeriod =>
  !!(partialWorkPeriod.dayOfWeek && partialWorkPeriod.startTime && partialWorkPeriod.endTime)

export const dateTimeIntervalsOverlap = (startA: number, endA: number, startB: number, endB: number) =>
  startA < endB && endA > startB
