import type { ShellHook } from './models'
import { getSidebarAPI } from '../../services/sidebar'
import { canShowSideBar } from '../bootstrap-helpers'
import { assertIsSingleSpaCustomEvent, SINGLE_SPA_BEFORE_MOUNT_ROUTING_EVENT } from '../../common/single-spa-events'
import type { RegisterApplicationConfig, SingleSpaCustomEventDetail } from 'single-spa'
import { findRegisteredApplicationsByNames } from './registered-applications-utils'
import type { CustomProps } from '../../common'
import { emitLinksUpdated } from '../../services/navigation/event-bus'
import { type shellEvents, ShellNamespace } from '../../services/shell-namespace'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { getViewsApplication } from '../../experiences'
import { getEventBus } from '../../services/namespaces/event-bus'
import { onWindowUnload } from '../../common/helpers/window'

const SETTINGS_APPLICATION_NAME = 'goto/settings'

const setSidebarVisibility = (show: boolean) => {
  const sidebarAPI = getSidebarAPI()
  if (show) {
    sidebarAPI.show()
  } else {
    sidebarAPI.hide()
  }
}

export const applicationHasHideSidebarConfig = (
  registeredApplications: readonly RegisterApplicationConfig<CustomProps>[],
): boolean =>
  registeredApplications.some(registeredApplication => {
    const { customProps } = registeredApplication
    return !!(customProps as CustomProps)?.shellConfig?.hideSidebar
  })

const applicationisInViews = (appName: string) => getViewsApplication().some(viewsApp => viewsApp.name === appName)

const mountedAppShouldBeCheckedForVisibility = (appName: string) =>
  appName === SETTINGS_APPLICATION_NAME || applicationisInViews(appName)

const updateSidebarVisibility = (mountedApps: string[] = []) => {
  const registeredApplications = findRegisteredApplicationsByNames(mountedApps)
  if (
    !mountedApps.some(appName => mountedAppShouldBeCheckedForVisibility(appName)) &&
    registeredApplications?.length === 0
  ) {
    return
  }
  const showSidebar = canShowSideBar() && !applicationHasHideSidebarConfig(registeredApplications)
  setSidebarVisibility(showSidebar)
  emitLinksUpdated()
}

const handleBeforeMountRoutingEvent = (event: CustomEvent<SingleSpaCustomEventDetail>) => {
  assertIsSingleSpaCustomEvent(event)
  const mountedApps = (event.detail?.appsByNewStatus?.MOUNTED as string[]) ?? []
  updateSidebarVisibility(mountedApps)
}

export const initializeSidebarVisibility: ShellHook = async () => {
  globalThis.addEventListener(SINGLE_SPA_BEFORE_MOUNT_ROUTING_EVENT, handleBeforeMountRoutingEvent)

  if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_LOAD_VIEWS_ON_APP_START, false)) {
    const { applicationReady } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    const onApplicationReady = () => {
      updateSidebarVisibility()
    }
    applicationReady.on(onApplicationReady)
    onWindowUnload(() => {
      applicationReady.removeListener(onApplicationReady)
    })
    setSidebarVisibility(canShowSideBar())
    /**
    * When the locale changes, we reload the application
    *  Before we reload, we need to clear the store with the current nav item.
    localeChanged.on(() => {
      // JIRA : https://jira.ops.expertcity.com/browse/SCORE-3199
    })
    **/
  }
}
