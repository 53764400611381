import { html, property } from 'lit-element'
import { ShellElement } from '../../../common/shell-element'
import { t } from '../../../directives/translate'
import { SVG_ADD_FILLED } from '@getgo/chameleon-icons'
import scheduleCustomHoursStyles from './schedule-custom-hours.styles.scss'
import { getDialogService } from '../../../services/dialog/public-facade'
import { getDocument } from '../../../common/dom-helpers'
import {
  CLOSE_CUSTOM_HOURS_MODAL,
  GoToScheduleCustomHoursModal,
  NEW_ONE_TIME_PERIOD,
  type NewOneTimePeriodEvent,
} from '../schedule-custom-hours-modal/schedule-custom-hours-modal'

export class GoToScheduleCustomHours extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours'

  @property({ type: Boolean }) isScheduleEnabled = false
  private customHoursModal: GoToScheduleCustomHoursModal | undefined

  static get styles() {
    return scheduleCustomHoursStyles
  }

  private createCustomHoursModal() {
    this.customHoursModal = getDocument().createElement(GoToScheduleCustomHoursModal.tagName)
    this.customHoursModal.addEventListener(NEW_ONE_TIME_PERIOD, this.handleNewOneTimePeriod)
    this.customHoursModal.addEventListener(CLOSE_CUSTOM_HOURS_MODAL, this.handleCloseCustomHoursModal)
    return this.customHoursModal
  }

  private readonly handleNewOneTimePeriod = (event: CustomEvent<NewOneTimePeriodEvent>) => {
    // Temporary. The data received will be handled in a separate task
    console.log('New one time period', event.detail.oneTimePeriod)
    this.closeCustomHoursModal()
  }

  private readonly handleCloseCustomHoursModal = () => {
    this.closeCustomHoursModal()
  }

  private closeCustomHoursModal() {
    // Removing the listeners when closing an instance of the modal. This is to avoid memory leaks as the modal can be opened and closed multiple times
    this.customHoursModal?.removeEventListener(NEW_ONE_TIME_PERIOD, this.handleNewOneTimePeriod)
    this.customHoursModal?.removeEventListener(CLOSE_CUSTOM_HOURS_MODAL, this.handleCloseCustomHoursModal)
    this.customHoursModal = undefined
    getDialogService().close()
  }

  render() {
    return html`
      <div class="custom-hours-header">
        <chameleon-typography class="small-header" variant="body-medium-strong"
          >${t('Custom hours')}</chameleon-typography
        >
        <div class="custom-hours-set">
          <chameleon-typography variant="body-small" color="type-color-secondary"
            >${t(
              'Add holidays, quiet hours, and other exceptions to your schedule availability.',
            )}</chameleon-typography
          ><chameleon-button
            class="add-custom-hours"
            variant="secondary"
            size="medium"
            @click=${() => {
              getDialogService().open(this.createCustomHoursModal())
            }}
            ?disabled=${!this.isScheduleEnabled}
            ><chameleon-svg slot="start">${SVG_ADD_FILLED}</chameleon-svg>${t('Add custom hours')}</chameleon-button
          >
        </div>
      </div>
      <div class="custom-hours-schedule">${'add later'}</div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-custom-hours': GoToScheduleCustomHours
  }
}
