import { html, LitElement, property } from 'lit-element'
import { repeat } from 'lit-html/directives/repeat'
import type { OptionComponent } from '@getgo/chameleon-web'

import { getI18nLocaleName } from '../../common/locales'

import type { GoToLanguageSelectElementProps } from './types'
import { ifDefined } from 'lit-html/directives/if-defined'
import { getShellAnalytics } from '../../common/shell-api-helpers'
import type { I18nLocale } from '../../common/translate-helpers/types'
import { asI18nLocale } from '../../common/translate-helpers/locales'

const asI18nLocales = (locales: readonly unknown[]) => locales.map(asI18nLocale).filter(Boolean)

const renderLanguageRow = (locale: I18nLocale, isSelected: boolean) => html`
  <chameleon-option value=${locale} selected=${isSelected}>${getI18nLocaleName(locale)}</chameleon-option>
`

export class LanguageSelectElement extends LitElement implements GoToLanguageSelectElementProps {
  static readonly tagName = 'goto-language-select'

  @property({ type: Array, attribute: 'locales', reflect: true }) locales: readonly string[] = []
  @property({ type: String, attribute: 'selected', reflect: true }) selectedLocale: string | undefined
  render() {
    return html`
      <chameleon-select
        size="large"
        value=${ifDefined(this.selectedLocale)}
        selectedValue=${ifDefined(this.selectedLocale)}
        @change=${this.handleLanguageChange}
      >
        ${repeat(
          asI18nLocales(this.locales ?? []),
          locale => locale,
          // TODO handle undefined locale https://jira.ops.expertcity.com/browse/SCAPI-467

          locale => renderLanguageRow(locale!, locale === this.selectedLocale),
        )}
      </chameleon-select>
    `
  }

  private handleLanguageChange(event: CustomEvent) {
    event.stopPropagation()
    event.cancelBubble = true

    const target = event.target as OptionComponent

    getShellAnalytics().track('GoTo > Settings - Language', {
      action: 'Selected',
      event: 'Changed',
      language: target.value,
    })

    // TODO handle undefined locale https://jira.ops.expertcity.com/browse/SCAPI-467

    this.updateLocale(asI18nLocale(target.value!)!)
  }

  private updateLocale(locale: I18nLocale) {
    this.selectedLocale = locale
    this.dispatchEvent(new CustomEvent<I18nLocale>('change', { bubbles: true, composed: true, detail: locale }))
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-language-select': LanguageSelectElement
  }
}
