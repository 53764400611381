import type { SingleSpaCustomEventDetail } from 'single-spa'
import { isMainWindow } from '../services/container'
import { getShellLogger } from './logger'
import { assertIsSingleSpaCustomEvent, SINGLE_SPA_APP_CHANGE } from './single-spa-events'

/**
 * @link https://single-spa.js.org/docs/api/#custom-events
 */
/* istanbul ignore next */
export const getMountedExperiencesFromStatusEvent = (
  event: CustomEvent<SingleSpaCustomEventDetail>,
): readonly string[] => event?.detail?.appsByNewStatus?.MOUNTED ?? []

/**
 * @link https://single-spa.js.org/docs/api/#custom-events
 */
export const getUnmountedExperiencesFromStatusEvent = (
  event: CustomEvent<SingleSpaCustomEventDetail>,
): readonly string[] => event?.detail?.appsByNewStatus?.NOT_MOUNTED ?? []

/**
 * Listen for the single spa app change event and log apps that are being mounted/unmounted
 */
export const startSingleSpaAppChangeLogger = () => {
  let currentApps: string[] = []

  const updateCurrentApp = () => {
    if (isMainWindow()) {
      const gotoAppExperiences = [
        'goto/banner-notification',
        'goto/snackbar-notification',
        'goto/modal-notification',
        'goto/topbar',
        'goto/navigation',
      ]
      const currentExperiences = currentApps.filter(app => !gotoAppExperiences.includes(app)).join('|')
      getShellLogger().setContext('shell_experiences', currentExperiences)
    }
  }

  window.addEventListener(SINGLE_SPA_APP_CHANGE, event => {
    assertIsSingleSpaCustomEvent(event)

    const unmountedApps = getUnmountedExperiencesFromStatusEvent(event)
    const mountedApps = getMountedExperiencesFromStatusEvent(event)

    if (unmountedApps.length) {
      unmountedApps.forEach(app => (currentApps = currentApps.filter(curApp => curApp !== app)))
      updateCurrentApp()
    }

    if (mountedApps.length) {
      mountedApps.forEach(app => {
        if (!currentApps.some(curApp => curApp === app)) {
          currentApps.push(app)
        }
      })
      updateCurrentApp()
    }
  })
}
