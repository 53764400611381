import { getFromLocalStorage, getLocationSearchParamsByName, setToLocalStorage } from '../../../common/dom-helpers'
import { LocalStorageKeys } from '../../../environments'
import { decodeBase64URIParameters } from '../../auth/utils'
import { encodeOverride } from './encode'
import type { GoToExtensionOverrideConfig } from './models'
import { isGoToExperienceOverrideConfig, isGoToExtensionOverrideConfig, validatedConfigs } from './validation'

export const OVERRIDE_EXPERIENCE_PARAMETER = 'oe'
export const OVERRIDE_EXTENSION_PARAMETER = 'ox'

/**
 * Get the base64 override from URL querystring
 */
export function getUrlParameterOverride<T>(
  overrideParameter: string,
  validator: (object: unknown) => object is T,
): readonly T[] | null {
  const base64EncodedOverride = getLocationSearchParamsByName(overrideParameter)
  if (!base64EncodedOverride) {
    return null
  }
  let decodedOverride: any
  try {
    decodedOverride = decodeBase64URIParameters(base64EncodedOverride)
  } catch {
    return null
  }
  return validatedConfigs(decodedOverride, validator)
}

/**
 * Get the base64 override from localstorage
 */
export function getLocalStorageOverride<T>(
  localStorageKey: LocalStorageKeys,
  validator: (object: unknown) => object is T,
): readonly T[] | null {
  const str = getFromLocalStorage(localStorageKey)
  if (!str) {
    return null
  }
  let parsedOverride: any
  try {
    parsedOverride = JSON.parse(str)
  } catch {
    return null
  }
  return validatedConfigs(parsedOverride, validator)
}

/**
 * Set the experiences override into the localStorage
 */
export function setLocalStorageExperiencesOverride(experiencesOverride: unknown) {
  const base64EncodedExperiences = encodeOverride(experiencesOverride, isGoToExperienceOverrideConfig)
  if (base64EncodedExperiences) {
    setToLocalStorage(LocalStorageKeys.gotoExperiencesOverride, base64EncodedExperiences)
  }
}

/**
 * Set the extensions override into the localStorage
 */
export function setLocalStorageExtensionsOverride(extensionsOverride: readonly GoToExtensionOverrideConfig[]) {
  const result = extensionsOverride.filter(override => isGoToExtensionOverrideConfig(override))
  const jsonStr = JSON.stringify(result)
  setToLocalStorage(LocalStorageKeys.gotoExtensionsOverride, jsonStr)
}
