enum ResponseType {
  Basic = 'basic',
  Cors = 'cors',
  Default = 'default',
  Error = 'error',
  Opaque = 'opaque',
}

const dispatchCacheRehydrateMessage = async (message: any): Promise<void> => {
  if ('serviceWorker' in navigator && (navigator.serviceWorker as ServiceWorkerContainer).controller) {
    try {
      ;(navigator.serviceWorker as ServiceWorkerContainer).controller?.postMessage(message)
    } catch (err) {
      console.warn(err)
    }
  }
}

export const CACHES_TO_REHYDRATE = {
  LANGUAGE: 'language',
  SCRIPTS: 'scripts',
  IMAGES: 'images',
}

export const DEFAULT_TRIGGER_REHYDRATION_MESSAGE = { type: 'REHYDRATE_CACHES_FOR_SW' }

export const rehydrateServiceWorkerCaches = () => {
  dispatchCacheRehydrateMessage(DEFAULT_TRIGGER_REHYDRATION_MESSAGE)
}

export const isOpaqueResponse = (response: Response) => response.type === ResponseType.Opaque && response.status === 0

export const responseCanBeCached = (response: Response) => response.ok || isOpaqueResponse(response)
