import type { Timer } from './helpers'

/**
 * Based on
 * https://developer-internal.goto.com/apis/notification-channel/v1#operation/getNotifications
 */

export interface NotificationChannelEventData<T> {
  /**
   * Actual content of the notification that varies depending on the
   * source and the type of the notification. For encrypted data, the
   * content will be in the form of a `EncryptedData` definition
   */
  readonly content: T

  /**
   * Identifies the source application issuing the notification. For
   * encrypted notifications, the source will always be `notification`
   * and the encrypted payload will contain the actual `source` of the
   * notification.
   */
  readonly source: string

  /**
   * The usage type of this notification. The usage could be for VOIP, ALERT or BACKGROUND notifications.
   * The VOIP usage must be used only to initiate a call or a meeting. This will make the device ring and the user
   * will be prompted to answer a call or a video call/conference.
   * The ALERT must be used when the notification needs to be display immediately to the user, such as an incoming messages.
   * The BACKGROUND notification is not displayed to the user, it is transfered towards the application.
   * Default: 'BACKGROUND'
   * Enum: 'VOIP' 'ALERT' 'BACKGROUND'
   */
  readonly usage?: 'VOIP' | 'ALERT' | 'BACKGROUND'

  /**
   * The time of the event. Used to indicate when the event occured.
   * ISO8601Timestamp
   */
  readonly timestamp: string

  /**
   * Type of the event. Used as a discriminator of the server application
   * to determine the content. For encrypted notifications, the `type`
   * will always be `encryptedData` and the encrypted payload will
   * contain the actual `type` of the notification.
   */
  readonly type: string

  /**
   * Flag to bypass the Do-Not-Disturb setting, where notification events will be sent out
   * regardless if the user has set his Do-Not-Disturb flag or not.
   * Default: false
   */
  readonly bypassDoNotDisturb: boolean

  /**
   * Time to live in seconds for the notification.
   */
  readonly ttl?: number

  /**
   * APNS or Firebase topic
   */
  readonly topic?: string

  /**
   * Push notification immediate dipslay information
   */
  readonly displayNotification?: DisplayNotification
}
export interface DisplayNotification {
  /**
   * Title to display
   */
  readonly title: string
  /**
   * Text to display in the body
   */
  readonly body: string
}

export interface NotificationChannelEvent<T = unknown> {
  readonly code?: number
  readonly data: NotificationChannelEventData<T>
  readonly event: string
  readonly eventId: number
  readonly correlationId?: number
  readonly error?: RTCError
}

export interface ChannelDataInfo {
  readonly channelType: 'Push' | 'WebSockets' | 'Webhook'
  readonly channelURL: string
}

export interface ChannelInfo {
  readonly channelId: string
  readonly channelLifetime: number
}

export interface ChannelRefreshInfo {
  readonly channelLifetime: number
}

export interface NotificationChannelInfo extends ChannelInfo {
  readonly applicationTag?: string
  readonly channelData?: ChannelDataInfo
  readonly callbackURL: string
  readonly resourceURL?: string
}

export type NotificationChannelRefreshInfo = ChannelInfo

export interface RTCError {
  readonly errorCode: string
  readonly message: string
  readonly constraintViolations?: readonly RTCconstraintViolation[]
}

export interface RTCconstraintViolation {
  readonly constraint: string
}

export interface ChannelResponse {
  readonly code: number
  readonly correlationId: number
  readonly data: unknown
  readonly error?: ChannelError
}

export interface ChannelError extends RTCError {
  readonly reference: string
}

export interface ChannelRequest {
  readonly command: string
  readonly requestTimer: Timer
  resolve(value?: unknown | PromiseLike<unknown> | undefined): void
  reject(reason?: unknown): void
}

export enum NotificationChannelMessageType {
  presence = 'presence',
}
