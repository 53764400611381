import type { ExtensionConfig } from '@goto/shell-common'

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/gtc-cc-qm-scorecard.config',
  url: new URL('/experiences/cc-qm-scorecard-config/goto-app-extension.js', location.origin),
  prerequisites: {
     ccFlags: 'cc-config.quality-management',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
  }
}

export default extensionConfig

