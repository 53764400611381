import {
  type NotificationChannelInfo,
  type NotificationChannelCloseEvent,
  type NotificationChannelErrorEvent,
  type NotificationChannelEventMap,
  type NotificationChannelMessageEvent,
  type NotificationChannel,
} from '@goto/shell-notification-channel'
import { getShellLogger } from '../../../common/logger'
import { getEventBus } from '../../namespaces/event-bus'
import { NotificationChannelEvents, NotificationChannelNamespace } from '../../notification-channel'
import { NotificationHandlerEventType } from '@goto/shell-notification-channel'

export class ShellNotificationDefaultDispatcher implements NotificationChannel {
  private readonly notificationNamespace = getEventBus().register(
    NotificationChannelNamespace,
    NotificationChannelEvents,
  )

  constructor(protected readonly notificationChannel: NotificationChannel) {
    this.addListeners()
  }

  get id(): string {
    return this.notificationChannel.id
  }

  get channelInfo(): NotificationChannelInfo | undefined {
    return this.notificationChannel.channelInfo
  }

  get isConnected(): boolean | undefined {
    return this.notificationChannel.isConnected
  }

  public start(): void {
    this.notificationChannel.start()
  }

  public addListener<K extends keyof NotificationChannelEventMap>(
    type: K,
    listener: (ev: NotificationChannelEventMap[K]) => void,
  ): void {
    this.notificationChannel.addListener(type, listener)
  }

  public removeListener<K extends keyof NotificationChannelEventMap>(
    type: K,
    listener: (ev: NotificationChannelEventMap[K]) => void,
  ): void {
    this.notificationChannel.removeListener(type, listener)
  }

  /**
   * @deprecated
   * Use notification.addListener(type, listener) instead
   */
  public subscribe(listener: (event: NotificationChannelMessageEvent) => void) {
    this.notificationChannel.addListener(NotificationHandlerEventType.MESSAGE, listener)
  }

  /**
   * @deprecated
   * Use notification.removeListener(type, listener) instead
   */
  public unsubscribe(listener: (event: NotificationChannelMessageEvent) => void) {
    this.notificationChannel.removeListener(NotificationHandlerEventType.MESSAGE, listener)
  }

  protected addListeners(): void {
    this.notificationChannel.addListener(NotificationHandlerEventType.OPEN, this.handleOpenEvent.bind(this))
    this.notificationChannel.addListener(NotificationHandlerEventType.CLOSE, this.handleCloseEvent.bind(this))
    this.notificationChannel.addListener(NotificationHandlerEventType.MESSAGE, this.handleMessageEvent.bind(this))
    this.notificationChannel.addListener(NotificationHandlerEventType.ERROR, this.handleErrorEvent.bind(this))
  }

  protected handleOpenEvent(): void {
    const channelInfo: NotificationChannelInfo = {
      callbackURL: this.channelInfo?.callbackURL ?? '',
      channelId: this.channelInfo?.channelId ?? '',
      channelLifetime: this.channelInfo?.channelLifetime ?? 0,
    }
    this.notificationNamespace.emit.connected(channelInfo)
  }

  protected handleCloseEvent(event: NotificationChannelCloseEvent): void {
    this.notificationNamespace.emit.disconnected(event as any) // TODO remove `as any` when the old NC will be remove [SCORE-2503]
    getShellLogger().error(event)
  }

  protected handleMessageEvent(event: NotificationChannelMessageEvent<string>): void {
    this.notificationNamespace.emit.message(event as any) // TODO remove `as any` when the old NC will be remove [SCORE-2503]
  }

  protected handleErrorEvent(event: NotificationChannelErrorEvent): void {
    getShellLogger().error('WebSocket:Error', event)
    this.notificationNamespace.emit.error(event as any) // TODO remove `as any` when the old NC will be remove [SCORE-2503]
  }
}
