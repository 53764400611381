import { mapEntitlementsFromRoles } from '../../services/identity/entitlement'
import { type LicenseInfoFromGraphQL } from '../../services/identity/models'
import type { ShellUserAccountLicense } from './model'

export class ShellUserAccountLicenseImpl implements ShellUserAccountLicense {
  key: string
  channel: string
  enabled: boolean
  description: string
  roles: readonly string[]
  entitlements: readonly string[]

  constructor(params: LicenseInfoFromGraphQL) {
    this.key = params.key
    this.channel = params.channel
    this.enabled = params.enabled
    this.description = params.description
    this.entitlements = convertRolesToUserAccountLicenseEntitlements(params.roles ?? [])
    this.roles = this.entitlements.concat(params.roles ?? [])
  }
}

const convertRolesToUserAccountLicenseEntitlements = (roles: readonly string[] = []): readonly string[] =>
  mapEntitlementsFromRoles(roles)
