import { getIntegrationMetaData } from '../../core/integrations-helpers'
import { getExternalInterface } from '../../services/external-interface'

export const getProduct = () => {
  if (getExternalInterface().isIntegration || getExternalInterface().isCompanion) {
    const metadata = getIntegrationMetaData()
    return `${metadata?.id}`
  }

  return GOTO_APP
}

export const GOTO_APP = 'GoTo App'
