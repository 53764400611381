import { type BrandVariationKeys, type BrandingMandatoryMembers } from '../feature-flags/models'

export enum Brand {
  GoTo = 'GoTo',
  ConnectBundle = 'ConnectBundle',
  Jive = 'Jive',
}

/**
 * Interface representing the Shell Branding API.
 * This interface provides methods and properties related to branding and image assets.
 */
export interface ShellBrandingApi {
  /**
   * @description Takes the name of the component/element defined in LaunchDarkly and fetches its branding variation.
   * The variation's value can be text or an ID. If the variation is an ID, it will be used to build
   * the path to the image that needs to be rendered.
   *
   * @param component The name of the component for which to fetch the branding variation.
   * @returns The branding value associated with the provided component.
   */
  readonly getBrandValue: <T extends BrandVariationKeys>(component: T) => BrandingMandatoryMembers[T]
  /**
   * @description Enumeration of different brand options.
   */
  readonly BrandVariationKeys: typeof BrandVariationKeys
  /**
   * @description Takes the ID of an asset and creates the path to fetch it.
   *
   * @param id The ID of the asset for which to generate the path.
   * @returns The path to fetch the specified asset.
   */
  readonly getBrandAssetsPath: (id: string) => string
}
