import { html, property, state } from 'lit-element'
import { SVG_MESSAGE_DOTS_OUTLINED } from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import type { PresenceEvents, PresenceUpdated } from '../../services/presence'
import { PresenceServiceNamespace } from '../../services/presence'
import { getShellApiInstance, getShellAnalytics } from '../../common/shell-api-helpers'
import { getShellLogger } from '../../common/logger'
import type { Listener } from '../../services/namespaces'
import { nothing } from 'lit-html'
import userNoteIconStyles from './user-note-icon.styles.scss'
import { ZIndex } from '../../common/z-index'
import { type PopoverPosition, type RoundButtonSize, type RoundButtonVariant } from '@getgo/chameleon-core'
import type { PresenceNamespace } from '../../services/namespaces/presence-namespace'
import { PRESENCE_NAMESPACE } from '../../services/namespaces/presence-namespace'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { GOTO_USER_NOTE_SELECTOR, type GotoUserNoteIconComponent } from '@goto/shell-common'
import { ShellElement } from '../../common/shell-element'

export class GotoUserNoteIcon extends ShellElement implements GotoUserNoteIconComponent {
  static readonly tagName = GOTO_USER_NOTE_SELECTOR
  static get styles() {
    return [userNoteIconStyles]
  }
  private _externalUserKey = ''
  @property({ type: String }) set externalUserKey(externalUserKey: string) {
    if (externalUserKey !== this._externalUserKey) {
      const previousValue = this._externalUserKey
      if (previousValue && this.isConnected) {
        this.unsubscribe()
      }
      this._externalUserKey = externalUserKey
      if (this.isConnected) {
        this.addListenerAndSubscribe()
      }
      this.requestUpdate('externalUserKey', previousValue)
    }
  }
  @property({ type: String }) tooltipPosition: PopoverPosition = 'bottom-center'
  @property({ type: String }) tooltipZIndex = ZIndex.tooltip
  @property({ type: String }) buttonSize: RoundButtonSize = 'small'
  @property({ type: String }) buttonVariant: RoundButtonVariant = 'secondary'
  @property({ type: Boolean }) canEditStatus = false
  @state() private userNote? = ''
  private readonly currentUserExternalUserKey = getShellApiInstance().user.key ?? ''

  connectedCallback() {
    super.connectedCallback()
    this.addListenerAndSubscribe()
  }

  private addListenerAndSubscribe() {
    if (this._externalUserKey) {
      this.listenForPresenceUpdatedEvent()
      this.subscribeToPresence()
    }
  }

  private subscribeToPresence() {
    const { presence } = getShellApiInstance()
    if (presence) {
      presence
        .subscribe(this._externalUserKey)
        .then(() => {
          this.unsubscribeFunctions.push(() => {
            presence.unsubscribe(this._externalUserKey)
          })
        })
        .catch(() => {
          getShellLogger().error(`failed to subscribe to presence for external user key ${this._externalUserKey}`)
        })
    }
  }

  private listenForPresenceUpdatedEvent() {
    const { eventBus } = getShellApiInstance()
    const { presenceUpdate } = eventBus.subscribeTo<typeof PresenceServiceNamespace, typeof PresenceEvents>(
      PresenceServiceNamespace,
    )

    const presenceUpdatedHandler: Listener<PresenceUpdated> = (payload: PresenceUpdated) => {
      const { externalUserKey, userNote } = payload
      if (this._externalUserKey === externalUserKey) {
        this.userNote = userNote
      }
    }

    presenceUpdate.on(presenceUpdatedHandler)

    this.unsubscribeFunctions.push(() => {
      presenceUpdate.removeListener(presenceUpdatedHandler)
    })
  }

  private getTranslatedButtonLabel() {
    return `${getTranslation('Status message')}: ${this.userNote}`
  }

  private getUserNoteIconButtonTemplate() {
    return html`<chameleon-round-button
      id="user-note-icon"
      class="user-note-icon-behind-avatar"
      size=${this.buttonSize}
      variant=${this.buttonVariant}
      label=${this.getTranslatedButtonLabel()}
      @click=${() => {
        // Mixpanel; Open Custom Status modal
        getShellAnalytics().track('GoTo > Custom Status Modal', { action: 'Click', event: 'Open', from: 'Topbar' })
        getShellApiInstance()
          .namespaces.retrieve<PresenceNamespace>(PRESENCE_NAMESPACE)
          .commands.openPresenceNoteDialog.execute()
      }}
      inactive
    >
      ${this.getSVGChatDotInactiveTemplate()}
    </chameleon-round-button>`
  }

  private getSVGChatDotInactiveTemplate() {
    return html` <chameleon-svg id="user-note-icon">${unsafeSVG(SVG_MESSAGE_DOTS_OUTLINED)}</chameleon-svg> `
  }

  render() {
    return this.userNote
      ? html` ${this.currentUserExternalUserKey === this._externalUserKey && this.canEditStatus
            ? this.getUserNoteIconButtonTemplate()
            : this.getSVGChatDotInactiveTemplate()}
          <chameleon-tooltip-v3
            position=${this.tooltipPosition}
            z-index=${this.tooltipZIndex}
            trigger-id="user-note-icon"
          >
            ${this.userNote}
          </chameleon-tooltip-v3>`
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-user-note-icon': GotoUserNoteIcon
  }
}
