import { LitElement } from 'lit-element'
import { onWindowUnload } from './helpers/window'

export class ShellElement extends LitElement {
  public unsubscribeFunctions: (() => void)[] = []

  unsubscribe = (): void => {
    this.unsubscribeFunctions.forEach(unsubscribe => unsubscribe())
    this.unsubscribeFunctions = []
  }
  connectedCallback() {
    super.connectedCallback()

    onWindowUnload(this.unsubscribe)
  }
  disconnectedCallback(): void {
    this.unsubscribe()
    super.disconnectedCallback()
  }
}
