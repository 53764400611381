import { html, state } from 'lit-element'
import { t } from '../../../directives/translate'
import { ShellElement } from '../../../common/shell-element'
import scheduleCustomHoursModalStyles from './schedule-custom-hours-modal.styles.scss'
import { getShellApiInstance } from '../../../common/shell-api-helpers'
import { type OneTimePeriod } from '../schedule-manager/models'
import {
  formatISOTime,
  getListOfTimeForOneDayWithInterval,
  initialTimeOfTheDay,
  lastTimeOfTheDay,
} from '../schedule-settings-utils'
import { type ChangeEvent } from 'react'
import { type CheckboxComponent } from '@getgo/chameleon-web'
import { nothing } from 'lit-html'

export interface NewOneTimePeriodEvent {
  readonly oneTimePeriod: OneTimePeriod
}

export interface OneTimePeriodRawData {
  name: string
  startDay: string
  endDay: string
  startTime: string
  endTime: string
  dndEnabled: boolean
}

const customHoursTimeIntervalMins = 15

export const NEW_ONE_TIME_PERIOD = 'new-one-time-period'
export const CLOSE_CUSTOM_HOURS_MODAL = 'close-custom-hours-modal'

export class GoToScheduleCustomHoursModal extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours-modal'
  private readonly user = getShellApiInstance().user
  @state() private allDayChecked = false

  private readonly oneTimePeriodRawData: OneTimePeriodRawData = {
    name: '',
    startDay: '',
    endDay: '',
    startTime: '',
    endTime: '',
    dndEnabled: true,
  }
  private readonly oneTimePeriod: OneTimePeriod = {
    name: '',
    startDate: '',
    endDate: '',
    // checked to true by default
    dndEnabled: true,
  }

  static get styles() {
    return scheduleCustomHoursModalStyles
  }

  private getUserLocale() {
    if (this.user.locale) {
      // adapt to format accepted by chameleon-date-picker
      return this.user.locale.replace('_', '-')
    }
    return 'en-US'
  }

  getCurrentDate() {
    const newDate = new Date().toISOString().split('T')[0]
    return newDate
  }

  private handleCancelClick() {
    this.dispatchEvent(new CustomEvent(CLOSE_CUSTOM_HOURS_MODAL))
  }

  private createISODate(date: string, time: string) {
    return date + 'T' + time
  }

  private handleNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    this.oneTimePeriodRawData.name = event.target.value
  }

  private handleStartDateChange(event: ChangeEvent<HTMLInputElement>) {
    this.oneTimePeriodRawData.startDay = event.currentTarget.value
  }

  private handleEndDateChange(event: ChangeEvent<HTMLInputElement>) {
    this.oneTimePeriodRawData.endDay = event.currentTarget.value
  }

  private handleStartTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    this.oneTimePeriodRawData.startTime = event.currentTarget.value
  }

  private handleEndTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    this.oneTimePeriodRawData.endTime = event.currentTarget.value
  }

  private handleAlldayCheckboxChange(event: ChangeEvent<CheckboxComponent>) {
    if (event.target.checked) {
      this.allDayChecked = true
      this.oneTimePeriodRawData.startTime = initialTimeOfTheDay
      this.oneTimePeriodRawData.endTime = lastTimeOfTheDay
    } else {
      this.allDayChecked = false
      this.oneTimePeriodRawData.startTime = ''
      this.oneTimePeriodRawData.endTime = ''
    }
  }

  private handleDNDCheckboxChange(event: ChangeEvent<CheckboxComponent>) {
    this.oneTimePeriodRawData.dndEnabled = event.target.checked
  }

  private handleConfirmClick() {
    const hasStartDate = !!this.oneTimePeriodRawData.startDay && !!this.oneTimePeriodRawData.startTime
    const hasEndDate = !!this.oneTimePeriodRawData.startDay && !!this.oneTimePeriodRawData.startTime

    if (this.oneTimePeriodRawData.name && hasStartDate && hasEndDate) {
      this.oneTimePeriod.name = this.oneTimePeriodRawData.name
      this.oneTimePeriod.startDate = this.createISODate(
        this.oneTimePeriodRawData.startDay,
        this.oneTimePeriodRawData.startTime,
      )
      this.oneTimePeriod.endDate = this.createISODate(
        this.oneTimePeriodRawData.endDay,
        this.oneTimePeriodRawData.endTime,
      )
      this.oneTimePeriod.dndEnabled = this.oneTimePeriodRawData.dndEnabled

      this.dispatchEvent(
        new CustomEvent<NewOneTimePeriodEvent>(NEW_ONE_TIME_PERIOD, {
          detail: { oneTimePeriod: this.oneTimePeriod },
        }),
      )
    }
  }

  private renderSelectOption(value: string, displayValue: string) {
    return html`<chameleon-option value=${value}>${displayValue}</chameleon-option>`
  }

  private renderCustomHoursNameInput() {
    return html`
      <div class="name-input-section">
        <chameleon-text-field placeholder=${t('Enter a name')} fullwidth @change=${this.handleNameInputChange}>
          ${t('Custom hours name')}
        </chameleon-text-field>
      </div>
    `
  }

  private renderAllDayOrStartDateSection() {
    return html`
      <div class="all-day-start-section date-time-container">
        ${this.renderDatePicker('start')}
        ${this.allDayChecked
          ? html`${this.renderDatePicker('end')}`
          : html` <chameleon-select in-dialog @change=${this.handleStartTimeChange} class="start-time-select">
              <chameleon-option value="" class="hidden">${t('Time')}</chameleon-option>
              ${getListOfTimeForOneDayWithInterval(customHoursTimeIntervalMins).map(value =>
                this.renderSelectOption(value, formatISOTime(value)),
              )}
            </chameleon-select>`}
        <chameleon-checkbox class="all-day-checkbox" @change=${this.handleAlldayCheckboxChange}
          >${t('All day')}</chameleon-checkbox
        >
      </div>
    `
  }

  private renderEndDateSection() {
    return html`
      <div class="end-section date-time-container">
        ${this.renderDatePicker('end')}
        <chameleon-select in-dialog @change=${this.handleEndTimeChange} class="end-time-select">
          <chameleon-option value="" class="hidden">${t('Time')}</chameleon-option>
          ${getListOfTimeForOneDayWithInterval(customHoursTimeIntervalMins).map(value =>
            this.renderSelectOption(value, formatISOTime(value)),
          )}
        </chameleon-select>
      </div>
    `
  }

  private renderDatePicker(calendarType: 'start' | 'end') {
    return html`
      <chameleon-date-picker
        input-label=${calendarType === 'start' ? t('Start') : t('End')}
        class=${calendarType === 'start' ? 'start-date-picker' : 'end-date-picker'}
        locale=${this.getUserLocale()}
        timezone=${this.user.location.timeZone}
        calendar-toggle-label=${calendarType === 'start'
          ? t('Toggle start date calendar')
          : t('Toggle end date calendar')}
        min=${this.getCurrentDate()}
        @change=${calendarType === 'start' ? this.handleStartDateChange : this.handleEndDateChange}
      ></chameleon-date-picker>
    `
  }

  private renderDNDSection() {
    return html` <div class="dnd-section">
      <chameleon-checkbox ?checked=${true} @change=${this.handleDNDCheckboxChange}
        >${t('Do not disturb during these hours')}</chameleon-checkbox
      >
      <chameleon-typography variant="caption-medium" color="type-color-secondary" class="dnd-description"
        >${t('Automatically turn off notifications for this time range.')}</chameleon-typography
      >
    </div>`
  }

  render() {
    return html`
      <form>
        <chameleon-dialog open size="large">
          <div class="modal-header" slot="title">
            <chameleon-typography variant="heading-small">${t('Set your custom hours')}</chameleon-typography>
          </div>
          <div class="modal-body">
            ${this.renderCustomHoursNameInput()}${this.renderAllDayOrStartDateSection()}
            ${this.allDayChecked ? nothing : this.renderEndDateSection()}${this.renderDNDSection()}
          </div>
          <div class="modal-footer" slot="actions">
            <chameleon-button size="medium" variant="tertiary" @click=${this.handleCancelClick} class="cancel-button"
              >${t('Cancel')}</chameleon-button
            >
            <chameleon-button size="medium" @click=${this.handleConfirmClick} class="confirm-button"
              >${t('Confirm')}</chameleon-button
            >
          </div>
        </chameleon-dialog>
      </form>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-custom-hours-modal': GoToScheduleCustomHoursModal
  }
}
