/* istanbul ignore file */
import { html } from 'lit-element'
import { singleSpaLit } from '../../../common'
import { GOTO_SNACKBAR_NOTIFICATION_SELECTOR } from '../../../common/container'

const lifecycles = singleSpaLit({
  template: () => html`<goto-snackbar-notification></goto-snackbar-notification>`,
  domElement: GOTO_SNACKBAR_NOTIFICATION_SELECTOR,
})

export default lifecycles
