import { getExternalInterface } from '../../services/external-interface'
import { type ExtensionConfig } from '@goto/shell-common'
import { addExtensionWithDependencies } from './utils'

export const filterForIntegration = (extensionConfigs: ExtensionConfig[]) => {
  if (getExternalInterface().isIntegration) {
    const extensions = new Set<string>()

    extensionConfigs.forEach(extensionConfig => {
      if (extensionConfig.integrationConfig?.shouldLoadInIntegration) {
        addExtensionWithDependencies(extensions, extensionConfigs, extensionConfig)
      }
    })

    return extensionConfigs.filter(extensionConfig => extensions.has(extensionConfig.id))
  }

  return extensionConfigs
}
