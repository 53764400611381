import { asTranslationKey } from '../../../common/translate-helpers/i18n-utils'

export const GOTO_EDIT_USER_PRESENCE_NOTE_DIALOG = `goto-edit-user-presence-note-dialog`

export const GOTO_USER_PRESENCE_NOTE_MAX_LENGTH = 80

export const transKeys = {
  dialogTitle: asTranslationKey(`Set a status message`),
  inputBefore: asTranslationKey(`Status message`),
  inputPlaceholder: asTranslationKey(`Update status message`),
  inputHelperText: asTranslationKey(`Maximum length is {{maxLength}} characters`),
  saveButton: asTranslationKey(`Save`),
  cancelButton: asTranslationKey(`Cancel`),
}
