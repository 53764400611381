import { LitElement, html, query } from 'lit-element'
import { SVG_APPLE_MONOCHROMATIC } from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import macOSSupportBannerStyles from './macos-support-banner.styles.scss'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { getOSVersion } from '../../common/user-agent-helpers'
import { canShowTrialBanner } from '../../core/hooks/trial-banner'
import { type BannerComponent } from '@getgo/chameleon-web'
import { getCurrentDate } from '../../common/helpers/date'

export class MacOSSupportBanner extends LitElement {
  static readonly tagName = 'goto-macos-support'
  @query('chameleon-banner') private chameleonBanner!: BannerComponent

  private learnMoreLink = 'https://support.goto.com/connect/help/how-do-i-download-and-install-the-goto-app'

  static get styles() {
    return macOSSupportBannerStyles
  }

  private getBannerMessage() {
    const macOSVersion = this.getMacOSVersion()
    return getTranslation(`{{macOSVersion}} For new GoTo updates, you'll need MacOS 10.15 (Catalina) or higher.`, {
      macOSVersion,
    })
  }

  private isBeforeSierraAndMojaveEndOfSupport() {
    const endOfSupportDate = new Date('2024-02-29 23:59')
    const currentDate = getCurrentDate()

    if (endOfSupportDate > currentDate) {
      return true
    } else {
      return false
    }
  }

  private getBannerLinkText() {
    return getTranslation('Learn more')
  }

  private getMacOSVersion() {
    const osVersion = getOSVersion()
    if (osVersion?.startsWith('10.13') && this.isBeforeSierraAndMojaveEndOfSupport()) {
      return getTranslation('MacOS 10.13 (High Sierra) support is ending Feb. 29th.')
    }
    if (osVersion?.startsWith('10.14') && this.isBeforeSierraAndMojaveEndOfSupport()) {
      return getTranslation('MacOS 10.14 (Mojave) support is ending Feb. 29th.')
    }
    return getTranslation('Your OS is no longer supported.')
  }

  private getClosable() {
    // If the user also has a trial banner, this banner must be closable
    if (canShowTrialBanner()) {
      return true
    }
    return false
  }

  render() {
    return html`<chameleon-banner ?closable=${this.getClosable()} @close=${() => this.chameleonBanner.remove()}>
      <chameleon-svg slot="icon">${unsafeSVG(SVG_APPLE_MONOCHROMATIC)}</chameleon-svg>
      <span>${this.getBannerMessage()}</span>
      <chameleon-link slot="action" href="${this.learnMoreLink}" target="_blank">
        ${this.getBannerLinkText()}
      </chameleon-link>
    </chameleon-banner>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-macos-support': MacOSSupportBanner
  }
}
