import { AuthContainer } from './authentication.container'
import { getExternalInterface } from '../external-interface'
import { onWindowUnload } from '../../common/helpers/window'

export class AuthCompanion extends AuthContainer {
  constructor() {
    super()
    this.addListeners()
  }

  private addListeners(): void {
    const handleAuthLogout = () => {
      this.logout()
    }

    getExternalInterface().addCallback('auth-logout', handleAuthLogout)

    onWindowUnload(() => {
      getExternalInterface().removeCallback('auth-logout', handleAuthLogout)
    })
  }

  /**
   * @description Logout flow for companion auth.
   */
  async logout() {
    return super.logout().then(() => {
      getExternalInterface().send({ type: 'post-logout', payload: {} })
      this.navigateToLandingPage()
    })
  }

  async completeAuthProcess(params: URLSearchParams) {
    const token = await super.completeAuthProcess(params)

    getExternalInterface().send({ type: 'auth-token', payload: token })

    return token
  }
}
