import { type NavigationItemUI } from '@goto/shell-common'
import { getExtensionsCacheFromLocalStorage } from '../../../extensions/utils'
import { type NavigationItemsService } from './model'

class NavigationItemsServiceImpl implements NavigationItemsService {
  private navigationOrder: string[] = []

  private sortNavigationItems(items: NavigationItemUI[]): NavigationItemUI[] {
    const unknownItemIndex = this.navigationOrder.length
    const compareItems = (item1: NavigationItemUI, item2: NavigationItemUI): number => {
      let index1 = this.navigationOrder.indexOf(item1.id)
      index1 = index1 === -1 ? unknownItemIndex : index1
      let index2 = this.navigationOrder.indexOf(item2.id)
      index2 = index2 === -1 ? unknownItemIndex : index2
      return index1 - index2
    }
    return items.sort(compareItems)
  }

  public getNavigationOrder(): string[] {
    return this.navigationOrder
  }

  public setNavigationOrder(orderedIds: string[]) {
    this.navigationOrder = orderedIds
  }

  // @returns a list of NavigationItem
  public getNavigationItemsFromCache(): NavigationItemUI[] {
    const cacheNav: NavigationItemUI[] = []
    const extensionsCache = getExtensionsCacheFromLocalStorage()
    if (extensionsCache?.length) {
      extensionsCache.forEach(extension => {
        if (extension.navigationItems?.length) {
          extension.navigationItems.forEach(item => {
            cacheNav.push(item)
          })
        }
      })
    }
    return this.sortNavigationItems(cacheNav)
  }
}

const navigationItemsServiceInstance = new NavigationItemsServiceImpl()

export const getNavigationItemsService = (): NavigationItemsService => navigationItemsServiceInstance
