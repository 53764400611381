export type BadgeNumberIdentifier = Exclude<
  { readonly [key: string]: string | number | boolean; readonly id: string },
  { readonly value: number }
>

/**
 * Filter definition for badge numbers
 */
export type BadgeNumberFilter = Partial<BadgeNumberIdentifier>

export interface BadgeNumberInfo extends BadgeNumberIdentifier {
  /**
   * value of the badge entry
   */
  readonly value: number
}

/**
 * Shell Badge Number Info
 */
export interface ShellBadgeNumberInfo extends BadgeNumberInfo {
  /**
   * if true, shell use this entry to calculate dock's badge value
   */
  readonly showInDock: boolean
  /**
   * if true, shell use this entry to calculate the side navigation badge value
   */
  readonly showInNavigation: boolean
}

export type BadgeNumberEventType = 'change'

export class BadgeNumberEvent extends Event {
  constructor(
    type: BadgeNumberEventType,
    public value: number = 0,
    eventIniDict?: EventInit,
  ) {
    super(type, eventIniDict)
  }
}

export type BadgeNumberEventListener = (event: BadgeNumberEvent) => void
