import { isUserOnline } from '../../common'
import { clearTimeout, setTimeout } from '../../common/dom-helpers'
import { onWindowUnload } from '../../common/helpers/window'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import type { BannerNotificationInstance, SnackbarNotificationInstance } from '../../services/display-notification'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { getEventBus } from '../../services/namespaces/event-bus'
import type { NotificationChannelEvents } from '../../services/notification-channel'
import { NotificationChannelNamespace } from '../../services/notification-channel'
import type { ShellHook } from './models'

export const initializeNotificationController: ShellHook = async () => {
  let userIsOffline = false
  let userIsDisconnected = false
  let currentSnackbarNotification: SnackbarNotificationInstance | null = null
  let currentBannerNotification: BannerNotificationInstance | null = null
  const channelEvents = getEventBus().subscribeTo<
    typeof NotificationChannelNamespace,
    typeof NotificationChannelEvents
  >(NotificationChannelNamespace)

  const SHOW_DISCONNECTION_DELAY_IN_MS = 10 * 1000 // 10 seconds

  let disconnectionTimeout: number | undefined = undefined

  const disconnectedHandler = () => {
    disconnectionTimeout = setTimeout(showDisconnectedNotification, SHOW_DISCONNECTION_DELAY_IN_MS)
  }

  const showDisconnectedNotification = () => {
    disconnectionTimeout = undefined
    if (currentSnackbarNotification) {
      closeSnackbarNotifications(currentSnackbarNotification)
    }
    if (currentBannerNotification) {
      closeBannerNotification(currentBannerNotification)
    }
    userIsDisconnected = true
    // We only display a snackbar if the user has lost internet connection
    if (!isUserOnline()) {
      userIsOffline = true
      currentSnackbarNotification = showNoInternetConnectionSnackbar()
    }
    if (
      !getFeatureFlagValue(FeatureFlagsVariations.SHELL_TRACK_NC_DISCONNECT_BANNER) &&
      !getShellApiInstance().extensions.isStandaloneRoute(window.location.pathname)
    ) {
      currentBannerNotification = showNotificationChannelDisconnectedBanner()
    }
  }

  const connectedHandler = () => {
    if (disconnectionTimeout !== undefined) {
      clearTimeout(disconnectionTimeout)
      disconnectionTimeout = undefined
    }

    if (currentSnackbarNotification) {
      closeSnackbarNotifications(currentSnackbarNotification)
    }
    if (currentBannerNotification) {
      closeBannerNotification(currentBannerNotification)
    }
    if (userIsDisconnected) {
      userIsDisconnected = false
      if (userIsOffline) {
        currentSnackbarNotification = showInternetConnectionRestoredSnackbar()
        userIsOffline = false
      } else {
        if (
          !getFeatureFlagValue(FeatureFlagsVariations.SHELL_TRACK_NC_DISCONNECT_BANNER) &&
          !getShellApiInstance().extensions.isStandaloneRoute(window.location.pathname)
        ) {
          currentSnackbarNotification = showNotificationChannelConnectionRestoredSnackbar()
        }
      }
    }
  }

  channelEvents.disconnected.addListener(disconnectedHandler)
  channelEvents.connected.addListener(connectedHandler)

  onWindowUnload(() => {
    channelEvents.disconnected.removeListener(disconnectedHandler)
    channelEvents.connected.removeListener(connectedHandler)
  })
}

const showNoInternetConnectionSnackbar = (): SnackbarNotificationInstance => {
  const noInternetConnectionSnackbar = getShellApiInstance().display.snackbar({
    title: getTranslation('No internet connection'),
    id: 'connection-lost',
    message: getTranslation('Check your network or hold on while we try to reconnect.'),
    actions: [
      {
        label: getTranslation('Dismiss'),
        handler() {
          closeSnackbarNotifications(noInternetConnectionSnackbar)
        },
      },
    ],
  })
  return noInternetConnectionSnackbar
}

const showInternetConnectionRestoredSnackbar = (): SnackbarNotificationInstance => {
  const internetConnectionRestoredSnackbar = getShellApiInstance().display.snackbar({
    title: getTranslation(`You're back online`),
    id: 'connection-restored',
    message: getTranslation('Everything should be working normally again.'),
    actions: [
      {
        label: getTranslation('Dismiss'),
        handler() {
          closeSnackbarNotifications(internetConnectionRestoredSnackbar)
        },
      },
    ],
    dataTest: 'internetConnectionRestoredSnackbar',
  })
  return internetConnectionRestoredSnackbar
}

const showNotificationChannelConnectionRestoredSnackbar = (): SnackbarNotificationInstance => {
  const notificationChannelConnectionRestoredSnackbar = getShellApiInstance().display.snackbar({
    title: getTranslation('All of GoTo is back online'),
    id: 'connection-restored',
    actions: [
      {
        label: getTranslation('Dismiss'),
        handler() {
          closeSnackbarNotifications(notificationChannelConnectionRestoredSnackbar)
        },
      },
    ],
    dataTest: 'notificationChannelConnectionRestoredSnackbar',
  })
  return notificationChannelConnectionRestoredSnackbar
}

const showNotificationChannelDisconnectedBanner = (): BannerNotificationInstance =>
  getShellApiInstance().display.banner({
    description: getTranslation("Can't connect with all of GoTo. Some features are temporarily offline."),
    closable: false,
    variant: 'neutral',
    id: 'shellDisconnectionBanner',
    target: window,
  })

const closeSnackbarNotifications = (currentSnackbarNotification: SnackbarNotificationInstance) => {
  currentSnackbarNotification.close()
}

const closeBannerNotification = (currentBannerNotification: BannerNotificationInstance) => {
  currentBannerNotification.close()
}
