/** A basic rate limiter class. Intended usage:
 * ```ts
 * const rateLimiter = new RateLimiter(10, 1000) // max 10 requests per 1000 ms
 *
 * const handleRequest = () => {
 *   if (rateLimiter.isAllowed()) {
 *     rateLimiter.recordRequest()
 *     // handle the request ...
 *   } else {
 *     // handle the case when the rate limit has been reached
 *   }
 * }
 *
 * // or
 * const handleRequestAsync = async () => {
 *   await sleep(rateLimiter.timeMsUntilAllowed())
 *   rateLimiter.recordRequest()
 *   // handle the request ...
 * }
 * ```
 */
export class RateLimiter {
  private readonly requestTimestampsMsWithinInterval: number[] = []

  /**
   * @param limit - The maximum number of requests allowed within the interval.
   * @param intervalMs - The interval in milliseconds.
   */
  constructor(
    private readonly limit: number,
    private readonly intervalMs: number,
  ) {}

  /** @returns `true` if a new request is allowed at the time of calling this method, `false` otherwise. */
  isAllowed() {
    return this.timeMsUntilAllowed() === 0
  }

  /** @returns the number of milliseconds needed to wait until a new request is allowed. Can be `0` if a new request is
   *   allowed at the time of calling this method, or a positive number if a new request is not allowed at the moment
   *   and need to wait. */
  timeMsUntilAllowed() {
    const currentTimestampMs = Date.now()
    this.removeOldRequests(currentTimestampMs)

    if (this.requestTimestampsMsWithinInterval.length < this.limit) {
      return 0
    }

    // We account for the case where the rate limit has been passed. For example: limit is 10 but we have 15 requests in
    // the array (the rate limiter has not been obeyed for some reason). In this case, we use the timestamp of the 6th
    // element (index 5) to calculate the time until a new request is allowed. We are calculating how much time needs to
    // elapse until this element gets outside of the interval.
    // For example: if this 6th element has a timestamp of 50 and the interval is 10 ms, this element will become old
    // the moment the timestamp reaches 60 (when this time comes, we will keep only elements in the range 51-60 inclusive).
    const indexFirstRequestInLimit = this.requestTimestampsMsWithinInterval.length - this.limit
    const timestampMsFirstRequestInLimitBecomesOld = this.requestTimestampsMsWithinInterval[indexFirstRequestInLimit] + this.intervalMs
    // Make sure we return a positive number, just in case.
    return Math.max(0, timestampMsFirstRequestInLimitBecomesOld - currentTimestampMs)
  }

  /** Informs the rate limiter that a new request was made. */
  recordRequest() {
    const currentTimestampMs = Date.now()
    this.requestTimestampsMsWithinInterval.push(currentTimestampMs)
  }

  private removeOldRequests(currentTimestampMs: number) {
    // Example: if requestTimestampsMsWithinInterval is [50, 51, 52, 53, 54, 55, 56, 57, 58, 59], currentTimestampMs is
    // 60, and intervalMs is 10, then:
    //  - earliestTimestampMsWithinInterval = 60 - 10 + 1 = 51
    //  - the time interval 51-60 (inclusive) represents the current interval of 10 ms. Timestamp of 50 is outside of
    //    this interval.
    //  - we modify requestTimestampsMsWithinInterval to be [51, 52, 53, 54, 55, 56, 57, 58, 59]

    const earliestTimestampMsWithinInterval = currentTimestampMs - this.intervalMs + 1
    const indexEarliestElementToRemain = this.requestTimestampsMsWithinInterval.findIndex(
      timestampMs => timestampMs >= earliestTimestampMsWithinInterval,
    )
    if (indexEarliestElementToRemain >= 0) {
      // Delete the first part of the array where the old elements are (if any).
      this.requestTimestampsMsWithinInterval.splice(0, indexEarliestElementToRemain)
    } else {
      // Delete all elements (if any), they are all old.
      this.requestTimestampsMsWithinInterval.splice(0, Infinity)
    }
  }
}
