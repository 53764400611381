export const IFRAME_ID = 'IFRAME_ID'
export const DEFAULT_ISLOGGEDIN_STATE = false
export const ISLOGGEDIN_IFRAME_MESSAGE_TYPE = 'ISLOGGEDIN_IFRAME_MESSAGE_TYPE'

/**
 * Increased to 15 seconds because of issues with auth token not refreshing because of poor network latency
 * refreshAuth() function was not refreshing token because isLoggedIn() was returning false
 * isLoggedIn() was returning false because timeout was not long enough for allowing app to reauthenticate
 * in hidden iFrame.
 *
 * If changing this value, please make sure to test the following:
 * 1. Have only a meeting link open (i.e. app not open in another tab)
 * 2. Reduce network latency to be slower than your new value
 * 3. Modify oauth token in local storage so that it needs to refresh
 * 4. Make some sort of API call from the console
 * 5. Ensure that token is refreshed in localstorage after the time limit without needing to reload the page
 * @see https://jira.ops.expertcity.com/browse/SCORE-2746
 */
export const IS_LOGGEDIN_IFRAME_LOAD_TIMEOUT = 15000

export const API_NOT_LOGGED_PARAMETER = 'error'
export const API_LOGGED_PARAMETER = 'access_token'

export const API_REDIRECT_URL_PATHNAME = '/authentication'
