import { html, LitElement } from 'lit-element'

import { Container, Plugins } from '@getgo/container-client'

import { resolvePromise } from '../../directives/resolved-promise'
import { getShellLogger } from '../../common/logger'
import { formatApplicationVersion } from '../../common/version-helpers'
import { getHostAppVersion } from '../../common/host-app-version'

const VERSIONS_PREFIX = 'GoTo'

const getAppVersions = async (): Promise<string> => {
  const versionParts = [VERSIONS_PREFIX, formatApplicationVersion(getHostAppVersion())]

  try {
    if (Container.isPluginAvailable('ContainerInfo')) {
      versionParts.push((await Plugins.ContainerInfo.getInfo())?.appVersion)
    }
  } catch (err) {
    getShellLogger().error(`Cannot get container appVersion from Plugins.ContainerInfo.getInfo()`, err)
  }

  return versionParts.join(' ').trim()
}

export const GOTO_VERSION_TAGNAME = 'goto-version'

export class VersionElement extends LitElement {
  static readonly tagName = GOTO_VERSION_TAGNAME
  render() {
    return html`${resolvePromise(getAppVersions())}`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-version': VersionElement
  }
}
