import { displayBannerWhenReady } from '../../../packages/notifications/utils'
import { banner } from '../../display-notification'

import type { BannerNotification } from '../../display-notification/models'

export const showOverrideBannerConfig: BannerNotification = {
  closable: true,
  description:
    'To use master branch, delete the goto-experiences-override/goto-extensions-override key from localstorage. For more information see',
  variant: 'neutral',
  link: {
    text: 'documentation',
    href: 'https://github.com/jive/goto-shell/blob/master/README.md#override-experience-or-extension',
  },
  target: window,
  id: 'overrideBanner',
}

export const displayOverrideBanner = () => {
  banner(showOverrideBannerConfig)
}

export const initOverrideBanner = () => {
  displayBannerWhenReady(displayOverrideBanner)
}
