import type { RegisterApplicationConfig } from 'single-spa'
import type { CustomProps } from '../common'
import type { Environment } from '../environments'

import type { ShellAuthApi } from '../services/auth'
import type { shellDisplayNotificationAPI } from '../services/display-notification'
import type { ContactAPI } from '../services/contacts'

import type {
  IdentityApi,
  UserAdminInfo,
  UserProfileEmail,
  UserProfileEntitlement,
  UserProfileInfo,
} from '../services/identity/models'

import type { GoToLicense, ShellFeatureFlagsAPIs } from '../services/feature-flags'
import type { ShellI18NAPI } from '../services/i18n'
import type { ContainerAPI } from '../services/container'
import type { IShellPresenceService } from '../services/presence'
import type { namespaces, EventBus } from '../services/namespaces'
import type { ContextApi } from '../services/context/models'
import type { GetNotificationSettingsType } from '../services/notification-settings'
import type { AnalyticsAPI } from '../services/analytics/models'
import type { CalendarAPI } from '../services/calendar'
import type { MetricsAPI } from '../services/metrics'
import type { MetaAPI } from '../services/meta'
import type { AvatarInfo } from '../services/avatar/models'
import type { ProductEnabledDate } from '../services/pendo-integration/models'
import type { DialogAPI } from '../services/dialog/models'
import type { BadgeNumbersService } from '../services/badge-numbers'
import type { Contact } from '@goto/contacts-definitions'
import type { ShellUserAccount, ShellUserInfo } from './user-info/model'
import { ShellUserInfoImpl } from './user-info/user-info'
import type { InspectorPanelAPI } from '../services/inspector-panel/models'
import type { ShellUserPreferencesInstance } from '../services/user-preferences/models'
import type { NotificationChannel } from '@goto/shell-notification-channel'
import type { ExtensionsAPI } from '../services/extensions/models'
import { type ShellBrandingApi } from '../services/branding/models'
import { type AppManagerAPI } from '../services/app-manager'
import type { Logger } from '@getgo/goto-logger'
import { type ExternalInterface } from '../services/external-interface/external-interface'
import type { PhoneSystemAPI } from '../services/phone-system/models'
import { type NavigationAPI } from '../services/navigation'
export type { MetaAPI } from '../services/meta'
export * from './user-info/model'

export type ExternalUserKey = string
export type ISO8601Timestamp = string

/**
 * Interface definition of the window.shell object.
 * Properties of window.shell might be undefined based on the current users authentication state.
 */
export interface ShellApi {
  readonly app: AppManagerAPI
  readonly auth: ShellAuthApi
  readonly badgeNumbers: BadgeNumbersService
  readonly container: ContainerAPI
  readonly eventBus: EventBus
  readonly namespaces: namespaces
  readonly user: ShellUserInfo
  /**
   * @deprecated
   * Upgrade to use `shell.user` instead
   * https://github.com/jive/goto-shell/blob/master/docs/adr/2022-09-30-CORE-user-info.md
   */
  readonly identity?: IdentityApi
  readonly context: ContextApi
  readonly display: typeof shellDisplayNotificationAPI
  readonly featureFlags: ShellFeatureFlagsAPIs
  readonly branding: ShellBrandingApi
  readonly settings: ShellSettings
  readonly analytics: AnalyticsAPI
  readonly logger: Logger
  readonly metrics: MetricsAPI
  readonly meta: MetaAPI
  readonly dialog: DialogAPI
  readonly navigation: NavigationAPI
  readonly inspectorPanel: InspectorPanelAPI
  readonly currentEnvironment: Environment
  readonly extensions: ExtensionsAPI
  readonly phoneSystem: PhoneSystemAPI

  calendar?: CalendarAPI
  contacts?: ContactAPI
  notificationChannel?: NotificationChannel
  presence?: IShellPresenceService
  i18n: ShellI18NAPI
  userPreferences?: ShellUserPreferencesInstance
}

interface ShellSettings {
  readonly getNotificationSettings: GetNotificationSettingsType
}

/**
 * Seems like a semi odd place to keep this global def, but this is the lowest maintenance spot. If you have a better
 * idea please share.
 */
declare global {
  // eslint-disable-next-line no-var
  var shell: ShellApi
  // eslint-disable-next-line no-var
  var shellExternalInterface: ExternalInterface
  // eslint-disable-next-line no-var
  var shellEnvironmentConfig: ShellHostConfig
  interface Window {
    shell: ShellApi
    shellExternalInterface: ExternalInterface
    contacts_list?: Contact[]
    readonly shellEnvironmentConfig: ShellHostConfig
  }
}

/**
 * Config specific to dealing with shell experience environment
 */
export interface ShellConfig {
  readonly hideSidebar?: boolean
  readonly hideTopbar?: boolean
}
/**
 * Config to setup variables specific to shell environment
 */
export interface ShellHostConfig {
  readonly environment: Environment
}

export interface ShellHostMeta {
  readonly hostAppVersion: string
  readonly chameleonWebVersion: string | null
}

export type ShellAuthContext = {
  user: ShellUserInfo
  me?: UserProfileInfo
  /**
   * @deprecated use shell.user.avatar instead
   */
  avatar?: AvatarInfo
  meFromExternalAdmin?: UserAdminInfo
  userProfile?: {
    /**
     * @deprecated use `shell.user.entitlements` instead
     */
    readonly entitlements?: readonly string[]
    readonly emails?: readonly UserProfileEmail[]
    readonly firstEmail?: string
    readonly name?: string
    readonly id?: string
  }
  /**
   * @deprecated use shell.user.hasPermissions instead
   */
  permissions?: readonly ShellUserAuthorityContextPermissions[]
  pbxId?: string
  accountKeys?: readonly string[]
  contextApi?: ContextApi
  licenses?: readonly GoToLicense[]
  productEnabledDates?: readonly ProductEnabledDate[]
  isCalendarConnected?: Promise<boolean>
  currentAccount?: ShellUserAccount
  userCreationDate?: string
}

export const defaultConfig: ShellConfig = { hideSidebar: false, hideTopbar: false }

export class ShellAuthContextImpl implements ShellAuthContext {
  user = new ShellUserInfoImpl({})
  me?: UserProfileInfo | undefined
  avatar?: AvatarInfo | undefined
  meFromExternalAdmin?: UserAdminInfo | undefined
  userProfile?:
    | {
        readonly entitlements?: readonly UserProfileEntitlement[] | undefined
        readonly emails?: readonly UserProfileEmail[] | undefined
        readonly firstEmail?: string | undefined
        readonly name?: string | undefined
        readonly id?: string | undefined
      }
    | undefined
  pbxId?: string | undefined
  accountKeys?: readonly string[] | undefined
  contextApi?: ContextApi | undefined
  licenses?: readonly GoToLicense[] | undefined
  productEnabledDates?: readonly ProductEnabledDate[] | undefined
  isCalendarConnected?: Promise<boolean> | undefined
  permissions = []
}

export enum ShellLicenseType {
  FREEMIUM = 'FREEMIUM',
  CONTACT_CENTER = 'CONTACT_CENTER',
  CONTACT_CENTER_FOR_SUPPORT = 'CONTACT_CENTER_FOR_SUPPORT',
}

export enum ShellUserAuthorityContextPermissions {
  VIEW_CC_CONFIG_FREE_TRIAL_ELIGIBILITY = 'cc-config.free-trial-eligibility',
  VIEW_AGENT_PAGE = 'cc-agent.portal',
  VIEW_CONTACT_CENTER_CONFIGURATION_PAGE = 'cc-config.gtc',
  VIEW_SUPERVISOR_PAGE = 'cc-supervisor',
  VIEW_SUPERVISOR_EXPERIENCE = 'cc-supervisor.experience',
  VIEW_ANALYTICS_EXPERIENCE = 'unified-analytics.portal',
  VIEW_OMNI_INBOX = 'omni-inbox',
  VIEW_SHARED_CONTACTS = 'contact.view',
  EDIT_SHARED_CONTACTS = 'contact.edit',
}

export interface ShellUserAuthorityContextOverrideParams {
  roleId: string[]
  license: ShellLicenseType
}

export interface ShellUserAuthorityContext {
  license: string | null
  readonly permissions: ShellUserAuthorityContextPermissions[]
}

export type GotoRegisteredApplications = Map<string, RegisterApplicationConfig<CustomProps>>

export interface Registrant<T> {
  registerApplication: (name: string, application: T) => void
  getRegisteredApplications: () => GotoRegisteredApplications
}
