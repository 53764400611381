import type { ShellApi, ShellAuthContext } from './models'
import { getContactAPI } from '../services/contacts'
import { getCurrentEnvironment } from '../environments'
import { shellDisplayNotificationAPI } from '../services/display-notification'
import { type EventBusPrivate, getEventBus } from '../services/namespaces/event-bus'
import { shellAuthApi } from '../services/auth'
import type { Locale } from '../services/i18n'
import { getShellI18NAPI } from '../services/i18n'
import { containerAPI } from '../services/container'
import { getNamespaces } from '../services/namespaces'
import { getNavigationAPI } from '../services/navigation'
import { getShellLogger } from '../common/logger'
import { getCalendarConnector, getCalendarAPI } from '../services/calendar'
import { getNotificationSettings } from '../services/notification-settings'
import { analytics } from '../services/analytics'
import { shellBrandingApi } from '../services/branding'
import { getMetricsAPI } from '../services/metrics'
import { getAppManagerAPI } from '../services/app-manager'
import { subscribeToBadgeEvent } from '../common/badge-helpers'
import { getMetaAPI } from '../services/meta/index'
import { getDialogAPI } from '../services/dialog/public-facade'
import { getBadgeNumbersAPI } from '../services/badge-numbers/public-facade'
import { createUserInfoAPI } from './user-info/public-facade'
import { getInspectorPanelAPI } from '../services/inspector-panel/public-facade'
import { getUserPreferencesAPI } from '../services/user-preferences/public-facade'
import { getUserPreferencesConnector } from '../services/user-preferences/connectors/user-preferences.connector'
import { createNotificationChannel } from '../services/notification-channel-new/notification-channel-facade-factory'
import { shellFeatureFlagsAPI } from '../services/feature-flags'
import { getExtensionsAPI } from '../services/extensions/public-facade'
import { addExternalInterfaceEventAdapter } from '../services/external-interface/external-interface-event-adapter'
import { phoneSystemAPI } from '../services/phone-system'
import { createPresenceService } from '../services/presence/presence-service-factory'
import { shouldUsePlugin } from '../services/external-interface/utils'
import { getExternalInterface } from '../services/external-interface'

export const createShellApi = (authContext: ShellAuthContext): ShellApi => {
  const eventBus = getEventBus()
  const namespaces = getNamespaces()

  if (getExternalInterface().isCompanion || (getExternalInterface().isIntegration && shouldUsePlugin())) {
    addExternalInterfaceEventAdapter(eventBus as EventBusPrivate)
  }

  const shellApi: ShellApi = {
    app: getAppManagerAPI(),
    auth: shellAuthApi,
    badgeNumbers: getBadgeNumbersAPI(),
    eventBus,
    namespaces,
    context: authContext.contextApi ?? {},
    user: createUserInfoAPI(authContext.user),
    container: containerAPI,
    display: shellDisplayNotificationAPI,
    extensions: getExtensionsAPI(),
    featureFlags: shellFeatureFlagsAPI,
    phoneSystem: phoneSystemAPI,
    branding: shellBrandingApi,
    i18n: getShellI18NAPI(authContext.user.locale as Locale),
    presence: undefined,
    settings: {
      getNotificationSettings,
    },
    analytics,
    logger: getShellLogger(),
    metrics: getMetricsAPI(),
    meta: getMetaAPI(),
    navigation: getNavigationAPI(),
    dialog: getDialogAPI(),
    inspectorPanel: getInspectorPanelAPI(),
    currentEnvironment: getCurrentEnvironment(),
    userPreferences: undefined,
  }
  subscribeToBadgeEvent()

  /**
   * The following parts of the API require the user to be authenticated.
   * `me` (the user's profile info) is always fetched right after the authentication succeds
   */
  if (authContext.user.key) {
    shellApi.calendar = getCalendarAPI(getCalendarConnector(), authContext.user.key, getEventBus())

    shellApi.contacts = getContactAPI()

    shellApi.userPreferences = getUserPreferencesAPI(getUserPreferencesConnector())

    const notificationChannel = createNotificationChannel(authContext.user.key)
    shellApi.notificationChannel = notificationChannel
    notificationChannel.start()

    try {
      shellApi.presence = createPresenceService(shellApi.notificationChannel.id)
    } catch (e) {
      shellApi.logger.error(`We could not initialize PresenceService: ${e}`)
    }
  }

  return shellApi
}
