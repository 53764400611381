import { GOTO_DIALOG_CONTAINER_SELECTOR } from '../../common/container'
import { getDocument } from '../../common/dom-helpers'
import { getShellLogger } from '../../common/logger'

export class DialogService {
  private readonly dialogContainerSelector = GOTO_DIALOG_CONTAINER_SELECTOR

  open(content: HTMLElement) {
    const dialogContainer = getDocument().querySelector(this.dialogContainerSelector)

    if (dialogContainer?.childNodes.length === 0) {
      dialogContainer?.appendChild(content)
    } else {
      getShellLogger().error('Cannot display more than 1 dialog at a time.')
    }
  }

  close() {
    const dialogContainer = getDocument().querySelector(this.dialogContainerSelector)

    if (dialogContainer) {
      dialogContainer.innerHTML = ''
    }
  }
}
