import type { ProductTrial } from '../../services/context/models'

export class ProductTrialImpl implements ProductTrial {
  free?: boolean
  trial?: boolean
  product?: string
  endDate?: Date
  active?: boolean

  constructor(params: ProductTrial | undefined) {
    this.free = params?.free
    this.trial = params?.trial
    this.product = params?.product
    this.endDate = params?.endDate
    this.active = params?.active
  }
}
