import { onWindowUnload } from '../../common/helpers/window'
import { type ExternalUserKey } from '../../core/models'
import { getExternalInterface } from '../external-interface'
import {
  type PresenceServiceMessagePayload,
  type PresenceServiceMessage,
} from '../external-interface/messages/presence-service'
import { getUserKey } from '../external-interface/utils'
import {
  type ShellServiceSubscriptionState,
  type IShellPresenceService,
  type PresenceSnapshot,
  type UserPresence,
} from './models'

export class ShellPresenceServiceCompanionAdapter implements IShellPresenceService {
  /**
   * @deprecated Leave this empty
   */
  subscriptions: Map<string, ShellServiceSubscriptionState> = new Map()

  constructor(private readonly presenceService: IShellPresenceService) {
    this.initEvents()
    this.subscribeToCurrentUserPresence()
  }

  subscribe(externalUserKey: ExternalUserKey): Promise<void> {
    return this.presenceService.subscribe(externalUserKey)
  }

  unsubscribe(externalUserKey: ExternalUserKey): void {
    this.presenceService.unsubscribe(externalUserKey)
  }

  getUserPresence(): Promise<void | UserPresence> {
    return this.presenceService.getUserPresence()
  }

  setUserPresence(presence: Partial<UserPresence>): Promise<void | UserPresence> {
    return this.presenceService.setUserPresence(presence)
  }

  getPresenceSnapshots(externalUserKey: ExternalUserKey): Promise<PresenceSnapshot> {
    return this.presenceService.getPresenceSnapshots(externalUserKey)
  }

  private initEvents() {
    const externalInterface = getExternalInterface()

    externalInterface.addCallback('presence-service', this.handlePresenceServiceEvents)

    onWindowUnload(() => {
      externalInterface.removeCallback('presence-service', this.handlePresenceServiceEvents)
    })
  }

  private send<T extends PresenceServiceMessagePayload>(payload: T) {
    getExternalInterface().send<PresenceServiceMessage>({
      type: 'presence-service',
      payload,
    })
  }

  private readonly handlePresenceServiceEvents = async (payload: PresenceServiceMessagePayload) => {
    switch (payload.topic) {
      case 'subscribe': {
        const typedPayload = payload as PresenceServiceMessagePayload<'subscribe'>

        await this.subscribe(typedPayload.data.externalUserKey).catch(() => {})

        this.send<PresenceServiceMessagePayload<'subscribe-response'>>({
          topic: 'subscribe-response',
          data: {
            requestId: typedPayload.data.requestId,
          },
        })

        break
      }

      case 'get-user-presence': {
        const presence = await this.getUserPresence().catch(() => {})

        this.send<PresenceServiceMessagePayload<'get-user-presence-response'>>({
          topic: 'get-user-presence-response',
          data: {
            requestId: payload.data.requestId,
            presence,
          },
        })
        break
      }

      case 'set-user-presence': {
        const typedPayload = payload as PresenceServiceMessagePayload<'set-user-presence'>

        const presence = await this.setUserPresence(typedPayload.data.presence).catch(() => {})

        this.send<PresenceServiceMessagePayload<'set-user-presence-response'>>({
          topic: 'set-user-presence-response',
          data: {
            requestId: typedPayload.data.requestId,
            presence,
          },
        })

        break
      }

      case 'get-presence-snapshots': {
        const typedPayload = payload as PresenceServiceMessagePayload<'get-presence-snapshots'>

        const presenceSnapshot = await this.getPresenceSnapshots(typedPayload.data.externalUserKey).catch(() => {})

        this.send<PresenceServiceMessagePayload<'get-presence-snapshots-response'>>({
          topic: 'get-presence-snapshots-response',
          data: {
            requestId: typedPayload.data.requestId,
            presenceSnapshot,
          },
        })

        break
      }
    }
  }

  /**
   * This is to keep at least one subscription alive so if there's a ongoing call
   * It will remains active when the integration closes or reloads
   */
  private subscribeToCurrentUserPresence() {
    getUserKey().then(externalUserKey => {
      if (externalUserKey) {
        this.subscribe(externalUserKey)
      }
    })
  }
}
