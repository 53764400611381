import { InspectorPanelService } from './inspector-panel.service'
import type { InspectorPanelAPI } from './models'

let _inspectorPanelAPI: InspectorPanelAPI
let _inspectorPanelService: InspectorPanelAPI

export const getInspectorPanelAPI = (): InspectorPanelAPI => {
  if (!_inspectorPanelAPI) {
    _inspectorPanelAPI = createInspectorPanelAPI()
  }
  return _inspectorPanelAPI
}

const createInspectorPanelAPI = (): InspectorPanelAPI => {
  const inspectorPanelService = getInspectorPanelService()
  const inspectorPanelAPI: InspectorPanelAPI = {
    isOpen() {
      return inspectorPanelService.isOpen()
    },
    open(element) {
      return inspectorPanelService.open(element)
    },
    close() {
      return inspectorPanelService.close()
    },
  }
  return Object.freeze(inspectorPanelAPI)
}

export const getInspectorPanelService = (): InspectorPanelService => {
  if (!_inspectorPanelService) {
    _inspectorPanelService = new InspectorPanelService()
  }
  return _inspectorPanelService
}
