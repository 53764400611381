/* istanbul ignore file */
import { html } from 'lit-element'
import { type SingleSpaLitProps, singleSpaLit } from '../../common'
import { GOTO_CONTENT_SELECTOR } from '../../common/container'

const lifecycles = singleSpaLit<SingleSpaLitProps>({
  template: html`<goto-integration-start-plugin></goto-integration-start-plugin>`,
  domElement: GOTO_CONTENT_SELECTOR,
})

export default lifecycles
