import { ShellNotificationDefaultDispatcher } from './shell-notification-default-dispatcher'
import { getExternalInterface } from '../../external-interface'
import {
  type NotificationChannelCloseEvent,
  type NotificationChannelErrorEvent,
  type NotificationChannelMessageEvent,
} from '@goto/shell-notification-channel'
import {
  type NotificationChannelMessagePayload,
  type NotificationChannelMessage,
} from '../../external-interface/messages/notification-channel'

export class ShellNotificationCompanionDispatcher extends ShellNotificationDefaultDispatcher {
  protected addListeners(): void {
    super.addListeners()

    const handleOpenCheckEvent = (payload: NotificationChannelMessagePayload<'open-check'>) => {
      if (payload.topic === 'open-check' && this.id) {
        this.handleOpenEvent()
      }
    }
    getExternalInterface().addCallback('notification-channel', handleOpenCheckEvent)
  }

  protected handleOpenEvent(): void {
    super.handleOpenEvent()
    getExternalInterface().send<NotificationChannelMessage>({
      type: 'notification-channel',
      payload: {
        topic: 'open',
        data: {
          id: this.id,
          channelInfo: this.channelInfo,
        },
      },
    })
  }

  protected handleCloseEvent(event: NotificationChannelCloseEvent): void {
    super.handleCloseEvent(event)
    getExternalInterface().send<NotificationChannelMessage>({
      type: 'notification-channel',
      payload: {
        topic: 'close',
        data: {
          error: event,
        },
      },
    })
  }

  protected handleMessageEvent(event: NotificationChannelMessageEvent<string>): void {
    super.handleMessageEvent(event)
    getExternalInterface().send<NotificationChannelMessage>({
      type: 'notification-channel',
      payload: {
        topic: 'message',
        data: {
          message: event,
        },
      },
    })
  }

  protected handleErrorEvent(event: NotificationChannelErrorEvent): void {
    super.handleErrorEvent(event)
    getExternalInterface().send<NotificationChannelMessage>({
      type: 'notification-channel',
      payload: {
        topic: 'error',
        data: {
          event,
        },
      },
    })
  }
}
