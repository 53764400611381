import type {
  CreateIncidentBody,
  CreateIncidentData,
  FileAttachmentInfo,
  ResolveData,
  ResolvePostTicketResult,
  ResolveService,
  ResolveServiceData,
  ResolveServiceExtractData,
} from './models'
import { ResolveApiCallResult } from './models'
import { getShellLogger } from '../../../common/logger'
import { getShellApiInstance } from '../../../common/shell-api-helpers'
import { authenticatedFetch } from '../../../services/auth'
import { snackbar } from '../../../services/display-notification'
import { getTranslation } from '../../../services/i18n/i18nUtils'
import { environment } from '../../../environments'
import uniqueId from 'lodash.uniqueid'

export const postTicket = async (createIncidentData: CreateIncidentData): Promise<ResolvePostTicketResult> => {
  try {
    await authenticatedFetch(`${environment().gotoResolveUrl}/incidents`, {
      method: 'POST',
      headers: {
        AccountKey: getShellApiInstance()?.context?.account?.key ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createIncidentBody(createIncidentData)),
      credentials: undefined,
    })
    return { status: ResolveApiCallResult.SUCCESS }
  } catch (error) {
    getShellLogger().error(`Post incident ticket error`, error)
    let message = 'Post'
    if (error instanceof Error) {
      message = error.message
    }
    return { status: ResolveApiCallResult.FAILURE, message }
  }
}

const createIncidentBody = (createIncidentData: CreateIncidentData) => {
  let body: CreateIncidentBody

  body = {
    data: {
      type: 'incidents',
      attributes: {
        title: createIncidentData.title,
      },
      relationships: {
        service: {
          data: {
            type: 'service',
            id: createIncidentData.serviceId,
          },
        },
        category: {
          data: {
            type: 'category',
            id: createIncidentData.categoryId,
          },
        },
        priority: {
          data: {
            type: 'priority',
            id: createIncidentData.priorityId,
          },
        },
      },
    },
  }

  if (createIncidentData.date) {
    body = {
      ...body,
      data: {
        ...body.data,
        attributes: {
          ...body.data.attributes,
          due_date: createIncidentData.date,
        },
      },
    }
  }
  if (createIncidentData.description) {
    const tempId = uniqueId().toString()
    body = {
      ...body,
      data: {
        ...body.data,
        relationships: {
          ...body.data.relationships,
          symptoms: {
            data: {
              'temp-id': tempId,
              type: 'symptoms',
              method: 'create',
            },
          },
        },
      },
      included: [
        {
          'temp-id': tempId,
          type: 'symptoms',
          attributes: {
            note: createIncidentData.description ?? '',
            note_type: 'Symptom',
          },
        },
      ],
    }
  }
  if (createIncidentData.file) {
    body = {
      ...body,
      included: [
        {
          ...body.included,

          attributes: {
            ...body.included?.[0]?.attributes,
            attachments: [
              {
                filename: createIncidentData.file.name,
                content_type: createIncidentData.file.type,
                data: createIncidentData.file.data,
              },
            ],
          },
        },
      ],
    }
  }
  return body
}

export const getServices = async (): Promise<readonly ResolveService[]> => {
  try {
    const response = await authenticatedFetch<ResolveData>(`${environment().gotoResolveUrl}/incidents/services.json`, {
      method: 'GET',
      headers: {
        AccountKey: getShellApiInstance()?.context?.account?.key ?? '',
        'Content-Type': 'application/json',
      },
      credentials: undefined,
    })
    return (await response.json()).data
  } catch (e) {
    // TODO: proper error handling if ticket not successfully created https://jira.ops.expertcity.com/browse/SCORE-1763
    getShellLogger().error(e)
    return []
  }
}

export const getDataForSelectedService = async (serviceId: number): Promise<ResolveServiceExtractData> => {
  try {
    const response = await authenticatedFetch<ResolveServiceData>(
      `${
        environment().gotoResolveUrl
      }/incidents/services.json?include=categories,service_priorities,incident_statuses&service_id=${serviceId}`,
      {
        method: 'GET',
        headers: {
          AccountKey: getShellApiInstance()?.context.account?.key ?? '',
          'Content-Type': 'application/json',
        },
        credentials: undefined,
      },
    )
    return (await response.json()).data[0]
  } catch (e) {
    // TODO: proper error handling if ticket not successfully created https://jira.ops.expertcity.com/browse/SCORE-1763
    getShellLogger().error(e)
    return {} as ResolveServiceExtractData
  }
}

/* istanbul ignore next Web API */
export const convertFileToBase64 = (file: File): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export const getFileInfo = async (file: File): Promise<FileAttachmentInfo> => {
  try {
    const base64File = await convertFileToBase64(file)
    return {
      name: file.name,
      type: file.type,
      // Resolve team will likely remove the extra 'data' use in the value. They will let us know when the change is done
      data: `data: '${base64File}'`,
      attachmentError: false,
    }
  } catch {
    return {
      name: file.name,
      type: file.type,
      attachmentError: true,
    }
  }
}

export const displaySubmissionSuccessSnackbar = () => {
  snackbar({
    id: 'resolve-success-snackbar',
    title: getTranslation('Service ticket submitted'),
    message: getTranslation('Status updates will be sent by email.'),
  })
}
