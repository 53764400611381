import { asTranslationKey } from './../../common/translate-helpers/i18n-utils'
/* istanbul ignore file */
export interface GoToDatePickerProps {
  readonly label: string
  readonly requireFutureDate: boolean
  readonly placeholder: string
  readonly date: string
}

export const DD_MM_YYYY_DATE_FORMAT = asTranslationKey('DD/MM/YYYY')
