import { getAccountKeyFromShellLocalStorage } from './../context/index'
import { authenticatedFetch } from '../auth'
import {
  type UserInfoQueryResponse,
  type UserFromExternalAdmin,
  type AccountInfoFromGraphQL,
  CustomGraphQlError,
} from './models'
import { environment } from '../../environments'
import type { JifApi, Principal, User } from '../context/models'
import { getPbxIdFromJiveResourceName } from '../../packages/account-switcher/account-switcher-helper'
import { getJiveApiBaseUrl } from '../../core/environment'
import { getAccountSettingsQueries } from './account-settings-queries'
import { HttpResponseError } from '../../http-utils'

export const getJifUser = async (jiveId: string) =>
  await getHttpDataResponse<JifApi>(`${getJiveApiBaseUrl()}/jif/v4/user/jiveId/${jiveId}`)

export const getPrincipal = async (userEmail: string) =>
  await getHttpDataResponse<Principal>(`${getJiveApiBaseUrl()}/v5/identity/v1/principals/${userEmail}`)

/**
 * @deprecated only used for me.entitlements which is also deprecated. Use shell.user.roles instead
 */
export const getRolesFromAccounts = (accounts?: readonly AccountInfoFromGraphQL[]) => {
  const roles: string[] = []
  accounts?.forEach(account => {
    ;(account.licenses ?? []).forEach(license => {
      roles.push(...(license.roles ?? []))
    })
  })
  return roles
}

export const getUserFromExternalAdmin = async (userKey: string) =>
  await getHttpDataResponse<UserFromExternalAdmin>(
    `${environment().iamBaseUrl}/ext-admin/rest/users/${userKey}?consistency=eventual`,
    { credentials: undefined },
  )

const getSettingsQuery = () => {
  const settingsQueries = getAccountSettingsQueries()

  if (!settingsQueries.has('phone')) {
    settingsQueries.set('phone', new Set<string>())
  }
  settingsQueries.get('phone')?.add('engagePlgOptOut')
  settingsQueries.get('phone')?.add('inboxPlgOptOut')
  settingsQueries.get('phone')?.add('textAssistantTermsOfServiceAccepted')
  settingsQueries.get('phone')?.add('textAssistantTermsOfServiceAcceptedByAdmin')
  settingsQueries.get('phone')?.add('goToAdminIntegrations')

  if (!settingsQueries.has('meeting')) {
    settingsQueries.set('meeting', new Set<string>())
  }
  settingsQueries.get('meeting')?.add('gotoAppEnabled')
  settingsQueries.get('meeting')?.add('gotoAppEntitled')
  settingsQueries.get('meeting')?.add('gotoAppProvisioned')

  return settingsQueries
}

export const createGraphQLQuery = () => {
  const settingsQuery = getSettingsQuery()
  const settings = `${Array.from(settingsQuery)
    .map(
      ([name, values]) => `${name} {
        ${Array.from(values)
          .map(value => value)
          .join('\n        ')}
      }`,
    )
    .join('\n      ')}` // Spaces for formatting purpose

  return `{
  user {
    email
    createTime
    firstName
    key
    lastName
    locale
    timeZone
    location
  }
  accounts {
    name
    key
    unifiedAdmin
    slowChannelEnabled
    partnerAccountKey
    licenses {
      accountKey
      description
      roles
      channel
      enabled
      key
      type
    }
    settings {
      ${settings}
    }
  }
}`
}

export const getUserInfo = async () => {
  const response = await authenticatedFetch(`${environment().authUrl}/me/graphql`, {
    method: 'POST',
    headers: [['Content-Type', 'application/json']],
    body: JSON.stringify({
      query: createGraphQLQuery(),
    }),
    credentials: 'omit',
  })

  const { data, errors }: UserInfoQueryResponse = await response.json()

  if (errors && errors.length > 0) {
    const { body, status, statusText } = await response

    throw new HttpResponseError({
      message: new CustomGraphQlError(errors).toString(),
      response,
      status,
      statusText,
      body,
    })
  }

  return data
}

export const getAccountKey = (userKey: string, accounts: readonly User[], pbxId?: string): string | undefined => {
  const accountMatchingPBXId = accounts.find(account => getPbxIdFromJiveResourceName(account.jrn) === pbxId)
  if (accountMatchingPBXId) {
    return accountMatchingPBXId.accountKey
  }
  const shellContextLocalStorageAccountKey = getAccountKeyFromShellLocalStorage(userKey)
  if (shellContextLocalStorageAccountKey) {
    return shellContextLocalStorageAccountKey
  }
  //if no account key is available in local storage, return first in list of accounts
  const firstAccount = accounts[0] ?? {}
  return firstAccount.accountKey
}

export const getHttpDataResponse = async <T = unknown>(input: RequestInfo, init?: RequestInit) => {
  try {
    const response = await authenticatedFetch<T>(input, init)
    return await response.json()
  } catch {
    return await ({} as Promise<T>)
  }
}
