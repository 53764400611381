import { getFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { getShellLogger } from '../../common/logger'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { LocalStorageKeys } from '../../environments'
import { getFeatureFlagValue } from '../feature-flags'
import { FeatureFlagsVariations } from '../feature-flags/models'
import type { Theme } from '../shell-namespace'
import { emitThemeChanged } from '../shell-namespace'
import { GoToAppTheme, type MSTheme } from './models'

export async function setChameleonTheme(theme: Theme) {
  const currentTheme = await getChameleonTheme()
  if (currentTheme !== theme) {
    saveChameleonTheme(theme)
    emitThemeChanged(theme)
  }
}

export function getDefaultTheme(): Theme {
  return isDarkModeEnabled() ? 'system' : 'light'
}

export function isDarkModeEnabled() {
  return getFeatureFlagValue(FeatureFlagsVariations.SHELL_ENABLE_DARKMODE)
}

export function updateChameleonThemeProvider(themeProvider: Element, theme: Theme) {
  if (theme === 'system') {
    themeProvider.setAttribute('automatic', 'true')
    themeProvider.removeAttribute('theme')
  } else {
    themeProvider.setAttribute('theme', theme)
    themeProvider.removeAttribute('automatic')
  }
}

export function saveChameleonTheme(theme: Theme) {
  const userPreferences = getShellApiInstance().userPreferences
  userPreferences?.setPreference('theme', theme)
  setToLocalStorage(LocalStorageKeys.chameleonTheme, theme)
}

export function getDefaultChameleonTheme(): Theme {
  const savedTheme = getFromLocalStorage(LocalStorageKeys.chameleonTheme) as Theme | null
  if (isDarkModeEnabled() && savedTheme) {
    return savedTheme
  } else {
    return getDefaultTheme()
  }
}

export async function getChameleonTheme(): Promise<Theme> {
  const userPreferences = getShellApiInstance().userPreferences
  let theme = isDarkModeEnabled()
    ? ((await userPreferences?.getPreference('theme')) as Theme | null)
    : getDefaultTheme()

  if (!theme) {
    theme = getDefaultTheme()
  }
  return theme
}

const resolveMSTeamsTheme = (theme: MSTheme): Theme => {
  if (theme === 'dark') {
    return GoToAppTheme.DARK
  } else if (theme === 'contrast') {
    return GoToAppTheme.HIGH_CONTRAST
  }

  return GoToAppTheme.LIGHT
}

export const subscribeToMsTeamThemeChanged = () => {
  import('@microsoft/teams-js')
    .then(({ app }) =>
      app.initialize().then(() =>
        app.getContext().then(context => {
          emitThemeChanged(resolveMSTeamsTheme(context.app.theme as MSTheme))
          app.registerOnThemeChangeHandler((data: MSTheme) => {
            emitThemeChanged(resolveMSTeamsTheme(data))
          })
        }),
      ),
    )
    .catch(err => {
      getShellLogger().error('Could not initialize msteams sdk', err)
    })
}
