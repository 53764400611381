/* istanbul ignore file */
import { Container } from '@getgo/container-client'
import type { IGotoLoggerMessage, Logger } from '@getgo/goto-logger'
import { GotoLogger, LOG_CATEGORIES } from '@getgo/goto-logger'
import { attachToLoggingService } from './logger-target-logging-service'

let rootLogger: Logger | null = null

export function getShellLogger(): Logger {
  if (!rootLogger) {
    rootLogger = createRootLogger()
  }

  return rootLogger
}

const createRootLogger = (): Logger => {
  const logger = GotoLogger.createRoot({ mincategory: 'debug' })

  // The use of Container.isNative is intentional as the Logger plugin is also available on browser
  if (Container.isNative && Container.isPluginAvailable('Logger')) {
    const containerLogger = Container.plugins.Logger.createLogger('shell')
    const rootLoggerSetContext = logger.setContext

    // overwrite the root logger's function to allow the context to be set in the Container as well
    logger.setContext = (property, value) => {
      rootLoggerSetContext.apply(logger, [property, value])
      Container.plugins.Logger.setContext?.(property, value)
    }

    // the logger provided by the LoggerPlugin of container-client does not support advanced logger features
    // such as creating child loggers, which is why we have to manually forward the logs and attach the
    // origin of the logger if it was a child logger of the shell (otherwise all child logs would be
    // registered as `shell`). The downside of this approach is that we potentially log `origin` twice, once
    // from shell and once from the Goto container
    //
    // to be solved in (https://jira.ops.expertcity.com/browse/REDEX-436)
    LOG_CATEGORIES.forEach(category => {
      const logForwarderHandler = (message: IGotoLoggerMessage) => {
        const logMessageContent = [...message.data]
        const { origin } = message
        if (origin !== '') {
          logMessageContent.push({ origin })
        }
        containerLogger[category](...logMessageContent)
      }
      logger.subscribe(category, logForwarderHandler)
    })
  } else {
    // if on web, shell will be responsible for remote logging
    attachToLoggingService(logger)
  }

  return logger
}
