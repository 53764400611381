import { approvedShellBannerIds } from './../../../services/display-notification/models'
import { html } from 'lit-element'
import { nothing } from 'lit-html'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { ifDefined } from 'lit-html/directives/if-defined'
import type { BannerComponent } from '@getgo/chameleon-web'
import { getEventBus } from '../../../services/namespaces/event-bus'
import type {
  BannerNotification,
  DisplayNotificationEvents,
  BannerNotificationWithId,
} from '../../../services/display-notification'
import { DisplayNotificationsNamespace } from '../../../services/display-notification'
import { isMacContainer } from '../../../services/container/helpers'
import { AbstractNotificationLitElement } from '../model'

import styles from './banner-notification.styles.scss'

interface ParentTemplateData {
  readonly variant: Required<BannerComponent['variant']> | 'neutral'
  readonly closable?: boolean
  readonly description: ReturnType<typeof html>
}

const template = (
  data: ParentTemplateData,
  actionLinkElement: ReturnType<typeof html>,
  onClose: (event?: UIEvent) => unknown,
) =>
  html`<!-- GoToBannerNotification: Begin -->
    <!-- Fix issue with lit-plugin https://jira.ops.expertcity.com/browse/SCORE-944 -->
    <chameleon-banner variant=${(data.variant ?? 'neutral') as any} ?closable=${data.closable} @close=${onClose}>
      ${actionLinkElement}
      <p>${data.description}</p>
    </chameleon-banner>
    <!-- GoToBannerNotification: End -->`

export class GoToBannerNotification extends AbstractNotificationLitElement<BannerNotification> {
  static readonly tagName = 'goto-banner-notification'
  static styles = styles

  connectedCallback() {
    super.connectedCallback()
    if (isMacContainer()) {
      /**
       * Make the banner draggable if we are on Mac OS Container since it is closest to the top edge of the Window,
       * where users are accustomed to dragging.
       */
      this.classList.add('draggable')
    }
  }

  protected setupEventHandlers(): void {
    const eventBus = getEventBus()
    const { banner, removeBanner } = eventBus.subscribeTo<
      typeof DisplayNotificationsNamespace,
      typeof DisplayNotificationEvents
    >(DisplayNotificationsNamespace)

    const approvedShellBanner = (notification: BannerNotification) => {
      if (notification.id) {
        return approvedShellBannerIds.includes(notification.id)
      }
      return false
    }

    const onAddNotification = (notification: BannerNotification) => {
      const duplicateNotification = this.notifications.some(
        existingNotification => existingNotification.id === notification.id,
      )
      if (!duplicateNotification && approvedShellBanner(notification)) {
        this.addNotification(notification)
      }
    }

    banner.on(onAddNotification)
    removeBanner.on(this.removeNotificationThroughEmitter)

    this.unsubscribeFunctions.push(() => {
      banner.removeListener(onAddNotification)
      removeBanner.removeListener(this.removeNotificationThroughEmitter)
    })
  }

  protected removeNotificationThroughEmitter = (data: BannerNotification): void => {
    const index = this.notifications.findIndex(notification => notification.id === data.id)
    if (index >= 0) {
      this.notifications.splice(index, 1)
      this.requestUpdate()
    }
  }

  protected removeNotification(): void {
    // We only and always remove the first
    if (this.notifications.length > 0) {
      this.notifications.splice(0, 1)
      this.requestUpdate()
    }
  }

  render() {
    // Display the most important banner first, determined by hierarchy of ApprovedShellBannerIds

    const notificationHierarchyIndex = (notification: BannerNotificationWithId) =>
      approvedShellBannerIds.indexOf(notification.id)

    const compareNotificationHierarchy = (a: BannerNotificationWithId, b: BannerNotificationWithId) =>
      notificationHierarchyIndex(a) - notificationHierarchyIndex(b)

    const notification = this.notifications.sort(compareNotificationHierarchy)[0]

    if (!notification) {
      this.classList.remove('show')
      return nothing
    }
    this.classList.add('show')
    const { description, icon, variant, link } = notification

    const renderIcon = icon ? html` <chameleon-svg slot="icon"> ${unsafeSVG(icon)} </chameleon-svg> ` : nothing

    const renderLinkElement = link
      ? html`<chameleon-link
          slot="action"
          target="_blank"
          href=${ifDefined(link.href)}
          @click=${link?.onClick ?? nothing}
          >${link.text}</chameleon-link
        >`
      : nothing

    const iconAndLink = html`${renderIcon} ${renderLinkElement}`

    const onClose = (/*event: UIEvent*/) => {
      this.removeNotification()
      notification?.onClose?.()
    }

    const data: ParentTemplateData = {
      closable: !!notification.closable,
      variant: variant ?? 'neutral',
      description: html`${description}`,
    }

    return html`${template(data, iconAndLink, onClose)}`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-banner-notification': GoToBannerNotification
  }
}
