/* istanbul ignore file */

import { getShellApiInstance } from '../common/shell-api-helpers'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/shell-sw.js')
      .then(registration => {
        const channel = new BroadcastChannel('shell-serviceworker-channel')

        const postAccessToken = () => {
          const serviceWorker = registration.active ?? registration.waiting ?? registration.installing
          const token = getShellApiInstance().auth.getToken()?.access_token ?? ''

          serviceWorker?.postMessage({ token, message: 'post-accesstoken' })
        }

        channel.addEventListener('message', event => {
          if (event.data === 'request-accesstoken') {
            postAccessToken()
          }
        })
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError)
      })
  })
}
