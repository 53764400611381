import { getBrandValue } from '../services/branding/brand-helpers'
import { BrandVariationKeys } from '../services/feature-flags/models'

export const setTitleElementFromBranding = () => {
  const titleElement = document.querySelector<HTMLTitleElement>('title')
  if (!titleElement) {
    return
  }
  titleElement.innerText = getBrandValue(BrandVariationKeys.JIVE_BRAND_NAME)
}
