export interface PbxFeatureFlagService {
  readonly context: string
  readonly feature: PbxAvailableFeatureFlags
  readonly isDefault: boolean
  readonly setting: PbxFeatureFlagStatus
}

export enum PbxAvailableFeatureFlags {
  MEETINGS = 'jiveweb.meetings',
  STANDARD = 'jiveweb.standard',
  MESSAGING = 'jiveweb.messaging',
  RESOLVE = 'admin.helpdesk-management.dashboard',
}

export enum PbxFeatureFlagStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

/**
 * Represents a feature flag configuration for a PBX.
 */
export interface PbxFeatureFlags {
  readonly [key: string]: PbxFeatureFlagStatus.ENABLED | PbxFeatureFlagStatus.DISABLED
}

/**
 * Represents a collection of PBX feature flags grouped by PBX.
 */
export interface PbxFeatureFlagsCollection {
  readonly [key: string]: PbxFeatureFlags
}

/**
 * Represents the Phone System API.
 */
export interface PhoneSystemAPI {
  /**
   * Retrieves the feature flags for a specific PBX.
   * @param pbxId - The ID of the PBX.
   * @returns A promise that resolves to the PbxFeatureFlags object.
   */
  getPbxFeatureFlags: (pbxId: string) => Promise<PbxFeatureFlags | undefined>

  /**
   * Retrieves the value of a specific feature flag for a PBX.
   * @param pbxId - The ID of the PBX.
   * @param featureFlag - The name of the feature flag.
   * @returns A promise that resolves to the PbxFeatureFlagStatus object.
   */
  getPbxFeatureFlagValue: (pbxId: string, featureFlag: string) => Promise<PbxFeatureFlagStatus | undefined>
}
