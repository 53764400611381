import { getStoredCurrentAccount } from '../../common/user-helpers'
import { getEventBus, eventEmitter } from './../namespaces/event-bus'
import { type AccountAndPbxInfo, type AccountSwitchCompletedPayload, type AccountSwitchPayload } from './models'

export const AccountNamespace = 'account'

export const AccountEvents = {
  accountSwitchBegin: eventEmitter<AccountAndPbxInfo>(),
  accountSwitch: eventEmitter<AccountSwitchPayload>(),
  accountSwitchCompleted: eventEmitter<AccountSwitchCompletedPayload>(),
}

const { emit } = getEventBus().register<typeof AccountEvents>(AccountNamespace, AccountEvents)

/**
 * switchAccount updates the user's selected account for use across the entire application
 * @param accountSwitchPayload is the payload of the account switch event sent to jiveweb via the eventbus, including the account id and the account name
 * @param accountSwitchCompletedPayload is the payload of the account switch sent to GoTo app via the eventbus, including pbx id and local storage key
 */
export const switchAccount = (accountAndPbxInfo: AccountAndPbxInfo | null): void => {
  if (accountAndPbxInfo) {
    const currentAccount = getStoredCurrentAccount(accountAndPbxInfo.externalUserKey)

    if (currentAccount.accountKey !== accountAndPbxInfo.accountKey) {
      // make sure to reload only if the account changes
      emit.accountSwitchBegin(accountAndPbxInfo)
      emit.accountSwitch({ accountKey: accountAndPbxInfo.accountKey, accountName: accountAndPbxInfo.accountName })
      emit.accountSwitchCompleted({
        accountKey: accountAndPbxInfo.accountKey,
        pbxId: accountAndPbxInfo.pbxId,
        externalUserKey: accountAndPbxInfo.externalUserKey,
      })
    }
  }
}
