import mixpanel from 'mixpanel-browser'
import { environment } from '../../../environments'
import type { MixPanelParams, MixPanelRegister } from './models'

export const createMixpanel = (externalUserkey?: string, mixPanelParams?: MixPanelParams) => {
  const { mixpanelToken, jiveApiBaseUrl } = environment()

  // The following configuration is required to make mixpanel work with the edge server proxy
  // the idea is to use the edge server as a proxy to mixpanel to avoid being blocked by ad blockers.
  // api calls made to /act are proxied to mixpanel.com/track
  // api calls made to /connect are proxied to mixpanel.com/engage

  const MIXPANEL_PROXY_DOMAIN = `${jiveApiBaseUrl}/analytics/v1`

  mixpanel.init(mixpanelToken, {
    api_host: MIXPANEL_PROXY_DOMAIN,
    api_routes: {
      track: `act/`,
      engage: `connect/`,
    },
  })

  mixpanel.set_config({
    cross_subdomain_cookie: false,
    disable_persistence: true,
    ip: false,
    ignore_dnt: true,
  })
  mixpanel.identify(externalUserkey)
  const { Entitlements = [], ...rest } = mixPanelParams ?? {}
  const registerData: MixPanelRegister = {
    businessUnit: 'goto',
    application: 'goto-app',
    Entitlements,
    ...rest,
  }
  mixpanel.register(registerData)
  mixpanel?.people?.set({ Entitlements, ExternalUserkey: externalUserkey })
}
