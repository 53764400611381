import { reloadPage, setTimeout, getDocument, popFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { onWindowUnload } from '../../common/helpers/window'
import { getShellLogger } from '../../common/logger'
import { isBackendReachable } from '../../common/networkUtils'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { canReloadApplication } from '../auth/can-reload'
import { getDesktopVersion } from '../container/helpers'
import { getFeatureFlagValue } from '../feature-flags'
import { FeatureFlagsVariations } from '../feature-flags/models'
import type { UpdateEvents } from '../update'
import { UpdateNamespace } from '../update'
import { getAutoUpdate, quitAndInstall } from '../update/helpers'

// We store a small structure in local storage to help us keep track of the app update attempt (a page reload or a
// container restart to update).
type AppUpdateInfoAction = 'pageReload' | 'containerRestart'
interface AppUpdateInfo {
  readonly action: AppUpdateInfoAction
  readonly shellVersion: string
  readonly containerVersion?: string
  readonly startTime: number
}
const APP_UPDATE_INFO_KEY = 'shell-app-update-start-time'

const REFRESH_INTERVAL_IN_HOURS = 24
const MINUTES_IN_AN_HOUR = 60
const SECONDS_IN_A_MINUTE = 60
const MILLISECONDS_IN_A_SECOND = 1000
const DEFAULT_INTERVAL_MS =
  REFRESH_INTERVAL_IN_HOURS * MINUTES_IN_AN_HOUR * SECONDS_IN_A_MINUTE * MILLISECONDS_IN_A_SECOND

let containerUpdateIsAvailable = false
let timeoutID: number | null = null

// Exported for testing purposes only
export const clearUpdateTimer = () => {
  if (timeoutID) {
    clearTimeout(timeoutID)
    timeoutID = null
  }
}

const visibilityChangeHandle = async () => {
  if (getDocument().visibilityState === 'hidden' && (await isBackendReachable())) {
    getDocument().removeEventListener('visibilitychange', visibilityChangeHandle)
    reloadIfAppIsIdle()
  }
}

const addVisibilityChangeListener = () => {
  getDocument().addEventListener('visibilitychange', visibilityChangeHandle)
}

const createTimer = (interval: number) => {
  clearUpdateTimer()
  timeoutID = setTimeout(async () => {
    if (getDocument().visibilityState === 'hidden') {
      const isBackendReachableValue = await isBackendReachable()
      if (isBackendReachableValue) {
        reloadIfAppIsIdle()
      } else {
        visibilityChangeHandle()
      }
    } else {
      addVisibilityChangeListener()
    }
  }, interval)
}

export const setupAppUpdateTimer = () => {
  listenToUpdateAvailableEvent()
  popAppUpdateInfoAndLog()
  if (!timeoutID) {
    createTimer(DEFAULT_INTERVAL_MS)
  }
}

const reloadIfAppIsIdle = async () => {
  const canReload = await canReloadApplication()
  if (canReload) {
    const action: AppUpdateInfoAction = containerUpdateIsAvailable ? 'containerRestart' : 'pageReload'
    await setAppUpdateInfoAndLog(action)
    if (containerUpdateIsAvailable) {
      quitAndInstall()
    } else {
      reloadPage()
    }
  }
}

const listenToUpdateAvailableEvent = () => {
  const { eventBus } = getShellApiInstance()
  const { updateAvailable } = eventBus.subscribeTo<typeof UpdateNamespace, typeof UpdateEvents>(UpdateNamespace)

  const updateAvailableHandler = () => {
    // If a container update is available, we check a feature flag that can override the timer interval. The intent is
    // that the feature flag would reduce the interval (from the default 24h), not increase it.
    containerUpdateIsAvailable = true
    const refreshIntervalOverride = getFeatureFlagValue(FeatureFlagsVariations.SHELL_APP_UPDATE_INTERVAL_OVERRIDE)
    if (refreshIntervalOverride) {
      // Re-create the timer with the interval from the feature flag.
      createTimer(Number(refreshIntervalOverride))
    }
  }

  updateAvailable.on(updateAvailableHandler)

  onWindowUnload(() => {
    updateAvailable.removeListener(updateAvailableHandler)
  })

  const update = getAutoUpdate()
  if (update) {
    updateAvailableHandler()
  }
}

const popAppUpdateInfoAndLog = async () => {
  const appUpdateInfo = popFromLocalStorage(APP_UPDATE_INFO_KEY)
  if (!appUpdateInfo) {
    // This page load is not due to the app update logic. Nothing to log.
    return
  }

  try {
    const beforeInfo = JSON.parse(appUpdateInfo) as AppUpdateInfo
    const currentShellVersion = getShellApiInstance().meta.shellVersion
    const currentContainerVersion = await getDesktopVersion()
    const shellUpdated = currentShellVersion !== beforeInfo.shellVersion
    const containerUpdated = currentContainerVersion !== beforeInfo.containerVersion
    const durationMs = Date.now() - beforeInfo.startTime
    getShellLogger().log(
      `appUpdate type=afterAction action=${beforeInfo.action} ` +
        `shellVersion=${currentShellVersion} shellVersionBefore=${beforeInfo.shellVersion} shellUpdated=${shellUpdated} ` +
        `containerVersion=${currentContainerVersion} containerVersionBefore=${beforeInfo.containerVersion} containerUpdated=${containerUpdated} ` +
        `durationMs=${durationMs}`,
    )
  } catch {
    getShellLogger().error('appUpdate type=error reason=deserializingAppUpdateInfo')
  }
}

const setAppUpdateInfoAndLog = async (action: AppUpdateInfoAction) => {
  const appUpdateInfo: AppUpdateInfo = {
    action,
    shellVersion: getShellApiInstance().meta.shellVersion,
    startTime: Date.now(),
    containerVersion: await getDesktopVersion(),
  }
  setToLocalStorage(APP_UPDATE_INFO_KEY, JSON.stringify(appUpdateInfo))
  getShellLogger().log(
    `appUpdate type=beforeAction action=${action} ` +
      `shellVersion=${appUpdateInfo.shellVersion} ` +
      `containerVersion=${appUpdateInfo.containerVersion}`,
  )
}
