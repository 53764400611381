import type { UserPreferencesAPI } from './models'

export class UserPreferencesService implements UserPreferencesAPI {
  constructor(private readonly userPreferencesConnector: UserPreferencesAPI) { }

  getPreferences = async (): Promise<any> => await this.userPreferencesConnector.getPreferences()

  getPreference = async <T, R>(path: string, defaultValue?: T): Promise<R> =>
    await this.userPreferencesConnector.getPreference(path, defaultValue)

  setPreference = async <T>(path: string, data: T): Promise<void> =>
    await this.userPreferencesConnector.setPreference(path, data)

  deletePreference = async (path: string): Promise<void> => await this.userPreferencesConnector.deletePreference(path)
}
