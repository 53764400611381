import type { ExtensionConfig } from '@goto/shell-common'

const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-dashboard',
  url: new URL('/experiences/cc-dashboard/@ucc/cc-dashboard-extension.mjs', location.origin),
  esModule: true,
  prerequisites: {
    ccFlags: 'cc-supervisor.wallboard',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

export default extensionConfig
