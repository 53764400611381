// TODO: let's revisit that
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { isMainWindow } from '../container/windows-management'
import { type EventBusExternalInterfaceAdapterPrivate } from '../external-interface/types/event-bus-adapter-strategy'
import { NodeEventEmitter } from './models'
import type {
  Listener,
  EventConsumer,
  EventEmitter,
  EventConsumers,
  EventEmitters,
  EventConsumerFunctions,
} from './models'

type Subscription = (value: unknown) => void

const EVENT_BUS_MAX_LISTENERS = 40

const createListener =
  <EventPayload extends object>(
    emitterPromise: Promise<NodeEventEmitter>,
    fnName: EventConsumerFunctions<EventPayload>,
  ) =>
  (eventName: string, listener: Listener<EventPayload>) => {
    emitterPromise.then<void>(emitter => {
      emitter[fnName](eventName, listener)
    })
    return
  }
const createListenerWithEventName =
  <EventPayload extends object>(
    emitterPromise: Promise<NodeEventEmitter>,
    fnName: EventConsumerFunctions<EventPayload>,
    eventName: string,
  ) =>
  (listener: Listener<EventPayload>) =>
    createListener<EventPayload>(emitterPromise, fnName)(eventName, listener)

const createRegisteredEventConsumer = <EventPayload extends object>(
  emitterPromise: Promise<NodeEventEmitter>,
  eventName: string,
): EventConsumer<EventPayload> => ({
  on: createListenerWithEventName<EventPayload>(emitterPromise, 'on', eventName),
  once: createListenerWithEventName<EventPayload>(emitterPromise, 'once', eventName),
  addListener: createListenerWithEventName<EventPayload>(emitterPromise, 'addListener', eventName),
  removeListener: createListenerWithEventName<EventPayload>(emitterPromise, 'removeListener', eventName),
  prependListener: createListenerWithEventName<EventPayload>(emitterPromise, 'prependListener', eventName),
  prependOnceListener: createListenerWithEventName<EventPayload>(emitterPromise, 'prependOnceListener', eventName),
})

const convertToNodeEventEmitter = <EventEmitter extends EventConsumers & NodeEventEmitter>(
  emitterPromise: Promise<EventEmitter>,
): EventEmitter => {
  const eventEmitter = {
    on: createListener(emitterPromise, 'on'),
    once: createListener(emitterPromise, 'once'),
    addListener: createListener(emitterPromise, 'addListener'),
    removeListener: createListener(emitterPromise, 'removeListener'),
    prependListener: createListener(emitterPromise, 'prependListener'),
    prependOnceListener: createListener(emitterPromise, 'prependOnceListener'),
  }

  const proxyHandler: ProxyHandler<EventEmitter> = {
    get: (target, eventName: string) => {
      if (eventName in target) {
        return target[eventName]
      }
      return createRegisteredEventConsumer(emitterPromise, eventName)
    },
  }
  return new Proxy(eventEmitter, proxyHandler) as EventEmitter
}

export class EventBusImpl {
  private readonly eventBusRegistry = new Map<string, EventEmitters & NodeEventEmitter>()
  private readonly promiseResolveRegistry = new Map<string, Subscription[]>()
  private eventAdapter?: EventBusExternalInterfaceAdapterPrivate

  setEventAdapter(eventAdapter: EventBusExternalInterfaceAdapterPrivate) {
    this.eventAdapter = eventAdapter
  }

  removeEventAdapter(): void {
    this.eventAdapter = undefined
  }

  emitEvent<EventPayload extends object>(
    namespace: string,
    eventName: string,
    eventPayload: EventPayload,
    callerId?: string,
  ): void {
    const emitter = this.getEmitter(namespace)
    if (emitter && emitter.emit) {
      emitter.emit[eventName](eventPayload, callerId)
    }
  }

  register<EventEmittersDictionary extends EventEmitters>(namespace: string, events: EventEmittersDictionary) {
    if (!this.hasEmitter(namespace)) {
      this.createEmitter<EventEmittersDictionary>(namespace, events)
      this.processPendingPromises(namespace)
    }

    return this.getEmitter<EventEmittersDictionary>(namespace)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  subscribeTo<Namespace extends string = string, EventConsumerDictionary extends EventConsumers = {}>(
    namespace: Namespace,
  ) {
    if (this.hasEmitter(namespace)) {
      const emitter = this.getEmitter(namespace)
      return convertToNodeEventEmitter<NodeEventEmitter & EventConsumerDictionary>(
        Promise.resolve(emitter.emit as unknown as NodeEventEmitter & EventConsumerDictionary),
      )
    }

    const promise = new Promise<NodeEventEmitter & EventConsumerDictionary>(resolve => {
      const promises = this.promiseResolveRegistry.get(namespace)
      if (promises) {
        promises.push(resolve as Subscription)
      } else {
        this.promiseResolveRegistry.set(namespace, [resolve as Subscription])
      }
    })

    return convertToNodeEventEmitter<NodeEventEmitter & EventConsumerDictionary>(promise)
  }

  private processPendingPromises(namespace: string) {
    const emitter = this.getEmitter(namespace)

    const promises = this.promiseResolveRegistry.get(namespace)
    if (promises) {
      promises.forEach(promise => promise(convertToNodeEventEmitter(Promise.resolve(emitter.emit))))
      this.promiseResolveRegistry.delete(namespace)
    }
  }

  private createEmitter<EventEmittersDictionary extends EventEmitters>(
    namespace: string,
    events: EventEmittersDictionary,
  ) {
    const emitter = Object.keys(events).reduce(
      (emitter, eventName) => {
        Object.defineProperty(emitter, eventName, {
          value: this.createRegisteredEventEmitter(Promise.resolve(emitter), namespace, eventName),
        })
        emitter.setMaxListeners(EVENT_BUS_MAX_LISTENERS)
        return emitter
      },
      new NodeEventEmitter() as NodeEventEmitter & EventEmittersDictionary,
    )

    this.eventBusRegistry.set(namespace, emitter)
  }

  private hasEmitter(namespace: string) {
    return !!this.eventBusRegistry.get(namespace)
  }

  private getEmitter<EventEmittersDictionary extends EventEmitters>(namespace: string) {
    return {
      emit: this.eventBusRegistry.get(namespace)! as NodeEventEmitter & EventEmittersDictionary,
      // eslint-disable-next-line @typescript-eslint/no-empty-object-type
      subscribeTo: <Namespace extends string = string, EventConsumerDictionary extends EventConsumers = {}>(
        namespace: Namespace,
      ) => this.subscribeTo<Namespace, EventConsumerDictionary>(namespace),
    }
  }

  private createRegisteredEventEmitter<EventPayload extends object>(
    emitterPromise: Promise<NodeEventEmitter>,
    namespace: string,
    eventName: string,
  ): EventEmitter<EventPayload> {
    const emitFn = (args: EventPayload, callerId?: string) =>
      emitterPromise.then(emitter => {
        if (this.eventAdapter && !callerId) {
          this.eventAdapter.handleEvent(namespace, eventName, args)
        }
        return emitter.emit(eventName, args, callerId)
      })
    return Object.assign(emitFn, {
      eventName,
      emit: emitFn,
      ...createRegisteredEventConsumer<EventPayload>(emitterPromise, eventName),
    })
  }
}

/**
 * EventBus
 */
const eventNotRegistered: <T = void>() => T = () => {
  throw 'Event is not registered in the Event Bus'
}

export const eventEmitter = <EventPayload>(): EventEmitter<EventPayload> =>
  Object.assign(async () => eventNotRegistered<boolean>(), {
    emit: async () => eventNotRegistered<boolean>(),
    on: eventNotRegistered,
    once: eventNotRegistered,
    addListener: eventNotRegistered,
    removeListener: eventNotRegistered,
    prependListener: eventNotRegistered,
    prependOnceListener: eventNotRegistered,
    eventName: 'Not Registered',
  })

export type EventBus = Omit<EventBusImpl, 'emitEvent' | 'setEventAdapter' | 'removeEventAdapter'>
export type EventBusPrivate = EventBusImpl

/**
 * The EventBus can be used to register Events from an Experience / Element
 * as well as to be able to react to events emitted by other Experiences or Elements.
 *
 * The EventBus uses the Node 'events' library but hides the interface and instead
 * uses syntactic sugar to easily refer to events that were declared by other Experiences / Elements.
 */
let eventBus!: EventBus

export const getEventBus = (): EventBus => {
  if (isMainWindow()) {
    if (!eventBus) {
      eventBus = new EventBusImpl()
    }
    return eventBus
  }

  return getShellApiInstance().eventBus
}
