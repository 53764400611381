import { TopbarService } from './topbar-service'
import type { TopbarAPI } from './models'
let _topbarAPI: TopbarAPI
let _topbarService: TopbarService
export const getTopbarAPI = (): TopbarAPI => {
  if (!_topbarAPI) {
    _topbarAPI = createTopbarAPI()
  }
  return _topbarAPI
}
const createTopbarAPI = (): TopbarAPI => {
  const topbarService = getTopbarService()
  const topbarAPI: TopbarAPI = {
    show() {
      return topbarService.show()
    },
    hide() {
      return topbarService.hide()
    },
    isVisible() {
      return topbarService.isVisible()
    },
  }
  return Object.freeze(topbarAPI)
}
export const getTopbarService = (): TopbarService => {
  if (!_topbarService) {
    _topbarService = new TopbarService()
  }
  return _topbarService
}
