import { badgeToMapKey, isBadgeNumberMatchingFilter } from './helpers'
import type {
  BadgeNumberEventListener,
  BadgeNumberEventType,
  BadgeNumberFilter,
  BadgeNumberIdentifier,
  BadgeNumberInfo,
} from './models'
import { BadgeNumberEvent } from './models'
import type { BadgeNumbersService } from './public-api'

export class BadgeNumbersServiceImpl implements BadgeNumbersService {
  private badges: Map<string, BadgeNumberInfo> = new Map()
  private listeners: Map<BadgeNumberEventType, Map<BadgeNumberEventListener, BadgeNumberFilter[]>> = new Map()

  private doNotifyBadgeNumber<BNI extends BadgeNumberIdentifier>(
    type: BadgeNumberEventType,
    badgeNumberIdentifier: BNI,
  ) {
    const listeners = this.getListenersByType(type)
    listeners.forEach((filters, listener) => {
      if (!filters.length || filters.some(filter => isBadgeNumberMatchingFilter(badgeNumberIdentifier, filter))) {
        listener(new BadgeNumberEvent(type, this.getBadgeNumberValue(...filters)))
      }
    })
  }

  setBadgeNumber<BNI extends BadgeNumberInfo>(badgeNumberInfo: BNI): void {
    const key = badgeToMapKey(badgeNumberInfo)
    this.badges.set(key, badgeNumberInfo)
    this.doNotifyBadgeNumber('change', badgeNumberInfo)
  }

  removeBadgeNumber<BNI extends BadgeNumberIdentifier>(badgeNumberIdentifier: BNI): void {
    const key = badgeToMapKey(badgeNumberIdentifier)
    if (this.badges.has(key)) {
      this.badges.delete(key)
      this.doNotifyBadgeNumber('change', badgeNumberIdentifier)
    }
  }

  getBadgeNumberValue(...filters: readonly BadgeNumberFilter[]): number {
    return Array.from(this.badges.values()).reduce((result, current) => {
      if (!filters.length || filters.some(filter => isBadgeNumberMatchingFilter(current, filter))) {
        return result + current.value
      }
      return result
    }, 0)
  }

  private getListenersByType(type: BadgeNumberEventType): Map<BadgeNumberEventListener, BadgeNumberFilter[]> {
    let result = this.listeners.get(type)
    if (!result) {
      result = new Map()
      this.listeners.set(type, result)
    }
    return result
  }

  addEventListener(
    type: BadgeNumberEventType,
    listener: BadgeNumberEventListener,
    ...filters: BadgeNumberFilter[]
  ): void {
    const listeners = this.getListenersByType(type)
    listeners.set(listener, filters)
  }

  removeEventListener(type: BadgeNumberEventType, listener: BadgeNumberEventListener): void {
    const listeners = this.getListenersByType(type)
    if (listeners.has(listener)) {
      listeners.delete(listener)
    }
  }
}
