import type { ExtensionConfig } from '@goto/shell-common';
import { getFeatureFlag } from '../helpers/shell-helpers'

const contactCenterAgentBaseUrl = '/experiences/cc-agent';

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-agent/extension',
  get url() {
    // Specifying version is only used as exception for a quick rollback for instance, and not to manage deployement
    // TODO after release of cc-agent, update the default version to 'latest'
    const agentVersion = getFeatureFlag('cc-agent-version', null)
    return new URL(
      agentVersion
        ? `${contactCenterAgentBaseUrl}/${agentVersion}/extension.js`
        : `${contactCenterAgentBaseUrl}/extension.js`,
      location.origin,
    )
  },
  prerequisites: {
    ccFlags: 'cc-agent.portal',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
