import { getExtensionsManager } from '../../extensions/extensions-manager'
import { handleDefaultRouteRedirection } from '../../services/router'
import type { ShellHook } from './models'
import { getNavigationItems } from '../../extensions/utils'
import { type NavigationItem } from '@goto/shell-common'

export const initializeDefaultRoute: ShellHook = async shell => {
  const navigationItems = getExtensionsManager()
    .getExtensions()
    .reduce(
      (navigationItems, extension) => navigationItems.concat(getNavigationItems(extension)),
      [] as NavigationItem[],
    )
  const sortedNavigationItems = sortNavigationItems(navigationItems, shell.getNavigationOrder())

  await handleDefaultRouteRedirection(sortedNavigationItems)
}

const sortNavigationItems = (items: NavigationItem[], navigationOrder: readonly string[]) => {
  const unknownItemIndex = navigationOrder.length
  const compareItems = (item1: NavigationItem, item2: NavigationItem): number => {
    let index1 = navigationOrder.indexOf(item1.id)
    index1 = index1 === -1 ? unknownItemIndex : index1
    let index2 = navigationOrder.indexOf(item2.id)
    index2 = index2 === -1 ? unknownItemIndex : index2
    return index1 - index2
  }
  return items.length > 1 ? items.sort(compareItems) : items
}
