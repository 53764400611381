import type { Contact } from '@goto/contacts-definitions'

const getStringifiedNameAndEmail = (contact: Contact) => {
  const { name = {}, emailAddresses = [] } = contact
  const { firstName, lastName } = name
  const { email = '' } = emailAddresses[0] || {}

  return JSON.stringify({
    email: email.toLocaleLowerCase(),
    name: [firstName, lastName].filter((value): value is string => Boolean(value)).join(' '),
  })
}

/**
 * Takes an array of `Contact` and returns a deduplicated array of `Contact`.
 *
 * `Contact` are matched with their stringified first name, last name and first email.
 *
 * This behavior should replicate as much as possible the legacy deduplication on jiveweb:
 * https://github.com/jive/jiveweb/blob/master/packages/gtc/src/webworkers/localData.js
 *
 * NB: `Contact` data is squashed by the next matching `Contact` in the array (except for phone numbers which are meged) ie:
 * `Contact` at index 0 will be squashed by the the matching `Contact` at index 4 and so on.
 */
export const deduplicateContacts = (contacts: readonly Contact[]): readonly Contact[] => {
  const mergedContacts = new Map<string, Contact>()

  contacts.forEach(contact => {
    const key = getStringifiedNameAndEmail(contact)

    const previous = mergedContacts.get(key)

    const { phoneNumbers: previousPhoneNumbers = [] } = previous ?? {}
    const { phoneNumbers: newPhoneNumbers = [] } = contact

    /**
     * The intention in jiveweb is to return a new array with unique phone numbers only.
     * However the function doesn't actually do that and it just returns a merged array with duplicates.
     * https://github.com/jive/jiveweb/blob/0ac3904854fc555b073aae9bde8c78bbfc577280/packages/gtc/src/webworkers/localData.js#L207
     */
    const mergedPhoneNumbers = [...previousPhoneNumbers, ...newPhoneNumbers]
    const mergeContact = { ...(previous ?? {}), ...contact, phoneNumbers: mergedPhoneNumbers }

    mergedContacts.set(key, mergeContact)
  })

  return [...mergedContacts.values()]
}
