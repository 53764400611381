import type { retryOnOptions } from '../../common/fetch-utils'
import type { RTCError } from './models'

export class Timer {
  private start: number = Date.now()
  private id: ReturnType<typeof setTimeout> | null = null

  static create(callback: () => void, _delay: number) {
    return new Timer(callback, _delay)
  }

  constructor(
    private readonly callback: () => void,
    private delay: number,
  ) {
    this.resume()
  }

  pause() {
    this.stop()
    this.delay -= Date.now() - this.start
  }

  resume() {
    this.start = Date.now()
    this.stop()
    this.id = setTimeout(this.callback, this.delay)
  }

  stop() {
    if (this.id) {
      clearTimeout(this.id)
      this.id = null
    }
  }
}

export class Interval {
  private id: ReturnType<typeof setInterval> | null = null

  constructor(
    private readonly callback: () => void,
    private delay: number,
  ) {
    this.reset()
  }

  pause() {
    this.stop()
  }

  reset() {
    this.stop()
    this.id = setInterval(this.callback, this.delay)
  }

  stop() {
    if (this.id) {
      clearInterval(this.id)
      this.id = null
    }
  }
}

export const shouldRetryRefreshChannel = async (options: retryOnOptions<Response>) => {
  const { error, value } = options
  const { status } = value ?? {}

  if (status === 404 || status === 503) {
    return false
  }
  if (error) {
    return true
  }

  return status ? status >= 400 : false
}

const sampleRTCerror: RTCError = {
  errorCode: 'dang',
  message: 'wow',
}

export const isRTCerror = (maybeRTCerror: unknown): maybeRTCerror is RTCError =>
  typeof maybeRTCerror === 'object' &&
  maybeRTCerror !== null &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries(sampleRTCerror).every(([key, value]) => typeof value === typeof (maybeRTCerror as any)[key])
