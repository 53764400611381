import { DialogService } from './dialog-service'
import type { DialogAPI } from './models'

let _dialogAPI: ReturnType<typeof getDialogAPI>
let _dialogService: DialogService

export const getDialogAPI = (): DialogAPI => {
  if (!_dialogAPI) {
    _dialogAPI = createDialogAPI()
  }
  return _dialogAPI
}

const createDialogAPI = (): DialogAPI => {
  const dialogService = getDialogService()
  const dialogAPI: DialogAPI = {
    open(element) {
      return dialogService.open(element)
    },
    close() {
      return dialogService.close()
    },
  }
  return Object.freeze(dialogAPI)
}

export const getDialogService = (): DialogService => {
  if (!_dialogService) {
    _dialogService = new DialogService()
  }
  return _dialogService
}
