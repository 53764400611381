import { PrerequisiteRuleTypes, type ExtensionConfig } from '@goto/shell-common'
import { getOOSArtifactUrl } from '../helpers/experience-helpers'
import { getFeatureFlag, getLaunchDarklyFlag } from '../helpers/shell-helpers'
import { LaunchDarklyVariations, UnleashFlags } from '../models/feature-flag.models'
import { createIntegrationConfigIfFlagEnabled } from '../helpers/integration-helpers'

const integrationFlagSuffix = 'oos-training'

const getExtensionVersion = () => {
  const useUnleash = getFeatureFlag(UnleashFlags.OOS_USE_UNLEASH, false)

  return useUnleash
    ? getFeatureFlag(UnleashFlags.OOS_TRAINING_VERSION, 'release')
    : getLaunchDarklyFlag(LaunchDarklyVariations.OOS_TRAINING_VERSION)
}

const extensionConfig: ExtensionConfig = {
  id: '@goto/oos-training-app',
  get url() {
    const version = getExtensionVersion()
    return new URL(getOOSArtifactUrl('training-extension', version))
  },
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: 'g2t',
    [PrerequisiteRuleTypes.ACCOUNT_SETTINGS]: 'training.isTrainingGoToAppEnabled',
  },
  get integrationConfig() {
    return createIntegrationConfigIfFlagEnabled(integrationFlagSuffix)
  },
}

export default extensionConfig
