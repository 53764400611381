/**
 * Takes an initial type argument and returns a custom event creator which instantiates
 * CustomEvent with the same `type` and `options` but with customizable `detail`
 *
 * @param type the custom event type https://developer.mozilla.org/en-US/docs/Web/API/Event/type
 * @param options custom event options, defaults to { bubbles: true, composed: true }
 * @returns CustomEventCreator<T>
 */
export const nameSpacedCustomEventCreator =
  <Detail>(type: string, options: EventInit = { bubbles: true, composed: true }) =>
  <P extends Detail>(detail?: P): CustomEvent<P> =>
    new CustomEvent(type, {
      ...options,
      detail,
    })
