import { getShellApiInstance } from '../../common/shell-api-helpers'
import { UserProfileEntitlement } from '../../services/identity/models'
import type { ShellHook } from './models'

export const initializeCalendar: ShellHook = async shell => {
  const authContext = shell.getAuthContext()
  authContext.isCalendarConnected = Promise.resolve(false)
  if (authContext.userProfile?.entitlements?.includes(UserProfileEntitlement.GoToMeeting)) {
    authContext.isCalendarConnected =
      getShellApiInstance()
        .calendar?.isConnected()
        ?.catch(() => false) ?? Promise.resolve(false)
  }
}
