const avatarColors = [
  '--goto-avatar-color-00',
  '--goto-avatar-color-01',
  '--goto-avatar-color-02',
  '--goto-avatar-color-10',
  '--goto-avatar-color-11',
  '--goto-avatar-color-12',
  '--goto-avatar-color-20',
  '--goto-avatar-color-21',
  '--goto-avatar-color-22',
  '--goto-avatar-color-30',
  '--goto-avatar-color-31',
  '--goto-avatar-color-32',
  '--goto-avatar-color-40',
  '--goto-avatar-color-41',
  '--goto-avatar-color-42',
  '--goto-avatar-color-50',
  '--goto-avatar-color-51',
  '--goto-avatar-color-52',
]

const getNumberFromString = (str: string) => {
  let result = 0
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i)
  }
  return result
}

export const getAvatarColorTokenFromString = (str: string) =>
  avatarColors[getNumberFromString(str ?? '') % avatarColors.length]
