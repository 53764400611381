import { getWindow } from '../common/dom-helpers'

const MIN_IN_MS = 60000

/**
 * Handles actions to be put when the user is idling.
 * addIdleHandler should not be called twice by the same entity.
 * It sets up the eventListeners without removing them, since the goal is
 * for addIdleHandler to monitor inactivity for the duration the application is alive.
 *
 * @param idleTime a number that contains the user's idle time, in minutes,
 *                 before we consider the user idle and execute the callback
 *
 * @param onIdle callback function that fires when the idle condition is hit
 *
 * @param onActive (optional) callback function that fires when the use is out of idle
 *
 * @returns a query string, not including the '?'
 */

export const addIdleHandler = (idleTime: number, onIdle: () => void, onActive?: () => void) => {
  let idleTimeOut: ReturnType<typeof setTimeout>

  const idleTimeMs = idleTime * MIN_IN_MS

  const resetIdle = () => {
    clearTimeout(idleTimeOut)
    if (onActive) {
      onActive()
    }

    idleTimeOut = setTimeout(() => {
      onIdle()
      resetIdle()
    }, idleTimeMs)
  }

  getWindow().addEventListener('load', resetIdle, true)
  const events = ['mousedown', 'mousemove', 'mousewheel', 'keypress', 'scroll', 'touchstart']
  events.forEach(function (name) {
    getWindow().addEventListener(name, resetIdle, true)
  })
  resetIdle()
}
