import { StringAnalysisPropertyValue, removeAccents, stringIsPhoneNumber } from '../../common/helpers/string'

/**
 * Interface that defines a text range inside a string
 */
export interface TextRange {
  /**
   * text value of the range
   */
  value: string
  /**
   * starting index of the range
   */
  start: number
  /**
   * length of the text range
   */
  length: number
}

/**
 * Interface that defines a highlight text range
 */
export interface HighlightTextRange extends TextRange {
  /**
   * keyword associated to the range
   */
  keyword: string
}

const getPhoneNumberRegexPart = (keyword: string) => {
  // replace all extension patterns with a # as a and remove any other non-digit characters
  const cleanupPhoneNumberRegEx = /(extension:?|;?ext\\.?:?=?|x|#)|([^0-9])/gi
  const cleanWord = keyword.replace(cleanupPhoneNumberRegEx, (_match, extension, _otherChar) => extension ? '#' : '')
  let firstDigitRegex = '([\\(\\#x+]{1,2}\\s?|ext\\.?:?=?\\s?|extension:?\\s?){0,2}'
  let otherDigitsRegex = '\\s*([\\(\\)\\#\\.\\-\\sx]{0,2}|;?ext\\.?:?=?|extension:?)'
  const hasExtension = cleanWord.indexOf('#') >= 0
  if (hasExtension) {
    // regular expression to find digits and symbols depending of placement in the string
    firstDigitRegex = '([\\(\\+]{1,2}\\s?){0,2}'
    otherDigitsRegex = '\\s*[\\(\\)\\.\\-\\s]{0,2}'
  }
  // replacing digits and extensions placeholders (#)
  const result = cleanWord.replace(/(\d)|(#)/gi, (_match, digit, extension, index) => {
    if (extension) {
      // replace extension placeholders with the proper regex depending if it's a the begining
      return index ? '\\s?([\\#x]{1,2}\\s?|;?ext\\.?:?=?\\s?|extension:?\\s?)' : '([\\#x]{1,2}\\s?|ext\\.?:?=?\\s?|extension:?\\s?)'
    }
    return (index ? otherDigitsRegex : firstDigitRegex) + digit
  })
  return result + '\\)?'
}

const getKeywordRegexPart = (
  keyword: string,
  highlightOptions: HighlightOptions
): string => {
  let result = keyword
  if (highlightOptions.phoneNumbers && stringIsPhoneNumber(keyword) !== StringAnalysisPropertyValue.FALSE) {
    return getPhoneNumberRegexPart(keyword)
  } else if (highlightOptions.ignoreAccents) {
    result = removeAccents(result)
  }
  return `(${escapeForRegex(result)})`
}

const escapeForRegex = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

/**
 * Definition of callback signature for text highlighting
 */
export type HighlightCallback = (range: HighlightTextRange) => string

/**
 * Interface that allow to specify highlight options
 * If not specified, default are
 * ignoreCase: false
 * ignoreAccents: false
 * phoneNumbers: false
 */
export interface HighlightOptions {
  /**
   * ignore casing of keywords and string to highlight
   */
  ignoreCase?: boolean
  /**
   * ignore accents in keywords and in string to highlight
   */
  ignoreAccents?: boolean
  /**
   * If a keyword looks like a phone number, highlight will be perform in a smart way to highlight
   * phone numbers as much as possible. ie: 555-3333, (555)3333, 5553333 would all highlight (555)-3333
   */
  phoneNumbers?: boolean
}

const highlightRanges = (text: string, ranges: HighlightTextRange[], highlightCallback: HighlightCallback): string => {
  let result = ''
  let lastChar = 0
  ranges.forEach(range => {
    result += text.substring(lastChar, range.start)
    result += highlightCallback(range)
    lastChar = range.start + range.length
  })
  result += text.substring(lastChar, text.length)
  return result
}

/**
 * Transforms a string to highlight specified keywords
 * @param text text in which we want to perform highlight
 * @param keywords keywords to highlight
 * @param highlightCallback callback used to transform the original string part with highlight
 * ie:  (range: HighlightTextRange) => `<span class="highlight">${range.value}</span>`
 * @param highlightOptions highlight options
 * @returns transformed string with highlighted keywords
 */
export const highlightKeywords = (
  text: string,
  keywords: readonly string[],
  highlightCallback: HighlightCallback,
  highlightOptions: HighlightOptions = {},
) => {
  const filteredKeywords = keywords.filter(keyword => keyword.length >= 1)
  if (filteredKeywords.length) {
    const ranges = createHighlightTextRanges(text, filteredKeywords, highlightOptions)
    return highlightRanges(text, ranges, highlightCallback)
  }
  return text
}

/**
 * Resolves and returns corresponding index in original string for an index in a string
 * that has its accents removed.  Some accents removal result into two characters instead of one
 * ie: Ǽ -> AE, Œ -> OE
 * @param original Original string (with accents)
 * @param index Index in string that has accents removed
 * @returns corresponding index in the original string
 */
const resolveOriginalCharIndex = (original: string, index: number): number => {
  let noAccentIndex = 0
  let originalIndex = 0
  while (noAccentIndex < index) {
    noAccentIndex += removeAccents(original[originalIndex]).length
    originalIndex++
  }
  return originalIndex
}

/**
 * Retrieves a function that can be used to resolve an index in original string
 * @param original Original string (with accents)
 * @param withoutAccent string without accent
 * @returns a function that can be used to resolve an index in original string
 */
const getOriginalIndexResolver = (original: string, withoutAccent: string) => {
  if (original.length !== withoutAccent.length) {
    return (index: number) => resolveOriginalCharIndex(original, index)
  }
  return (index: number) => index
}

/**
 * Create a regular expression that can be used to find keywords for highlight
 * @param keywords Keywords to highlight
 * @param highlightOptions highlight options
 * @returns Regular expression that can be used to find keywords for highlight
 */
const createRegexForHighlight = (keywords: readonly string[], highlightOptions: HighlightOptions = {}) => {
  const keywordRegExParts = keywords.map(keyword => getKeywordRegexPart(keyword, highlightOptions))
  return new RegExp(`${keywordRegExParts.join('|')}`, highlightOptions.ignoreCase ? 'gi' : 'g')
}

/**
 * Creates text ranges that can be used to perform highlight in a string
 * @param text text in which we want to perform highlight
 * @param keywords keywords to highlight
 * @param highlightOptions highlight options
 * @returns text ranges that can be used for highlight
 */
export const createHighlightTextRanges = (
  text: string,
  keywords: readonly string[],
  highlightOptions: HighlightOptions = {},
): HighlightTextRange[] => {
  const result: HighlightTextRange[] = []
  const sortedKeywords = [...keywords].sort((a, b) => b.length - a.length)

  let textToHighlight = text
  if (highlightOptions.ignoreAccents) {
    textToHighlight = removeAccents(text)

  }
  const resolveOriginalIndex = getOriginalIndexResolver(text, textToHighlight)
  const re = createRegexForHighlight(sortedKeywords, highlightOptions)
  const matches = textToHighlight.matchAll(re)
  for (const match of matches) {
    const foundMatch = match[0]
    const keywordIndex = match.findIndex((value, index) => index > 0 && value === foundMatch) - 1
    const keyword = sortedKeywords[keywordIndex]
    const startIndex = resolveOriginalIndex(match.index ?? 0)
    const endIndex = resolveOriginalIndex((match.index ?? 0) + match[0].length)
    result.push({
      value: text.substring(startIndex, endIndex),
      start: startIndex,
      length: endIndex - startIndex,
      keyword,
    })
  }
  return result
}
