import { reloadPage } from '../common/dom-helpers'
import { getTranslation } from '../services/i18n/i18nUtils'
import { banner } from '../services/display-notification'

export const unrecoverableErrorBanner = () => {
  banner({
    target: window,
    description: getTranslation('Looks like something went wrong while loading GoTo. Try refreshing the app.'),
    closable: false,
    variant: 'danger',
    link: { text: getTranslation('Refresh now'), onClick: reloadPage },
    id: 'shellUnrecoverableErrorBanner',
  })
}
