import { wait } from './helpers'
import { SignalController } from './signal-utils'

export type retryOnOptions<T> = {
  readonly attempt?: number
  readonly error?: unknown
  readonly value?: T
}

export type retryOptions<T = any> = {
  readonly maxRetryNumber?: number
  readonly retryAfterInMilliseconds?: number
  readonly randomized?: boolean
  readonly exponential?: boolean
  readonly retryOn?: (options: retryOnOptions<T>) => Promise<boolean>
}

export interface Retryable {
  <T>(func: () => Promise<T>, options?: retryOptions<T>): Promise<T>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const executeSandbox = async (func: () => Promise<any>) => {
  try {
    const value = await func()
    return { value }
  } catch (error) {
    return { error }
  }
}
const defaultRetryOn = ({ error }: retryOnOptions<any>) => !!error

/**
 * Retry function take a function to execute with a retry handler
 * The retry logic is defined into the options
 *
 */
export const retry: Retryable = async (func, options = { retryAfterInMilliseconds: 1 }) => {
  let attempt = 0
  let shouldRetry = false
  let result
  let { maxRetryNumber = 0, retryAfterInMilliseconds = 0 } = options
  const { randomized = false, exponential = false } = options
  const { retryOn = defaultRetryOn } = options

  if (maxRetryNumber < 0) {
    maxRetryNumber = 0
  }
  if (retryAfterInMilliseconds < 0) {
    retryAfterInMilliseconds = 0
  }

  if (randomized) {
    retryAfterInMilliseconds = Math.floor(Math.random() * retryAfterInMilliseconds) + retryAfterInMilliseconds
  }

  do {
    // Add the timer before retry
    if (attempt > 0) {
      await wait(retryAfterInMilliseconds)
      if (exponential) {
        retryAfterInMilliseconds = retryAfterInMilliseconds * (attempt + 1)
      }
    }
    attempt += 1
    // Execute the function in a sandbox
    result = await executeSandbox(func)
    shouldRetry = await retryOn({ attempt, error: result.error, value: result.value })
  } while (attempt < 1 + maxRetryNumber && shouldRetry)
  // return the last execution result
  const { value, error } = result
  if (error) {
    throw error
  }
  return value
}

export type RetryableRequestInit = RequestInit & {
  readonly retry?: retryOptions
  readonly timeoutInMS?: number
}

const fetchRetryOn = async (options: retryOnOptions<Response>) => {
  const { error, value } = options
  if (error) {
    return true
  }
  return value ? value.status >= 500 : false
}

export const fetchRetry: (input: RequestInfo, init?: RetryableRequestInit) => ReturnType<typeof fetch> = (
  input,
  init,
) => {
  const {
    maxRetryNumber = 0,
    retryAfterInMilliseconds = 0,
    retryOn = fetchRetryOn,
    exponential = false,
    randomized = false,
  } = init?.retry ?? {}
  const retryOptions: Required<retryOptions<Response>> = {
    maxRetryNumber,
    retryAfterInMilliseconds,
    retryOn,
    exponential,
    randomized,
  }
  const timeoutInMS = init?.timeoutInMS

  const run = timeoutInMS ? () => fetchWithTimeout(input, { ...init, timeoutInMS }) : () => fetch(input, init)

  return retry(run, retryOptions)
}

export type RequestInitWithTimeout = RequestInit & {
  readonly timeoutInMS: number
}

export const fetchWithTimeout: (input: RequestInfo, init: RequestInitWithTimeout) => Promise<Response> = (
  input,
  init,
) =>
  new Promise((resolve, reject) => {
    const { timeoutInMS, signal: originalSignal } = init
    const controller = new AbortController()

    // validation signals are well defined.
    const signals = [controller.signal, originalSignal].filter(
      (s): s is AbortSignal => s instanceof AbortSignal,
    )

    const signalController = new SignalController(signals)

    const timer = setTimeout(() => {
      controller.abort()
      reject(new Error('Timeout'))
    }, timeoutInMS)

    fetch(input, { ...init, signal: signalController.signal })
      .then(value => {
        clearTimeout(timer)
        signalController.cleanup()
        resolve(value)
      })
      .catch(r => {
        clearTimeout(timer)
        signalController.cleanup()
        reject(r)
      })
  })

