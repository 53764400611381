import { shellComponents } from './component-dictionary'

/**
 * @description This function register the customs components of the app
 */
export const registerCustomComponents = () => {
  Object.entries(shellComponents).forEach(registerComponent)
}

/**
 * @description this function validates if a custom component already exists on window
 *  if it's not the case, it registers the component
 * @param tagName : Tag name of the component
 * @param customElementConstructor : Component constructor
 */
const registerComponent = ([tagName, customElementConstructor]: readonly [string, CustomElementConstructor]) => {
  if (!window.customElements.get(tagName)) {
    window.customElements.define(tagName, customElementConstructor)
  }
}
