import { navigateToUrl, singleSpaLit } from '../../common'
import { html } from 'lit-element'
import { GOTO_ROOT_SELECTOR } from '../../common/container'
import { environment } from '../../environments'
import { openWindow } from '../../services/container/windows-management'
import { login } from '../../services/auth/authentication'

export default singleSpaLit({
  template: () =>
    html`<goto-unauthenticated-error
      .login=${() => {
        navigateToUrl('/')
        login()
      }}
      .support=${() => {
        openWindow(environment().supportUrl, 'get-support', { saveSizeAndPosition: true })
      }}
    ></goto-unauthenticated-error>`,
  domElement: GOTO_ROOT_SELECTOR,
})
