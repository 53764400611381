export const isInPathname = (pathname: string) => (value: string) =>
  new RegExp(`(^${value}$|${value}/|${value}$)`).test(pathname)

export const isRouteMatching = (route: string, pattern: string | RegExp) => {
  let regExp: RegExp
  if (typeof pattern === 'string') {
    regExp = new RegExp(pattern)
  } else {
    regExp = pattern
  }
  return regExp.test(route)
}

/**
 * Converts an object to a query string. Heavily inspired from:
 * https://gitlab.com/renoirb/renoirb-particles/-/blob/@renoirb%2Fdata-source_v0.3.3/packages/data-source/src/helpers.ts#L113
 *
 * certain characters will be encoded as utf-8 see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#description
 *
 * Properties with a nullable value are discarded
 *
 * @param obj an object to be converted to a query string
 *
 * @returns a query string, not including the '?'
 */
export const convertObjectToQueryString = (obj: object) => {
  const params = Object.entries(obj).filter(([, value]) => value != null)
  return new URLSearchParams(params).toString()
}

/**
 * Takes a url and returns a URL object if the url is valid, or '' if not.
 */
export const coerceToUrl = (url: string): URL | '' => {
  let out: URL | '' = ''
  try {
    out = createUrlFromString(url)
  } catch {
    // fail silently
  }
  return out
}

/**
 * Throws if the argument is not a valid string that can't be used for an URL.
 */
export const assertsIsUrl: (url: unknown) => asserts url is URL = url => {
  try {
    createUrlFromString(url as string)
  } catch (err) {
    const message = String(err)
    throw new TypeError(message)
  }
}

/**
 * Create an url from a string.
 * Throws if the url is invalid
 */
export const createUrlFromString = (str: string): URL => {
  let out = undefined
  try {
    out = new URL(str)
  } catch (err) {
    const message = String(err)
    throw new TypeError(message)
  }
  return out
}
