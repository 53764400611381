import { isRouteMatching } from '../../common'
import type { ExtensionConfig } from '@goto/shell-common'
import { addExtensionWithDependencies } from './utils'

export const getStandaloneExtensionConfigForCurrentLocation = (
  extensionConfigs: readonly ExtensionConfig[],
  route: string,
) =>
  extensionConfigs.find(extensionConfig =>
    extensionConfig.standaloneRoutes?.some(pattern => isRouteMatching(route, pattern)),
  )

export const filterForStandaloneRoutes = (
  extensionConfigs: readonly ExtensionConfig[],
  route: string,
): readonly ExtensionConfig[] => {
  const matchingExtensionConfig = getStandaloneExtensionConfigForCurrentLocation(extensionConfigs, route)
  if (matchingExtensionConfig) {
    const extensions = new Set<string>()

    addExtensionWithDependencies(extensions, extensionConfigs, matchingExtensionConfig)
    return extensionConfigs.filter(extensionConfig => extensions.has(extensionConfig.id))
  }
  return extensionConfigs
}
