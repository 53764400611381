import type { ShellConfig } from './models'
import type { SettingsInfo } from '../common'

import type { RegisterApplicationConfig } from 'single-spa'
import {
  GTC_GUIDE_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  LANDING_ROUTE,
  START_MS_TEAMS_PLUGIN_ROUTE,
  INTEGRATION_REDIRECT_PROXY_ROUTE,
} from '../common/routes'

import { getShellApiInstance } from '../common/shell-api-helpers'
import { getWindowOptions } from '../services/container/windows-management'
import {
  createBannerNotificationElement,
  createChameleonLauncherScreenElement,
  createChameleonThemeProviderElement,
  createExperienceContentElement,
  createGotoRootElement,
  createMainElement,
  createModalNotificationElement,
  createNavigationElement,
  createDialogContainer,
  createSnackbarNotificationElement,
  createSpecificAreaElement,
  createTopBarContainer,
  getLocationPathname,
  createInspectorPanelContainer,
  createCriticalBannerContainerElement,
  isInIframe,
} from '../common/dom-helpers'
import { BASEURL_AUTH, userReportUUID } from '../environments'
import { setupUserReport } from '../packages/user-report/index'
import { GOTO_ROOT_SELECTOR, REBRANDING_2021_THEME } from '../common/container'
import { createSecureIframe } from '../services/auth/serverIsLoggedIn'
import { gotoSettingPageExperienceConfig, generalSettingsExperienceConfigs } from '../experiences'
import { createSettingsViewApplicationConfig } from '../experiences/helpers'
import { updateChameleonThemeProvider } from '../services/theme'
import { getExternalInterface } from '../services/external-interface'
import { type Theme } from '../services/shell-namespace'
import { isStandaloneRoute } from '../extensions/utils'

/**
 * The general settings will be a static list of GotoExperienceConfig which we transform into the proper format that will be used to register them as experiences.
 */
export function createGeneralSettingsExperiences(): readonly RegisterApplicationConfig[] {
  return Object.values(generalSettingsExperienceConfigs).map(config => createSettingsViewApplicationConfig(config))
}

/**
 * Create the setting experience.
 * This is the settings main page that own all the settings
 * @param SettingsInfo[]
 * @returns
 */
export const createGotoSettingsExperience = (settingsInfo: readonly SettingsInfo[]): RegisterApplicationConfig => ({
  ...gotoSettingPageExperienceConfig,
  customProps: { settingLinks: settingsInfo },
})

export const goToConnectExperienceRoutes = [
  '/dashboard',
  '/domain',
  '/jc-jiveweb',
  '/settings',
  '/shared',
  GTC_GUIDE_ROUTE,
] as const

export const routesWithoutSidebar = [
  '/onboard-calendar',
  '/onboard-gotoconnect',
  '/tos',
  '/calendar-connected',
  TERMS_OF_SERVICE_ROUTE,
  LANDING_ROUTE,
  BASEURL_AUTH.redirect,
  START_MS_TEAMS_PLUGIN_ROUTE,
  INTEGRATION_REDIRECT_PROXY_ROUTE,
] as const

export const routesWithoutTopbar = [LANDING_ROUTE, START_MS_TEAMS_PLUGIN_ROUTE, INTEGRATION_REDIRECT_PROXY_ROUTE]

export const canShowSideBar = (config?: ShellConfig): boolean => {
  const isAuthenticated = !!getShellApiInstance()?.user.key
  const onRouteWithoutSidebar = routesWithoutSidebar.some(route => getLocationPathname().startsWith(route))
  const isLocationWithoutSidebar = onRouteWithoutSidebar || getLocationPathname() === '/'

  // No sidebar - if the user is not authenticated or if current path in `routesWithoutSidebar` or is standalone route
  if (!isAuthenticated || isLocationWithoutSidebar || isStandaloneRoute(getLocationPathname())) {
    return false
  }

  return !!getWindowOptions()?.showSideBar && !config?.hideSidebar
}

export const canShowTopBar = (config?: ShellConfig) => {
  const onRouteWithoutTopbar = routesWithoutTopbar.some(route => getLocationPathname() === route)
  const isAuthenticated = !!getShellApiInstance()?.user.key

  // No topbar - if the user is not authenticated or if current path in `routesWithoutTopbar` or is standalone route
  if (!isAuthenticated || onRouteWithoutTopbar || isStandaloneRoute(getLocationPathname())) {
    return false
  }

  return !!getWindowOptions()?.showTopBar && !config?.hideTopbar
}

export const SHELL_CHAMELEON_THEME_PROVIDER = 'shell-chameleon-theme-provider'

/**
 * Creates the HTML layout for the Shell
 */
export function scaffoldHTML(theme: Theme) {
  if (document.querySelector(GOTO_ROOT_SELECTOR)) {
    return
  }
  const appRoot = createGotoRootElement()
  const topbarContainer = createTopBarContainer()
  const inspectorPanelContainer = createInspectorPanelContainer()
  const main = createMainElement()
  const content = createExperienceContentElement()
  content.appendChild(createChameleonLauncherScreenElement())
  const navigation = createNavigationElement()

  const criticalBannerContainer = createCriticalBannerContainerElement()
  const bannerNotification = createBannerNotificationElement()
  const snackbarNotification = createSnackbarNotificationElement()
  const modalNotification = createModalNotificationElement()
  const dialogContainer = createDialogContainer()

  const chameleonThemeProvider = createChameleonThemeProviderElement()
  const specificArea = createSpecificAreaElement()

  chameleonThemeProvider.setAttribute(REBRANDING_2021_THEME, 'true')
  chameleonThemeProvider.id = SHELL_CHAMELEON_THEME_PROVIDER

  updateChameleonThemeProvider(chameleonThemeProvider, theme)

  const mainChildren = [content]
  mainChildren.forEach(element => {
    main.appendChild(element)
  })

  const appRootChildren = [
    criticalBannerContainer,
    bannerNotification,
    topbarContainer,
    navigation,
    specificArea,
    main,
    inspectorPanelContainer,
  ]

  appRootChildren
    .filter((value): value is HTMLElement => Boolean(value))
    .forEach(element => {
      appRoot.appendChild(element)
    })

  const appItems = [appRoot, modalNotification, dialogContainer]

  appItems.forEach(element => chameleonThemeProvider.append(element))
  document.body.append(chameleonThemeProvider)
  if (!isInIframe()) {
    document.body.append(createSecureIframe())
  }
  document.body.querySelector('.goto-container')?.append(snackbarNotification)

  // Feedback form setup https://www.userreport.com/
  const { mount } = setupUserReport(window)
  mount(userReportUUID)
}

export enum AppSkins {
  GOTO_APP = 'gotoapp',
  MS_TEAMS = 'msteams',
}

export const resolveChameleonThemeProviderSkin = () => {
  const themeProvider = document.getElementById(SHELL_CHAMELEON_THEME_PROVIDER)
  if (themeProvider) {
    const { isIntegration, isCompanion } = getExternalInterface()
    const appSkin = isIntegration || isCompanion ? AppSkins.MS_TEAMS : AppSkins.GOTO_APP
    themeProvider.setAttribute('skin', appSkin)
  }
}
