import { measureLoadingTime } from '../../core/performance'

export const UNLEASH_SETUP = 'UnleashSetup'
export const UNLEASH_START_MARK = `${UNLEASH_SETUP} start`
export const UNLEASH_DONE_MARK = `${UNLEASH_SETUP} done`

export const LAUNCHDARKLY_SETUP = 'launchDarklySetup'
export const LAUNCHDARKLY_START_MARK = `${LAUNCHDARKLY_SETUP} start`
export const LAUNCHDARKLY_DONE_MARK = `${LAUNCHDARKLY_SETUP} done`

export const measureUnleashSetupLoadingTime = measureLoadingTime(
  `shell_${UNLEASH_SETUP}`,
  UNLEASH_START_MARK,
  UNLEASH_DONE_MARK,
)
export const measureLaunchDarklySetupLoadingTime = measureLoadingTime(
  `shell_${LAUNCHDARKLY_SETUP}`,
  LAUNCHDARKLY_START_MARK,
  LAUNCHDARKLY_DONE_MARK,
)
