import { LitElement, html } from 'lit-element'
import { t } from '../../directives/translate'
import styles from './terms-of-service.styles.scss'
import { acceptTermsOfService, TOS_PREVIOUS_URL_KEY } from '../../services/terms-of-service/index'
import { navigateToUrl } from '../../common/helpers'
import { navigateToExternal, openNewTabFor, popFromLocalStorage, reloadPage } from '../../common/dom-helpers'
import { ROOT_ROUTE } from '../../common/routes'
import { isContainer } from '../../services/container/helpers'
import {
  TERMS_OF_SERVICE_DE_URL,
  TERMS_OF_SERVICE_EN_URL,
  TERMS_OF_SERVICE_ES_URL,
  TERMS_OF_SERVICE_FR_URL,
  TERMS_OF_SERVICE_PT_URL,
  environment,
} from '../../environments'
import { Plugins } from '@getgo/container-client'
import { getShellNamespaces } from '../../common/shell-api-helpers'
import type { ToSNamespace } from '../../services/namespaces/tos-namespace'
import { TOS_NAMESPACE } from '../../services/namespaces/tos-namespace'

export class TermsOfService extends LitElement {
  static readonly tagName = 'goto-terms-of-service'
  static get styles() {
    return styles
  }

  private acceptTerms() {
    const tosNamespace = getShellNamespaces().retrieve<ToSNamespace>(TOS_NAMESPACE)
    if (tosNamespace.commands.singleFireAccept.isHandled()) {
      tosNamespace.commands.singleFireAccept.execute()
      tosNamespace.commands.singleFireAccept.removeHandler()
      tosNamespace.commands.singleFireRefuse.removeHandler()
    } else {
      acceptTermsOfService().then(() => {
        const previousUrl = popFromLocalStorage(TOS_PREVIOUS_URL_KEY)
        if (previousUrl) {
          navigateToUrl(previousUrl)
          reloadPage()
        } else {
          // Jiveweb is not loaded in that experience so reloading is an easy way to go to the normal experience
          navigateToUrl(ROOT_ROUTE)
          reloadPage()
        }
      })
    }
  }

  private refuseTerms() {
    const tosNamespace = getShellNamespaces().retrieve<ToSNamespace>(TOS_NAMESPACE)
    if (tosNamespace.commands.singleFireRefuse.isHandled()) {
      tosNamespace.commands.singleFireRefuse.execute()
      tosNamespace.commands.singleFireAccept.removeHandler()
      tosNamespace.commands.singleFireRefuse.removeHandler()
    } else {
      const myAccountPageUrl = environment().myAccountPageUrl
      if (isContainer()) {
        openNewTabFor(myAccountPageUrl)
        Plugins.LifeCycle.quit?.()
      } else {
        navigateToExternal(myAccountPageUrl)
      }
    }
  }

  render() {
    return html`<chameleon-dialog open close-button-label=${t('Close dialog')}>
      <span slot="title">${t('Terms of Service')}</span>
      <chameleon-typography tag="p" variant="body-medium"
        >${t('Please read and acknowledge the terms of service governing the use of this application:')}
      </chameleon-typography>
      <chameleon-typography tag="p" variant="body-medium" class="tos-list">
        <a href="${TERMS_OF_SERVICE_EN_URL}" target="_blank">English ${t('English')}</a><br />
        <a href="${TERMS_OF_SERVICE_ES_URL}" target="_blank">Español ${t('Spanish')}</a><br />
        <a href="${TERMS_OF_SERVICE_DE_URL}" target="_blank">Deutsch ${t('German')}</a><br />
        <a href="${TERMS_OF_SERVICE_FR_URL}" target="_blank">Français ${t('French')}</a><br />
        <a href="${TERMS_OF_SERVICE_PT_URL}" target="_blank">Português ${t('Portuguese')}</a>
      </chameleon-typography>
      <div slot="actions">
        <chameleon-button @click=${this.acceptTerms} data-test="agree">${t('I agree')}</chameleon-button>
        <chameleon-button variant="secondary" @click=${this.refuseTerms} data-test="disagree"
          >${t('I disagree')}</chameleon-button
        >
      </div>
    </chameleon-dialog>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-terms-of-service': TermsOfService
  }
}
