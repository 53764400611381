import { getShellLogger } from '../../common/logger'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { type ExtensionConfig } from '@goto/shell-common'
import { getExtensionsManager } from '../extensions-manager'

export const filterForBlockedExtensions = (
  extensionConfigs: readonly ExtensionConfig[],
): readonly ExtensionConfig[] => {
  let filteredExtensions: readonly ExtensionConfig[] = []
  const blockedExtensionIds = getExtensionsManager().getBlockedExtensionIds()
  if (blockedExtensionIds.size > 0) {
    getShellLogger().error(
      `The following extensions are being blocked by the ${FeatureFlagsVariations.SHELL_BLOCKED_EXTENSION_IDS} feature flag: ${Array.from(blockedExtensionIds).join(', ')}`,
    )

    filteredExtensions = extensionConfigs.filter(extensionConfig => !blockedExtensionIds.has(extensionConfig.id))
  } else {
    filteredExtensions = extensionConfigs
  }

  return filteredExtensions
}
