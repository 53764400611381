import { type RulePrivate, type Rule } from './rule'

export class NotRule implements Rule, RulePrivate {
  constructor(private rule: Rule) {}

  public isValid(): boolean {
    return !this.rule.isValid()
  }

  public getNames(): readonly string[] {
    return (this.rule as unknown as RulePrivate).getNames()
  }
}
