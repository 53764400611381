import { Container, Plugins } from '@getgo/container-client'
import { getShellLogger } from '../../common/logger'
import { isContainer } from '../container/helpers'
import type { AppManagerService as IAppManagerService } from './models'
export class AppManagerService implements IAppManagerService {
  async quit() {
    if (Container.isPluginAvailable('LifeCycle')) {
      // If success you probably won't have time to do anything
      // The Promise return is only in case it rejects

      try {
        await Plugins.LifeCycle.quit?.()
      } catch (e) {
        getShellLogger().error('Impossible to close the application.')
        throw new Error(e as string | undefined)
      }
    } else if (!isContainer()) {
      return await Promise.reject('We are in a browser.')
    } else {
      return await Promise.reject()
    }
  }
}
