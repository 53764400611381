import { PrerequisiteRuleTypes, type ExtensionConfig } from '@goto/shell-common'
import { getOOSArtifactUrl } from '../helpers/experience-helpers'
import { getFeatureFlag } from '../helpers/shell-helpers'
import { UnleashFlags } from '../models/feature-flag.models'
import { createIntegrationConfigIfFlagEnabled } from '../helpers/integration-helpers'

const integrationFlagSuffix = 'oos-teaser'

const extensionConfig: ExtensionConfig = {
  id: '@goto/oos-teaser',
  get url() {
    const version = getFeatureFlag(UnleashFlags.OOS_TEASER_VERSION, 'release')
    return new URL(getOOSArtifactUrl('teaser-extension', version))
  },
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: '!g2w',
    [PrerequisiteRuleTypes.ACCOUNT_SETTINGS]: '!webinar.isWebinarGoToAppEnabled && gotoapp.showWebinarTeaser',
  },
  get integrationConfig() {
    return createIntegrationConfigIfFlagEnabled(integrationFlagSuffix)
  },
}

export default extensionConfig
