/**
 * V2 avatar sizes in pixels
 */
export enum AvatarSize {
  /**
   * 24x24
   */
  SMALL = 'small',
  /**
   * 32x32
   */
  MEDIUM = 'medium',
  /**
   * 40x40
   */
  LARGE = 'large',
  /**
   * 48x48
   */
  X_LARGE = 'xlarge',
}

export interface AvatarInfo {
  readonly isDefault: boolean
  readonly urlSmall: string
  readonly urlMedium: string
  readonly urlLarge: string
  readonly urlXLarge: string
}
