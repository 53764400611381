
import { getExternalInterface } from '../external-interface/external-interface-adapter'
import { NavigationService } from './navigation-service'
import { NavigationServiceCompanionAdapter } from './navigation-service-companion-adapter'
import type { NavigationAPI } from './public-api'

let navigationAPI: NavigationAPI

export const getNavigationAPI = () => {
  if (!navigationAPI) {
    createNavigationAPI()
  }

  return navigationAPI
}

const createNavigationAPI = () => {
  navigationAPI = new NavigationService()

  if (getExternalInterface().isCompanion) {
    // Wrap the navigation service into an adapter used when running in the companion app.
    navigationAPI = new NavigationServiceCompanionAdapter(navigationAPI)
  }
}
