import { getShellLogger } from '../../../common/logger'
import { getDefaultUserSchedule } from './../helpers'
import {
  ScheduleProps,
  type WorkPeriod,
  type UserSchedule,
  type OneTimePeriod,
  type SchedulePropsValueType,
} from './models'
import { getSampleCurrentUserSchedule, updateUserScheduleBFF } from './samples'
import { defaultWorkScheduleDays, defaultWorkScheduleTime } from '../schedule-settings-models'

export class ScheduleManager {
  private currentUserSchedule: UserSchedule | undefined
  private modifiedUserSchedule: UserSchedule | undefined

  constructor() {
    this.fetchCurrentUserSchedule()
  }

  get defaultWorkSchedule(): WorkPeriod[] {
    return defaultWorkScheduleDays.map(dayOfWeek => ({
      dayOfWeek: dayOfWeek,
      startTime: defaultWorkScheduleTime.start,
      endTime: defaultWorkScheduleTime.end,
    }))
  }

  private fetchCurrentUserSchedule() {
    try {
      // getSampleCurrentUserSchedule is temporary until the backend is ready. This should be a call to the shell bff

      const currentUserSchedule = getSampleCurrentUserSchedule()
      if (currentUserSchedule) {
        this.currentUserSchedule = currentUserSchedule
      } else {
        this.currentUserSchedule = getDefaultUserSchedule()
      }
    } catch (e) {
      getShellLogger().error('Error getting user schedule', e)
    }
  }

  private setModifiedUserSchedule(scheduleProps: ScheduleProps, schedulePropsValue: SchedulePropsValueType) {
    if (!this.modifiedUserSchedule && this.currentUserSchedule) {
      // create a copy of the user schedule to keep track of the changes
      this.modifiedUserSchedule = { ...this.currentUserSchedule }
    }
    switch (scheduleProps) {
      case ScheduleProps.ENABLED:
        this.modifiedUserSchedule!.enabled = schedulePropsValue as boolean
        break
      case ScheduleProps.DND_AUTOMATION_ENABLED:
        this.modifiedUserSchedule!.dndAutomationEnabled = schedulePropsValue as boolean
        break
      case ScheduleProps.WORK_SCHEDULE:
        this.modifiedUserSchedule!.workPeriods = schedulePropsValue as WorkPeriod[]
        break
      case ScheduleProps.CUSTOM_SCHEDULE:
        this.modifiedUserSchedule!.oneTimePeriods = schedulePropsValue as OneTimePeriod[]
        break
    }
  }

  /**
   * Clears the value of the modified user schedule to undefined - indicating no modifications
   */
  public clearModifiedUserSchedule() {
    this.modifiedUserSchedule = undefined
  }

  /**
   * @returns the current unmodified user schedule
   */
  public getCurrentUserSchedule(): UserSchedule | undefined {
    return this.currentUserSchedule
  }

  /**
   * @returns the modified user schedule
   */
  public getModifiedUserSchedule(): UserSchedule | undefined {
    return this.modifiedUserSchedule
  }

  /**
   * Resets the value of the modified user schedule
   * @returns the current unmodified user schedule
   */

  public resetUserSchedule() {
    this.clearModifiedUserSchedule()
    return this.getCurrentUserSchedule()
  }

  /**
   * @returns the current unmodified work schedule for the user
   */
  public getCurrentWorkSchedule(): WorkPeriod[] | undefined {
    return this.currentUserSchedule?.workPeriods
  }

  /**
   * @returns the modified work schedule for the user
   */
  public getModifiedWorkSchedule(): WorkPeriod[] | undefined {
    return this.modifiedUserSchedule?.workPeriods
  }

  /**
   * @returns the current unmodified custom schedule for the user
   */
  public getCurrentCustomSchedule(): OneTimePeriod[] | undefined {
    return this.currentUserSchedule?.oneTimePeriods
  }

  /**
   * @returns the modified custom schedule for the user
   */
  public getModifiedCustomSchedule(): OneTimePeriod[] | undefined {
    return this.modifiedUserSchedule?.oneTimePeriods
  }

  /**
   * Updates the enabled status of the modified user schedule
   * @param enabled the new enabled status
   */
  public updateUserScheduleEnabled(enabled: boolean): void {
    this.setModifiedUserSchedule(ScheduleProps.ENABLED, enabled)
  }

  /**
   * Updates the dndAutomationEnabled status of the modified user schedule
   * This boolean is set for the entire work schedule period
   * @param dndAutomationEnabled the new dndAutomationEnabled status
   */
  public updateDndAutomationEnabled(dndAutomationEnabled: boolean): void {
    this.setModifiedUserSchedule(ScheduleProps.DND_AUTOMATION_ENABLED, dndAutomationEnabled)
  }

  /**
   * Updates the work schedule of the modified user schedule
   * @param workSchedule the new work schedule
   */
  public updateWorkSchedule(workSchedule: WorkPeriod[]): void {
    this.setModifiedUserSchedule(ScheduleProps.WORK_SCHEDULE, workSchedule)
  }

  /**
   * Updates the custom schedule of the modified user schedule
   * @param customSchedule the new custom schedule
   */
  public updateCustomSchedule(customSchedule: OneTimePeriod[]): void {
    this.setModifiedUserSchedule(ScheduleProps.CUSTOM_SCHEDULE, customSchedule)
  }

  /**
   * Saves the modified user schedule in the backend.
   */
  public saveUserSchedule(): void {
    try {
      // updateUserScheduleBFF is temporary until the backend is ready. This should be a call to the shell bff
      updateUserScheduleBFF(this.modifiedUserSchedule)
    } catch (e) {
      getShellLogger().error('Error sending user schedule', e)
    }
  }
}

let scheduleManagerInstance: ScheduleManager

export const getScheduleManager = (): ScheduleManager => {
  if (!scheduleManagerInstance) {
    scheduleManagerInstance = new ScheduleManager()
  }
  return scheduleManagerInstance
}
