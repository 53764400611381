import { eventEmitter, getEventBus, getNamespaces } from '../../services/namespaces'
import type { GlobalSearchQueryUI } from './global-search-models'
import { addToSearchHistory, navigateToSearchPage } from './helpers'

export const GLOBAL_SEARCH_NAMESPACE = 'GlobalSearch'

export interface GlobalSearchNamespace {
  readonly commands: {
    executeSearch(query: GlobalSearchQueryUI): void
  }
}

export const setupGlobalSearchCommands = async () => {
  const globalSearchNamespace = getNamespaces().retrieve<GlobalSearchNamespace>(GLOBAL_SEARCH_NAMESPACE)
  globalSearchNamespace.commands.executeSearch.addHandler((query: GlobalSearchQueryUI) => {
    addToSearchHistory(query)
    navigateToSearchPage(query)
  })
}

export interface GlobalSearchQueryEventPayload {
  readonly query: GlobalSearchQueryUI
}

export const globalSearchEvents = {
  /**
   * searchFieldQueryChange is emitted when the query changes in the search field
   */
  searchFieldQueryChange: eventEmitter<GlobalSearchQueryEventPayload>(),

  /**
   * resultPageQueryChange is emitted when the query changes in the search results page
   */
  resultPageQueryChange: eventEmitter<GlobalSearchQueryEventPayload>(),

  /**
   * categoriesUpdated is emitted when the Global Search categories are updated
   */
  categoriesUpdated: eventEmitter<void>(),
}
const { emit } = getEventBus().register<typeof globalSearchEvents>(GLOBAL_SEARCH_NAMESPACE, globalSearchEvents)

export const notifySearchFieldQueryChange = (payload: GlobalSearchQueryEventPayload) => {
  emit.searchFieldQueryChange(payload)
}

export const notifyResultPageQueryChange = (payload: GlobalSearchQueryEventPayload) => {
  emit.resultPageQueryChange(payload)
}

export const emitCategoriesUpdated = () => {
  emit.categoriesUpdated()
}

export const executeSearch = (query: GlobalSearchQueryUI) => {
  const globalSearchNamespace = getNamespaces().retrieve<GlobalSearchNamespace>(GLOBAL_SEARCH_NAMESPACE)
  globalSearchNamespace.commands.executeSearch.execute(query)
}
