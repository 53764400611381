import { AuthWeb } from './authentication.web'
import { getExternalInterface } from '../external-interface'
import { getStoreCurrentRoute } from '../../common/store-current-route'
import { getInflightRequest } from './utils'
import { navigateToUrl } from '../../common/helpers'
import { END_MS_TEAMS_AUTHENTICATED_ROUTE, ROOT_ROUTE, START_MS_TEAMS_AUTHENTICATED_ROUTE } from '../../common/routes'
import { getLocationOrigin, getLocationSearch, navigateToExternal } from '../../common/dom-helpers'
import { authCodeVerificationKey } from './constants'
import { getIntegrationAuthRedirectUrl } from '../../core/integrations-helpers'
import { app, authentication } from '@microsoft/teams-js'
import { environment } from '../../environments'
import { getShellLogger } from '../../common/logger'
import { cleanUsePlugin, shouldUsePlugin } from '../external-interface/utils'
import { onWindowUnload } from '@goto/shell-common'
import { getToken } from './token'

export class AuthIntegration extends AuthWeb {
  constructor() {
    super()
    if (shouldUsePlugin()) {
      this.addListeners()

      const handleConnection = (isConnected: boolean) => {
        if (isConnected && getToken()) {
          const url = getInflightRequest() ?? getStoreCurrentRoute() ?? ROOT_ROUTE
          navigateToUrl(url)
        }
      }

      handleConnection(getExternalInterface().available)

      getExternalInterface().addConnectionCallback(e => handleConnection(e.connectionStatus === 'connected'))
    }
  }

  private async getAuthenticateParameters () {
    const context = await app.getContext()
    const clientType = context.app.host.clientType
    const { authUrl, authClientId } = environment()
    const codeChallenge = await this.getCodeChallenge()
    return {
      url:
        getLocationOrigin() +
        START_MS_TEAMS_AUTHENTICATED_ROUTE +
        `?oauthRedirectMethod={oauthRedirectMethod}&authId={authId}&hostRedirectUrl={hostRedirectUrl}&authClientId=${encodeURIComponent(authClientId)}&authUrl=${encodeURIComponent(authUrl)}&codeChallenge=${encodeURIComponent(codeChallenge)}`,
      isExternal: clientType === 'web',
    }
  }

  private async launchAuthenticationFromMsTeamInExternalWindow() {
    if (app.isInitialized()) {
      const authenticationParameter = await this.getAuthenticateParameters()
      const encodedNonce = this.setLocalStorageEncodedNonce()
      authentication
        .authenticate(authenticationParameter)
        .then(authorizationCode => {
          navigateToExternal(
            environment().authRedirectUrl +
              `?code=${authorizationCode}&state=${encodeURIComponent(JSON.stringify({ nonce: encodedNonce, inflightRequest: getLocationOrigin(), redirectUri: getLocationOrigin() + END_MS_TEAMS_AUTHENTICATED_ROUTE }))}`,
          )
        })
        .catch(reason => {
          getShellLogger().error('Authentication for ms-teams without plugin failed: ' + reason)
        })
    } else {
      getShellLogger().error('try to authentication without plugin outside of ms teams application')
    }
  }

  private requestTokenInMsTeams() {
    const searchParams = new URLSearchParams(getLocationSearch())
    const authClientInstance = this.getAuthClientInstance()
    return authClientInstance.requestPKCEToken({
      code_verifier: sessionStorage.getItem(authCodeVerificationKey) ?? '',
      code: searchParams.get('code') ?? '',
      redirect_url: getIntegrationAuthRedirectUrl(),
    })
  }

  protected shouldNavigateToLandingPage() {
    return (
      shouldUsePlugin() &&
      getExternalInterface().supportsCompanion &&
      !getExternalInterface().available &&
      this.isAuthenticationRequired()
    )
  }

  private addListeners(): void {
    const handlePostLogout = () => {
      this.doPostLogout()
    }

    getExternalInterface().addCallback('post-logout', handlePostLogout)

    onWindowUnload(() => {
      getExternalInterface().removeCallback('post-logout', handlePostLogout)
    })
  }

  private doPostLogout(): void {
    this.removeTokens()
    this.navigateToLandingPage()
  }

  async logout() {
    if (shouldUsePlugin()) {
      cleanUsePlugin()
      getExternalInterface().send({ type: 'auth-logout', payload: {} })
    } else {
      cleanUsePlugin()
      super.logout()
    }
  }

  async login() {
    if (shouldUsePlugin()) {
      getExternalInterface().send({ type: 'request-auth-token', payload: {} })
    } else {
      this.launchAuthenticationFromMsTeamInExternalWindow()
    }
  }

  async authProcess() {
    this.navigateToLandingPage()
  }

  requestToken() {
    if (shouldUsePlugin()) {
      return super.requestToken()
    }
    return this.requestTokenInMsTeams()
  }
}
