import { type NotificationChannelInfo, type NotificationChannel } from '@goto/shell-notification-channel'
import { getExternalInterface } from '../../external-interface'
import {
  type NotificationChannelMessagePayload,
  type NotificationChannelMessage,
} from '../../external-interface/messages/notification-channel'
import { ShellNotificationDefaultDispatcher } from './shell-notification-default-dispatcher'
import { type ExternalInterfaceConnectionStatusChangedEvent } from '../../external-interface/external-interface'
import { DesktopCompanionConnectionStatus } from '@getgo/container-client'
import { onWindowUnload } from '../../../common/helpers/window'

export class ShellNotificationIntegrationDispatcher
  extends ShellNotificationDefaultDispatcher
  implements NotificationChannel
{
  private _id: string = ''
  private _channelInfo: NotificationChannelInfo | undefined

  private connectionStatus: 'connected' | 'disconnected' = 'disconnected'

  protected addListeners(): void {
    super.addListeners()

    const externalInterface = getExternalInterface()

    const handleNotificationChannelMessages = (payload: NotificationChannelMessagePayload) => {
      switch (payload.topic) {
        case 'open-check':
          this.handleNotificationChannelOpenCheckEvent()
          break
        case 'open':
          this.handleNotificationChannelOpenEvent(payload as NotificationChannelMessagePayload<'open'>)
          break
        case 'close':
          this.handleNotificationChannelCloseEvent(payload as NotificationChannelMessagePayload<'close'>)
          break
        case 'error':
          this.handleNotificationChannelErrorEvent(payload as NotificationChannelMessagePayload<'error'>)
          break
        case 'message':
          this.handleNotificationChannelMessageEvent(payload as NotificationChannelMessagePayload<'message'>)
          break
      }
    }

    externalInterface.addCallback('notification-channel', handleNotificationChannelMessages)

    const resolveConnectionState = (isConnected: boolean) => {
      if (isConnected) {
        externalInterface.send<NotificationChannelMessage<'open-check'>>({
          type: 'notification-channel',
          payload: {
            topic: 'open-check',
            data: {},
          },
        })
      }
    }

    resolveConnectionState(externalInterface.available)

    const handleConnectionStatusChange = (event: ExternalInterfaceConnectionStatusChangedEvent) => {
      resolveConnectionState(event.connectionStatus === DesktopCompanionConnectionStatus.connected)
    }

    externalInterface.addConnectionCallback(handleConnectionStatusChange)

    onWindowUnload(() => {
      externalInterface.removeCallback('notification-channel', handleNotificationChannelMessages)
      externalInterface.removeConnectionCallback(handleConnectionStatusChange)
    })
  }

  get id(): string {
    return this._id
  }

  get isConnected(): boolean {
    return this.connectionStatus === 'connected'
  }

  get channelInfo(): NotificationChannelInfo | undefined {
    return this._channelInfo
  }

  start(): void {
    // block start of the integration notification channel
  }

  //  This is a private function that is only added as a callback and not called directly as part of this file
  //  TODO: https://jira.ops.expertcity.com/browse/SCORE-2946
  //  Verify either 1) how to test this as a callback on the external interface or 2) whether this can be protected and not private
  /* istanbul ignore next */

  private readonly handleNotificationChannelOpenCheckEvent = () => {
    if (this.isConnected) {
      this.handleOpenEvent()
    }
  }

  protected handleNotificationChannelOpenEvent(payload: NotificationChannelMessagePayload<'open'>) {
    this.connectionStatus = 'connected'
    this._id = payload.data.id
    this._channelInfo = payload.data.channelInfo
    super.handleOpenEvent()
  }

  protected handleNotificationChannelCloseEvent(payload: NotificationChannelMessagePayload<'close'>) {
    this.connectionStatus = 'disconnected'
    super.handleCloseEvent(new CloseEvent('close', payload.data.error))
  }

  protected handleNotificationChannelErrorEvent(payload: NotificationChannelMessagePayload<'error'>) {
    super.handleErrorEvent(payload.data.event)
  }

  protected handleNotificationChannelMessageEvent(payload: NotificationChannelMessagePayload<'message'>) {
    super.handleMessageEvent(payload.data.message)
  }
}
