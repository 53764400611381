import type { ShellHook } from './models'
import { isContainer } from '../../services/container/helpers'
import { isMainWindow } from '../../services/container/windows-management'
import { initializeDockPresenceIndicator } from '../../packages/presence-indicator/dock-presence-indicator'
import { initializeAutoUpdater } from '../../services/update/helpers'
import { initializeDockBadgeCount } from '../../packages/dock-badge-count/dock-badge-count'

export const initializeContainer: ShellHook = async () => {
  if (isContainer() && isMainWindow()) {
    initializeDockPresenceIndicator()
    initializeAutoUpdater()
    initializeDockBadgeCount()
  }
}
