import { getEventBus, eventEmitter } from '../../namespaces/event-bus'

export const CalendarNameSpace = 'Calendar'

export const CalendarEvents = {
  calendarUpdate: eventEmitter<void>(),
} as const

const { emit } = getEventBus().register(CalendarNameSpace, CalendarEvents)

/**
 * Notify that one or more calendar events have changed.
 *
 * We don't have visibilty on which calendar event has changed due to limitations with
 * the backend service.
 */
export const calendarUpdate = (): void => {
  emit.calendarUpdate()
}
