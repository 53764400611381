import { brandDefaultValues } from '../feature-flags'
import type { BrandingMandatoryMembers } from '../feature-flags/models'

/**
 * Takes the name of the component/element which is defined in LaunchDarkly and fetches its branding variation.
 * The variation's value can be a text or an id. If the variation is an id, it will be used to build the path to the image that we need to render
 */
export const getBrandValue = <T extends keyof BrandingMandatoryMembers>(component: T): BrandingMandatoryMembers[T] =>
  brandDefaultValues[component]

/**
 * Takes the id of an assets and creates the path to fetch it.
 */
export const getBrandAssetsPath = (id: string): string => `assets/${id}`
