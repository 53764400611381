import { assert } from '../../common/assert-helpers'
import type { BusyState } from './models'
import { BUSY_STATES } from './models'

/**
 * Assertion function, throws if the given value is not a valid BusyState
 */
export function isValidBusyState(busyState: unknown): asserts busyState is BusyState {
  assert(BUSY_STATES.includes(busyState as BusyState), `${busyState} is not a valid BusyState value`)
}
