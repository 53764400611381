import type { ExtensionConfigProvider } from '../../extensions/extensions-manager-intf'
import { getExtensionsManager } from '../../extensions/extensions-manager'
import { getRuleNames } from '../../extensions/prerequisites/rule'

export const getAccountSettingsQueries = (): Map<string, Set<string>> => {
  const result = new Map<string, Set<string>>()
  const extensionConfigs = (getExtensionsManager() as unknown as ExtensionConfigProvider).getExtensionConfigs()

  extensionConfigs.forEach(extensionConfig => {
    if (extensionConfig?.prerequisites?.accountSettings) {
      const rules = getRuleNames(extensionConfig?.prerequisites?.accountSettings)

      rules.forEach(rule => {
        const [target, value] = rule.split('.')

        if (!result.has(target)) {
          result.set(target, new Set())
        }

        result.get(target)?.add(value)
      })
    }
  })

  return result
}
