import { ShellElement } from '../../../common/shell-element'
import { html, property, state } from 'lit-element'
import { SVG_EDIT_OUTLINED, SVG_PRESENCE_DND, SVG_TRASH_OUTLINED } from '@getgo/chameleon-icons'
import { type OneTimePeriod } from '../schedule-manager/models'
import scheduleCustomHoursCardStyles from './schedule-custom-hours-card.styles.scss'
import { t } from '../../../directives/translate'
import { nothing } from 'lit-html'
import { formatDateTimeRange } from '../../../services/i18n'
import { dateTimeFormatOptions } from '../schedule-settings-models'

export const GoToScheduleCustomHoursCardEvents = {
  edit: 'custom-hours-card-edit',
  delete: 'custom-hours-card-delete',
}

export class GoToScheduleCustomHoursCard extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours-card'

  @property({ type: Object }) oneTimePeriod: OneTimePeriod = {
    name: '',
    startDate: '',
    endDate: '',
    dndEnabled: false,
  }
  @property({ type: Boolean }) isScheduleEnabled = false

  @state() isScheduleInThePast: boolean = false

  static get styles() {
    return scheduleCustomHoursCardStyles
  }

  private handleEditClick() {
    this.dispatchEvent(
      new CustomEvent<{ oneTimePeriod: OneTimePeriod }>(GoToScheduleCustomHoursCardEvents.edit, {
        bubbles: true,
        detail: { oneTimePeriod: this.oneTimePeriod },
      }),
    )
  }

  private handleDeleteClick() {
    this.dispatchEvent(
      new CustomEvent<{ oneTimePeriod: OneTimePeriod }>(GoToScheduleCustomHoursCardEvents.delete, {
        bubbles: true,
        detail: { oneTimePeriod: this.oneTimePeriod },
      }),
    )
  }

  connectedCallback() {
    super.connectedCallback()
    if (this.oneTimePeriod.endDate && this.oneTimePeriod.startDate) {
      const currentDate = new Date()
      const endDate = new Date(this.oneTimePeriod.endDate)
      this.isScheduleInThePast = currentDate > endDate
    }
  }

  private renderEditButton() {
    return html`<chameleon-icon-button
      size="small"
      class="custom-hours-card-edit"
      variant="secondary"
      ?disabled=${!this.isScheduleEnabled}
      @click=${() => this.handleEditClick()}
      ><chameleon-svg>${SVG_EDIT_OUTLINED}</chameleon-svg></chameleon-icon-button
    >`
  }

  private renderDeleteButton() {
    return html`<chameleon-icon-button
      size="small"
      class="custom-hours-card-delete"
      variant="secondary"
      ?disabled=${!this.isScheduleEnabled}
      @click=${() => this.handleDeleteClick()}
      ><chameleon-svg>${SVG_TRASH_OUTLINED}</chameleon-svg></chameleon-icon-button
    >`
  }

  private renderDNDSection() {
    if (this.isScheduleInThePast) {
      return nothing
    }

    if (this.oneTimePeriod.dndEnabled) {
      return html`<div class="custom-hours-card-dnd-enabled">
        <chameleon-svg class="custom-hours-card-dnd-enabled-icon">${SVG_PRESENCE_DND}</chameleon-svg
        ><chameleon-typography variant="caption-medium"
          >${t('Notifications will be turned off during this time')}</chameleon-typography
        >
      </div>`
    }
    return html`<chameleon-typography variant="caption-medium" class="custom-hours-card-dnd-disabled"
      >${t('Notifications may still come through, depending on your profile status')}</chameleon-typography
    >`
  }

  private renderPastStatus() {
    if (this.isScheduleInThePast) {
      return html`<chameleon-chip-v2 size="xsmall" class="custom-hours-card-information-title-status" name="Past"
        >${t('Past')}</chameleon-chip-v2
      >`
    }
    return nothing
  }

  private getFormattedDate(startDate: string, endDate: string) {
    return formatDateTimeRange(new Date(startDate), new Date(endDate), dateTimeFormatOptions)
  }

  render() {
    return html`<div class="custom-hours-card">
      <div class="custom-hours-card-information">
        <div class="custom-hours-card-information-title custom-hours-card-information-row">
          <chameleon-typography variant="body-small-strong" tag="h3">${this.oneTimePeriod.name}</chameleon-typography>
          ${this.renderPastStatus()}
        </div>
        <chameleon-typography class="custom-hours-card-information-row" variant="caption-medium"
          >${this.getFormattedDate(this.oneTimePeriod.startDate, this.oneTimePeriod.endDate)}</chameleon-typography
        >
        ${this.renderDNDSection()}
      </div>
      <div class="custom-hours-card-actions">${this.renderDeleteButton()} ${this.renderEditButton()}</div>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-custom-hours-card': GoToScheduleCustomHoursCard
  }
}
