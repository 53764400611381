import type { TypedResponse } from '../services/auth/authenticatedFetch'
import { HttpResponseError } from './httpResponseError'

export const isFulfilled = <T>(result: PromiseSettledResult<T>): result is PromiseFulfilledResult<T> =>
  result.status === 'fulfilled'

/**
 * Throws an HttpResponseError if the input has a status between 400 and 500 inclusively.
 *
 * @param input - the Response to be asserted
 * @param errorMessage - an error message to be included in the HttpResponseError
 * @throws HttpResponseError
 */
export const assertsIsValidResponse: <T = unknown>(
  input: TypedResponse<T> | undefined,
  errorMessage: string,
) => asserts input is TypedResponse<T> = (input, errorMessage = '') => {
  const { status = 412, statusText = 'Precondition Failed', body = null } = input ?? {}

  if (status >= 400 && status <= 500) {
    throw new HttpResponseError({ message: errorMessage, response: input, status, statusText, body })
  }
}

/**
 * Return true if the error is an AbortError.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 *
 * @param maybeAbortError unknown object that is maybe an AbortError
 * @returns boolean
 */
export const isAbortError = (error: unknown): error is DOMException =>
  error instanceof DOMException && error.name === 'AbortError'
