import { onWindowUnload } from '../../common/helpers/window'
import { type EventBusPrivate } from '../namespaces/event-bus'
import {DisplayNotificationStrategy} from "./eventbus-strategies/display-notification-strategy";
import {DefaultEventBusExternalInterfaceAdapter} from "./default-external-interface-event-adapter";

export const addExternalInterfaceEventAdapter = (eventBus: EventBusPrivate) => {
  const eventAdapter = new DefaultEventBusExternalInterfaceAdapter(eventBus)
  const displayNotificationStrategy = new DisplayNotificationStrategy()
  eventAdapter.addStrategy(displayNotificationStrategy)
  eventBus.setEventAdapter(eventAdapter)
  onWindowUnload(() => {
    eventAdapter.removeStrategy(displayNotificationStrategy)
    eventBus.removeEventAdapter()
  })
}
