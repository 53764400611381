import { getActiveUrlPath } from '../../../common/dom-helpers'

/**
 * Check the location path and determine if it's matching with a link href
 * This must support the following path/link matching:
 *  - Basic matching with trailing slash tolerance: link hrefs `/path` and `/path/` match active url paths `/path` and
 *    `/path/ (in any combination)
 *  - Start of path matching: link href `/path` matches active url path `/path/nested`
 *  - QueryString matching: link href `/path?foo=bar` matches active url path `/path?foo=bar`
 *    - but link href `/path` does not match active url path `/path?foo=bar`. This only supports query string following
 *      path with exact matching order.
 * Should not match:
 * - Deep path matching: link href `/path` does not match active url paths `/deep/path` or `/deep/path/nested`. The
 *   active url path must start with the link href. Reason: we do not want `/voice` and `/meetings` to both match
 *   `/voice/meetings`.
 */

export const linkIsActive = (href: string) => {
  const activeURL = getActiveUrlPath()

  // Do not match if the active url path contains `shell-settings`.
  if (activeURL.match(/^[^?#]*shell-settings/g)) {
    return false
  }

  const hrefCleanedUpForRegex = href
    .replace(/\/$/g, '')    // remove trailing slash (if present)
    .replace(/\?/g, '\\?')  // escape `?` in the href to be `\?` in the regex
  
  // Regex that matches `href` at the beginning of the `activeURL`, strictly followed by either `/`, `&` (for extra
  // query params), or the end of the string.
  const regex = new RegExp(`^(${hrefCleanedUpForRegex})(?:\\/|&|$)`, 'g')
  return regex.test(activeURL)
}
