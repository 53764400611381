import { getDocument } from '../../common/dom-helpers'
import { getDialogService } from '../dialog/public-facade'
import { getFeatureFlagValue } from '../feature-flags'
import { FeatureFlagsVariations } from '../feature-flags/models'
import { getNamespaces } from '.'
import { GotoAssistant } from '../../packages/goto-assistant/goto-assistant'

export const SHELL_HELP_NAMESPACE = 'ShellHelp'

export interface ShellHelpNamespace {
  readonly commands: {
    openGotoAssistant(): void
  }
}

export const setupShellHelpCommands = async () => {
  const shellHelpNamespace = getNamespaces().retrieve<ShellHelpNamespace>(SHELL_HELP_NAMESPACE)
  const isGotoAssistantEnabled = !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_CHATBOT)

  if (isGotoAssistantEnabled) {
    shellHelpNamespace.commands.openGotoAssistant.addHandler(() => {
      getDialogService().open(getDocument().createElement(GotoAssistant.tagName))
    })
  }
}
