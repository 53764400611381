import type { ShellUserAuthorityContextPermissions } from '../../core/models'
import type { AvatarInfo } from '../avatar/models'
import type { Locale } from '../i18n'

/**
 * @deprecated use `shell.user.emails` instead
 */
export interface UserProfileEmail {
  readonly value: string
  readonly type: string
  readonly primary: boolean
}

/**
 * @deprecated use `shell.user.meta` && `shell.user.location` instead
 */
export interface UserProfileMeta {
  readonly created: string
  readonly location: string
}

/**
 * @deprecated use `shell.user.name` instead
 */
export interface UserProfileName {
  readonly givenName: string
  readonly familyName: string
}

export enum UserProfileEntitlement {
  GoToMeeting = 'g2m',
  GoToWebinar = 'g2w',
  Concierge = 'concierge',
  Grasshopper = 'grasshopper',
  OpenVoice = 'openvoice',
  GoToMyPC = 'g2p',
  GoToTraining = 'g2t',
  GoToAssist = 'g2a',
  GoToAssistSeeit = 'g2aseeit',
  GoToAssistServiceDesk = 'g2asd',
  GoToAssistRemoteSupport = 'g2ars',
  PromptAI = 'prompt',
  Jive = 'jive',
  HVoice = 'hvoice',
  RescueAssist = 'rescueAssist',
  GoToConnect = 'gotoconnect',
}

/**
 * @deprecated use `shell.user` instead
 */
export interface UserProfileInfo {
  readonly displayName: string
  readonly emails: readonly UserProfileEmail[]
  /**
   * @deprecated use `shell.user.entitlements` instead
   */
  readonly entitlements: readonly UserProfileEntitlement[]
  readonly error?: Error
  readonly id: string
  readonly locale: Locale
  readonly meetingId?: string
  readonly meta: UserProfileMeta
  readonly name: UserProfileName
  readonly schemas?: readonly string[]
  readonly meetingSubject?: string
  readonly timezone: string
  readonly userName: string
}

/**
 * @deprecated use `shell.user` instead
 */
export interface UserAdminInfo {
  readonly key: string
  readonly accountKey: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly locale: string
  readonly accounts: readonly Account[]
  readonly settings?: Settings
}

type SettingsKeys = 'G2ARS' | 'G2ASEEIT' | 'G2M' | 'G2T' | 'G2W' | 'OPENVOICE'
type Settings = Partial<Record<SettingsKeys, { readonly enabledDate?: number; readonly [key: string]: unknown }>>

/**
 * @deprecated use `shell.user` instead
 */
export interface UserFromExternalAdmin {
  readonly canAuthenticate: boolean
  readonly adminRoles?: readonly string[]
  readonly email: string
  readonly emailLocked: boolean
  readonly firstName: string
  readonly managedGroupKey?: readonly string[]
  readonly groupKey?: string
  readonly groupKeyPath?: readonly string[]
  readonly groupName?: string
  readonly groupNamePath?: readonly string[]
  readonly groups: readonly {
    readonly key: string
    readonly keyPath: readonly string[]
    readonly name: string
    readonly namePath: readonly string[]
  }[]
  readonly identityReadOnly: boolean
  readonly inviteCount: number
  readonly enabledDate: number
  readonly key: string
  readonly lastName: string
  readonly licenseKeys?: readonly string[]
  readonly locale: string
  readonly nameLocked: boolean
  readonly passwordCreated: boolean
  readonly products?: readonly string[]
  readonly settings?: Settings
  readonly status: string
  readonly systemGeneratedEmail: boolean
  readonly timezone: string
}

/**
 * @deprecated use `shell.user.accounts` list to get `licenses` list for this account
 */
export interface License {
  readonly channel: string
  readonly description: string
  readonly enabled: boolean
  readonly key: string
  readonly products: readonly string[]
  readonly type: string
}

/**
 * @deprecated use `shell.user.accounts` instead
 */
export interface Account {
  readonly enabledDate?: number
  readonly key: string
  readonly licenses?: readonly License[]
  readonly name?: string
  readonly toggleUnifiedAdmin?: boolean
  readonly unifiedAdmin?: boolean
  readonly slowChannelEnabled?: boolean
  readonly partnerAccountKey?: string
}

/**
 * @deprecated Use ShellUserInfo instead ex: `shell.user`
 */
export interface IdentityApi {
  readonly me?: UserProfileInfo
  readonly avatar?: AvatarInfo
  readonly meFromExternalAdmin?: UserAdminInfo
  /**
   * @deprecated use shell.user.hasPermissions instead
   */
  readonly permissions: readonly ShellUserAuthorityContextPermissions[]
  /**
   * @deprecated use shell.user.hasPermissions instead
   */
  readonly hasPermissions: (permissions: readonly ShellUserAuthorityContextPermissions[]) => boolean
}

export interface UserInfoQueryResponse {
  readonly data: UserInfo
  readonly errors: GraphQLError[]
}

export interface GraphQLError {
  readonly code: string
  readonly message: string
}
export class CustomGraphQlError extends Error {
  constructor(private readonly errors: GraphQLError[]) {
    super()
  }
  toString() {
    return this.errors.map(error => `Code: ${error.code}, Message: ${error.message}`).join('\n')
  }
}

export interface UserInfo {
  readonly accounts?: readonly AccountInfoFromGraphQL[]
  readonly user: UserInfoFromGraphQL
}

//TODO: update naming to remove 'FromGraphQL' once other interfaces are deleted/deprecated
//https://jira.ops.expertcity.com/browse/SCORE-1898
export interface UserInfoFromGraphQL {
  readonly email: string
  readonly createTime: string
  readonly firstName: string
  readonly key: string
  readonly lastName: string
  readonly locale: Locale
  readonly timeZone: string
  readonly location: string
}

//TODO: update naming to remove 'FromGraphQL' once other interfaces are deleted/deprecated
//https://jira.ops.expertcity.com/browse/SCORE-1898
export interface AccountInfoFromGraphQL {
  readonly name: string
  readonly key: string
  readonly unifiedAdmin: boolean
  readonly licenses?: readonly LicenseInfoFromGraphQL[] | null
  readonly settings: SettingsInfoFromGraphQL
  readonly slowChannelEnabled: boolean
  readonly partnerAccountKey: string
}

//TODO: update naming to remove 'FromGraphQL' once other interfaces are deleted/deprecated
//https://jira.ops.expertcity.com/browse/SCORE-1898
export interface LicenseInfoFromGraphQL {
  readonly accountKey: string
  readonly description: string
  readonly roles?: readonly string[] | null
  readonly channel: string
  readonly enabled: boolean
  readonly key: string
  readonly type: string
}

//TODO: update naming to remove 'FromGraphQL' once other interfaces are deleted/deprecated
//https://jira.ops.expertcity.com/browse/SCORE-1898
export interface SettingsInfoFromGraphQL extends Record<string, Record<string, boolean | null | undefined>> {
  readonly meeting: SettingsInfoMeetingGraphQL
  readonly phone: SettingsInfoPhoneFromGraphQL
}

export interface SettingsInfoMeetingGraphQL extends Record<string, boolean | null | undefined> {
  readonly gotoAppEnabled: boolean
  readonly gotoAppEntitled: boolean
  readonly gotoAppProvisioned: boolean
}

export interface SettingsInfoPhoneFromGraphQL extends Record<string, boolean | null | undefined> {
  readonly engagePlgOptOut: boolean | null
  readonly inboxPlgOptOut: boolean | null
  readonly textAssistantTermsOfServiceAccepted: boolean | null
  readonly textAssistantTermsOfServiceAcceptedByAdmin: boolean | null
  readonly goToAdminIntegrations: boolean | null
}
