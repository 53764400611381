import { type KeyboardShortcutId, type KeyboardShortcutsUnsubscribeFn, Plugins } from '@getgo/container-client'
import { openNewTabFor } from '../../common/dom-helpers'
import { navigateToUrl } from '../../common/helpers'
import { type NavigationItemUI } from '@goto/shell-common'

export class NavigationKeyboardShortcutService {
  private subscriptions: Array<KeyboardShortcutsUnsubscribeFn> = []

  /**
   * Subscribe each item in the navigation to a Keyboard shortcut by index
   * container-client have navigation1 to navigation9 keyboardShortcuts ids to subscribe to
   */
  subscribeNavItemShortcuts(links: NavigationItemUI[]) {
    this.unsubscribeNavItemsShortcuts().then(() => {
      links.map(async (link, index) => {
        const navigationIndex = index + 1
        // The maximum number of navigation subscribe in container is 9 (1 to 9)
        if (navigationIndex <= 9) {
          const keyboardShortcutId = `navigation${navigationIndex}` as KeyboardShortcutId

          Plugins.KeyboardShortcuts.subscribe(keyboardShortcutId, window, () => {
            if (link.externalLink) {
              openNewTabFor(link.pathname)
            } else {
              navigateToUrl(link.pathname)
            }
          })
            .then(unsubscribeFn => {
              this.subscriptions.push(unsubscribeFn)
            })
            .catch(error => {
              console.error('Failed to subscribe to keyboard shortcut', error)
            })
        }
      })
    })
  }

  private async unsubscribeNavItemsShortcuts() {
    for (const unsubscribeFn of this.subscriptions) {
      await unsubscribeFn()
    }
    this.subscriptions = []
  }
}
