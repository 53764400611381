import { areEquals } from '../../common'
import { getWindow } from '../../common/dom-helpers'
import { onWindowUnload } from '../../common/helpers/window'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import type { RoutingListener } from '../../common/single-spa-events'
import { assertIsSingleSpaCustomEvent, SINGLE_SPA_ROUTING_EVENT } from '../../common/single-spa-events'
import { getMountedShellApps } from '../../experiences/helpers'
import {
  getContextLocalStorageCurrentMountedApps,
  setContextLocalStorageCurrentMountedApps,
} from '../../services/context'
import { emitAppChangedEvent, emitRouteChangedEvent } from '../../services/shell-namespace'

export const routingEventListener: RoutingListener<void> = event => {
  assertIsSingleSpaCustomEvent(event)
  emitRouteChangedEvent()

  const externalUserKey = getShellApiInstance().user.key ?? ''

  const previousMountedApps = getContextLocalStorageCurrentMountedApps(externalUserKey)
  const mountedApps = getMountedShellApps()

  if (!areEquals(mountedApps, previousMountedApps)) {
    setContextLocalStorageCurrentMountedApps(externalUserKey, mountedApps)

    emitAppChangedEvent({
      from: previousMountedApps,
      to: mountedApps,
    })
  }
}

export const initRoutingEventListener = () => {
  const listener: EventListener = event => routingEventListener(event)

  getWindow().addEventListener(SINGLE_SPA_ROUTING_EVENT, listener)
  onWindowUnload(() => {
    getWindow().removeEventListener(SINGLE_SPA_ROUTING_EVENT, listener)
  })
}
