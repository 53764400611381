import { waitForElement } from './dom-helpers'
import { GOTO_SETTINGS_SELECTOR } from './container'
import { waitForNextEventCycle } from './helpers'
import { SHELL_SETTINGS_ROUTE } from './routes'

/**
 * Create the setting href use to navigate to the setting page
 * Shell settings are under a base path that must be prepend (SHELL_SETTINGS_ROUTE) in addition with an optinal section name
 * @param href
 * @param baseRoute
 * @returns
 */
export const createSettingsHref = (href: string, baseRoute?: string) => {
  const cleanHref = href.replace(/^\/+/gm, '')
  const shellSettingHref = baseRoute
    ? `${SHELL_SETTINGS_ROUTE}/${baseRoute}/${cleanHref}`
    : `${SHELL_SETTINGS_ROUTE}/${cleanHref}`
  return shellSettingHref
}

export const waitForSettingsContainer = async () => {
  await waitForElement(GOTO_SETTINGS_SELECTOR, document.body)
  await waitForNextEventCycle()
}

