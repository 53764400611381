import { AppStateService } from './app-state-service'
import type { AppStateAPI } from './public-api'

let _appStateService: AppStateService | undefined
let _appStateAPI: AppStateAPI | undefined

export const getAppStateService = () => {
  if (!_appStateService) {
    _appStateService = new AppStateService()
  }

  return _appStateService
}

export const getAppStateAPI = (): AppStateAPI => {
  const appStateService = getAppStateService()
  const { setCheckPointState, getAppState } = appStateService

  if (!_appStateAPI) {
    _appStateAPI = {
      setCheckPointState: setCheckPointState.bind(appStateService),
      getAppState: getAppState.bind(appStateService),
    }
  }

  return _appStateAPI
}
