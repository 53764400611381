/**
 * Calculates the number of days between two timestamps in milliseconds.
 *
 * @param date1 - The first timestamp.
 * @param date2 - The second timestamp.
 * @returns The number of days between the two timestamps.
 */
export const getDaysBetweenTimestampsMs = (date1: number, date2: number): number => {
  // Convert the dates to local time at midnight to handle daylight savings changes
  const oneDay = 24 * 60 * 60 * 1000 // Hours * Minutes * Seconds * Milliseconds

  // Ensure we compare the time at midnight by setting hours to 0
  const date1Midnight = new Date(date1)
  date1Midnight.setHours(0, 0, 0, 0)

  const date2Midnight = new Date(date2)
  date2Midnight.setHours(0, 0, 0, 0)

  // Convert to milliseconds since epoch
  const date1Time = date1Midnight.getTime()
  const date2Time = date2Midnight.getTime()

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = date1Time - date2Time

  // Convert milliseconds to full days, truncating towards zero
  return Math.trunc(differenceInMilliseconds / oneDay)
}
