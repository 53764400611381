import { Presence } from '@getgo/chameleon-core'
import { getShellLogger } from '../../common/logger'
import { environment } from '../../environments'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { authenticatedFetch } from '../auth'
import type { NotificationChannelEvent } from '../notification-channel/models'
import { NotificationChannelMessageType } from '../notification-channel/models'
import type {
  CreateSubscriptionsResponse,
  GetAppearanceResponse,
  PresenceMessage,
  ShellServiceSubscriptionState,
  UserPresenceMessage,
  Presence as UccPresence,
  PresenceSubscription,
} from './models'
import { Appearance } from './models'
import { getJiveApiBaseUrl } from '../../core/environment'
import type { ExternalUserKey } from '../../core/models'

const presenceToAppearanceDict: Record<Presence, Appearance> = {
  [Presence.AVAILABLE]: Appearance.ONLINE,
  [Presence.BUSY]: Appearance.BUSY,
  [Presence.DND]: Appearance.DO_NOT_DISTURB,
  [Presence.DO_NOT_DISTURB]: Appearance.DO_NOT_DISTURB,
  [Presence.AWAY]: Appearance.AWAY,
  [Presence.OFFLINE]: Appearance.OFFLINE,
  [Presence.DISCONNECTED]: Appearance.OFFLINE,
}

export const convertAppearanceToPresence = (appearance: Appearance): Presence =>
  (Object.keys(presenceToAppearanceDict).find(
    key => presenceToAppearanceDict[key as Presence] === appearance,
  ) as Presence) ?? Presence.OFFLINE

export const convertPresenceToAppearance = (presence: Presence): Appearance =>
  presenceToAppearanceDict[presence] ?? Appearance.OFFLINE

export const isCurrentUser = (externalUserKey: string) => {
  const currentExternalUserKey = getShellApiInstance().user.key ?? ''
  return currentExternalUserKey === externalUserKey
}

export const createPresenceSubscription = async (
  externalUserKeys: readonly ExternalUserKey[],
  serviceSubscription: Promise<CreateSubscriptionsResponse>,
): Promise<readonly PresenceSubscription[]> => {
  try {
    const subscriptionResponse = await serviceSubscription
    return subscriptionResponse.items
  } catch (error) {
    getShellLogger().error(
      `failed to create presence subscription for external user key ${externalUserKeys} with error: ${error}`,
    )

    return await Promise.reject()
  }
}

export const isPresenceMessage = (
  message: NotificationChannelEvent<unknown>,
): message is NotificationChannelEvent<PresenceMessage> => message.data.type === NotificationChannelMessageType.presence

/**
 * A UserPresence is related to that targetted user's context
 * (i.e. the user currently signed in)
 * and is not necessarily exclusive from presenceUpdate event.
 */
export const isUserPresenceMessage = (
  message: NotificationChannelEvent<unknown>,
): message is NotificationChannelEvent<UserPresenceMessage> =>
  isPresenceMessage(message) && isCurrentUser(message.data.content.externalUserKey)

export const getUsersAppearance = async (externalUserKeys: readonly string[]): Promise<readonly UccPresence[]> => {
  if (externalUserKeys.length > 0) {
    const params = new URLSearchParams()
    externalUserKeys.forEach(userKey => params.append('userKey', userKey))

    try {
      // TODO Remove this 'if' in SCORE-1220
      // We need to bypass this auth because jiveApiBaseUrl in RC is not deployed yet.
      if (environment().environment === 'rc') {
        const uccPresence: UccPresence[] = []
        return await Promise.resolve(uccPresence)
      }

      const response = await authenticatedFetch<GetAppearanceResponse>(
        `${getJiveApiBaseUrl()}/presence/v1/presence?${params.toString()}`,
      )
      return (await response.json()).items
    } catch (err) {
      getShellLogger().error(`failed to get user appearance: ${err}`)
    }
  }
  return []
}

export const convertUsersAppearanceToPresenceSnapshots = (usersAppearance: readonly UccPresence[]) =>
  usersAppearance.map(userAppearance => {
    const { timestamp, externalUserKey } = userAppearance
    const presence = convertAppearanceToPresence(userAppearance.appearance)
    return {
      timestamp,
      externalUserKey,
      presence,
    }
  })

export const hasSubscribers = (subscription: ShellServiceSubscriptionState | undefined): boolean =>
  !!(subscription && subscription.subscribersCount > 0)
