import { type ExternalInterface } from './external-interface'
import { ExternalInterfaceAdapter } from './external-interface-adapter'
import { externalInterfaceIntegrationConnectionHandler } from './external-interface-integration-connection-handler'
import { externalInterfaceCommandDispatcher } from './external-interface-command-dispatcher'
import { getWindow } from '../../common/dom-helpers'
import { externalInterfaceCompanionConnectionHandler } from './external-interface-companion-connection-handler'
import { externalInterfaceAppStateListener } from './external-interface-app-state-listener'
import { externalInterfaceAppStateDispatcher } from './external-interface-app-state-dispatcher'
import {
  externalInterfaceCompanionNavigationHandler,
  externalInterfaceIntegrationNavigationHandler,
} from './external-interface-navigation'
import { externalInterfaceCompanionUpdateListener } from './external-interface-companion-update-listener'
import { externalInterfaceCompanionUpdateDispatcher } from './external-interface-companion-update-dispatcher'
import { shouldUsePlugin } from './utils'

export const createExternalInterface = (): ExternalInterface => {
  const externalInterface = new ExternalInterfaceAdapter()
  if (shouldUsePlugin()) {
    externalInterfaceAppStateListener(externalInterface)
    externalInterfaceAppStateDispatcher(externalInterface)
    externalInterfaceCompanionConnectionHandler(externalInterface)
    externalInterfaceIntegrationConnectionHandler(externalInterface)
    externalInterfaceCommandDispatcher(externalInterface)
    externalInterfaceIntegrationNavigationHandler(externalInterface)
    externalInterfaceCompanionNavigationHandler(externalInterface)
    externalInterfaceCompanionUpdateListener(externalInterface)
    externalInterfaceCompanionUpdateDispatcher(externalInterface)
  }
  return externalInterface
}

export const getOpenerExternalInterface = () => {
  // In some cases, we might have a Cross Origin Window opening up a Shell based app
  // from another location
  try {
    return getWindow().opener?.shellExternalInterface
  } catch {
    return undefined
  }
}
