import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import type { NotificationSettings } from './models'

export const NotificationSettingsNamespace = 'NotificationSettings'

export const NotificationSettingsEvents = {
  notificationSettingsUpdate: eventEmitter<NotificationSettings>(),
}

const { emit } = getEventBus().register<typeof NotificationSettingsEvents>(
  NotificationSettingsNamespace,
  NotificationSettingsEvents,
)

/**
 * Updates the values of the notification settings
 */
export const updateNotificationSettings = (event: NotificationSettings): void => {
  emit.notificationSettingsUpdate(event)
}
