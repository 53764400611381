import { getAuthInstance } from './authentication.factory'
import type { LoginOptions } from './models'

export const login = async (options?: LoginOptions) => await (await getAuthInstance()).login(options?.inflightPathname)

/*
  In the container while we are doing automatic process (refresh, 401 on page opening)
  we want to be abe to login once and only once pending the user/browser response
*/
export const loginOnce = async () => await (await getAuthInstance()).loginOnce()

export const logout = async () => await (await getAuthInstance()).logout()

export const auth = async () => await (await getAuthInstance()).auth()

export const isAuthenticationRequired = async () => (await getAuthInstance()).isAuthenticationRequired()

export const setUnguardedRoutes = async (unguardedRoutes: readonly string[]) =>
  (await getAuthInstance()).setUnguardedRoutes(unguardedRoutes)
