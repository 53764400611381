import { Subject } from 'rxjs'

export class IsLoggedInState extends Subject<boolean> {
  private static instance: IsLoggedInState | null = null
  private isLoggedIn = false

  private constructor() {
    super()
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new IsLoggedInState()
    }
    return this.instance
  }

  public update(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn
    this.next(isLoggedIn)
  }

  public waitIsLoggedInState() {
    return this.isLoggedIn ? Promise.resolve(true) : Promise.resolve(false)
  }
}
