import { getFromLocalStorage, setToLocalStorage } from '../../../common/dom-helpers'
import { LocalStorageKeys } from '../../../environments'
import { authenticatedFetch } from '../../auth'
import { getExternalInterface } from '../../external-interface'
import { emitUserPreferencesUpdatedEvent } from '../events'
import { type UserPreferencesAPI, type UserPreferencesConnectorParams } from '../models'
import { UserPreferencesConnectorAdapter } from './user-preferences-connector-adapter'
import { shouldUsePlugin } from '../../external-interface/utils'

export const LOCAL_STORAGE_KEY = LocalStorageKeys.userPreferences

export class UserPreferencesServiceConnector implements UserPreferencesAPI {
  private readonly apiBaseUrl: string

  constructor(params?: UserPreferencesConnectorParams) {
    this.apiBaseUrl = params?.apiUrl ?? ''
  }

  /**
   * Retrieves ALL of the user preferences from local storage or the given API url
   * @returns User Preferences object
   */
  getPreferences = async (): Promise<any> => {
    if (!this.apiBaseUrl) {
      return this.getPreferencesFromLocalStorage() ?? {}
    }
    const query = {
      method: 'GET',
    }
    return (await this.queryAPI(`${this.apiBaseUrl}/preferences`, query)) ?? {}
  }

  /**
   * Retrieves A SPECIFIC user preference from local storage or the given API url
   * @param path - the specific reference to the user preference to be retrieved
   * @param defaultValue - a default value to be returned if no reference is found
   * @returns User Preferences object
   */
  getPreference = async <T, R>(path: string, defaultValue?: T): Promise<R> => {
    if (!this.apiBaseUrl) {
      const preferences = await this.getPreferences()
      return this.getPreferenceFromLocalStorage(preferences, path, defaultValue)
    }
    const query = {
      method: 'GET',
      body: { path, defaultValue },
    }
    return await this.queryAPI(`${this.apiBaseUrl}/preference`, query)
  }

  /**
   * Sets A SPECIFIC user preference in local storage or the given API url
   * @param path - the specific reference to the user preference to be set
   * @param data - the data to be set for the given reference
   * @returns User Preferences object
   */
  setPreference = async <T>(path: string, data: T): Promise<void> => {
    if (!this.apiBaseUrl) {
      const preferences = await this.getPreferences()
      return this.setPreferenceInLocalStorage(preferences, path, data)
    }
    const query = {
      method: 'POST',
      body: { path, data },
    }
    await this.queryAPI(`${this.apiBaseUrl}/preference`, query)
  }

  /**
   * Deletes A SPECIFIC user preference in local storage or the given API url
   * @param path - the specific reference to the user preference to be deleted
   * @returns User Preferences object
   */
  deletePreference = async (path: string): Promise<void> => {
    if (!this.apiBaseUrl) {
      const preferences = await this.getPreferences()
      return this.deletePreferenceFromLocalStorage(preferences, path)
    }
    const query = {
      method: 'DELETE',
      body: { path },
    }
    await this.queryAPI(`${this.apiBaseUrl}/preference`, query)
  }

  /**
   * Retrieves ALL of the user preferences from the local storage
   * @returns User Preferences object
   */
  private readonly getPreferencesFromLocalStorage = () => {
    const preferences = getFromLocalStorage(LOCAL_STORAGE_KEY)
    if (preferences) {
      return JSON.parse(preferences)
    }
  }

  /**
   * Performs a fetch call to the specified API
   * @param url - the URL of the API to be fetched
   * @param query - the HTTP request object
   * @returns a HTTP response
   */
  private readonly queryAPI = async <R>(url: string, query?: any): Promise<R> => {
    const response = await authenticatedFetch(url, query)
    return await response.json()
  }

  private readonly getPreferenceFromLocalStorage = (preferences: any, path: string, defaultValue: any) => {
    const foundPreference = this.getPreferenceByPath(preferences, path)
    return foundPreference ?? defaultValue
  }

  private readonly setPreferenceInLocalStorage = (preferences: any, path: string, data: any) => {
    const updatedPreferences = this.setPreferenceByPath(preferences, path, data)
    setToLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(updatedPreferences))
    emitUserPreferencesUpdatedEvent({ path, data })
  }

  private readonly deletePreferenceFromLocalStorage = (preferences: any, path: string) => {
    const updatedPreferences = this.deletePreferenceByPath(preferences, path)
    setToLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(updatedPreferences))
    emitUserPreferencesUpdatedEvent({ path, data: null })
  }

  /**
   * Finds the value for a given string path
   * E.g. "shortcuts.keyboard.f1" would return the F1 keyboard shortcut value
   * @param path - the string path to an entry in the user preferences
   * @returns the value of a given string path
   */
  private readonly getPreferenceByPath = (preferences: any, path: string): any => {
    path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    path = path.replace(/^\./, '') // strip a leading dot
    const splitPath = path.split('.')
    for (let i = 0, n = splitPath.length; i < n; ++i) {
      const key = splitPath[i]
      if (key in preferences) {
        preferences = preferences[key]
      } else {
        return
      }
    }
    return preferences
  }

  /**
   * Sets the value for a given string path
   * E.g. ("shortcuts.keyboard.f1", "backspace") would set F1 shortcut to "backspace"
   * @param preferences - the current user preferences object
   * @param path - the string path to a new or existing entry in the user preferences
   * @param data - the value to be set for the given string path
   * @returns updated user preferences
   */
  private readonly setPreferenceByPath = (preferences: any, path: string, data: any): any => {
    const [head, ...rest] = path.split('.')

    return {
      ...preferences,
      [head]: rest.length ? this.setPreferenceByPath(preferences[head], rest.join('.'), data) : data,
    }
  }

  /**
   * Deletes the value for a given string path
   * E.g. "shortcuts.keyboard.f1" would delete the F1 shortcut preference
   * @param preferences - the current user preferences object
   * @param path - the string path to an existing entry in the user preferences
   * @returns updated user preferences with removed preference
   */
  private readonly deletePreferenceByPath = (preferences: any, path: string): any => {
    const [head, ...rest] = path.split('.')

    if (rest.length) {
      return {
        ...preferences,
        [head]: this.deletePreferenceByPath(preferences[head], rest.join('.')),
      }
    }

    delete preferences[head]
    return preferences
  }
}

let userPreferencesConnector: UserPreferencesAPI

export const createUserPreferencesConnector = (params?: UserPreferencesConnectorParams): UserPreferencesAPI => {
  const userPreferencesAPI: UserPreferencesAPI = new UserPreferencesServiceConnector(params)
  if (getExternalInterface().isCompanion || (getExternalInterface().isIntegration && shouldUsePlugin())) {
    return new UserPreferencesConnectorAdapter(userPreferencesAPI)
  }
  return userPreferencesAPI
}

export const getUserPreferencesConnector = (params?: UserPreferencesConnectorParams): UserPreferencesAPI => {
  if (!userPreferencesConnector) {
    userPreferencesConnector = createUserPreferencesConnector(params)
  }

  return userPreferencesConnector
}

export type UserPreferencesConnector = UserPreferencesServiceConnector
