import type { ExtensionConfig } from '@goto/shell-common'
import { getFeatureFlag, getLaunchDarklyFlag } from '../helpers/shell-helpers'
import { LaunchDarklyVariations, UnleashFlags } from '../models/feature-flag.models'
import { getOOSArtifactUrl } from '../helpers/experience-helpers'
import { createIntegrationConfigIfFlagEnabled } from '../helpers/integration-helpers'

const integrationFlagSuffix = 'oos-meeting-background-service'

const getExtensionVersion = () => {
  const useUnleash = getFeatureFlag(UnleashFlags.OOS_USE_UNLEASH, false)

  return useUnleash
    ? getFeatureFlag(UnleashFlags.OOS_MEETING_BG_SERVICE_VERSION, 'release')
    : getLaunchDarklyFlag(LaunchDarklyVariations.OOS_MEETING_BG_SERVICE_VERSION)
}

const extensionConfig: ExtensionConfig = {
  id: '@goto/oos-meeting-background-service',
  get url() {
    const version = getExtensionVersion()
    return new URL(getOOSArtifactUrl('meeting-background-service-extension', version))
  },
  get integrationConfig() {
    return createIntegrationConfigIfFlagEnabled(integrationFlagSuffix)
  },
}

export default extensionConfig
