import { onWindowUnload } from './helpers/window'
import type { BadgeEvents } from '../services/badge'
import { BadgeNamespace } from '../services/badge'
import type { BadgeConfig } from '../services/badge/models'
import { getEventBus } from '../services/namespaces/event-bus'
import { ACTIVITY_ROUTE, getNavigationIdByRoute, MESSAGE_ROUTE, VOICEMAIL_ROUTE } from './routes'

export const subscribeToBadgeEvent = () => {
  const { badgeNumberUpdated } = getEventBus().subscribeTo<typeof BadgeNamespace, typeof BadgeEvents>(BadgeNamespace)
  badgeNumberUpdated.addListener(setToBadgeNumbers)

  onWindowUnload(() => {
    badgeNumberUpdated.removeListener(setToBadgeNumbers)
  })
}

// If we receive a badge event from the old event bus badge update, we're setting the badge value with the new badging service
const setToBadgeNumbers = (event: BadgeConfig) => {
  const badgeNumberInfo = {
    id: getNavigationIdByRoute(event.route),
    value: event.value,
    showInNavigation:
      event.route === ACTIVITY_ROUTE || event.route === MESSAGE_ROUTE || event.route === VOICEMAIL_ROUTE,
    showInDock: event.route === ACTIVITY_ROUTE || event.route === MESSAGE_ROUTE,
  }
  shell.badgeNumbers.setBadgeNumber(badgeNumberInfo)
}
