import type { Part } from 'lit-html'
import { directive } from 'lit-html'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'

/**
 * Default person name format
 * @property givenName string represented by firstName in NorthAmerica
 * @property familyName string represented by lastName in NorthAmerica
 */
const defaultPersonNameFormat = asTranslationKey('personNameFormat-MEDIUM')

/**
 * PersonName model
 * @property givenName string
 * @property familyName string
 */
export interface PersonName extends Record<string, unknown> {
  readonly givenName: string
  readonly familyName: string
}

/**
 * A helper function that format the person name in the appropriate locale
 * @param personName PersonName
 * @returns string
 */
export const formatPersonName = (personName: PersonName) =>
  getTranslation(defaultPersonNameFormat, { ...personName, defaultValue: '{{givenName}} {{familyName}}' })
/**
 * Directive that outputs the formatted person name based on the locale. It also updates if the locale changes.
 * @param personName person names to format
 */
export const personName = directive(personName => (part: Part) => {
  part.setValue(formatPersonName(personName))
  part.commit()
})
