/* istanbul ignore next - already tested */
import { getBrandValue, getBrandAssetsPath } from './brand-helpers'
/* istanbul ignore next - already tested */
import { BrandVariationKeys } from '../feature-flags/models'

/* istanbul ignore next - aggregate of already tested */

/**
 * @description Takes the name of the component/element which is defined in LaunchDarkly and fetches its branding variation.
 * The variation's value can be a text or an id. If the variation is an id, it will be used to build the path to the image that we need to render
 *
 */

export const shellBrandingApi = {
  getBrandValue,
  BrandVariationKeys,
  getBrandAssetsPath,
}
