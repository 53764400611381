import { type GotoNotificationChannel } from '@goto/shell-notification-channel'
import { ShellNotificationDefaultDispatcher } from './shell-notification-default-dispatcher'
import { getExternalInterface } from '../../external-interface'
import { ShellNotificationIntegrationDispatcher } from './shell-notification-integration-dispatcher'
import { ShellNotificationCompanionDispatcher } from './shell-notification-companion-dispatcher'
import { shouldUsePlugin } from '../../external-interface/utils'

export const createNotificationDispatcher = (
  notificationChannel: GotoNotificationChannel,
): ShellNotificationDefaultDispatcher => {
  if (getExternalInterface().isIntegration && shouldUsePlugin()) {
    return new ShellNotificationIntegrationDispatcher(notificationChannel)
  }
  if (getExternalInterface().isCompanion) {
    return new ShellNotificationCompanionDispatcher(notificationChannel)
  }

  return new ShellNotificationDefaultDispatcher(notificationChannel)
}
