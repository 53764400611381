import { getNavigatorUserAgent } from './dom-helpers'

interface BrowserVersion {
  readonly name: string | undefined
  readonly version: string | undefined
  readonly major?: string | undefined
}

export interface OSData {
  readonly name: string | undefined
  readonly version: string | undefined
  readonly major: string | undefined
}

export interface MobileDownloadInfo {
  readonly type: string
  readonly imageSrc: string
  readonly downloadUrl: string
}

export const iPhoneDownloadInfo = {
  type: 'iPhone',
  imageSrc: 'assets/mobileDownload/app-store-badge.svg',
  downloadUrl: 'https://apps.apple.com/us/app/goto/id1465614785',
}

export const androidDownloadInfo = {
  type: 'Android',
  imageSrc: 'assets/mobileDownload/google-play-badge.png',
  downloadUrl: 'https://play.google.com/store/apps/details?id=com.logmein.gotoconnect&pli=1',
}

/**
 * Parses the OS information from the navigator userAgent
 * @returns an object of type OSData
 */
const parseUserAgentOS = (): OSData => {
  const userAgent = getNavigatorUserAgent()

  const operatingSystems: { [name: string]: RegExp } = {
    'Mac OS': /Mac OS X ([0-9]\d*((\.|_)[0-9]\d*)*)/,
    Windows: /Windows NT ([0-9]\d*((\.|_)[0-9]\d*)*)/,
    Android: /Android ([0-9]\d*((\.|_)[0-9]\d*)*)/,
  }

  for (const osName in operatingSystems) {
    const regex = operatingSystems[osName]
    const match = regex.exec(userAgent)
    if (match) {
      const version = match[1]?.replace(/_/g, '.')
      return {
        name: osName,
        version: version,
        major: version?.split('.')[0],
      }
    }
  }
  return { name: undefined, version: undefined, major: undefined }
}

/**
 * Parses the Browser information from the navigator userAgent
 * @returns an object of type BrowserVersion
 */
const parseUserAgentBrowser = (): BrowserVersion => {
  const userAgent = getNavigatorUserAgent()

  const browsers: { [name: string]: RegExp } = {
    Firefox: /Firefox\/([0-9]\d*(\.[0-9]\d*)*)/,
    Edge: /Edg\/([0-9]\d*(\.[0-9]\d*)*)/,
    Safari: /Version\/([0-9]\d*(\.[0-9]\d*)*) Safari/,
    Chrome: /Chrome\/([0-9]\d*(\.[0-9]\d*)*) /,
  }

  for (const browserName in browsers) {
    const regex = browsers[browserName]
    const match = regex.exec(userAgent)
    if (match) {
      const version = match[1]?.replace(/_/g, '.')
      return {
        name: browserName,
        version: version,
        major: version?.split('.')[0],
      }
    }
  }
  return { name: undefined, version: undefined, major: undefined }
}

/**
 * getOS parses the os information provided by navigator.userAgent in the browser
 * @returns an object of type OSData
 */
export const getOS = (): OSData => parseUserAgentOS()

/**
 * getOSVersion retrieves os version from navigator.userAgent
 * @returns os version
 */
export const getOSVersion = (): string | undefined => getOS().version

/**
 * getOSVersionMajor retrieves os major version from navigator.userAgent
 * @returns os major version
 */
export const getOSVersionMajor = (): string | undefined => getOSVersion()?.split('.')[0]

/**
 * getOSName retrieves os name from navigator.userAgent
 * @returns os name
 */
export const getOSName = (): string | undefined => getOS().name

/**
 * getBrowser parses the browser information provided by navigator.userAgent in the browser
 * @returns an object of type BrowserVersion
 */
export const getBrowser = (): BrowserVersion => parseUserAgentBrowser()

/**
 * getBrowserName retrieves browser name from the browser information provided by navigator.userAgent
 * @returns browser name
 */
export const getBrowserName = () => getBrowser().name

/**
 * getBrowserVersion retrieves browser version from the browser information provided by navigator.userAgent
 * @returns browser version
 */
export const getBrowserVersion = () => getBrowser().version

/**
 * @returns a boolean indicating if the user is using an iPhone
 */
export const isIPhoneUser = (): boolean => {
  const userAgent = getNavigatorUserAgent()
  return /iPhone/i.test(userAgent)
}
/**
 * @returns a boolean indicating if the user is using an Android device
 */
export const isAndroidUser = (): boolean => {
  const userAgent = getNavigatorUserAgent()
  return /Android/i.test(userAgent)
}
/**
 * @returns a boolean indicating if the user is using a mobile device
 */
export const isMobileUser = (): boolean => isIPhoneUser() || isAndroidUser()

export const getMobileOSDownloadInfo = () => {
  if (isIPhoneUser()) {
    return iPhoneDownloadInfo
  }
  return androidDownloadInfo
}
