import { getFeatureFlag } from './shell-helpers'

const MS_TEAMS_FF_PREFIX = 'goto-shell-ms-teams-'

const basicIntegrationConfig = {
  shouldLoadOnMobile: false,
  shouldLoadInIntegration: true,
  shouldLoadInCompanion: false,
}

export const createIntegrationConfigIfFlagEnabled = (
  flagSuffix: string,
  integrationConfig = basicIntegrationConfig,
) => {
  const extensionIntegrationFeatureFlag = MS_TEAMS_FF_PREFIX + flagSuffix
  if (getFeatureFlag(extensionIntegrationFeatureFlag, false)) {
    return integrationConfig
  }
  return undefined
}
