import type { AppProps, Application, LifeCycles } from 'single-spa'
import { getDocument } from '../../common/dom-helpers'

export type LifeCyclePromise = (config: AppProps) => Promise<LifeCycles<AppProps>>
const mountedAppsByGroup = new Map<string, string[]>()

const addMountedAppToGroup = (applicationIdentifier: string, groupIdentifier: string) => {
  const apps = mountedAppsByGroup.get(groupIdentifier) ?? []
  apps.push(applicationIdentifier)
  mountedAppsByGroup.set(groupIdentifier, apps)
}

const removeMountedAppFromGroup = (applicationIdentifier: string, groupIdentifier: string) => {
  const apps = mountedAppsByGroup.get(groupIdentifier) ?? []
  const index = apps.indexOf(applicationIdentifier)
  if (index > -1) {
    apps.splice(index, 1)
  }
  mountedAppsByGroup.set(groupIdentifier, apps)
}

const createMountWrapper =
  (lifecycles: LifeCycles, applicationIdentifier: string, groupIdentifier: string, beforeMount?: () => Promise<void>) =>
    async (props: AppProps) => {
      if (beforeMount) {
        await beforeMount()
      }
      addMountedAppToGroup(applicationIdentifier, groupIdentifier)
      const { mount } = lifecycles
      if (Array.isArray(mount)) {
        Promise.all([mount.map(m => m(props))])
      } else {
        mount(props)
      }
    }

const createUnmountWrapper =
  (lifecycles: LifeCycles, applicationIdentifier: string, groupIdentifier: string) => async (props: AppProps) => {
    removeMountedAppFromGroup(applicationIdentifier, groupIdentifier)
    const { unmount } = lifecycles
    if (Array.isArray(unmount)) {
      Promise.all([unmount.map(m => m(props))])
    } else {
      unmount(props)
    }
  }

export const isFunction = (functionToCheck: unknown): functionToCheck is CallableFunction =>
  typeof functionToCheck === 'function'

export const createGroupLifeCycleWrapper =
  (
    app: Application,
    applicationIdentifier: string,
    groupIdentifier: string,
    beforeMount?: () => Promise<void>,
  ): LifeCyclePromise =>
    async (config: AppProps) => {
      if (isFunction(app)) {
        return await app(config).then(lifecycle => ({
          ...lifecycle,
          mount: createMountWrapper(lifecycle, applicationIdentifier, groupIdentifier, beforeMount),
          unmount: createUnmountWrapper(lifecycle, applicationIdentifier, groupIdentifier),
        }))
      }
      return {
        ...app,
        mount: createMountWrapper(app, applicationIdentifier, groupIdentifier, beforeMount),
        unmount: createUnmountWrapper(app, applicationIdentifier, groupIdentifier),
      }
    }

export const createElementGetter = (selector: string) => {
  let elementBySelector: HTMLElement | null
  return () => {
    elementBySelector = getDocument().querySelector(selector) ?? elementBySelector
    return elementBySelector
  }
}
