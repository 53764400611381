import { eventEmitter, getEventBus } from '../namespaces/event-bus'

export const NavigationNamespace = 'NavigationNamespace'

export const NavigationEvents = {
  linksUpdated: eventEmitter<void>(),
}

const { emit } = getEventBus().register(NavigationNamespace, NavigationEvents)

export const emitLinksUpdated = () => {
  emit.linksUpdated()
}
