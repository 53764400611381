import { html } from 'lit-element'
import { nothing } from 'lit-html'
import { repeat } from 'lit-html/directives/repeat'
import { nanoid } from 'nanoid'

import { getEventBus } from '../../../services/namespaces/event-bus'
import type {
  SnackbarNotification,
  SnackbarNotificationAction,
  DisplayNotificationEvents,
} from '../../../services/display-notification'
import { DisplayNotificationsNamespace } from '../../../services/display-notification'
import { AbstractNotificationLitElement } from '../model'

import styles from './snackbar-notification.styles.scss'

const clearTimeoutFor = (data?: SnackbarNotification): number => {
  let cancelled = 0
  if (data) {
    for (const pleaseClearTimeout of data.timeoutCancelers ?? []) {
      ++cancelled
      pleaseClearTimeout()
    }
    data.timeoutCancelers = void 0
  }
  return cancelled
}

export class GoToSnackbarNotification extends AbstractNotificationLitElement<SnackbarNotification> {
  static readonly tagName = 'goto-snackbar-notification'
  static styles = styles

  protected setupEventHandlers(): void {
    const eventBus = getEventBus()
    const { snackbar, removeSnackbar, updateSnackbarProgress } = eventBus.subscribeTo<
      typeof DisplayNotificationsNamespace,
      typeof DisplayNotificationEvents
    >(DisplayNotificationsNamespace)

    snackbar.on(this.addNotification)
    removeSnackbar.on(this.removeNotification)
    updateSnackbarProgress.on(this.updateProgress)

    this.unsubscribeFunctions.push(() => {
      snackbar.removeListener(this.addNotification)
      removeSnackbar.removeListener(this.removeNotification)
      updateSnackbarProgress.removeListener(this.updateProgress)
    })

    this.unsubscribeFunctions.push(() => {
      this.notifications.forEach(n => clearTimeoutFor(n))
      this.notifications = []
    })
  }

  protected createNotificationPredicate(subject: SnackbarNotification) {
    const { id: needle } = subject
    return (item: SnackbarNotification) => {
      const { id } = item
      return needle === id
    }
  }

  protected addNotification = (snackbarNotification: SnackbarNotification): void => {
    // snackbarNotification is an object that can be shared between many windows
    // To avoid collision and override between windows this object is copied
    const data = { ...snackbarNotification }
    // The issue with this is that that nanoid() would be different between tabs/windows
    data.id = data.id ?? nanoid()

    const NO_PROGRESS = -1
    const { progress = NO_PROGRESS } = data
    const hasProgress = progress !== NO_PROGRESS
    if (!data.timeoutCancelers) {
      data.timeoutCancelers = []
    }
    // Automatically remove the snackbar after 8 seconds if has no progress
    if (!hasProgress) {
      // This might not properly catch when something started with no progress
      // and then gets a progress
      const timeoutId = this.ownerDocument?.defaultView?.setTimeout(() => {
        this.removeNotification(data)
      }, 8000)
      // Caveat: timeoutId is not the same between realms, and this makes all realms to use that same
      // So, to cancel the removing, we keep a list of clearTimeout that are bound to this notification
      if (data.timeoutCancelers) {
        const clearTimeoutBoundFn = this.ownerDocument?.defaultView?.clearTimeout
        if (timeoutId && clearTimeoutBoundFn) {
          data.timeoutCancelers.push(() => {
            clearTimeoutBoundFn(timeoutId)
          })
        }
      }
    }

    super.addNotification(data)
  }

  protected removeNotification = (data: SnackbarNotification): void => {
    const getNotificationIndex = this.createNotificationPredicate(data)
    const index = this.notifications.findIndex(getNotificationIndex)
    if (index >= 0) {
      this.notifications.splice(index, 1)
      this.requestUpdate()
    }
  }

  private updateProgress = (data: SnackbarNotification): void => {
    const getNotificationIndex = this.createNotificationPredicate(data)
    const index = this.notifications.findIndex(getNotificationIndex)
    const notification = this.notifications[index] ?? ({} as SnackbarNotification)
    const progress = data.progress ?? -1

    // If it has been removed, yet we want to update
    if (this.notifications[index]) {
      if (progress > 99) {
        this.notifications[index].progress = 100
        clearTimeoutFor(this.notifications[index])
        this.removeNotification(notification)
      } else if (Number.isFinite(progress)) {
        clearTimeoutFor(this.notifications[index])
        this.notifications[index].progress = progress
        super.requestUpdate('notifications', notification)
      }
    }
  }

  private readonly renderButton = ({ label, handler }: SnackbarNotificationAction) =>
    html`<chameleon-button variant="primary" size="small" @click=${handler}> ${label} </chameleon-button>`

  render() {
    const notification = this.notifications[0]
    if (!notification) {
      return nothing
    }
    const NO_PROGRESS = -1

    const { title, message, actions = [] } = notification ?? {}
    const progress = notification.progress ?? NO_PROGRESS
    const hasProgress = progress !== NO_PROGRESS

    return html`<chameleon-snackbar role="alert">
      <h2 slot="title">${title}</h2>
      <p slot="message">${message}</p>
      ${hasProgress
        ? html`<chameleon-progress-indicator
            progress="${progress}"
            slot="progress"
            inverse
          ></chameleon-progress-indicator>`
        : nothing}
      <div slot="action">${repeat(actions, action => action.label, this.renderButton)}</div>
    </chameleon-snackbar>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-snackbar-notification': GoToSnackbarNotification
  }
}
