import { getMountedExperiencesFromStatusEvent } from '../../common/single-spa-debug'
import { assertIsSingleSpaCustomEvent, SINGLE_SPA_APP_CHANGE } from '../../common/single-spa-events'

const isBannerAppMounted = (mountedApps: readonly string[]) => mountedApps.indexOf('goto/banner-notification') !== -1

/**
 * Takes a callback, and calls the CB once the banner experience has mounted.
 * This is to avoid a race condition where a banner tries to display before the banner experience is mounted.
 */
export const displayBannerWhenReady = (callback: () => void) => {
  const displayBannerIfBannerMounted = (event: Event) => {
    assertIsSingleSpaCustomEvent(event)

    const mountedApps = getMountedExperiencesFromStatusEvent(event)

    if (isBannerAppMounted(mountedApps)) {
      callback()
      // banner displayed so clean up the event handler we no longer need
      window.removeEventListener(SINGLE_SPA_APP_CHANGE, displayBannerIfBannerMounted)
    }
  }
  window.addEventListener(SINGLE_SPA_APP_CHANGE, displayBannerIfBannerMounted, { once: true })
}
