import { getShellApiInstance } from '../../common/shell-api-helpers'
import { authenticatedFetch } from '../auth'
import { PbxAvailableFeatureFlags, PbxFeatureFlagStatus } from './models'
import type { PbxFeatureFlagsCollection, PbxFeatureFlags, PhoneSystemAPI } from './models'
import { getJiveApiBaseUrl } from '../../core/environment'
import { getShellLogger } from '../../common/logger'

let cachedFeatureFlags: PbxFeatureFlagsCollection | undefined = undefined

export const isMeetingsFlagEnabledInContextPBX = async () =>
  await isFlagEnabledInContextPBX(PbxAvailableFeatureFlags.MEETINGS)

export const isStandardFlagEnabledInContextPBX = async () =>
  await isFlagEnabledInContextPBX(PbxAvailableFeatureFlags.STANDARD)

export const isResolveFlagEnabledInContextPBX = async () =>
  await isFlagEnabledInContextPBX(PbxAvailableFeatureFlags.RESOLVE)

/**
 * Checks if the pbx feature flag is enabled
 * @param featureFlag name of the pbx feature flag to check
 * @returns true if the feature flag is enabled, false otherwise
 */
export const isFlagEnabledInContextPBX = async (featureFlag: string) => {
  const context = getShellApiInstance().context
  const pbxId = context.pbx?.id

  if (!pbxId) {
    return false
  }
  const fetchedFlag = await getPbxFeatureFlagValue(pbxId, featureFlag)
  return fetchedFlag === PbxFeatureFlagStatus.ENABLED
}

/**
 * Retrieves the feature flags for a PBX.
 * @param pbxId - The ID of the PBX.
 * @returns A Promise that resolves to the feature flags object for the PBX.
 */
export const fetchPbxFlags = async (pbxId: string): Promise<PbxFeatureFlags | undefined> => {
  const pbxFeatureFlagsUrl = `${getJiveApiBaseUrl()}/features/v1/${pbxId}`
  const headers = {
    headers: { accept: 'application/json' },
    credentials: undefined,
  }
  try {
    const response = await authenticatedFetch<PbxFeatureFlags>(pbxFeatureFlagsUrl, headers)
    const featureFlags = await response.json()

    cachedFeatureFlags = Object.assign(cachedFeatureFlags ?? {}, { [pbxId]: featureFlags })

    return featureFlags
  } catch (error) {
    getShellLogger().error(`error in API call for ${pbxFeatureFlagsUrl}`, error)
    return undefined
  }
}

/**
 * Retrieves the cached feature flags for a specific PBX ID.
 *
 * @param pbxId - The ID of the PBX.
 * @returns The cached feature flags for the specified PBX ID, or `undefined` if not found.
 */
export const getFromCachedFeatureFlags = (pbxId: string): PbxFeatureFlags | undefined =>
  cachedFeatureFlags && cachedFeatureFlags[pbxId] ? cachedFeatureFlags[pbxId] : undefined

/**
 * Retrieves the feature flags for a PBX.
 * If the feature flags are available in the cache, they will be returned.
 * Otherwise, the feature flags will be fetched from the server.
 *
 * @param pbxId - The ID of the PBX.
 * @returns A promise that resolves to the feature flags for the PBX.
 */
export const getPbxFeatureFlags = async (pbxId: string): Promise<PbxFeatureFlags | undefined> =>
  getFromCachedFeatureFlags(pbxId) ?? (await fetchPbxFlags(pbxId))

/**
 * Retrieves the value of a specific feature flag for a PBX.
 * @param pbxId - The ID of the PBX.
 * @param featureFlag - The name of the feature flag.
 * @returns A promise that resolves to the value of the feature flag.
 */
export const getPbxFeatureFlagValue = async (
  pbxId: string,
  featureFlag: string,
): Promise<PbxFeatureFlagStatus | undefined> => {
  const flags = await getPbxFeatureFlags(pbxId)
  return flags?.[featureFlag]
}

/**
 * The phone system API object.
 */
export const phoneSystemAPI: PhoneSystemAPI = {
  getPbxFeatureFlags,
  getPbxFeatureFlagValue,
}
