/**
 * @param min number
 * @param max number
 * @returns return random int between "min" and "max" where "max" is exclusive and "min" is inclusive
 */
export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}
