import { getFeatureFlagValue } from '../services/feature-flags'
import { FeatureFlagsVariations } from '../services/feature-flags/models'
import { getShellLogger } from './logger'

const head = document.querySelector(`head`)

const STYLE_NODE = 'STYLE'
const LINK_NODE = 'LINK'
const OLD_SHELL_MAIN_CSS_ID = 'main-css'
const SHELL_MAIN_CSS_ID = 'shell-main-css'
const OLD_CHAMELEON_THEME_CSS_ID = 'chameleon-theme-skin-style'

const observerConfig = {
  attributes: true,
  childList: true,
  characterData: true,
}

/**
 * Added a mutation observer to detect when a <style> tag is added the in order to always add shell's style as the last style applied.
 * This is to make sure that the shell's styles are not overwritten by an experience styles
 */
export const keepShellStyleSheetsLastInNodeList = () => {
  const SHELL_CHAMELEON_STYLE_IDS = [OLD_SHELL_MAIN_CSS_ID, SHELL_MAIN_CSS_ID, OLD_CHAMELEON_THEME_CSS_ID]
  const observer = new MutationObserver(mutations => {
    let shouldRearrangeStyles = false
    const shellStyleSheets = Array.from(head?.querySelectorAll(`#${SHELL_MAIN_CSS_ID}`)?.values() ?? [])

    const nodesToRemove = mutations.reduce((frameworkStyleNodes: Element[], mutation) => {
      const addedNodes = Array.from(mutation.addedNodes)
      addedNodes.forEach(node => {
        const nodeId = (node as Element).id
        const isFrameworkStyle = node.nodeName === STYLE_NODE && SHELL_CHAMELEON_STYLE_IDS.includes(nodeId)
        if (
          isFrameworkStyle &&
          ((nodeId === SHELL_MAIN_CSS_ID && shellStyleSheets.length > 1) || nodeId === OLD_SHELL_MAIN_CSS_ID || nodeId === OLD_CHAMELEON_THEME_CSS_ID)
        ) {
          frameworkStyleNodes.push(node as Element)
        }
      })
      return frameworkStyleNodes
    }, [])

    shouldRearrangeStyles = mutations.some(mutation => {
      const addedNodes = Array.from(mutation.addedNodes)
      return addedNodes.some(node => {
        const isNodeStyleOrLink = node.nodeName === STYLE_NODE || node.nodeName === LINK_NODE
        const isNodeInShellStylesArray = isNodeinStyleArray(node, shellStyleSheets)

        return isNodeStyleOrLink && !isNodeInShellStylesArray
      })
    })
    if (shouldRearrangeStyles) {
      // Then append shell styles
      shellStyleSheets.forEach(styleSheet => head?.appendChild(styleSheet))

      shouldRearrangeStyles = false
    }

    if (nodesToRemove.length) {
      const nodeIds = nodesToRemove.map(node => node.id).join(';')
      if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_REMOVE_FRAMEWORK_CSS)) {
        nodesToRemove.forEach(node => node.remove())
        getShellLogger().log('shell undesired css nodes removed:', nodeIds)
      } else {
        getShellLogger().log('shell undesired css nodes:', nodeIds)
      }
    }
  })

  if (head) {
    observer.observe(head, observerConfig)
  }
}

const isNodeinStyleArray = (node: Node, styleElementArray: Element[]) =>
  styleElementArray.some(styleElement => node === styleElement)
