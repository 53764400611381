import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import type { PresenceUpdated, UserPresenceUpdated } from './models'

export const PresenceServiceNamespace = 'PresenceService'

export const PresenceEvents = {
  presenceUpdate: eventEmitter<PresenceUpdated>(),
  userPresenceUpdate: eventEmitter<UserPresenceUpdated>(),
} as const

const { emit } = getEventBus().register(PresenceServiceNamespace, PresenceEvents)

export const presenceUpdate = (payload: PresenceUpdated) => {
  emit.presenceUpdate(payload)
}

export const userPresenceUpdate = (payload: UserPresenceUpdated) => {
  emit.userPresenceUpdate(payload)
}
