import { getShellApiInstance } from '../../common/shell-api-helpers'
import { isMainWindow } from '../container/windows-management'
import i18n from 'i18next'
import { resolveTranslatable } from '../../common/translate-helpers/translatable'
import type { Translatable } from '../../common/translate-helpers/types'

/**
 * Returns the i18n property of the main shell's instance
 * @returns the i18n property of the shell's instance of the app's main window
 */
export const getI18n = () => (isMainWindow() ? i18n : getShellApiInstance().i18n.instance)

/**
 * Returns a translated string and
 * marks a string to be parsed by the i18next parser (at scripts/i18next-parser.config.js)  to create i18n.json files
 * To be used in banners or snackbars or code that will not be "directly" rendered but will be rendered through a command (event bus, shell api)
 *
 * @param key string to be translated
 * @param interpolation interpolation values
 * @returns the translated string
 */
export const getTranslation = (translatable: Translatable, interpolation?: Record<string, unknown>) => {
  const translation =
    typeof translatable === 'string' ? getI18n().t(translatable, interpolation) : resolveTranslatable(translatable)
  return translation
}
