//@TODO will be removed by https://jira.ops.expertcity.com/browse/SCORE-1094

import { isExperienceLoaderOptions } from '../../../common/loaders'
import type { GoToExperienceOverrideConfig, GoToExtensionOverrideConfig } from './models'

/* istanbul ignore next -> this is only a schema object so the functions are never called */
const schemaExperience: GoToExperienceOverrideConfig = {
  name: '',
  app: { lifecycles: { id: '', url: '' }, cssUrls: [], jsUrls: [] },
}

const schemaExtension: GoToExtensionOverrideConfig = {
  id: '',
  url: '',
}

type WithAllValidKeys<T> = { readonly [Property in keyof T]: T[Property] }
/**
 *
 * @param schema default schema of the root keys Type predicate to generate
 * @returns
 */
const createKeysValidator = <T extends object>(schema: T) => {
  const keys = Object.keys(schema) as unknown as readonly (keyof T)[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value: unknown): value is WithAllValidKeys<T> => keys.every(key => key in (value as any))
}

/**
 * Validate the GoToExperienceOverrideConfig type
 */
export const isGoToExperienceOverrideConfig = (experience: unknown): experience is GoToExperienceOverrideConfig => {
  if (!experience || typeof experience !== 'object') {
    return false
  }
  const isValidKeysForGotoExperienceOverrideConfig = createKeysValidator(schemaExperience)
  return isValidKeysForGotoExperienceOverrideConfig(experience) && !!isExperienceLoaderOptions(experience.app)
}

/**
 * Validate the GoToExtensionOverrideConfig type
 */
export const isGoToExtensionOverrideConfig = (extension: unknown): extension is GoToExtensionOverrideConfig => {
  if (!extension || typeof extension !== 'object') {
    return false
  }
  const keysValidator = createKeysValidator(schemaExtension)
  return keysValidator(extension)
}

/**
 * Validate the configs type
 */
export function validatedConfigs<T>(
  configs: unknown,
  validator: (object: unknown) => object is T,
): readonly T[] | null {
  const validConfigsOverride = Array.isArray(configs) ? configs.filter(validator) : null
  return validConfigsOverride
}
