import { getShellLogger } from '../../common/logger'
import { html, state } from 'lit-element'
import { nothing } from 'lit-html'
import userNoteInputStyles from './user-note-input.styles.scss'
import { SVG_MESSAGE_DOTS_OUTLINED, SVG_CLOSE_CIRCLE_FILLED } from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { t } from '../../directives/translate'
import { noop } from '../../common'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import type { PresenceEvents, PresenceUpdated, UserPresenceUpdated } from '../../services/presence'
import { PresenceServiceNamespace } from '../../services/presence'
import type { Listener } from '../../services/namespaces'
import { ifDefined } from 'lit-html/directives/if-defined'
import { ShellElement } from '../../common/shell-element'

export class GoToUserNoteInput extends ShellElement {
  static readonly tagName = 'goto-user-note-input'
  private unsubscribePresenceUpdate = noop

  @state() private userNoteInput: string | undefined

  static get styles() {
    return userNoteInputStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.listenForPresenceUpdatedEvent()
  }

  disconnectedCallback() {
    this.unsubscribePresenceUpdate()
    this.unsubscribePresenceUpdate = noop
    super.disconnectedCallback()
  }

  private listenForPresenceUpdatedEvent() {
    const { eventBus } = getShellApiInstance()
    const { userPresenceUpdate } = eventBus.subscribeTo<typeof PresenceServiceNamespace, typeof PresenceEvents>(
      PresenceServiceNamespace,
    )

    const presenceUpdatedHandler: Listener<PresenceUpdated> = (payload: UserPresenceUpdated) => {
      const { userNote } = payload
      this.userNoteInput = userNote
    }

    userPresenceUpdate.on(presenceUpdatedHandler)

    this.unsubscribeFunctions.push(() => {
      userPresenceUpdate.removeListener(presenceUpdatedHandler)
    })
  }

  private renderClearUserNoteIcon() {
    return this.userNoteInput
      ? html`
          <span class="clear-user-note-icon" slot="end" @click=${this.clearUserNote} @keyup=${this.clearUserNote}>
            <chameleon-svg id="clear-user-note">${unsafeSVG(SVG_CLOSE_CIRCLE_FILLED)}</chameleon-svg>
            <chameleon-tooltip-v3 label="${t('Clear status')}" position="top-center" trigger-id="clear-user-note">
              ${t('Clear status')}
            </chameleon-tooltip-v3>
          </span>
        `
      : nothing
  }

  private async clearUserNote(event: CustomEvent) {
    event.stopPropagation()
    const { presence } = getShellApiInstance()
    const userNote = ''
    try {
      await presence?.setUserPresence({ userNote })
    } catch (err) {
      getShellLogger().error(`Couldn't update userNote: ${err}`)
    }
  }

  private getUserNoteInputTemplate(slot?: string) {
    return html`<chameleon-text-field
      readonly
      fieldsize="small"
      id="user-note-input"
      placeholder=${t('Set a status message')}
      value=${ifDefined(this.userNoteInput)}
      .fullwidth=${true}
      slot=${ifDefined(slot)}
    >
      <chameleon-svg class="chat-dots-icon" slot="start">${unsafeSVG(SVG_MESSAGE_DOTS_OUTLINED)}</chameleon-svg>
      ${this.renderClearUserNoteIcon()}${t('Status message')}
    </chameleon-text-field>`
  }

  private getChameleonTooltipTemplate() {
    return html`
      ${this.getUserNoteInputTemplate()}
      <chameleon-tooltip-v3 delay="0" position="bottom-center" trigger-id="user-note-input">
        ${this.userNoteInput}
      </chameleon-tooltip-v3>
    `
  }

  render() {
    return html`${this.userNoteInput ? this.getChameleonTooltipTemplate() : this.getUserNoteInputTemplate()}`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-user-note-input': GoToUserNoteInput
  }
}
