import type { ValueResolver } from './name-rule'
import { NameRule } from './name-rule'
import { NotRule } from './not-rule'
import type { LogicalOperator } from './logical-operator-rule'
import { LogicalOperatorRule } from './logical-operator-rule'

export interface Rule {
  isValid(): boolean
}

export interface RulePrivate {
  getNames(): readonly string[]
}

export const isRuleValid = (rule: string, valueResolver: ValueResolver): boolean =>
  parseRule(rule, valueResolver).isValid()

export const getRuleNames = (rule: string): readonly string[] =>
  (parseRule(rule, () => '') as unknown as RulePrivate).getNames()

export const parseRule = (str: string, valueResolver: ValueResolver): Rule => {
  const groupRegex = /\(([^)(]+)\)/g
  const groupRules: Rule[] = []

  const hasGroup = (str: string) => groupRegex.test(str)
  const replaceGroupWithRule = (str: string) =>
    str.replace(groupRegex, (_a: unknown, content: string) => {
      const index = groupRules.push(internalParseRule(content.trim(), groupRules, valueResolver)) - 1
      return `%%rule-${index}%%`
    })
  let rulesString = str
  while (hasGroup(rulesString)) {
    rulesString = replaceGroupWithRule(rulesString).trim()
  }
  if (rulesString.indexOf('(') >= 0 || rulesString.indexOf(')') >= 0) {
    console.debug(rulesString)
    throw new Error(`Error with group balancing for: ${str}`)
  }
  return internalParseRule(rulesString, groupRules, valueResolver)
}

const internalParseRule = (str: string, groupRules: readonly Rule[], valueResolver: ValueResolver): Rule => {
  const logicalOperatorRuleRegex = /^([^|&]*)(\|\||&&)(.*)$/gm
  const logicalOperatorRuleParts = logicalOperatorRuleRegex.exec(str)
  if (logicalOperatorRuleParts) {
    const left = logicalOperatorRuleParts[1].trim()
    const operator = logicalOperatorRuleParts[2].trim()
    const right = logicalOperatorRuleParts[3].trim()
    return new LogicalOperatorRule(
      internalParseRule(left, groupRules, valueResolver),
      internalParseRule(right, groupRules, valueResolver),
      operator as LogicalOperator,
    )
  }
  if (str.startsWith('!')) {
    return new NotRule(internalParseRule(str.substring(1), groupRules, valueResolver))
  }
  const isRuleIndexRegex = /^%%rule-([0-9]*)%%$/gm
  const isRuleIndex = isRuleIndexRegex.exec(str)
  if (isRuleIndex?.[1]) {
    return groupRules[+isRuleIndex[1]]
  }
  return NameRule.createFromString(str, valueResolver)
}
