import type { getEventBus } from '../namespaces'
import type { CalendarAPI } from './public-api'
import type { getCalendarConnector } from './cis-connector'
import { CalendarService } from './calendar-service'

let _calendarAPI: ReturnType<typeof getCalendarAPI>
let _calendarService: CalendarService

/**
 * Gets a reference to the CalendarAPI
 * @param calendarConnector the service which handles the HTTP calls to the CIS
 * @param externalUserKey the calendar owner's unique identitifer
 * @param eventBus reference to the Event Bus for communicating with the Shell and other experiences
 * @returns CalendarAPI
 */
export const getCalendarAPI = (
  calendarConnector: ReturnType<typeof getCalendarConnector>,
  externalUserKey: string,
  eventBus: ReturnType<typeof getEventBus>,
): CalendarAPI => {
  if (!_calendarAPI) {
    createCalendarAPI(calendarConnector, externalUserKey, eventBus)
  }

  return _calendarAPI
}

const createCalendarAPI = (...args: Parameters<typeof getCalendarAPI>) => {
  const calendarService = getCalendarService(...args)

  _calendarAPI = {
    isConnected: calendarService.isConnected.bind(calendarService),
    getEvents: calendarService.getEvents.bind(calendarService),
    navigateToCalendarSettings: calendarService.navigateToCalendarSettings.bind(calendarService),
    getConnectedServiceType: calendarService.getConnectedServiceType.bind(calendarService),
  }
}

const getCalendarService = (...args: Parameters<typeof getCalendarAPI>) => {
  if (!_calendarService) {
    _calendarService = new CalendarService(...args)
  }

  return _calendarService
}
