export type BusyState = (typeof BUSY_STATE)[number]

const BUSY_STATE = [
  /**
   * The user CAN be interrupted
   */
  'idle',
  /**
   * The user should not be interrupted
   */
  'busy',
  /**
   * The user will be prompted by before being interrupted.
   */
  'ask',
] as const

export const BUSY_STATES = Object.freeze(BUSY_STATE)

export interface AppStateService {
  /**
   * Returns the application's can reload state. All of the entity BusyState values are reduced to a single BusyState for the entire GotoApp
   *
   * If no entity states are present, then return 'idle'
   * If all entity states are 'idle', then return 'idle
   * If all entity states are either 'idle' or 'ask', then return 'ask'
   * If one or more entity states are 'busy', then return 'busy'
   * @returns BusyState
   */
  getAppState(): BusyState

  /**
   * Adds or updates a BusyState for a single checkpoint.
   *
   * Triggers an emission of the new global state on the event bus.
   *
   * @throws AssertionError if the given state is not a valid SafeToReloadApp
   */
  setCheckPointState(checkPointName: string, state: BusyState): void
}
