import { GOTO_NAVIGATION_SELECTOR } from '../../common/container'
import { getDocument } from '../../common/dom-helpers'
import { GoToNavRail } from '../../packages/navigation/navigation-rail/navigation-rail'

export class SidebarService {
  private sidebarElement: GoToNavRail | undefined
  sidebarContainerSelector = GOTO_NAVIGATION_SELECTOR

  createSidebarElement() {
    return getDocument().createElement(GoToNavRail.tagName)
  }

  show() {
    if (!this.sidebarElement) {
      this.sidebarElement = this.createSidebarElement()
    }
    const sidebarContainer = getDocument().querySelector(this.sidebarContainerSelector)
    if (this.sidebarElement?.parentElement !== sidebarContainer) {
      sidebarContainer?.appendChild(this.sidebarElement)
    }
  }

  hide() {
    if (this.sidebarElement?.parentElement) {
      this.sidebarElement?.remove()
    }
  }

  isVisible() {
    return !!this.sidebarElement && this.sidebarElement.parentNode !== null
  }
}
