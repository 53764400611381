import {
  wrappedAuthenticatedFetch,
  wrappedAuthenticatedFetchWithRetries,
  wrappedGetToken,
  wrappedIsLoggedIn,
  wrappedLogin,
  wrappedLogout,
  wrappedRefresh,
} from './wrapper'

export const shellAuthApi = {
  getToken: wrappedGetToken,
  isLoggedIn: wrappedIsLoggedIn,
  authenticatedFetch: wrappedAuthenticatedFetch,
  authenticatedFetchWithRetries: wrappedAuthenticatedFetchWithRetries,
  login: wrappedLogin,
  logout: wrappedLogout,
  refresh: wrappedRefresh,
}

export { auth, logout, login, loginOnce, isAuthenticationRequired } from './authentication'
export { isLoggedIn } from './serverIsLoggedIn'
export { getToken, setToken, setRefreshToken } from './token'
export { authenticatedFetch, authenticatedFetchWithRetries } from './authenticatedFetch'
export { refreshAuthentication } from './refresh'
export type { ShellAuthApi } from './models'
