/* istanbul ignore file */
import { html } from 'lit-html'
import type { SingleSpaLitProps } from '../../common/single-spa-lit'
import { singleSpaLit } from '../../common/single-spa-lit'
import { GOTO_CONTENT_ID } from '../../common/container'

const lifecycles = singleSpaLit<SingleSpaLitProps>({
  template: html`<goto-terms-of-service></goto-terms-of-service>`,
  domElement: GOTO_CONTENT_ID,
})

export default lifecycles
