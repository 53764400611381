import type { SingleSpaCustomEventDetail } from 'single-spa'

export const SINGLE_SPA_BEFORE_MOUNT_ROUTING_EVENT = 'single-spa:before-mount-routing-event'
export const SINGLE_SPA_APP_CHANGE = 'single-spa:app-change'
export const SINGLE_SPA_ROUTING_EVENT = 'single-spa:routing-event'
export const SINGLE_SPA_BEFORE_ROUTING_EVENT = 'single-spa:before-routing-event'

export function assertIsSingleSpaCustomEvent(event: Event): asserts event is CustomEvent<SingleSpaCustomEventDetail> {
  if (!(event instanceof CustomEvent && !!event.detail.appsByNewStatus)) {
    throw new Error('incorrect type')
  }
}

export type RoutingListener<T> = (evt: Event | CustomEvent<SingleSpaCustomEventDetail>, options?: T) => void
