import { authenticatedFetch } from '../auth'
import { getShellLogger } from '../../common/logger'
import { type OrganizationInfo } from '../context/models'
import { getGtcBffOrganizationInformationUrl } from '../gtc-bff/gtc-bff'

export const fetchOrganizationInfo = async (organizationId: string) => {
  const gtcBffOrganizationInfoUrl = getGtcBffOrganizationInformationUrl(organizationId)
  const headers = {
    headers: { accept: 'application/json' },
  }
  try {
    const response = await authenticatedFetch<OrganizationInfo>(gtcBffOrganizationInfoUrl, headers)
    return await response.json()
  } catch (error) {
    getShellLogger().error(`Unable to fetch pbx info thru ${gtcBffOrganizationInfoUrl}`, error)
  }
}
