import { UNAUTHENTICATED_ERROR } from '../common/routes'

abstract class RedirectError extends Error {
  abstract url: string
  constructor(message: string) {
    super(message)
    this.name = 'RedirectError'
  }
}

export class UnauthenticatedError extends RedirectError {
  public url: string
  constructor(message: string) {
    super(message)
    this.name = 'UnauthenticatedError'
    this.url = UNAUTHENTICATED_ERROR
  }
}
