import { SVG_DOWNLOAD_OUTLINED } from '@getgo/chameleon-icons'
import { html, LitElement, property } from 'lit-element'
import updateIconStyles from './update-icon.styles.scss'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'

export class GoToUpdateIcon extends LitElement {
  static readonly tagName = 'goto-update-icon'
  @property({ type: Number }) updateAge = 0

  static get styles() {
    return updateIconStyles
  }

  render() {
    let color = 'green-icon'
    if (this.updateAge > 7) {
      color = 'red-icon'
    } else if (this.updateAge > 4) {
      color = 'yellow-icon'
    }

    return html`<div class=${color}><chameleon-svg>${unsafeSVG(SVG_DOWNLOAD_OUTLINED)}</chameleon-svg></div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-update-icon': GoToUpdateIcon
  }
}
