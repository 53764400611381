import { UpdateNamespace, type UpdateEvents } from '../update'
import { quitAndInstall } from '../update/helpers'
import { type ExternalInterface } from './external-interface'

import { type CompanionMessagePayload } from './messages/companion-update'
import { sendMessageUpdateAvailable } from './external-interface-companion-update-helper'
import { type UpdateInfo } from '@getgo/container-client'
import { getEventBus } from '../namespaces/event-bus'

let updateAvailableForCompanion: UpdateInfo | undefined = undefined

export const externalInterfaceCompanionUpdateDispatcher = (externalInterface: ExternalInterface) => {
  const handleCompanionUpdate = (payload: CompanionMessagePayload) => {
    if (payload.topic === 'install-update') {
      quitAndInstall()
    } else if (payload.topic === 'get-update-status') {
      if (updateAvailableForCompanion) {
        sendMessageUpdateAvailable(updateAvailableForCompanion)
      }
    }
  }

  if (externalInterface.isCompanion) {
    externalInterface.addCallback('companion-update', handleCompanionUpdate)
    const { updateAvailable } = getEventBus().subscribeTo<typeof UpdateNamespace, typeof UpdateEvents>(UpdateNamespace)

    updateAvailable.on(updateInfo => {
      updateAvailableForCompanion = updateInfo
      sendMessageUpdateAvailable(updateInfo)
    })
  }
}
