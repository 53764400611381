import { getShellLogger } from '../../common/logger'
import { getAppStateService } from '../app-state/public-facade'
import {
  getHasSeenReloadFlagManager,
  isIdleOrGetUserResponse,
  waitForIdleOrGetUserResponse,
} from './can-reload-helpers'

/**
 * Resolves to true when the BusyState is true or prompt the user for a decision
 * if the state is 'ask' and resolve their decision.
 *
 * We only prompt the user for a decision once.
 */
export const canReloadApplication = (): Promise<boolean> => {
  if (getHasSeenReloadFlagManager().get()) {
    return Promise.resolve(false)
  }

  try {
    const busyState = getAppStateService().getAppState()
    return busyState === 'busy' ? waitForIdleOrGetUserResponse() : isIdleOrGetUserResponse(busyState)
  } catch (e) {
    /**
     * This function could get called before the the Shell API is loaded, which causes getAppStateService
     * to throw since it relies on other parts of the app which have a dependency on the Shell API.
     * Assume it's safe to reload if this happens since the user would not have had time to do anything.
     */
    getShellLogger().error('failed to get busy state', e)
    return Promise.resolve(true)
  }
}
