import type { shellEvents } from './../../../../services/shell-namespace/index'
import { ShellNamespace } from './../../../../services/shell-namespace/index'
import { getEventBus } from './../../../../services/namespaces/event-bus'
import { html, property } from 'lit-element'
import { nothing } from 'lit-html'
import { repeat } from 'lit-html/directives/repeat'
// for some unknown reasons this fails on the CI but not locally
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { InternalSpecificSettingLink } from '../../settings-models'
import type { InternalGeneralSettingLink } from '../../settings-models'
import { renderIcon, renderMenuItem } from '../../settings-helpers'
import { resolveTranslatable } from '../../../../common/translate-helpers/translatable'
import { ShellElement } from '../../../../common/shell-element'
import specificSettingsAccordionStyles from './specific-settings-accordion.styles.scss'

export class GoToSpecificSettingsAccordion extends ShellElement {
  static readonly tagName = 'goto-specific-settings-accordion'

  @property({ type: Object })
  link!: InternalSpecificSettingLink

  static get styles() {
    return specificSettingsAccordionStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.subscribeToRoutingChanges()
  }

  private subscribeToRoutingChanges(): void {
    const { routeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    routeChanged.on(this.rerenderSpecificSettings)
    this.unsubscribeFunctions.push(() => routeChanged.removeListener(this.rerenderSpecificSettings))
  }

  private readonly rerenderSpecificSettings = () => {
    this.requestUpdate()
  }

  render() {
    return this.link?.accordionName
      ? html`
          <chameleon-accordion role="menuitem">
            <chameleon-accordion-item fullwidth title=${resolveTranslatable(this.link.accordionName)}>
              <chameleon-typography class="accordion-item-heading" slot="heading">
                ${resolveTranslatable(this.link.accordionName)}
              </chameleon-typography>
              <chameleon-svg slot="start">${renderIcon(this.link.settingsIcon, false)}</chameleon-svg>
              ${repeat(
                this.link.settingsLinksInfo,
                linkInfo => resolveTranslatable(linkInfo.name, { raw: true }),
                ({ name, href }) =>
                  renderMenuItem(
                    {
                      name,
                      href,
                    } as InternalGeneralSettingLink,
                    this.link?.accordionName,
                  ),
              )}
            </chameleon-accordion-item>
          </chameleon-accordion>
        `
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-specific-settings-accordion': GoToSpecificSettingsAccordion
  }
}
