import { externalInterfaceOpenIntegrationWithRelUrl } from '../external-interface/external-interface-navigation'
import { type NavigationService } from './navigation-service'
import { type NavigationAPI } from './public-api'

/** Adapter of the navigation service which is used when running in the companion app. */
export class NavigationServiceCompanionAdapter implements NavigationAPI {
  constructor(private readonly navigationService: NavigationService) {}

  navigateMainWindowToUrl(relativeUrl: string) {
    this.navigationService.navigateMainWindowToUrl(relativeUrl)
    
    // When running in the companion app, we mirror navigations in the main window to the integration side.
    externalInterfaceOpenIntegrationWithRelUrl(relativeUrl)
  }
}
