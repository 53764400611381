import { DesktopCompanionConnectionStatus, type DesktopCompanionStatusChangedEvent } from '@getgo/container-client'
import { type ExternalInterface } from './external-interface'
import { getToken, login } from '../auth'

export const externalInterfaceCompanionConnectionHandler = (externalInterface: ExternalInterface) => {
  const handleRequestAuthToken = () => {
    const token = getToken()

    if (token) {
      externalInterface.send({ type: 'auth-token', payload: token })
    } else {
      login()
    }
  }

  const handleConnectionChanged = (e: DesktopCompanionStatusChangedEvent) => {
    if (e.connectionStatus === DesktopCompanionConnectionStatus.disconnected) {
      externalInterface.connect()
    }
  }

  if (externalInterface.isCompanion) {
    externalInterface.addCallback('request-auth-token', handleRequestAuthToken)
    externalInterface.addConnectionCallback(handleConnectionChanged)
    externalInterface.connect()
  }
}
