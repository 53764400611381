import { Presence } from '@getgo/chameleon-core'
import { BadgeStatus, Container, Plugins } from '@getgo/container-client'
import { onWindowUnload } from '../../common/helpers/window'
import { getShellLogger } from '../../common/logger'
import type { Listener, EventBus } from '../../services/namespaces'
import type { IShellPresenceService, PresenceEvents, PresenceUpdated } from '../../services/presence'
import { PresenceServiceNamespace } from '../../services/presence'
import { getShellApiInstance } from '../../common/shell-api-helpers'

export const initializeDockPresenceIndicator = () => {
  const { eventBus, presence, user } = getShellApiInstance()

  if (user.key && Container.isPluginAvailable('Badge')) {
    Plugins.Badge.setBadgeStatus(BadgeStatus.Initial)
    listenForPresenceUpdatedEvent(eventBus, user.key)
    subscribeToPresence(user.key, presence)
  }
}

const listenForPresenceUpdatedEvent = (eventBus: EventBus, userId: string) => {
  const { presenceUpdate } = eventBus.subscribeTo<typeof PresenceServiceNamespace, typeof PresenceEvents>(
    PresenceServiceNamespace,
  )

  const presenceUpdatedHandler: Listener<PresenceUpdated> = payload => {
    if (payload.externalUserKey === userId) {
      // TODO handle undefined presence https://jira.ops.expertcity.com/browse/SCAPI-469
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const badgeStatus = convertPresenceToBadgeStatus(payload.presence!)
      Plugins.Badge.setBadgeStatus(badgeStatus)
    }
  }

  presenceUpdate.on(presenceUpdatedHandler)

  onWindowUnload(() => {
    presenceUpdate.removeListener(presenceUpdatedHandler)
  })
}

const subscribeToPresence = (userId: string, presence?: IShellPresenceService) => {
  if (presence) {
    presence.subscribe(userId).catch(() => {
      getShellLogger().error(`failed to subscribe to presence for external user key ${userId}`)
    })
  }
}

const convertPresenceToBadgeStatus = (presence: Presence): BadgeStatus => {
  switch (presence) {
    case Presence.AVAILABLE:
      return BadgeStatus.Available
    case Presence.BUSY:
      return BadgeStatus.Busy
    case Presence.DND:
      return BadgeStatus.DoNotDisturb
    case Presence.AWAY:
      return BadgeStatus.Away
    case Presence.OFFLINE:
      return BadgeStatus.Offline
    case Presence.DISCONNECTED:
      return BadgeStatus.Disconnected
    default:
      getShellLogger().debug(`The ${presence} status is not mapped to any BadgeStatus. Setting the badge to offline.`)
      return BadgeStatus.Offline
  }
}
