import { getShellLogger } from '../../common/logger'
import { TERMS_OF_SERVICE_ROUTE } from '../../common/routes'
import { getFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { environment, LocalStorageKeys } from '../../environments'
import { authenticatedFetch } from '../auth'
import { navigateToUrl } from '../../common/helpers'

export interface CaPSResponse {
  readonly tcAgreement: boolean
}

export const TOS_PREVIOUS_URL_KEY = 'TOS_PREVIOUS_URL'

export const acceptTermsOfService = async () => {
  try {
    await authenticatedFetch(`${environment().caPSBaseUrl}/rest/v1/members/me/tcagreement`, {
      method: 'POST',
    })
  } catch (e) {
    getShellLogger().error('acceptTermsOfService failed', e)
  }
}

export const hasAcceptedTermsOfService = async () => {
  try {
    const response = await authenticatedFetch<CaPSResponse>(`${environment().caPSBaseUrl}/rest/v3/members/me`)
    const tcAgreement = (await response.json())?.tcAgreement
    if (tcAgreement) {
      return true
    }
    const hasAcceptedTermsOfServiceInGTC = !!getFromLocalStorage(LocalStorageKeys.hasAcceptedTermsOfServiceInGTC)
    if (hasAcceptedTermsOfServiceInGTC) {
      acceptTermsOfService()
      return true
    }
  } catch (e) {
    getShellLogger().error('hasAcceptedTermsOfService failed', e)
    return true // TODO SCAPI-770 Change this line to false and manage what to do on acceptance of TOS.
  }
  return false
}

/**
 * This is used to check if the user needs to be redirected to the terms of service page. The user needs to to accept them
 * in order to access the app but some pages (like the login page) should still be acessible.
 *
 * @param defaultRoute
 */
export const shouldNavigateToTos = async (): Promise<boolean> =>
  !!globalThis.shell.user.key && !(await hasAcceptedTermsOfService())

export const handleTosRedirection = async (redirectRoute?: string) => {
  if (!redirectRoute) {
    return
  }

  if (await shouldNavigateToTos()) {
    setToLocalStorage(TOS_PREVIOUS_URL_KEY, redirectRoute)
    navigateToUrl(TERMS_OF_SERVICE_ROUTE)
  } else {
    navigateToUrl(redirectRoute)
  }
}
