import { SHELL_APP_PREFIX } from '../constants'

import GoToBannerNotification from '../../packages/notifications/banner-notifications/goto-banner-notification'
import GoToModalNotification from '../../packages/notifications/modal-notifications/goto-modal-notification'
import GoToSnackbarNotification from '../../packages/notifications/snackbar-notifications/goto-snackbar-notification'
import type { RegisterApplicationConfig } from 'single-spa'

export const gotoBannerNotificationsConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}/banner-notification`,
  app: GoToBannerNotification,
  activeWhen: /* istanbul ignore next not useful for unit test */ () => true,
}

export const gotoSnackbarNotificationsConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}/snackbar-notification`,
  app: GoToSnackbarNotification,
  activeWhen: /* istanbul ignore next not useful for unit test */ () => true,
}

export const gotoModalNotificationsConfig: RegisterApplicationConfig = {
  name: `${SHELL_APP_PREFIX}/modal-notification`,
  app: GoToModalNotification,
  activeWhen: /* istanbul ignore next not useful for unit test */ () => true,
}
