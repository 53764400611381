import type { KeyboardShortcutId } from '@getgo/container-client'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { isMac } from '../../services/container/helpers'

export interface HotkeyUIStructureIdConfiguration {
  /**
   * Id from container-client. Each id is bind to a key combination we can subscribe to
   */
  readonly keyboardShortcutId: KeyboardShortcutId
  /**
   * Property to disable the edition of a local key combination
   * local is when a key combination can only be used when the user have focus on a window
   */
  readonly isLocalEditable: boolean
  /**
   * Property to disable the edition of a global key combination
   * Global is when a key combination can be used when the user is with and without the focus on a window
   * This key combination need to be set carefully because it will override other application if they use the same
   * key combination
   */
  readonly isGlobalEditable: boolean
  /**
   * Property to disable global key combination
   * Global is when a key combination can be used when the user is with and without the focus on a window
   * In the UI, it will not be possible to show or assign a global key combination for this keyboard shortcut id
   */
  readonly isGlobalDisabled?: boolean
}

export interface HotkeyUIStructureSection {
  readonly sectionLabelKey: string
  readonly keyboardShortcutIdsConfiguration: ReadonlyArray<HotkeyUIStructureIdConfiguration>
}

/**
 * We only use navigation1 as label in the UI because we want 1 section to combine all the keyboardShortcut ids (1 to 9)
 * returned by Container-client
 */
export const macOsCustomValueByKeyboardShortcutId: Partial<Record<KeyboardShortcutId, string>> = {
  navigation1: '⌘⌥ + 1 to 9',
}
export const windowsCustomValueByKeyboardShortcutId: Partial<Record<KeyboardShortcutId, string>> = {
  navigation1: 'Ctrl + Alt + 1 to 9',
}

export const macOsDefaultUIConfiguration: ReadonlyArray<HotkeyUIStructureSection> = [
  {
    sectionLabelKey: asTranslationKey('Main Shortcuts'),
    // TODO: add missing main shortcuts https://jira.ops.expertcity.com/browse/CONTAINER-1227
    keyboardShortcutIdsConfiguration: [
      { keyboardShortcutId: 'toggleMute', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'leaveMeetingOrHangUpCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'openSoftphone', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'openSettings', isGlobalEditable: true, isLocalEditable: true },
    ],
  },
  {
    sectionLabelKey: asTranslationKey('Call notifications'),
    keyboardShortcutIdsConfiguration: [
      { keyboardShortcutId: 'answerCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'declineCall', isGlobalEditable: true, isLocalEditable: true },
    ],
  },
  {
    sectionLabelKey: asTranslationKey('Navigation'),
    keyboardShortcutIdsConfiguration: [
      {
        keyboardShortcutId: 'navigation1',
        isGlobalEditable: false,
        isLocalEditable: false,
        isGlobalDisabled: true,
      },
    ],
  },
  // TODO: add meetings section https://jira.ops.expertcity.com/browse/CONTAINER-1228
  {
    sectionLabelKey: asTranslationKey('Phone'),
    keyboardShortcutIdsConfiguration: [
      { keyboardShortcutId: 'holdCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'transferCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'addParticipants', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'parkCall', isGlobalEditable: true, isLocalEditable: true },
    ],
  },
]

export const windowsDefaultUIConfiguration: ReadonlyArray<HotkeyUIStructureSection> = [
  {
    sectionLabelKey: asTranslationKey('Main Shortcuts'),
    // TODO: add missing main shortcuts https://jira.ops.expertcity.com/browse/CONTAINER-1227
    keyboardShortcutIdsConfiguration: [
      { keyboardShortcutId: 'toggleMute', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'leaveMeetingOrHangUpCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'openSoftphone', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'openSettings', isGlobalEditable: true, isLocalEditable: true },
    ],
  },
  {
    sectionLabelKey: asTranslationKey('Call notifications'),
    keyboardShortcutIdsConfiguration: [
      { keyboardShortcutId: 'answerCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'declineCall', isGlobalEditable: true, isLocalEditable: true },
    ],
  },
  {
    sectionLabelKey: asTranslationKey('Navigation'),
    keyboardShortcutIdsConfiguration: [
      {
        keyboardShortcutId: 'navigation1',
        isGlobalEditable: false,
        isLocalEditable: false,
        isGlobalDisabled: true,
      },
    ],
  },
  // TODO: add meetings section https://jira.ops.expertcity.com/browse/CONTAINER-1228
  {
    sectionLabelKey: asTranslationKey('Phone'),
    keyboardShortcutIdsConfiguration: [
      { keyboardShortcutId: 'holdCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'transferCall', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'addParticipants', isGlobalEditable: true, isLocalEditable: true },
      { keyboardShortcutId: 'parkCall', isGlobalEditable: true, isLocalEditable: true },
    ],
  },
]

export const KeyboardShortcutUIStructureProvider = {
  get: (): ReadonlyArray<HotkeyUIStructureSection> =>
    isMac() ? macOsDefaultUIConfiguration : windowsDefaultUIConfiguration,
  getCustomValue: (): Partial<Record<KeyboardShortcutId, string>> =>
    isMac() ? macOsCustomValueByKeyboardShortcutId : windowsCustomValueByKeyboardShortcutId,
}
