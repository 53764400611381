import { getEventBus, eventEmitter } from '../namespaces/event-bus'

export const ShellNamespace = 'Shell'

export class AsyncEvent extends Event {
  constructor(type: string, eventInitDict?: EventInit) {
    super(type, eventInitDict)
  }

  private readonly promises: Promise<void>[] = []

  /**
   * Add a promise to the list of promises to be settled before the allSettled promise resolves
   * @param promise
   */
  waitFor(promise: Promise<void>) {
    this.promises.push(promise)
  }

  /**
   * A promise that resolves when all of the `waitFor` promises have settled (resolved/rejected)
   */
  async allSettled() {
    await Promise.allSettled(this.promises)
  }
}

/**
 * Event that is emitted over the event bus when a browser reload is requested
 */
export class ShellReloadEvent extends AsyncEvent {
  constructor() {
    super('shellReloadEvent', { cancelable: true })
  }
}

export type Theme = 'light' | 'dark' | 'system' | 'high-contrast'

export interface ShellAppChangedEventsPayload {
  readonly from: readonly string[]
  readonly to: readonly string[]
}

export interface ShellExtensionReadyEventPayload {
  readonly extensionId: string
}

export const shellEvents = {
  /**
   * event to be emitted when a browser reload is required.
   */
  reloadPage: eventEmitter<ShellReloadEvent>(),

  /**
   * appChanged will be fired every time a mounted extension change
   * ex: appChanged({
   *   from: ['experience1']
   *   to: ['experience2']
   * })
   */
  appChanged: eventEmitter<ShellAppChangedEventsPayload>(),

  /**
   * routeChanged event will be emitted every time the app routing changes.
   */
  routeChanged: eventEmitter<void>(),

  /**
   * themeChanged event will be emitted every time the theme changes.
   * ex: themeChanged('light')
   **/
  themeChanged: eventEmitter<Theme>(),

  /**
   * The extensionReady event will be emitted when an extension is initalized.
   * We pass the extensionId to the event's payload.
   */
  extensionReady: eventEmitter<ShellExtensionReadyEventPayload>(),

  /**
   * The applicationReady event will be emitted when all extensions have settled their initialization process.
   */
  applicationReady: eventEmitter<void>(),

  /**
   * The logout event will be emitted when the user logs out.
   */
  onLogout: eventEmitter<void>(),
}
const { emit } = getEventBus().register<typeof shellEvents>(ShellNamespace, shellEvents)

export const emitAppChangedEvent = (payload: ShellAppChangedEventsPayload) => {
  emit.appChanged(payload)
}

export function emitThemeChanged(theme: Theme) {
  emit.themeChanged(theme)
}

export const emitRouteChangedEvent = () => emit.routeChanged()

export const emitExtensionReadyEvent = (payload: ShellExtensionReadyEventPayload) => emit.extensionReady(payload)

export const emitApplicationReadyEvent = () => emit.applicationReady()

export const emitOnLogoutEvent = () => emit.onLogout()
