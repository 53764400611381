import { getToken } from './token'
import { refreshAuthentication } from './refresh'
import { HttpResponseError } from '../../http-utils'
import type { RetryableRequestInit } from '../../common/fetch-utils'
import { fetchRetry, retry } from '../../common/fetch-utils'
import type { Token } from '@getgo/auth-client'
import { RateLimiterMap } from '../../common/rate-limiter-map'

const AUTHENTICATION_TIMEOUT = 30 * 1000 // 30 seconds

const rateLimiter = new RateLimiterMap('authenticatedFetch', 10, 3000)

export interface TypedResponse<T = unknown> extends Response {
  /**
   * this will override `json` method from `Body` that is extended by `Response`
   * interface Body {
   *     json(): Promise<any>;
   * }
   */
  json<P = T>(): Promise<P>
}

/** Rate-limit the authenticated fetches per URL and HTTP method. We have found cases where an extension can essentially
 * get into an infinite loop of making requests to the same URL (due to some bad state or a bug). The rate limiter will
 * log a line if a rate limit is reached, which we can use to determine how frequent or how serious this issue is.
 */
const waitForRateLimiter = async (input: RequestInfo, init?: RetryableRequestInit) => {

  const calcMethod = (input: RequestInfo, init?: RetryableRequestInit) => {
    // Determining the method is a bit tricky, but the order is the following.
    if (init?.method) {
      return init.method
    }
    if (typeof input === 'string') {
      return 'GET'
    }
    return input.method
  }
  const url = typeof input === 'string' ? input : input.url

  // Construct the key to be `<HTTP METHOD> <URL>`.
  const key = `${calcMethod(input, init)} ${url}`
  await rateLimiter.waitUntilAllowed(key)
  rateLimiter.recordRequest(key)
}

export const retryGetToken = async () => {
  const maxNbTries = 2
  let token = getToken()
  let nbOfTries = 0

  while (!token && nbOfTries < maxNbTries) {
    nbOfTries++
    // Reminder: calling authenticatedFetch before the token is set will resolve the promise
    // to undefined, and not the expected typed object
    await refreshAuthentication()
    token = getToken()
  }
  return token
}

const fetchAuth = async (token: Token, input: RequestInfo, init?: RetryableRequestInit): Promise<Response> => {
  const { token_type, access_token } = token
  const headers = new Headers(init?.headers)
  headers.set('Authorization', `${token_type} ${access_token}`)
  return await fetchRetry(input, {
    credentials: 'include',
    ...init,
    headers,
  })
}

export const authenticatedFetch = async <T = unknown>(
  input: RequestInfo,
  init?: RetryableRequestInit,
): Promise<TypedResponse<T>> => {
  await waitForRateLimiter(input, init)

  const token = await retryGetToken()
  if (!token?.access_token || !token.token_type) {
    throw new Error('Could not fetch the token')
  }

  const timeoutInMS = init?.timeoutInMS ?? AUTHENTICATION_TIMEOUT

  const response = await fetchAuth(token, input, { ...init, timeoutInMS })
  const { body, status, statusText } = response
  // Authenticated Fetch failed, so redirect to login
  if (status === 401) {
    await refreshAuthentication()
  } else if (status >= 400) {
    throw new HttpResponseError({
      message: `${status}: ${statusText}`,
      response,
      status,
      statusText,
      body,
    })
  }
  return response
}

/**
 * Returns a response based on authenticated fetch with randomized delays with a baseline.
 * This is to avoid DDOSing then back-end when we are trying to reconnect to the websocket.
 *
 * @param input - The request info, usually containing the info for the websocket
 * @param init - The request initiation that for fetch retries
 * @param retryInterval - The baseline interval, default is 250. This interval is randomized and added to the baseline
 * @param maxRetry The maximum number of times the fetch is attempted, default is 2.
 * @returns The response or throws an error if the number of retries runs out
 */

export const authenticatedFetchWithRetries = async <T = unknown>(
  input: RequestInfo,
  init?: RetryableRequestInit,
  retryInterval = 250,
  maxRetry = 2,
): Promise<TypedResponse<T>> =>
  await retry(() => authenticatedFetch(input, init), {
    maxRetryNumber: maxRetry,
    retryAfterInMilliseconds: retryInterval,
    randomized: true,
    exponential: true,
  })
