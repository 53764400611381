import { eventEmitter } from '../namespaces/event-bus'
import type { BusyState } from './models'

export const AppStateNamespace = 'AppState'

export const AppStateEvents = {
  /**
   * Emitted when the global BusyState value has changed
   */
  appStateChanged: eventEmitter<BusyState>(),
} as const
