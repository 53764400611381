import { SVG_PAPERCLIP_OUTLINED } from '@getgo/chameleon-icons'
import { html, LitElement, property, query } from 'lit-element'
import { t } from '../../directives/translate'
import { FILE_PICKER_CHANGE_EVENT } from './file-picker-event'
import filePickerStyles from './file-picker.styles.scss'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'

export class GoToFilePicker extends LitElement {
  static readonly tagName = 'goto-file-picker'

  private selectedFiles: File[] = []
  @property({ type: String }) fileTypes = ''
  @property({ type: Boolean }) disabled = false
  @query('input') nativeFileInput!: HTMLInputElement

  static get styles() {
    return filePickerStyles
  }

  private selectedFilesHandler(event: Event) {
    const fileList = (event.target as HTMLInputElement).files
    if (fileList) {
      this.selectedFiles = Array.from(fileList)
      this.dispatchEvent(new CustomEvent(FILE_PICKER_CHANGE_EVENT, { detail: { selectedFiles: this.selectedFiles } }))
    }
  }

  private handleFilePickerClick(event: Event) {
    // This will allow the change event to dispatch if a file is picked twice in a row
    // Temp fix, a proper solution will be tackled here https://jira.ops.expertcity.com/browse/SCORE-1821
    ;(event.target as HTMLInputElement).value = ''
  }

  private attachFileClickHandler() {
    this.nativeFileInput.click()
  }

  render() {
    return html`
      <input
        type="file"
        class="input-file"
        accept=${this.fileTypes}
        @change=${this.selectedFilesHandler}
        @click=${this.handleFilePickerClick}
      />
      <chameleon-button
        variant="neutral"
        class="file-upload-button"
        @click=${this.attachFileClickHandler}
        ?disabled=${this.disabled}
      >
        <chameleon-svg slot="start">${unsafeSVG(SVG_PAPERCLIP_OUTLINED)}</chameleon-svg>${t('Attach a file')}
      </chameleon-button>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-file-picker': GoToFilePicker
  }
}
