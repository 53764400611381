/**
 * Assertion function which ensures that whatever condition is being checked must be true for
 * the remainder of the containing scope.
 *
 * One use for this is checking if an optional value is truthy and having TS know it's truthy in the
 * entire scope without resorting to using clunky if statements
 *
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
 */
export function assert(condition: unknown, msg?: string | Error): asserts condition {
  if (!condition) {
    throw new AssertionError(msg)
  }
}

export class AssertionError extends Error {
  constructor(msg?: string | Error) {
    super(msg instanceof Error ? msg.message : `${msg ?? ''}`)
    this.name = 'AssertionError'

    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AssertionError.prototype)
  }
}

/**
 * Helper function which prevents TS from compiling since nothing is compatible with the never typed argument.
 *
 * Helpful when you want to check that all if/else paths or switch cases are handled
 *
 * @example
 * ```
 *  const exampleFn = (eventName: 'collapsed' | 'remove') => {
 *   switch (eventName) {
 *     case 'collapsed':
 *       doSomething()
 *       break
 *     default:
 *       assertUnreachable(eventName)
 *   }
 * }
 * ```
 * The above code would not type check since the 'remove' case is unhandled, and 'remove' is not compatible to the never typed argument.
 */
/* istanbul ignore next TS will not type check if any argument is given so this would prevent the tests from running */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here")
}

/**
 * a function which takes an unknown object and returns true if it matches an expected shape, false if not
 */
export type ObjectValidator = (a: unknown) => boolean

/**
 * Generic assertion function which casts the given object to the given type argument
 * for the current scope if the validator function returns true
 *
 * @param maybeExpectedObject the object to be asserted
 * @param validator function against which the expected object is validated
 */
export function assertIsExpectedObject<T>(
  maybeExpectedObject: unknown,
  validator: ObjectValidator,
): asserts maybeExpectedObject is T {
  assert(validator(maybeExpectedObject), 'Object does not have the expected shape')
}
