import '@getgo/chameleon-web/components/registerAll'
import { MenuSectionComponent } from '@getgo/chameleon-web/components/Menu/MenuSection/MenuSection'
import '@getgo/chameleon-web/components/Svg/register'

/*
  Temporary until fixed by Chameleon

  * Font-family: Chameleon-theme-provider sets the font family but some elements are under body but not under the theme provider.
                This fix make sure all children of `body` use the default font-family
  * Background-color: When we resize the window very quick, the render process can create issue where it uses the body background color
                before the theme provider color.
 */
window.document.body.style.fontFamily = 'var(--goto-font-family)'
window.document.body.style.backgroundColor = 'var(--goto-background-color-default)'

if (!customElements.get('chameleon-menu-section')) {
  customElements.define('chameleon-menu-section', MenuSectionComponent)
}

import { getShellLogger, GotoError } from '@goto/shell'
import Shell from '@goto/shell/loader'
import { attemptAppReloadWhenOnline, setupGtcExtensionPolyfillsAndServices } from './helpers/gtc-helpers'
import { isError } from './helpers/logging-helpers'
import { loadEnvironment } from './load-environment'
import { version, chameleonWebVersion, chameleonCoreVersion } from './version'

import extensionConfigs from './extension-configs'

// Temporary chameleon-svg-v-next patch - To be removed after the next deploy
class SvgWrapper extends HTMLElement {
  constructor() {
    super()

    const shadow = this.attachShadow({ mode: 'open' })
    const svg = document.createElement('chameleon-svg')
    const slot = document.createElement('slot')
    slot.onslotchange = () => {
      const icon = this.textContent?.trim() ?? ''
      if (icon) {
        this.textContent = ''
        svg.innerHTML = icon
      }
    }
    shadow.appendChild(slot)
    shadow.appendChild(svg)
  }
}
customElements.define('chameleon-svg-v-next', SvgWrapper)

const NAVIGATION_ITEM_ORDER = [
  '@call', // either @call (jiveweb) or @goto/voice-main' (goto-voice) will be shown depending on a feature flag.
  '@goto/voice-main',
  'inbox',
  '@messaging',
  'oos-meeting-app',
  '@meetings',
  'engagements',

  '@goto/contacts-ui-extension',
  '@contacts',
  '@contact',

  'contact-center',
  'oos-training-app',
  'oos-webinar-app',
  'analytics',
]

// temp fix unlocking clients from errored cached route
const currentRoute = localStorage.getItem('goto-current-route')
if (currentRoute === '/errored') {
  localStorage.removeItem('goto-current-route')
}

loadEnvironment().then(async environment => {
  try {
    // Load chameleon with the current version of chameleon-core
    Shell.addChameleonStyles(chameleonCoreVersion)
    // This function is an exception around GTC extension
    // Due to the legacy specific to Jiveweb a helper is used to load polyfills and services for GTC
    // This should not be reproduce for other extensions
    // throw 'error'
    await setupGtcExtensionPolyfillsAndServices()
    Shell.addExtensions(extensionConfigs)
    Shell.setNavigationOrder(NAVIGATION_ITEM_ORDER)
    Shell.setMeta({ hostAppVersion: version, chameleonWebVersion })
    await Shell.start({ environment })
  } catch (e) {
    getShellLogger().error(
      `shell encountered an error when starting: ${e}`,
      isError(e) ? [e.name, e.message, e.stack] : undefined,
    )

    // register the custom element in case shell failed to do so
    if (!window.customElements.get(GotoError.tagName)) {
      window.customElements.define(GotoError.tagName, GotoError)
    }

    window.document.body.innerHTML = `
    <chameleon-theme-provider skin="gotoapp" theme="light" rebranding2021="true">
        <${GotoError.tagName} style="display: flex; height: 100%"></${GotoError.tagName}>
    </chameleon-theme-provider>
    `
    attemptAppReloadWhenOnline()
    // rethrow the error so we continue to see these errors in the console
    throw e
  }
})
