import { type AccountEvents, AccountNamespace } from '../services/account'
import { type AccountAndPbxInfo } from '../services/account/models'
import { removeAccountAndPBXFromLocalStorage, setAccountAndPBXToLocalStorage } from '../services/context'
import { getUserKey } from '../services/external-interface/utils'
import { getEventBus } from '../services/namespaces'
import { reloadPage } from './dom-helpers'
import { onWindowUnload } from './helpers/window'

export const subscribeToAccountSwitch = () => {
  const handleAccountSwitchBegin = (accountSwitchBeginPayload: AccountAndPbxInfo) => {
    setAccountAndPBXToLocalStorage(accountSwitchBeginPayload)
  }

  const handleAccountSwitchCompleted = () => {
    // Voice reloads after 100ms, we should wait a bit longer to allow for that reload to occur first
    setTimeout(() => {
      getUserKey().then(userKey => {
        removeAccountAndPBXFromLocalStorage(userKey)
        reloadPage()
      })
    }, 150)
  }

  const { accountSwitchBegin, accountSwitchCompleted } = getEventBus().subscribeTo<
    typeof AccountNamespace,
    typeof AccountEvents
  >(AccountNamespace)

  accountSwitchBegin.on(handleAccountSwitchBegin)
  accountSwitchCompleted.on(handleAccountSwitchCompleted)

  onWindowUnload(() => {
    accountSwitchBegin.removeListener(handleAccountSwitchBegin)
    accountSwitchCompleted.removeListener(handleAccountSwitchCompleted)
  })
}
