/* istanbul ignore file */
import { html } from 'lit-element'
import { singleSpaLit } from '../../../common'
import { GOTO_MODAL_NOTIFICATION_SELECTOR } from '../../../common/container'

const lifecycles = singleSpaLit({
  template: () => html`<goto-modal-notification></goto-modal-notification>`,
  domElement: GOTO_MODAL_NOTIFICATION_SELECTOR,
})

export default lifecycles
