import { isMainWindow } from '../../services/container'
import { doLoadKeyboardShortcutConfiguration } from '../../services/keyboard-shortcut/keyboard-shortcut'
import { subscribeToKeyboardShortcutConfigurationChange } from '../../services/keyboard-shortcut/keyboard-shortcut-events'
import type { ShellHook } from './models'
import { Plugins } from '@getgo/container-client'
import { getShellLogger } from '../../common/logger'
import { navigateToUrl } from '../../common'
import { SHELL_SETTINGS_ROUTE } from '../../common/routes'
import { onWindowUnload } from '../../common/helpers/window'

export const initializeKeyboardShortcuts: ShellHook = async () => {
  subscribeToKeyboardShortcutConfigurationChange()
  Plugins.KeyboardShortcuts.systemInterface.initializeLogger(getShellLogger().create('keyboardShortcuts'))
  if (isMainWindow()) {
    await doLoadKeyboardShortcutConfiguration()
  }
}

/**
 * Subscribe the Settings link with the KeyboardShortcuts plugins only for the Main window
 */
export const subscribeSettingsLinkWithKeyboardShortcuts: ShellHook = async () => {
  if (isMainWindow()) {
    const unsubscribe = await Plugins.KeyboardShortcuts.subscribe('openSettings', window, () => {
      navigateToUrl(`${SHELL_SETTINGS_ROUTE}`)
    })

    onWindowUnload(async () => {
      await unsubscribe()
    })
  }
}
