/**
 * This is a decorator that measures the time a function takes to complete.
 * The measurement can later be accessed with performance.getEventByName(description)
 * @description The name of the measurement.
 */
export const performanceAsyncWrapper =
  <T>(description: string, originalMethod: () => Promise<T>) =>
  async () => {
    const markName = `${description} start`
    performance.mark(markName)

    const result = await originalMethod()

    performance.measure(description, markName)

    return result
  }
