import { html, LitElement } from 'lit-element'
import hotkeySettingsStyles from './keyboard-shortcut-settings.styles.scss'
import hotkeySettingsContainerStyles from './keyboard-shortcut-settings-container.styles.scss'
import { t } from '../../directives/translate'
import type { HotkeySection, HotkeyAction } from './keyboard-shortcut.models'
import { repeat } from 'lit-html/directives/repeat'
import {
  getKeyboardShortcutConfiguration,
  KeyCombinationValidator,
} from '../../services/keyboard-shortcut/keyboard-shortcut'
import { KeyboardShortcutUIStructureProvider } from './keyboard-shortcut-UI-structure-provider'
import type { KeyboardShortcutConfig } from '@getgo/container-client'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { KeyboardShortcutIdTranslation } from './keyboard-shortcut-translation'
import { isContainer } from '../../services/container/helpers'

export class GoToKeyboardShortcutSettings extends LitElement {
  static readonly tagName = 'goto-keyboard-shortcut-settings'

  private readonly keyCombinationValidator: KeyCombinationValidator = new KeyCombinationValidator()

  connectedCallback() {
    super.connectedCallback()
    this.keyCombinationValidator.fillListOfUsedCombinationsFromConfig(getKeyboardShortcutConfiguration())
  }

  static get styles() {
    const styles = [hotkeySettingsStyles]
    if (isContainer()) {
      styles.push(hotkeySettingsContainerStyles)
    }
    return styles
  }

  /**
   * @private
   * Extract a list of local and global configuration for a specific keyboard shortcut id
   * Each keyboard shortcut configuration can have none, 1 or more configurations for local and global
   */
  private extractLocalAndGlobalKeyboardShortcutConfigurations(
    keyboardShortcutConfigurationList: ReadonlyArray<KeyboardShortcutConfig> | undefined,
  ): {
    globalKeyBoardConfigurationList: ReadonlyArray<KeyboardShortcutConfig>
    localKeyBoardConfigurationList: ReadonlyArray<KeyboardShortcutConfig>
  } {
    if (keyboardShortcutConfigurationList) {
      const localKeyBoardConfigurationList: Array<KeyboardShortcutConfig> = []
      const globalKeyBoardConfigurationList: Array<KeyboardShortcutConfig> = []
      keyboardShortcutConfigurationList.forEach(keyboardShortcutConfiguration =>
        keyboardShortcutConfiguration.global
          ? globalKeyBoardConfigurationList.push(keyboardShortcutConfiguration)
          : localKeyBoardConfigurationList.push(keyboardShortcutConfiguration),
      )
      return {
        globalKeyBoardConfigurationList,
        localKeyBoardConfigurationList,
      }
    }
    return {
      globalKeyBoardConfigurationList: [],
      localKeyBoardConfigurationList: [],
    }
  }

  private getHotkeySections(): HotkeySection[] {
    const sections: Array<HotkeySection> = []

    const keyboardShortcutConfig = getKeyboardShortcutConfiguration()
    if (!keyboardShortcutConfig) {
      return []
    }

    /**
     * Use the KeyboardShortcut UI structure to extract each keyboard shortcut id with their local and global configurations to display them in the UI
     */
    KeyboardShortcutUIStructureProvider.get().forEach(hotkeyUIStructureSection => {
      const hotkeyAction: Array<HotkeyAction> = hotkeyUIStructureSection.keyboardShortcutIdsConfiguration.map(
        configuration => {
          const { globalKeyBoardConfigurationList, localKeyBoardConfigurationList } =
            this.extractLocalAndGlobalKeyboardShortcutConfigurations(
              keyboardShortcutConfig[configuration.keyboardShortcutId],
            )

          return {
            id: configuration.keyboardShortcutId,
            label: getTranslation(KeyboardShortcutIdTranslation?.[configuration.keyboardShortcutId] ?? ''),
            globalKeyCombination: globalKeyBoardConfigurationList,
            localKeyCombination: localKeyBoardConfigurationList,
            configuration: {
              isGlobalDisabled: !!configuration.isGlobalDisabled,
              isGlobalEditable: configuration.isGlobalEditable,
              isLocalEditable: configuration.isLocalEditable,
            },
          }
        },
      )
      sections.push({
        sectionLabelKey: hotkeyUIStructureSection.sectionLabelKey,
        hotkeyAction,
      })
    })
    return sections
  }

  private getGlobalHotkeyColumnName() {
    return isContainer()
      ? html`<div class="hotkey-settings-column keyboard-shortcut-settings-column">
          <chameleon-typography tag="p" variant="body-medium" color="type-color-secondary"
            >${t('Add a global shortcut')}</chameleon-typography
          >
        </div>`
      : null
  }

  render() {
    return html` <chameleon-typography class="hotkey-settings-header" tag="h1" variant="heading-large"
        >${t('Keyboard shortcuts')}</chameleon-typography
      >
      <chameleon-typography class="header-description" tag="p" variant="body-medium"
        >${t('Use these quick keystrokes to carry out common actions in your daily work')}</chameleon-typography
      >
      <div class="hotkey-settings-section">
        <chameleon-typography class="hotkey-header-description" tag="h2" variant="heading-medium"
          >${t('Assigned shortcuts')}</chameleon-typography
        >
        <chameleon-typography class="header-description" tag="p" variant="body-medium"
          >${t(
            'Edit your keyboard shortcuts to make them more convenient. You can also add global shortcuts that work even when GoTo is in the background.',
          )}</chameleon-typography
        >
        <div class="hotkey-settings">
          ${this.getGlobalHotkeyColumnName()}
          <div class="hotkey-settings-row">
            <chameleon-accordion outlined size="large">
              ${repeat(
                this.getHotkeySections(),
                section => `${section.sectionLabelKey}_section`,
                section =>
                  html`<chameleon-accordion-item chevron-position="right" ?expanded=${true}>
                    <div slot="heading">
                      <chameleon-typography class="hotkey-section-label" tag="h3" variant="heading-small"
                        >${t(section.sectionLabelKey)}</chameleon-typography
                      >
                    </div>
                    <goto-keyboard-shortcut-section
                      class="keyboard-shortcuts-section"
                      .hotkeyActionList=${section.hotkeyAction}
                      .keyCombinationValidator=${this.keyCombinationValidator}
                    ></goto-keyboard-shortcut-section>
                  </chameleon-accordion-item>`,
              )}
            </chameleon-accordion>
          </div>
        </div>
      </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-keyboard-shortcut-settings': GoToKeyboardShortcutSettings
  }
}
